import React from "react";
import { useDragLayer } from "react-dnd";
import ItemTypes from "./GalleryItemTypes";
import GalleryCardsDragPreview from "lunar/gallery/GalleryCardsDragPreview";

const getItemStyles = (currentOffset) => {
  if (!currentOffset) {
    return {
      display: "none",
    };
  }
  const { x, y } = currentOffset;
  return {
    transform: `translate(${x}px, ${y}px)`,
    filter: "drop-shadow(0 2px 12px rgba(0,0,0,0.45))",
  };
};

export default function GalleryCardDragLayer() {
  const { itemType, isDragging, item, currentOffset } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    })
  );

  const renderItem = (type, item) => {
    switch (type) {
      case ItemTypes.CARD:
        return <GalleryCardsDragPreview cards={item.cardsDragStack} />;
      default:
        return null;
    }
  };
  if (!isDragging) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-50 pointer-events-none">
      <div style={getItemStyles(currentOffset)}>
        {renderItem(itemType, item)}
      </div>
    </div>
  );
}
