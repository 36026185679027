import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "mapbox-gl/dist/mapbox-gl.css";
import DynamicMap, { NavigationControl, Marker } from "react-map-gl";
import mapboxgl from "mapbox-gl";

// https://stackoverflow.com/questions/65802002/mapbox-production-error-in-console-uncaught-referenceerror-y-is-not-defined
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

Map.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  initialZoom: PropTypes.number.isRequired,
};

export default function Map({ latitude, longitude, initialZoom }) {
  const [viewport, setViewport] = useState({
    latitude: latitude,
    longitude: longitude,
    zoom: initialZoom,
  });

  const markerSize = 75;
  const markerOffset = -(markerSize / 2);
  const markerStyles = {
    width: markerSize,
    height: markerSize,
  };

  useEffect(() => {
    setViewport({
      latitude: latitude,
      longitude: longitude,
      zoom: initialZoom,
    });
  }, [latitude, longitude, initialZoom]);

  return (
    <>
      <DynamicMap
        latitude={viewport.latitude}
        longitude={viewport.longitude}
        zoom={viewport.zoom}
        onViewportChange={setViewport}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/rteuscher/cksygrnfkxye917p6gsc8qlt7"
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
      >
        <Marker
          latitude={latitude}
          longitude={longitude}
          offsetLeft={markerOffset}
          offsetTop={markerOffset}
        >
          <div
            className="grid bg-pink-500 border border-pink-500 rounded-full bg-opacity-20 place-items-center"
            style={markerStyles}
          >
            <div className="w-3 h-3 bg-pink-500 rounded-full " />
          </div>
        </Marker>
        <NavigationControl className="top-5 right-5" showCompass={false} />
      </DynamicMap>
    </>
  );
}
