import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

InputTextAddon.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helper: PropTypes.string,
  type: PropTypes.string.isRequired,
  errors: PropTypes.object,
  register: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  onBlur: PropTypes.func,
  addonLeft: PropTypes.node,
  addonRight: PropTypes.node,
  className: PropTypes.string,
};

export default function InputTextAddon({
  name,
  label = "",
  helper = "",
  type,
  errors = {},
  register,
  disabled = false,
  placeholder = "",
  maxLength = undefined,
  onBlur = undefined,
  className = undefined,
  addonLeft = "",
  addonRight = "",
}) {
  return (
    <div className={className}>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        {addonLeft && (
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500 pointer-events-none">
            {addonLeft}
          </div>
        )}
        <input
          id={name}
          name={name}
          type={type}
          placeholder={placeholder}
          {...register(name)}
          readOnly={disabled}
          maxLength={maxLength}
          onBlur={onBlur}
          className={cn(
            "mt-1 shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-400",
            { "bg-gray-200 cursor-not-allowed": disabled },
            { "pl-10": addonLeft },
            { "pr-10": addonRight }
          )}
        />
        {addonRight && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500 pointer-events-none">
            {addonRight}
          </div>
        )}
      </div>
      {errors[name] && (
        <p className="mt-2 text-xs text-red-600">{errors[name].message}</p>
      )}
      {helper && <p className="mt-2 text-xs text-gray-500">{helper}</p>}
    </div>
  );
}
