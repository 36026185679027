import React from "react";
import PropTypes from "prop-types";
import TimeAgo from "timeago-react";
import { Link } from "react-router-dom";

BookingChange.propTypes = {
  userName: PropTypes.string,
  change: PropTypes.shape({
    id: PropTypes.number,
    admin_name: PropTypes.string,
    event: PropTypes.string,
    item_tx_number: PropTypes.string,
    item_associated_attributes: PropTypes.shape({
      trip_name: PropTypes.string,
    }),
    created_at: PropTypes.string,
    object_changes: PropTypes.shape({
      thermal_admin_id: PropTypes.array,
      status: PropTypes.array,
    }),
  }),
};

export default function BookingChange({ change, userName }) {
  let stateChange = null;

  if (change.event === "create") {
    if (change.object_changes.thermal_admin_id) {
      stateChange = "created";
    } else {
      stateChange = "requested";
    }
  } else if (change.event === "update") {
    if (change.object_changes.status) {
      stateChange = change.object_changes.status[1].replace(
        "denied",
        "declined"
      );
    }
  }

  if (!stateChange) return <></>;
  return (
    <div className="w-full pt-10 pl-10 pr-10 mr-20">
      <Link to={`/bookings/${change.item_tx_number}`}>
        <div className="flex items-end flex-grow px-8 py-4 border border-gray-100 rounded-full bg-gray-50">
          <div className="flex-grow text-sm font-medium">
            <span>Reservation </span>
            <span className="font-black">{stateChange}</span>
            <span> by {change.admin_name || userName}</span>
          </div>
          <div className="text-sm font-medium text-right text-gray-600 self-right">
            <TimeAgo datetime={change.created_at} />
          </div>
        </div>
      </Link>
    </div>
  );
}
