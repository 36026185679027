import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { updateAdventureBooking } from "lib/api";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import Button from "lunar/Button";
import InputText from "lunar/InputText";

GuidingDays.propTypes = {
  handleError: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  bookingID: PropTypes.string.isRequired,
  days: PropTypes.number,
  halfDays: PropTypes.number,
  quarterDays: PropTypes.number,
};

export default function GuidingDays({
  days = "",
  halfDays = "",
  quarterDays = "",
  bookingID,
  handleError,
  handleSuccess,
}) {
  const schema = Yup.object().shape({});
  const {
    register,
    handleSubmit,
    errors,
    reset,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      num_guiding_days: days,
      num_guiding_half_days: halfDays,
      num_guiding_quarter_days: quarterDays,
    });
  }, [bookingID]);

  async function handleSaveDays(data) {
    const response = await updateAdventureBooking(bookingID, data);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Guiding days updated");
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(handleSaveDays)} noValidate>
        <div>
          <p className="text-sm text-gray-500">
            Number of days of guiding travelers will receive.
          </p>
          <div className="flex flex-row mt-6 justify-center items-center">
            <div className="flex flex-row gap-y-4 gap-x-6">
              <InputText
                name="num_guiding_days"
                type="number"
                label="Full days"
                errors={errors}
                register={register}
              />
              <InputText
                name="num_guiding_half_days"
                type="number"
                label="Half days"
                errors={errors}
                register={register}
              />
              <InputText
                name="num_guiding_quarter_days"
                type="number"
                label="Quarter days"
                errors={errors}
                register={register}
              />
            </div>
            <div className="flex-grow mt-6 ml-6">
              <Button
                variant="secondary"
                type="submit"
                isSubmitting={isSubmitting}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
