import React, { useEffect, useState, useRef } from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";

Nav.propTypes = {
  regions: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
  activeRegionID: PropTypes.string,
};

export default function Nav({ activeRegionID = "", regions, handleClick }) {
  const [value, setValue] = useState("");
  const activeRef = useRef(activeRegionID);

  let countryName = null;
  function sortCountryThenName(a, b) {
    if (a.country.toLowerCase() > b.country.toLowerCase()) return 1;
    if (a.country.toLowerCase() < b.country.toLowerCase()) return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    return 0;
  }

  function countryPanel(name, id) {
    return (
      <div
        key={`region-country-${id}`}
        className="sticky top-0 z-10 px-6 py-2 text-xs font-bold text-gray-600 uppercase bg-gray-100 border-b border-gray-200"
      >
        {name}
      </div>
    );
  }

  function regionPanel(region, country = null) {
    const active = activeRegionID === region.id;

    const panel = (
      <div
        key={`region-${region.id}`}
        onClick={() => handleClick(region.id)}
        className={cn(
          "w-full flex flex-row gap-3 px-6 py-4 text-sm font-semibold border-b cursor-pointer bg-gray-50 overflow-hidden",
          active && "bg-blue-600"
        )}
        ref={active ? activeRef : null}
      >
        <div className="flex-auto">
          <div className={cn("truncate", active ? "text-white" : "")}>
            {region.name}
          </div>
          <div
            className={cn(
              "text-xs font-medium",
              active ? "opacity-50 text-white" : "text-gray-500"
            )}
          >
            {pluralize("trip", region.trips.length, true)}
          </div>
        </div>
        <div className="mt-4">
          <div
            className={`flex-shrink-0 w-2 h-2 rounded-full ${
              region.trips.length > 0 ? "bg-pink-400" : "bg-gray-300"
            }`}
          />
        </div>
      </div>
    );
    const buffer = [panel];
    if (country !== null) buffer.unshift(countryPanel(country, region.id));

    return buffer;
  }

  useEffect(() => {
    if (activeRef.current) {
      activeRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [activeRegionID]);

  return (
    <>
      <div className="px-2 py-3 font-bold border-b border-gray-200 bg-gray-50">
        <div className="flex items-center grid-flow-col">
          <input
            type="text"
            value={value}
            placeholder="Search for regions"
            onChange={(e) => setValue(e.target.value)}
            className="block w-full h-full font-medium text-gray-900 placeholder-gray-500 bg-transparent border-transparent text-md focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent"
          />
          <div>
            <MagnifyingGlassIcon className="w-5 h-5 mr-2 text-gray-500" />
          </div>
        </div>
      </div>
      <div className="overflow-y-auto">
        <div className="relative">
          {regions
            .filter((region) => {
              const country = region.country;
              const name = region.name;
              const countryLowerCase = country.toLocaleLowerCase();
              const nameLowerCase = name.toLocaleLowerCase();
              const hasTrip = region.trips.length > 0 ? "trip" : "";

              if (!value) return true;
              if (
                country.includes(value) ||
                name.includes(value) ||
                countryLowerCase.includes(value) ||
                nameLowerCase.includes(value) ||
                hasTrip.includes(value)
              ) {
                return true;
              }
            })
            .sort(sortCountryThenName)
            .map((region) => {
              if (countryName === null || countryName !== region.country) {
                countryName = region.country;
                return regionPanel(region, countryName);
              } else {
                return regionPanel(region);
              }
            })}
        </div>
      </div>
    </>
  );
}
