import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalBody from "lunar/ModalBody";
import ModalFooter from "lunar/ModalFooter";
import Button from "lunar/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import InputText from "lunar/InputText";
import SelectField from "lunar/SelectField";
import SelectSearch from "lunar/SelectSearch";
import { getDifferenceInDays } from "helpers/formatting";
import { parseISO, addDays, format } from "date-fns";
import { getAdventure, getAdventures } from "lib/api";

EditModal.propTypes = {
  setShowEditModal: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  showEditModal: PropTypes.bool.isRequired,
  handleError: PropTypes.func.isRequired,
  booking: PropTypes.object.isRequired,
};

export default function EditModal({
  showEditModal,
  setShowEditModal,
  handleEdit,
  handleError,
  booking,
}) {
  const schema = Yup.object().shape({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    adventure_id: Yup.string().required("Required"),
  });

  const {
    reset,
    register,
    handleSubmit,
    control,
    errors,
    setValue,
    getValues,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [adventures, setAdventures] = useState([]);
  const [selectedAdventure, setSelectedAdventure] = useState(null);
  const [selectedNumGuests, setSelectedNumGuests] = useState(
    booking.num_guests
  );
  const [accommodationsForAdventure, setAccommodationsForAdventure] = useState(
    []
  );
  const [
    filteredAccommodationsForAdventure,
    setFilteredAccommodationsForAdventure,
  ] = useState([]);

  useEffect(() => {
    (async () => {
      getAdventuresForOptions();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      getAccommodationsForAdventure(booking.adventure_id);
    })();
  }, []);

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current && filteredAccommodationsForAdventure.length > 0) {
      firstUpdate.current = false;
      return;
    }
    // force user to select accommodation if change in adventure or num travelers
    setValue("accommodation_id", "");
  }, [filteredAccommodationsForAdventure]);

  useEffect(() => {
    reset({
      first_name: booking.first_name,
      last_name: booking.last_name,
      email: booking.email,
      num_guests: booking.num_guests,
      adventure_id: booking.adventure_id,
      accommodation_id: booking.accommodation_id,
      start_date: booking.start_date,
      end_date: booking.end_date,
      num_nights: getDifferenceInDays(booking.end_date, booking.start_date),
    });
  }, [booking]);

  useEffect(() => {
    setValue("accommodation_id", booking.accommodation_id);
  }, []);

  async function getAdventuresForOptions() {
    const response = await getAdventures();
    response.errorMsg
      ? handleError(response.errorMsg)
      : setAdventures(response);
  }

  async function getAccommodationsForAdventure(id) {
    const response = await getAdventure(id);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      setSelectedAdventure(response);
      setAccommodationsForAdventure(response.accommodations);
      setAccommodationsOptions(response.accommodations, selectedNumGuests);
    }
  }

  async function handleAdventureChange(event) {
    getAccommodationsForAdventure(event.value);
  }

  function handleNumGuestsChange(event) {
    setSelectedNumGuests(event.target.value);
    setAccommodationsOptions(accommodationsForAdventure, event.target.value);
  }

  function setAccommodationsOptions(accommodations, numGuests) {
    setFilteredAccommodationsForAdventure(
      accommodations
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((acc) => ({
          id: acc.id,
          name: `${acc.name} (${acc.min_travelers} - ${acc.max_travelers} travelers)`,
          disabled:
            numGuests < acc.min_travelers ||
            numGuests > acc.max_travelers ||
            !acc.is_bookable,
        }))
    );
  }

  function handleNumNightsChange(event) {
    const startDate = getValues()["start_date"];
    if (startDate !== null) {
      setValue("end_date", format(addDays(parseISO(getValues()["start_date"]), event.target.value), "yyyy-MM-dd"));
    }
  }

  function handleStartDateChange(event) {
    const numNights = getValues()["num_nights"];
    if (!isNaN(numNights)) {
      setValue("end_date", format(addDays(parseISO(event.target.value), numNights), "yyyy-MM-dd"));
    }
  }

  return (
    <Modal open={showEditModal} setOpen={setShowEditModal}>
      <ModalTitle>Edit reservation</ModalTitle>
      <form onSubmit={handleSubmit(handleEdit)} noValidate>
        <ModalBody>
          <div className="grid gap-6 divide-y">
            <div>
              <h4 className="font-medium">Traveler information</h4>
              <div className="w-full inline-grid grid-cols-2 gap-6 mt-4">
                <InputText
                  name="first_name"
                  type="text"
                  label="First name"
                  errors={errors}
                  register={register}
                />
                <InputText
                  name="last_name"
                  type="text"
                  label="Last name"
                  errors={errors}
                  register={register}
                />
              </div>
              <div className="mt-4">
                <InputText
                  name="email"
                  type="email"
                  label="Email"
                  errors={errors}
                  register={register}
                />
              </div>
              <div className="inline-grid grid-cols-3 gap-6 mt-6">
                <SelectField
                  name="num_guests"
                  label="# of travelers"
                  options={Array.from(Array(21).keys())}
                  errors={errors}
                  register={register}
                  className="w-24"
                  onChange={handleNumGuestsChange}
                />
              </div>
            </div>
            <div className="pt-2">
              <h4 className="font-medium">Reservation</h4>
              <div className="mt-4">
                <SelectSearch
                  name="adventure_id"
                  label="Adventure"
                  helper="Choose the adventure to see and set more reservation information"
                  options={adventures
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((adventure) => ({
                      label: adventure.name,
                      value: adventure.id,
                    }))}
                  errors={errors}
                  control={control}
                  onChange={handleAdventureChange}
                />
                {selectedAdventure &&
                  filteredAccommodationsForAdventure.length > 0 && (
                    <SelectField
                      name="accommodation_id"
                      label="Accommodation"
                      options={filteredAccommodationsForAdventure.map(
                        (acc) => ({
                          name: acc.name,
                          id: acc.id,
                          disabled: acc.disabled,
                        })
                      )}
                      errors={errors}
                      register={register}
                      helper="The accommodation for the travelers."
                      className="mt-6"
                    />
                  )}

                <div className="inline-grid grid-cols-3 gap-6 mt-6">
                  <InputText
                    name="start_date"
                    type="date"
                    label="Start date"
                    placeholder="yyyy-mm-dd"
                    errors={errors}
                    register={register}
                    onBlur={handleStartDateChange}
                  />
                  <SelectField
                    name="num_nights"
                    label="# of nights"
                    options={Array.from(Array(60).keys())}
                    errors={errors}
                    register={register}
                    className="w-24"
                    onChange={handleNumNightsChange}
                  />
                  <InputText
                    name="end_date"
                    type="date"
                    label="End date"
                    placeholder="yyyy-mm-dd"
                    errors={errors}
                    register={register}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Cancel
          </Button>
          <Button type="submit" isSubmitting={isSubmitting}>
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
