import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "lunar/Button";
import {
  createAdventureWebsiteAccommodation,
  updateAdventureWebsiteAccommodation,
  deleteAdventureWebsiteAccommodation,
} from "lib/api";
import { formatImageURL } from "helpers/formatting";
import CreateUpdateAccommodationModal from "./CreateUpdateAccommodationModal";
import Table from "lunar/Table";
import TableHead from "lunar/TableHead";
import TableRow from "lunar/TableRow";
import TableCell from "lunar/TableCell";
import TableBody from "lunar/TableBody";
import TableActions from "lunar/TableActions";

AccommodationsTable.propTypes = {
  accommodations: PropTypes.array,
  adventureID: PropTypes.string,
  handleError: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
};

export default function AccommodationsTable({
  accommodations = [],
  adventureID = "",
  handleError,
  handleSuccess,
}) {
  const [showModal, setShowModal] = useState(false);
  const [accommodationToEdit, setAccommodationToEdit] = useState(null);

  async function handleDeleteAccommodation(id) {
    const response = await deleteAdventureWebsiteAccommodation(id);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Accommodation deleted");
    }
    setShowModal(false);
  }

  function handleEditAccommodation(adventureAccommodation) {
    setAccommodationToEdit(adventureAccommodation);
    setShowModal(true);
  }

  async function handleSaveAccommodation(data) {
    let response;
    if (data.id !== "") {
      response = await updateAdventureWebsiteAccommodation(data.id, data);
    } else {
      response = await createAdventureWebsiteAccommodation(data);
    }
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Accommodation saved");
    }
    setShowModal(false);
  }

  function handleAdd() {
    setAccommodationToEdit(null);
    setShowModal(true);
  }

  return (
    <>
      {accommodations && (
        <div>
          <Table className="table-fixed">
            <TableHead>
              <TableRow>
                <TableCell head first>
                  Image
                </TableCell>
                <TableCell head>Title</TableCell>
                <TableCell head>Description</TableCell>
                <TableCell head last>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accommodations.map((acc) => (
                <TableRow key={acc.id}>
                  <TableCell first>
                    {acc.image && (
                      <a
                        href={formatImageURL(acc.image.url)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={formatImageURL(acc.image.url, {
                            resize: { height: 50 },
                          })}
                          style={{ maxHeight: 50 }}
                          className="img-fluid"
                          alt={acc.name}
                        />
                      </a>
                    )}
                  </TableCell>
                  <TableCell>{acc.title}</TableCell>
                  <TableCell className="truncate">{acc.description}</TableCell>
                  <TableCell last>
                    <button
                      type="button"
                      onClick={() => handleDeleteAccommodation(acc.id)}
                      className="text-gray-600 hover:underline"
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      onClick={() => handleEditAccommodation(acc)}
                      className="ml-4 text-blue-600 hover:text-blue-900"
                    >
                      Edit
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TableActions>
            <Button variant="secondary" onClick={handleAdd}>
              Add accommodation
            </Button>
          </TableActions>
          {showModal && (
            <CreateUpdateAccommodationModal
              adventureID={adventureID}
              handleSaveAccommodation={handleSaveAccommodation}
              setShowModal={setShowModal}
              showModal={showModal}
              accommodation={accommodationToEdit}
              numAccommodations={
                accommodationToEdit
                  ? accommodations.length
                  : accommodations.length + 1
              }
            />
          )}
        </div>
      )}
    </>
  );
}
