import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { updateAdventureBooking } from "lib/api";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import Button from "lunar/Button";
import InputTextArea from "lunar/InputTextArea";

AccommodationsNotes.propTypes = {
  handleError: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  bookingID: PropTypes.string.isRequired,
  note: PropTypes.string,
};

export default function AccommodationsNotes({
  note = "",
  bookingID,
  handleError,
  handleSuccess,
}) {
  const schema = Yup.object().shape({});
  const {
    register,
    handleSubmit,
    errors,
    reset,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      accommodations_notes: note,
    });
  }, [bookingID]);

  async function handleSaveNote(data) {
    const response = await updateAdventureBooking(bookingID, data);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Note created");
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(handleSaveNote)} noValidate>
        <div>
          <div className="text-left">
            <InputTextArea
              name="accommodations_notes"
              errors={errors}
              register={register}
              className="w-full"
              rows={10}
            />
          </div>
          <div className="flex flex-row mt-4">
            <p className="text-sm text-gray-500">
              Extra notes about the accommodations (like room description). Basic markdown formatting
              supported.
            </p>
            <div className="flex-grow text-right">
              <Button
                variant="primary"
                type="submit"
                isSubmitting={isSubmitting}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
