/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";
import cn from "classnames";
import ItemTypes from "lunar/gallery/GalleryItemTypes";
import GalleryCardImage from "lunar/gallery/GalleryCardImage";
import GalleryCardContent from "lunar/gallery/GalleryCardContent";

GalleryCard.propTypes = {
  id: PropTypes.string,
  imageObj: PropTypes.object,
  index: PropTypes.number,
  deleteMediaImage: PropTypes.func.isRequired,
  selectedCards: PropTypes.array.isRequired,
  rearrangeCards: PropTypes.func.isRequired,
  setInsertIndex: PropTypes.func.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  clearItemSelection: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  insertLineStart: PropTypes.bool.isRequired,
  insertLineEnd: PropTypes.bool.isRequired,
};

export default function GalleryCard({
  id,
  imageObj,
  index,
  deleteMediaImage,
  selectedCards,
  rearrangeCards,
  setInsertIndex,
  onSelectionChange,
  clearItemSelection,
  isSelected,
  insertLineStart,
  insertLineEnd,
}) {
  const ref = useRef(null);
  const [tooSmall, setTooSmall] = useState(false);
  const [hoverCard, setHoverCard] = useState(false);

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.CARD,
    item: (monitor) => {
      const draggedCard = imageObj;
      let cards;
      if (selectedCards.find((card) => card.id === id)) {
        cards = selectedCards;
      } else {
        clearItemSelection();
        cards = [draggedCard];
      }
      const otherCards = cards.concat();
      otherCards.splice(
        cards.findIndex((c) => c.id === id),
        1
      );
      const cardsDragStack = [draggedCard, ...otherCards];
      const cardsIDs = cards.map((c) => c.id);
      return { cards, cardsDragStack, draggedCard, cardsIDs };
    },
    isDragging: (monitor) => {
      return monitor.getItem().cardsIDs.includes(id);
    },
    end: (item, monitor) => {
      rearrangeCards(item);
      clearItemSelection();
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ hovered }, drop] = useDrop({
    accept: ItemTypes.CARD,
    // drop: () => ({
    //               boxType: "Picture"
    //   }),
    hover: (item, monitor) => {
      const dragIndex = item.draggedCard.index;
      const hoverIndex = index;

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get horizontal middle
      const midX =
        hoverBoundingRect.left +
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      // Determine mouse position
      const pointerOffset = monitor.getClientOffset();
      const newInsertIndex =
        pointerOffset.x < midX ? hoverIndex : hoverIndex + 1;
      setInsertIndex(dragIndex, hoverIndex, newInsertIndex);
    },
    collect: (monitor) => ({
      hovered: monitor.isOver(),
    }),
  });

  drag(drop(ref));

  const onClick = (e) => {
    onSelectionChange(index, e.metaKey, e.shiftKey);
  };

  useEffect(() => {
    // This gets called after every render, by default
    // (the first one, and every one after that)

    // Use empty image as a drag preview so browsers don't draw it
    // and we can draw whatever we want on the custom drag layer instead.
    preview(getEmptyImage(), {
      // IE fallback: specify that we'd rather screenshot the node
      // when it already knows it's being dragged so we can hide it with CSS.
      captureDraggingState: true,
    });

    setTooSmall(imageObj.file.width !== null && imageObj.file.width < 750);

    // If you want to implement componentWillUnmount,
    // return a function from here, and React will call
    // it prior to unmounting.
    // return () => console.log('unmounting...');
  }, []);

  return (
    <div
      className="relative"
      key={"card-div-" + id}
      onMouseEnter={() => setHoverCard(true)}
      onMouseLeave={() => setHoverCard(false)}
    >
      {insertLineStart && hovered && (
        <div className="absolute inset-y-0 left-0 z-10 w-1 h-full bg-blue-500" />
      )}
      <div className="relative">
        <div
          className={cn(
            "rounded-lg overflow-hidden",
            isSelected &&
              "ring-2 ring-offset-2 ring-offset-gray-100 ring-blue-500",
            tooSmall && "ring-2 ring-offset-2 ring-offset-gray-100 ring-red-500"
          )}
          ref={ref}
          onClick={onClick}
        >
          <div className={isDragging ? "opacity-40" : "opacity-90"}>
            <GalleryCardImage imageObj={imageObj} />
          </div>
        </div>
        <div className="mt-3">
          <GalleryCardContent
            imageObj={imageObj}
            deleteMediaImage={deleteMediaImage}
            tooSmall={tooSmall}
            hoverCard={hoverCard}
          />
        </div>
      </div>
      {insertLineEnd && hovered && (
        <div className="absolute inset-y-0 right-0 z-10 w-1 h-full bg-blue-500" />
      )}
    </div>
  );
}
