import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalBody from "lunar/ModalBody";
import ModalFooter from "lunar/ModalFooter";
import Button from "lunar/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import InputText from "lunar/InputText";
import SelectField from "lunar/SelectField";
import { getProducts } from "lib/api";

CreateLineItemModal.propTypes = {
  showLineItemModal: PropTypes.bool.isRequired,
  setShowLineItemModal: PropTypes.func.isRequired,
  handleSaveLineItem: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  tripID: PropTypes.string.isRequired,
};

export default function CreateLineItemModal({
  showLineItemModal,
  setShowLineItemModal,
  handleSaveLineItem,
  tripID,
  setErrorMessage,
}) {
  const [products, setProducts] = useState([]);

  const schema = Yup.object().shape({
    id: Yup.number().required("Required"),
    quantity: Yup.number().integer().moreThan(0).required("Required"),
  });

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    async function populateProducts(tripID) {
      const response = await getProducts(tripID);
      if (response.errorMsg) {
        setErrorMessage(response.errorMsg);
      } else {
        setProducts(response);
      }
    }
    if (tripID) populateProducts(tripID);
  }, [tripID, setErrorMessage]);

  return (
    <Modal open={showLineItemModal} setOpen={setShowLineItemModal}>
      <ModalTitle>Create Line Item</ModalTitle>
      <form onSubmit={handleSubmit(handleSaveLineItem)} noValidate>
        <ModalBody>
          <SelectField
            name="id"
            label="Product"
            options={products}
            errors={errors}
            register={register}
          />
          <InputText
            name="quantity"
            type="text"
            label="Quantity"
            errors={errors}
            register={register}
            className="mt-6"
          />
        </ModalBody>
        <ModalFooter>
          <Button
            variant="secondary"
            onClick={() => setShowLineItemModal(false)}
          >
            Cancel
          </Button>
          <Button type="submit" isSubmitting={isSubmitting}>
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
