import React from "react";
import PropTypes from "prop-types";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalBody from "lunar/ModalBody";
import ModalFooter from "lunar/ModalFooter";
import Button from "lunar/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import TextField from "components/common/TextField";
import InputTextAddon from "lunar/InputTextAddon";

CreateLineItemModal.propTypes = {
  showLineItemModal: PropTypes.bool.isRequired,
  setShowLineItemModal: PropTypes.func.isRequired,
  handleSaveLineItem: PropTypes.func.isRequired,
};

export default function CreateLineItemModal({
  showLineItemModal,
  setShowLineItemModal,
  handleSaveLineItem,
}) {
  const schema = Yup.object().shape({
    description: Yup.string().required("Required"),
    price: Yup.number().notOneOf([0], "Can't be zero").required("Required"),
  });

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <Modal open={showLineItemModal} setOpen={setShowLineItemModal}>
      <ModalTitle>Create a line item</ModalTitle>
      <form onSubmit={handleSubmit(handleSaveLineItem)} noValidate>
        <ModalBody>
          <TextField
            name="description"
            type="text"
            label="Description"
            helper="No more than 50 characters. Example: $3,000 x 4 surfers"
            maxlength="50"
            errors={errors}
            register={register}
          />
          <InputTextAddon
            name="price"
            type="text"
            helper="Can be positive or negative value. For example, use -300 to discount a reservation by $300."
            label="Price"
            errors={errors}
            register={register}
            addonLeft="$"
          />
        </ModalBody>
        <ModalFooter>
          <Button
            variant="secondary"
            onClick={() => setShowLineItemModal(false)}
          >
            Cancel
          </Button>
          <Button type="submit" isSubmitting={isSubmitting}>
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
