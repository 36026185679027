import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { BrowserRouter } from "react-router-dom";
// waiting for merge of this pull request to remove findDOMNode warning
// https://github.com/jossmac/react-toast-notifications/pull/116
import { AuthProvider } from "context/auth-context";
import { LoadingProvider } from "context/loading-context";
import App from "App";
import * as serviceWorker from "./serviceWorker";
import { ToastProvider } from "react-toast-notifications";
import Toast from "lunar/Toast";
import "styles/global.css";

const CustomToast = ({ appearance, children = null }) => (
  <Toast appearance={appearance}>{children}</Toast>
);

CustomToast.propTypes = {
  appearance: PropTypes.string.isRequired,
  children: PropTypes.node,
};

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <ToastProvider
        components={{ Toast: CustomToast }}
        placement="top-right"
        autoDismiss={true}
        autoDismissTimeout={4000}
      >
        <AuthProvider>
          <LoadingProvider>
            <App />
          </LoadingProvider>
        </AuthProvider>
      </ToastProvider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
