import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useLoadingContext } from "context/loading-context";
import { ErrorAlert } from "components/common/Alerts";
import { createMessage, getMessageThreads, getMessage } from "lib/api";
import { threadStatus } from "helpers/messages";
import { humanDateTimeVersionTwo } from "helpers/formatting";
import Thread from "components/messages/Thread";
import Header from "components/messages/Header";
import Posts from "components/messages/Posts";
import Details from "components/messages/Details";
import Tab from "components/messages/Tab";
import Meta from "lunar/Meta";
import CreateConversationModal from "components/messages/CreateConversationModal";
import { PlusIcon } from "@heroicons/react/24/solid";
import { ShieldExclamationIcon } from "@heroicons/react/24/outline";

export default function Index() {
  const [threads, setThreads] = useState(null);
  const [threadCountAll, setThreadCountAll] = useState(null);
  const [threadCountOpen, setThreadCountOpen] = useState(null);
  const [activeMessageThread, setActiveMessageThread] = useState(null);
  const [activeMessageThreadStatus, setActiveMessageThreadStatus] =
    useState(null);
  const [threadsFilter, setThreadsFilter] = useState("Open");
  const [errorMessage, setErrorMessage] = useState("");
  const [showInitiateConversationModal, setShowInitiateConversationModal] =
    useState(false);
  const messageID = useParams().id;
  const { setLoading } = useLoadingContext();
  const navigate = useNavigate();

  const threadFilter = (thread) =>
    threadsFilter === "Open" ? threadStatus(thread).color === "red" : true;

  async function loadMessage(id) {
    const response = await getMessage(id);
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      setActiveMessageThread(response);
    }
  }

  async function handleThreadClick(id, allThreads = null) {
    loadMessage(id);
    setActiveMessageThreadStatus(
      threadStatus((allThreads || threads).find((thread) => thread.id === id))
    );
    window.history.pushState({}, undefined, `/messages/${id}`);
  }

  async function reloadActiveThread() {
    loadMessage(activeMessageThread.id);
  }

  async function handleSaveReply(data) {
    const response = await createMessage(data);
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      reloadActiveThread();
    }
  }

  function handleThreadsFilterClick(str) {
    setThreadsFilter(str);
    // TODO: refactor this call, used above as well
    const filteredThreads = threads.filter((thread) =>
      str === "Open" ? threadStatus(thread).color === "red" : true
    );
    if (filteredThreads.length > 0)
      handleThreadClick(filteredThreads[0].id, filteredThreads);
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getMessageThreads();
      if (response.errorMsg) {
        setErrorMessage(response.errorMsg);
      } else {
        setThreads(response);
        setThreadCountAll(response.length);
        const openThreads = response.filter(
          (x) => threadStatus(x).color === "red"
        );
        setThreadCountOpen(openThreads.length);
        if (response.length > 0) {
          if (messageID === undefined) {
            if (openThreads.length > 0) {
              handleThreadClick(openThreads[0].id, response);
            }
          } else {
            handleThreadClick(messageID, response);
          }
        }
      }
      setLoading(false);
    })();
  }, []);

  function handleInitiateConversation() {
    setShowInitiateConversationModal(true);
  }

  async function handleSaveInitialMessage(data) {
    data.receiver_type = "Operator";
    data.sender_type = "User";
    const response = await createMessage(data);
    if (response.errorMsg) {
      // addToast(response.errorMsg, { appearance: "error" });
    } else {
      setShowInitiateConversationModal(false);
      navigate(response.location);
    }
  }

  return (
    <>
      <Meta
        title={
          activeMessageThread &&
          `${activeMessageThread.user_first_name} ${activeMessageThread.user_last_name} + ${activeMessageThread.operator_name}`
        }
      />
      <ErrorAlert message={errorMessage} />
      <div className="flex flex-row h-screen overflow-hidden">
        <div className="flex flex-col w-64 overflow-hidden">
          <Header>
            <div className="grid items-center grid-cols-2">
              <div>Conversations</div>
              <div className="-mb-1 text-right">
                <button
                  type="button"
                  onClick={handleInitiateConversation}
                  className="text-gray-400 hover:text-gray-600"
                >
                  <PlusIcon className="w-4 h-4" />
                </button>
              </div>
            </div>
          </Header>
          <div className="grid grid-cols-2">
            <Tab
              name="Open"
              count={threadCountOpen}
              onClick={() => handleThreadsFilterClick("Open")}
              active={threadsFilter === "Open"}
            />
            <Tab
              name="All"
              count={threadCountAll}
              onClick={() => handleThreadsFilterClick("All")}
              active={threadsFilter === "All"}
            />
          </div>
          <div className="overflow-y-auto">
            <div className="flex-1 min-h-0 overflow-y-auto divide-y">
              {threads?.filter(threadFilter).map((thread) => (
                <Thread
                  active={activeMessageThread?.id === thread.id}
                  key={thread.id}
                  thread={thread}
                  handleClick={handleThreadClick}
                  threadsFilter={threadsFilter}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col flex-1 overflow-hidden border-l border-gray-200">
          <Header>
            <div className="grid items-center grid-flow-col">
              {activeMessageThread && (
                <div className="inline-grid items-center grid-flow-col auto-cols-max">
                  <>
                    {`${activeMessageThread.user_first_name} `}
                    {activeMessageThread.user_last_name}
                  </>
                  <div className="text-gray-400">&nbsp;+&nbsp;</div>
                  <div>{activeMessageThread.operator_name}</div>
                  {activeMessageThread?.messages.some(
                    (m) => m.operator_alert_header
                  ) && (
                    <div className={`ml-2 -mt-0.5 text-gray-800`}>
                      <ShieldExclamationIcon className="inline w-5 h-5" />
                    </div>
                  )}
                </div>
              )}
              <div className="text-xs font-medium text-right text-gray-400">
                {activeMessageThread &&
                  `Created on ${humanDateTimeVersionTwo(
                    activeMessageThread.messages[0].created_at
                  )}`}
              </div>
            </div>
          </Header>
          <Posts
            thread={activeMessageThread}
            status={activeMessageThreadStatus}
            setErrorMessage={setErrorMessage}
            reloadActiveThread={reloadActiveThread}
            handleSaveReply={handleSaveReply}
          />
        </div>
        <div className="flex flex-col overflow-hidden border-l border-gray-200">
          <Header>Details</Header>
          <div className="overflow-y-auto">
            <Details thread={activeMessageThread} />
          </div>
        </div>
      </div>
      {showInitiateConversationModal && (
        <CreateConversationModal
          showModal={showInitiateConversationModal}
          setShowModal={setShowInitiateConversationModal}
          handleSaveMessage={handleSaveInitialMessage}
          setErrorMessage={setErrorMessage}
        />
      )}
    </>
  );
}
