import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

Badge.propTypes = {
  status: PropTypes.string,
};

export default function Badge({ status = "" }) {
  let style = null;
  switch (status) {
    case "admin_created":
      style = "bg-blue-100 text-blue-800";
      break;
    case "created":
      style = "bg-red-100 text-red-800";
      break;
    case "modified":
      style = "bg-purple-100 text-purple-800";
      break;
    case "approved":
    case "partially_paid":
      style = "bg-yellow-100 text-yellow-800";
      break;
    case "denied":
      style = "bg-gray-200 text-gray-800";
      break;
    case "expired":
      style = "bg-gray-200 text-gray-800";
      break;
    case "paid":
    case "fully_paid":
      style = "bg-green-100 text-green-800";
      break;
    case "payment_failed":
      style = "bg-red-100 text-red-800";
      break;
    case "cancelled":
      style = "bg-gray-200 text-gray-800";
      break;
    default:
      style = "bg-gray-200 text-gray-800";
      break;
  }
  return (
    <div
      className={cn(
        style,
        "inline-flex items-center px-2 py-1 rounded-full text-xs font-medium"
      )}
    >
      {status === "denied"
        ? "Declined"
        : (status.charAt(0).toUpperCase() + status.slice(1)).replace(/_/g, " ")}
    </div>
  );
}
