/* eslint-disable react/display-name, react/prop-types */
import React, { Fragment, useState, useEffect } from "react";
import { useLoadingContext } from "context/loading-context";
import { getAccommodations, deleteAccommodation } from "lib/api";
import { useToasts } from "react-toast-notifications";
import { ErrorAlert } from "components/common/Alerts";
import PageListNav from "components/common/PageListNav";
import DataTable from "lunar/DataTable";
import DataTableSearch from "lunar/DataTableSearch";
import Meta from "lunar/Meta";
import NewModal from "components/accommodations/NewModal";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import ButtonText from "lunar/ButtonText";
import { useNavigate } from "react-router-dom";
import Button from "lunar/Button";
// import Button from "lunar/Button";

export default function Accommodations() {
  const [accommodations, setAccommodations] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const { addToast } = useToasts();
  const [globalFilter, setGlobalFilter] = useState("");
  const { setLoading } = useLoadingContext();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      populatePageAccommodations();
    })();
  }, []);

  async function confirmDeleteAccommodation(id) {
    if (window.confirm("Are you sure you want to delete this accomodation?")) {
      const response = await deleteAccommodation(id);
      if (response.errorMsg) {
        setErrorMessage(response.errorMsg);
      } else {
        addToast("Accommodation successfully deleted", {
          appearance: "success",
        });
        populatePageAccommodations();
      }
    }
  }

  async function populatePageAccommodations() {
    setLoading(true);
    const response = await getAccommodations();
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      setAccommodations(response.sort((a, b) => (a.name > b.name ? 1 : -1)));
    }
    setLoading(false);
  }

  const columns = React.useMemo(() => [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row: { original } }) => {
        return (
          <div>
            <div className="font-medium text-gray-900 whitespace-nowrap">
              {original.name}
            </div>
            <div className="max-w-xs mt-1 text-xs truncate">
              {original.adventures.length > 0 ? (
                <>
                  {original.adventures?.map((adventure, index) => (
                    <span key={`${adventure.id}-${index}`}>
                      <span key={adventure.id}>{adventure.name}</span>
                      {index !== original.adventures.length - 1 && (
                        <span>, </span>
                      )}
                    </span>
                  ))}
                </>
              ) : (
                <>~</>
              )}
            </div>
          </div>
        );
      },
    },
    {
      Header: "Status",
      accessor: "is_bookable",
      Cell: ({ row: { original } }) => {
        return (
          <>
            {original.is_bookable && (
              <div className="w-2.5 h-2.5 bg-green-500 rounded-full" />
            )}
          </>
        );
      },
    },
    {
      Header: "",
      accessor: "availability",
      Cell: ({ row: { original } }) => {
        return (
          <div className="text-right">
            {original.availability_url ? (
              <Button
                variant="secondary"
                onClick={(event) => {
                  event.preventDefault(),
                    window.open(original.availability_url);
                }}
              >
                Check availability
              </Button>
            ) : (
              <div />
            )}
          </div>
        );
      },
    },
    {
      Header: "ID",
      accessor: "id",
      Cell: ({ row: { original } }) => {
        return <span className="text-gray-900">{original.id}</span>;
      },
    },
    {
      Header: "",
      accessor: "actionsId",
      Cell: ({ row: { original } }) => {
        return (
          <div className="flex justify-end">
            <Menu as="div" className="relative inline-block text-gray-900">
              {({ open }) => (
                <>
                  <Menu.Button className="flex items-center rounded-full focus:outline-none">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon
                      className="w-5 h-5"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="absolute right-0 z-10 w-40 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      <Menu.Item>
                        <ButtonText
                          onClick={(e) => {
                            e.preventDefault(),
                              navigate(`/accommodations/edit/${original.id}`);
                          }}
                          className="block w-full px-4 py-2 text-sm text-left focus:ring-blue-500 hover:bg-gray-100"
                        >
                          View
                        </ButtonText>
                      </Menu.Item>
                      <Menu.Item>
                        <ButtonText
                          onClick={(e) => {
                            e.preventDefault(),
                              confirmDeleteAccommodation(original.id);
                          }}
                          className="block w-full px-4 py-2 text-sm text-left focus:ring-blue-500 hover:bg-gray-100"
                        >
                          Delete
                        </ButtonText>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        );
      },
    },
  ]);

  return (
    <>
      <Meta title={accommodations && `All ${accommodations.length} accommodations`} />
      <div className="min-h-screen overflow-y-scroll">
        <PageListNav
          dataTableSearch={
            <DataTableSearch
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              placeholder="Search for accommodations"
            />
          }
          actions={
            <NewModal populatePageAccommodations={populatePageAccommodations} />
          }
        />
        <ErrorAlert message={errorMessage} />
        {accommodations && (
          <DataTable
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            data={accommodations.map((accommodation) => ({
              id: accommodation.id,
              name: accommodation.name,
              adventures: accommodation.adventures,
              adventureNames:
                accommodation.adventures.map((a) => a.name).join(", ") ||
                "None",
              availability_url: accommodation.availability_url,
              actionsId: accommodation.id,
              is_bookable: accommodation.is_bookable,
              rowLink: `edit/${accommodation.id}`
            }))}
            columns={columns}
            rowLinkAttribute="rowLink"
            sortOptions={{ id: "id", desc: true }}
            hiddenColumns={["id"]}
          />
        )}
      </div>
    </>
  );
}
