import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import HiddenField from "components/common/HiddenField";
import SelectField from "lunar/SelectField";
import TextAreaAutoSize from "components/messages/TextAreaAutoSize";
import Button from "lunar/Button";

CreatePostGuide.propTypes = {
  userEmail: PropTypes.string,
  handleSaveReply: PropTypes.func.isRequired,
  employees: PropTypes.array,
  senderType: PropTypes.string,
  receiverID: PropTypes.number,
  receiverType: PropTypes.string,
  threadID: PropTypes.string,
};

export default function CreatePostGuide({
  userEmail,
  handleSaveReply,
  employees,
  senderType,
  receiverID,
  receiverType,
  threadID,
}) {
  const schema = Yup.object().shape({
    content: Yup.string().required("Required"),
  });

  const {
    register,
    handleSubmit,
    errors,
    reset,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      receiver_id: receiverID,
      receiver_type: receiverType,
      sender_type: senderType,
    },
  });

  function handleSend(data) {
    reset();
    handleSaveReply(data);
  }

  useEffect(() => {
    reset({
      receiver_id: receiverID,
    });
  }, [receiverID, threadID]);

  return (
    <div className="mb-4 ml-4 mr-4 border-2 border-gray-200 rounded-md ">
      <form onSubmit={handleSubmit(handleSend)} noValidate className="relative">
        <div className="grid grid-cols-2 pt-3 pb-3 pl-4 pr-4 border-b border-gray-200">
          <HiddenField name="receiver_id" register={register} />
          <HiddenField name="receiver_type" register={register} />
          <HiddenField name="sender_type" register={register} />
          <div className="flex flex-row items-center gap-1 text-sm">
            <SelectField
              name="sender_id"
              label="Employee"
              options={employees.map((emp) => ({
                id: emp.id,
                name: emp.name,
              }))}
              errors={errors}
              register={register}
              showLabel={false}
              inline={true}
              hideMargin
            />
          </div>
          <div className="text-sm font-medium text-right text-gray-400">
            to: {userEmail}
          </div>
        </div>
        <div className="relative">
          <TextAreaAutoSize
            name="content"
            register={register}
            placeholder="Write a reply"
            minRows={5}
            maxRows={12}
            className="block w-full p-4 pr-24 text-gray-900 placeholder-gray-500 bg-transparent border-transparent focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent"
          />
          <div className="absolute bottom-4 right-4">
            <Button
              type="submit"
              disabled={isSubmitting}
              isSubmitting={isSubmitting}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="inline w-4 h-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
              </svg>
              <span className="ml-1">Send</span>
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}
