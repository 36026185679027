import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import ReactTextAreaAutoSize from "react-textarea-autosize";

TextAreaAutoSize.propTypes = {
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  placeholder: PropTypes.string,
  className: PropTypes.node,
};

export default function TextAreaAutoSize({
  name,
  register,
  minRows = 3,
  maxRows = 10,
  placeholder = "",
  className = "",
}) {
  return (
    <ReactTextAreaAutoSize
      id={name}
      name={name}
      {...register(name)}
      minRows={minRows}
      maxRows={maxRows}
      placeholder={placeholder}
      className={
        (cn(
          "block w-full p-4 pr-24 bg-transparent border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent"
        ),
        className)
      }
    />
  );
}
