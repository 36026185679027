import React, { useState, useEffect } from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  getAccommodation,
  updateAccommodation,
  deleteAccommodationImages,
  updateAccommodationImagePositions,
} from "lib/api";
import { useLoadingContext } from "context/loading-context";
import { useToasts } from "react-toast-notifications";
import { FormProvider } from "context/form-context";
import { humanDate } from "helpers/formatting";
import HiddenField from "components/common/HiddenField";
import InputText from "lunar/InputText";
import InputTextWithButton from "lunar/InputTextWithButton";
import InputTextArea from "lunar/InputTextArea";
import Gallery from "lunar/gallery/Gallery";
import Toggle from "lunar/Toggle";
import Meta from "lunar/Meta";
import Form from "lunar/Form";
import FormTitle from "lunar/FormTitle";
import FormSections from "lunar/FormSections";
import FormSection from "lunar/FormSection";
import FormSectionHeader from "lunar/FormSectionHeader";
import FormSectionBody from "lunar/FormSectionBody";
import FormRow from "lunar/FormRow";
import AdventureTable from "components/accommodations/AdventureTable";

export default function Edit() {
  const [accommodation, setAccommodation] = useState({});
  const [isBookable, setIsBookable] = useState(null);
  const accommodationID = useParams().id;
  const { addToast } = useToasts();
  const { setLoading } = useLoadingContext();

  const schema = Yup.object().shape({
    name: Yup.string().required("Required"),
  });

  const {
    control,
    register,
    errors,
    reset,
    handleSubmit,
    formState: { isSubmitting },
    setValue,
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (accommodation) {
      reset({
        name: accommodation.name,
        is_bookable: accommodation.is_bookable,
        availability_url: accommodation.availability_url,
        notion_url: accommodation.notion_url,
        story: accommodation.story,
        min_travelers: accommodation.min_travelers,
        max_travelers: accommodation.max_travelers,
        address_line_1: accommodation.address_line_1,
        address_line_2: accommodation.address_line_2,
        address_city: accommodation.address_city,
        address_state_province: accommodation.address_state_province,
        address_postal_code: accommodation.address_postal_code,
        address_country: accommodation.address_country,
        cancellation_policy: accommodation.cancellation_policy,
      });
    }
  }, [reset, accommodation]);

  useEffect(() => {
    (async () => {
      populateAccommodationState(accommodationID);
    })();
  }, [accommodationID]);

  async function populateAccommodationState(accommodationID) {
    setLoading(true);
    const response = await getAccommodation(accommodationID);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      setAccommodation(response);
      setIsBookable(response.is_bookable);
    }
    setLoading(false);
  }

  function handleSuccess(msg) {
    populateAccommodationState(accommodationID);
    addToast(msg, { appearance: "success" });
  }

  function handleError(msg) {
    addToast(msg, { appearance: "error" });
  }

  async function submitForm(data) {
    const response = await updateAccommodation(accommodationID, data);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Accommodation successfully updated");
    }
  }

  async function submitFormAndClearImages(data) {
    await submitForm(data);
    setValue("accommodation_images_attributes", []);
  }

  function handleBookableToggle(val) {
    setValue("is_bookable", val);
    setIsBookable(val);
  }

  async function addAdventure(selectedOption) {
    const response = await updateAccommodation(accommodationID, {
      adventures_accommodations_attributes: [
        { adventure_id: selectedOption.id },
      ],
    });

    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Accommodation successfully updated");
    }
  }

  async function removeAdventure(association_id) {
    const response = await updateAccommodation(accommodationID, {
      adventures_accommodations_attributes: [
        { _destroy: association_id, id: association_id },
      ],
    });

    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Accommodation successfully updated");
    }
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Meta title={accommodation.name} />
      <FormProvider
        lastUpdated={
          accommodation.updated_at && humanDate(accommodation.updated_at)
        }
      >
        <Form
          handleSubmit={handleSubmit}
          submitForm={submitFormAndClearImages}
          isSubmitting={isSubmitting}
        >
          <HiddenField name="is_bookable" register={register} />
          <FormTitle title={accommodation.name || ""} />
          <FormSections>
            <FormSection>
              <FormSectionHeader
                title="Settings"
                description="The primary settings forming accommodation presentations."
              />
              <FormSectionBody className="flex flex-col gap-y-6">
                <FormRow>
                  <Toggle
                    label="Bookable"
                    enabled={isBookable}
                    handleToggle={handleBookableToggle}
                    layout="inline"
                  />
                </FormRow>
                <FormRow>
                  <InputText
                    name="name"
                    type="text"
                    label="Accommodation name"
                    errors={errors}
                    register={register}
                    placeholder="Villa Escondida"
                    className="w-1/2"
                  />
                </FormRow>
                <FormRow>
                  <InputTextWithButton
                    name="availability_url"
                    type="text"
                    label="Availability url"
                    errors={errors}
                    register={register}
                    className="w-1/2"
                    icon={<ArrowTopRightOnSquareIcon />}
                    buttonUrl={accommodation.availability_url}
                    alignButton="right"
                  />
                </FormRow>
                <FormRow>
                  <InputTextWithButton
                    name="notion_url"
                    type="text"
                    label="Notion url"
                    errors={errors}
                    register={register}
                    className="w-1/2"
                    icon={<ArrowTopRightOnSquareIcon />}
                    buttonUrl={accommodation.notion_url}
                    alignButton="right"
                  />
                </FormRow>
              </FormSectionBody>
            </FormSection>
            <FormSection>
              <FormSectionHeader
                title="Related adventures"
                description="The adventures currently associated with this accommodation."
              />
              <FormSectionBody>
                <AdventureTable
                  adventures={accommodation.adventures}
                  handleAddAdventure={addAdventure}
                  handleRemoveAdventure={removeAdventure}
                  handleError={handleError}
                />
              </FormSectionBody>
            </FormSection>
            <FormSection>
              <FormSectionHeader
                title="Story"
                description="The accommodation’s story used in adventures and listing views."
              />
              <FormSectionBody>
                <FormRow className="grid grid-flow-row">
                  <InputTextArea
                    name="story"
                    helper="Supports basic markdown formatting."
                    errors={errors}
                    register={register}
                    rows={10}
                  />
                </FormRow>
              </FormSectionBody>
            </FormSection>
            <FormSection>
              <FormSectionHeader
                title="Occupancy"
                description="Set the minimum and maximum travelers allowed for this accommodation."
              />
              <FormSectionBody>
                <FormRow>
                  <InputText
                    name="min_travelers"
                    type="number"
                    label="Min"
                    errors={errors}
                    register={register}
                    placeholder="1"
                    className="w-20"
                  />
                  <InputText
                    name="max_travelers"
                    type="number"
                    label="Max"
                    errors={errors}
                    register={register}
                    placeholder="6"
                    className="w-20"
                  />
                </FormRow>
              </FormSectionBody>
            </FormSection>
            <FormSection>
              <FormSectionHeader
                title="Physical address"
                description="The detailed location we'll share with travelers during booking."
              />
              <FormSectionBody>
                <FormRow className="grid grid-cols-6 gap-6">
                  <InputText
                    name="address_line_1"
                    type="text"
                    label="Street address"
                    errors={errors}
                    register={register}
                    className="col-span-6"
                  />
                  <InputText
                    name="address_line_2"
                    type="text"
                    label="Address line 2"
                    errors={errors}
                    register={register}
                    className="col-span-4"
                  />
                  <InputText
                    name="address_city"
                    type="text"
                    label="City"
                    errors={errors}
                    register={register}
                    className="col-span-2"
                  />
                  <InputText
                    name="address_state_province"
                    type="text"
                    label="State/province"
                    errors={errors}
                    register={register}
                    className="col-span-3"
                  />
                  <InputText
                    name="address_postal_code"
                    type="text"
                    label="Postal code"
                    errors={errors}
                    register={register}
                    className="col-span-1"
                  />
                  <InputText
                    name="address_country"
                    type="text"
                    label="Country"
                    errors={errors}
                    register={register}
                    className="col-span-2"
                  />
                </FormRow>
              </FormSectionBody>
            </FormSection>
            <FormSection>
              <FormSectionHeader
                title="Cancellation policy"
                description="Describe the policy for a cancellation. Basic markdown formatting supported."
              />
              <FormSectionBody>
                <InputTextArea
                  name="cancellation_policy"
                  type="text"
                  helper="Limit 500 characters."
                  errors={errors}
                  register={register}
                  rows={3}
                />
              </FormSectionBody>
            </FormSection>
            <FormSection>
              <FormSectionHeader
                title="Photos"
                description="Photos that bring to life the accommodation described in the story above."
              />
              <FormSectionBody>
                <FormRow>
                  <Gallery
                    parentID={accommodationID}
                    images={accommodation.accommodation_images}
                    handleError={handleError}
                    handleSuccess={handleSuccess}
                    updateFunc={updateAccommodation}
                    deleteFunc={deleteAccommodationImages}
                    updatePositionsFunc={updateAccommodationImagePositions}
                    imagesAttributeName="accommodation_images_attributes"
                    control={control}
                    setValue={setValue}
                  />
                </FormRow>
              </FormSectionBody>
            </FormSection>
          </FormSections>
        </Form>
      </FormProvider>
    </DndProvider>
  );
}
