import React from "react";
import PropTypes from "prop-types";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalBody from "lunar/ModalBody";
import ModalFooter from "lunar/ModalFooter";
import Button from "lunar/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import TextArea from "lunar/InputTextArea";
import Alert from "lunar/Alert";

PaymentModal.propTypes = {
  showPaymentModal: PropTypes.bool.isRequired,
  setShowPaymentModal: PropTypes.func.isRequired,
  handleSendPaymentToGuide: PropTypes.func.isRequired,
};

export default function PaymentModal({
  showPaymentModal,
  setShowPaymentModal,
  handleSendPaymentToGuide,
}) {
  const schema = Yup.object().shape({});

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <Modal open={showPaymentModal} setOpen={setShowPaymentModal}>
      <ModalTitle>Add a Thermal message to guide</ModalTitle>
      <form onSubmit={handleSubmit(handleSendPaymentToGuide)} noValidate>
        <ModalBody>
          <Alert
            type="warning"
            title="Is there something we need to tell the guide?"
            body="For example, if there's a multiple main products (e.g. two rooms), call that out."
          />
          <TextArea
            name="payout_notes"
            errors={errors}
            register={register}
            helper="Supports basic markdown formatting"
            className="mt-6"
          />
        </ModalBody>
        <ModalFooter>
          <Button
            variant="secondary"
            onClick={() => setShowPaymentModal(false)}
          >
            Cancel
          </Button>
          <Button type="submit" isSubmitting={isSubmitting}>
            Send
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
