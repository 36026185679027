import React from "react";
import cn from "classnames";
import { getDistanceToNowHours, timeAgoTiny } from "helpers/formatting";
import { threadStatus } from "helpers/messages";
import PropTypes from "prop-types";

Thread.propTypes = {
  thread: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
  threadsFilter: PropTypes.string,
};

export default function Thread({
  thread,
  handleClick,
  active = false,
  threadsFilter = "All",
}) {
  let dotBG;
  switch (threadStatus(thread).color) {
    case "red":
      dotBG = "bg-red-500";
      break;
    case "yellow":
      dotBG = "bg-yellow-500";
      break;
    case "gray":
      dotBG = "bg-gray-500";
      break;
  }

  return (
    <div
      onClick={() => handleClick(thread.id)}
      className={cn(
        "p-4 flex flex-row gap-3 cursor-pointer ",
        active ? "bg-blue-600" : "hover:bg-gray-200"
      )}
    >
      <div className="mt-2">
        <div className={`flex-shrink-0 w-2 h-2 rounded-full ${dotBG}`}></div>
      </div>
      <div className="flex-auto">
        <div
          className={cn(
            "text-sm font-bold truncate",
            active ? "text-white opacity-90" : "text-gray-900"
          )}
          style={{ maxWidth: "10rem" }}
        >
          {`${thread.user_first_name} ${thread.user_last_name}`}
        </div>
        <div
          className={cn(
            "text-sm font-medium truncate ",
            active ? "text-white opacity-60" : "text-gray-600"
          )}
          style={{ maxWidth: "10rem" }}
        >
          {thread.operator_name}
        </div>
      </div>
      <div
        className={cn(
          "mt-0.5 text-sm font-medium",
          active ? "text-white opacity-60" : "text-gray-600"
        )}
      >
        {threadsFilter === "Open"
          ? getDistanceToNowHours(thread.last_message_at)
          : timeAgoTiny(thread.last_message_at)}
      </div>
    </div>
  );
}
