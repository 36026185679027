import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalBody from "lunar/ModalBody";
import ModalFooter from "lunar/ModalFooter";
import Button from "lunar/Button";
import { parse, isValid } from "date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import TextField from "components/common/TextField";
import HiddenField from "components/common/HiddenField";
import TextInputPrice from "components/common/TextInputPrice";

FixedVariantModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  handleSaveVariant: PropTypes.func.isRequired,
  productID: PropTypes.string,
  variant: PropTypes.object,
};

export default function FixedVariantModal({
  showModal,
  setShowModal,
  handleSaveVariant,
  productID = "",
  variant = {},
}) {
  const schema = Yup.object().shape({
    price: Yup.number()
      .typeError("Number required")
      .positive("Positive decimal required")
      .required("Required"),
    usage_date: Yup.string()
      .required("Required")
      .test("is-date", "Valid date required", (value) =>
        isValid(parse(value, "yyyy-MM-dd", new Date()))
      ),
    // https://github.com/jquense/yup/issues/66
    quantity_available: Yup.number()
      .transform((cv) => (isNaN(cv) ? undefined : cv))
      .integer()
      .min(0),
    min_night_stay: Yup.number()
      .transform((cv) => (isNaN(cv) ? undefined : cv))
      .integer()
      .min(0),
  });

  const {
    reset,
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    //defaultValues: {
    //  product_id: productID,
    //},
  });

  useEffect(() => {
    if (variant) {
      reset({
        id: variant.id,
        price: variant.price,
        usage_date: variant.usage_date,
        quantity_available: variant.quantity_available,
        min_night_stay: variant.min_night_stay,
        product_id: productID,
      });
    } else {
      reset({
        id: "",
        price: "",
        usage_date: "",
        quantity_available: "",
        min_night_stay: "",
        product_id: productID,
      });
    }
  }, [reset, variant]);

  return (
    <Modal open={showModal} setOpen={setShowModal}>
      <ModalTitle>Create pricing</ModalTitle>
      <form onSubmit={handleSubmit(handleSaveVariant)} noValidate>
        <ModalBody>
          <HiddenField name="id" register={register} />
          <HiddenField name="product_id" register={register} />
          <div className="grid grid-cols-2 gap-x-6 ">
            <TextField
              name="usage_date"
              type="date"
              label="Start date"
              errors={errors}
              register={register}
            />
            <TextInputPrice
              name="price"
              label="Price"
              errors={errors}
              register={register}
            />
          </div>
          <div className="grid grid-cols-2 gap-x-6 ">
            <TextField
              name="quantity_available"
              type="text"
              label="Quantity available"
              errors={errors}
              register={register}
            />
            <TextField
              name="min_night_stay"
              type="text"
              label="Minimum nights"
              errors={errors}
              register={register}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button type="submit" isSubmitting={isSubmitting}>
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
