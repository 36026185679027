import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { getGuide, updateGuide } from "lib/api";
import { useLoadingContext } from "context/loading-context";
import { useToasts } from "react-toast-notifications";
import { FormProvider } from "context/form-context";
import { humanDate } from "helpers/formatting";
import InputText from "lunar/InputText";
import InputTextArea from "lunar/InputTextArea";
import InputImage from "lunar/InputImage";
import Meta from "lunar/Meta";
import Form from "lunar/Form";
import FormTitle from "lunar/FormTitle";
import FormSections from "lunar/FormSections";
import FormSection from "lunar/FormSection";
import FormSectionHeader from "lunar/FormSectionHeader";
import FormSectionBody from "lunar/FormSectionBody";
import FormRow from "lunar/FormRow";

export default function Edit() {
  const [guide, setGuide] = useState({});
  const guideId = useParams().id;
  const { addToast } = useToasts();
  const { setLoading } = useLoadingContext();

  const {
    register,
    errors,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  useEffect(() => {
    if (guide) {
      reset({
        name: guide.name,
        slug: guide.slug,
        title: guide.title,
        first_name: guide.first_name,
        video_url: guide.video_url,
        main_image: guide.main_image,
        short_description: guide.short_description,
        story: guide.story,
      });
    }
  }, [reset, guide]);

  useEffect(() => {
    (async () => {
      populateGuideState(guideId);
    })();
  }, [guideId]);

  async function populateGuideState(guideId) {
    setLoading(true);
    const response = await getGuide(guideId);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      setGuide(response);
    }
    setLoading(false);
  }

  function handleSuccess(msg) {
    populateGuideState(guideId);
    addToast(msg, { appearance: "success" });
  }

  function handleError(msg) {
    addToast(msg, { appearance: "error" });
  }

  async function submitForm(data) {
    const response = await updateGuide(guideId, data);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Guide successfully updated");
    }
  }

  return (
    <>
      <Meta title={guide.name} />
      <FormProvider
        lastUpdated={guide.updated_at && humanDate(guide.updated_at)}
      >
        <Form
          handleSubmit={handleSubmit}
          submitForm={submitForm}
          isSubmitting={isSubmitting}
        >
          <FormTitle title={guide.name || ""} />
          <FormSections>
            <FormSection>
              <FormSectionHeader
                title="Settings"
                description="The primary settings forming guide presentations."
              />
              <FormSectionBody className="flex flex-col gap-y-6">
                <FormRow>
                  <InputText
                    name="name"
                    type="text"
                    label="Guide name"
                    errors={errors}
                    register={register}
                    className="flex-grow"
                  />
                  <InputText
                    name="first_name"
                    type="text"
                    label="Guide first name"
                    errors={errors}
                    register={register}
                    className="flex-grow"
                  />
                </FormRow>
                <FormRow>
                  <InputText
                    name="slug"
                    type="text"
                    label="URL slug"
                    errors={errors}
                    register={register}
                    className="flex-grow"
                    disabled={true}
                  />
                  <InputText
                    name="video_url"
                    type="text"
                    label="Guide video url"
                    errors={errors}
                    register={register}
                    className="flex-grow"
                    disabled={true}
                  />
                </FormRow>
              </FormSectionBody>
            </FormSection>
            <FormSection>
              <FormSectionHeader title="Story" />
              <FormSectionBody className="flex flex-col gap-y-6">
                <FormRow>
                  <InputText
                    name="title"
                    type="text"
                    label="Guide title"
                    errors={errors}
                    register={register}
                    className="flex-grow"
                  />
                </FormRow>
                <FormRow>
                  <InputTextArea
                    name="short_description"
                    type="text"
                    label="Short description"
                    helper="Limit 250 characters."
                    errors={errors}
                    register={register}
                    rows={1}
                    className="flex-grow"
                    disabled={true}
                  />
                </FormRow>
                <FormRow>
                  <InputTextArea
                    name="story"
                    type="text"
                    label="Long description"
                    helper="Limit 500 characters."
                    errors={errors}
                    register={register}
                    rows={5}
                    className="flex-grow"
                  />
                </FormRow>
              </FormSectionBody>
            </FormSection>
            <FormSection>
              <FormSectionHeader title="Media" />
              <FormSectionBody>
                <FormRow>
                  <InputImage
                    name="main_image"
                    label="Hero image"
                    register={register}
                    image={guide.main_image}
                    className="flex-grow mr-10"
                    previewAspectRatio="4/3"
                  />
                </FormRow>
              </FormSectionBody>
            </FormSection>
          </FormSections>
        </Form>
      </FormProvider>
    </>
  );
}
