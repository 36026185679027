import React from "react";
import "./unauthenticated.css";
import Loading from "lunar/Loading";
import { Outlet } from "react-router-dom";
import { useLoadingContext } from "context/loading-context";

export default function Unauthenticated() {
  const { loading } = useLoadingContext();
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <Outlet />
        <Loading show={loading} />
      </div>
    </div>
  );
}
