import React, { useState } from "react";
import PropTypes from "prop-types";
import { useToasts } from "react-toast-notifications";
import { createGuide } from "lib/api";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import TextField from "components/common/TextField";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalFooter from "lunar/ModalFooter";
import ModalBody from "lunar/ModalBody";
import Button from "lunar/Button";

NewModal.propTypes = {
  populatePageGuides: PropTypes.func.isRequired,
};

export default function NewModal({ populatePageGuides }) {
  const [showNewModal, setShowNewModal] = useState(false);
  const { addToast } = useToasts();

  async function submitForm(data) {
    const response = await createGuide(data);
    if (response.errorMsg) {
      addToast(response.errorMsg, { appearance: "error" });
    } else {
      addToast("Guide successfully created", { appearance: "success" });
      setShowNewModal(false);
      populatePageGuides();
    }
  }

  const schema = Yup.object().shape({
    name: Yup.string().required("Required"),
  });

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <>
      <Button onClick={() => setShowNewModal(true)}>New guide</Button>
      <Modal open={showNewModal} setOpen={setShowNewModal}>
        <ModalTitle>Add a new guide</ModalTitle>
        <form onSubmit={handleSubmit(submitForm)} noValidate>
          <ModalBody>
            <TextField
              name="name"
              type="text"
              label="Name"
              errors={errors}
              register={register}
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" onClick={() => setShowNewModal(false)}>
              Cancel
            </Button>
            <Button type="submit" isSubmitting={isSubmitting}>
              Save
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
}
