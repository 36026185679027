import React from "react";
import PropTypes from "prop-types";

HiddenField.propTypes = {
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
};

export default function HiddenField({ name, register }) {
  return <input id={name} name={name} type="hidden" {...register(name)} />;
}
