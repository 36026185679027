import React from "react";
import {
  format,
  parseISO,
  fromUnixTime,
  getISODay,
  differenceInSeconds,
  differenceInDays,
  formatDistanceToNowStrict,
} from "date-fns";
import TimeAgo from "timeago-react";
import * as timeago from "timeago.js";
import enShort from "timeago.js/lib/lang/en_short";

// https://stackoverflow.com/questions/68707553/uncaught-referenceerror-buffer-is-not-defined
import { Buffer } from "buffer";
window.Buffer = Buffer;

export function longDate(date) {
  return format(parseISO(date), "MM/dd/yyyy");
}

export function longDate2(date) {
  return format(parseISO(date), "yyyy-MM-dd");
}

export function longDateHoursMinutes(time) {
  return format(parseISO(time), "MM/dd/yyyy h:mm a");
}

export function longDateTime(time) {
  return format(parseISO(time), "MM/dd/yyyy h:mm:ss a");
}

export function longDateTimeUnix(time) {
  return format(fromUnixTime(time), "MM/dd/yyyy h:mm:ss a");
}

export function humanDate(date) {
  return format(parseISO(date), "MMM d, yyyy");
}

export function humanDateTime(time) {
  return format(parseISO(time), "MMM d, yyyy h:mm:ss a");
}

export function humanDateTimeVersionTwo(time) {
  return format(parseISO(time), "MMM d, yyyy 'at' h:mm a");
}

export function humanDateTimeVersionThree(time) {
  return format(parseISO(time), "M/d 'at' h:mmaaa");
}

export function getDifferenceInSeconds(endTime, startTime) {
  return differenceInSeconds(parseISO(endTime), parseISO(startTime));
}

export function getDifferenceInDays(endTime, startTime) {
  return differenceInDays(parseISO(endTime), parseISO(startTime));
}

export function getDistanceToNowHours(time) {
  return formatDistanceToNowStrict(parseISO(time), {
    unit: "hour",
  })
    .replace(" hour", "h")
    .replace("s", "");
}

function dateFormatter(date, formatStr) {
  try {
    return format(parseISO(date), formatStr);
  } catch (error) {
    return date;
  }
}

export function isWeekendDate(date) {
  return [5, 6].includes(getISODay(parseISO(date)));
}

export function formatShortDateRange(start, end) {
  const startDate = dateFormatter(start, "MMM d");
  const endDate = dateFormatter(end, "MMM d, yyyy");
  const dateRange = `${startDate} - ${endDate}`;
  return dateRange;
}

export function money(val) {
  const moneyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return moneyFormatter.format(val).replace(/\D00(?=\D*$)/, "");
}

export function formatImageURL(imageURL, edits = {}) {
  if (imageURL && imageURL.indexOf("cloudfront.net/") !== -1) {
    // production - use image resizer and cloudfront
    const key = imageURL.split("cloudfront.net/")[1];
    const imageRequest = JSON.stringify({
      bucket: "thermal-web-images",
      key,
      edits,
    });
    return (
      imageURL.split(key)[0] + Buffer.from(imageRequest).toString("base64")
    );
  }
  // dev - use url (which will be S3 link)
  return imageURL;
}

function enShortTiny(number, index) {
  return [
    ["now", "now"],
    ["%ss", "%ss"],
    ["1m", "1m"],
    ["%sm", "%sm"],
    ["1h", "1h"],
    ["%sh", "%sh"],
    ["1d", "1d"],
    ["%sd", "%sd"],
    ["1w", "1w"],
    ["%sw", "%sw"],
    ["1mo", "1mo"],
    ["%smo", "%smo"],
    ["1yr", "1yr"],
    ["%syr", "%syr"],
  ][index];
}

export function timeAgo(datetime) {
  timeago.register("enShort", enShort);
  return <TimeAgo datetime={datetime} locale="enShort" />;
}

export function timeAgoTiny(datetime) {
  timeago.register("enShortTiny", enShortTiny);
  return <TimeAgo datetime={datetime} locale="enShortTiny" />;
}

export function shortPricingType(type) {
  switch (type) {
    case "per_person":
      return "PT";
    case "per_person_per_night":
      return "PT/PN";
    case "per_day":
      return "PD";
    case "per_person_per_day":
      return "PT/PD";
    default:
      return "PN";
  }
}

export function initialsFromName(nameString) {
  return nameString
    .match(/(\b\S)?/g)
    .join("")
    .match(/(^\S|\S$)?/g)
    .join("")
    .toUpperCase();
}

export function renameStatus(s) {
  switch (s) {
    case "fully_paid":
      return "Confirmed";
    case "partially_paid":
      return "Confirmed";
    case "cancelled":
      return "Cancelled";
    default:
      return "Status unknown";
  }
}
