import React from "react";
import PropTypes from "prop-types";

CardHeading.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  action: PropTypes.node,
};

export default function CardHeading({ heading = "", description = "", action = ""}) {
  return (
    <div className="px-4 py-3 sm:px-6 border-b border-gray-200">
      <div className="grid grid-cols-2 items-center">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {heading}
          </h3>
          {description && (
            <p className="mt-1 text-sm text-gray-500">{description}</p>
          )}
        </div>
        {action && <div className="flex-shrink-0 text-right">{action}</div>}
      </div>
    </div>
  );
}
