import React, { useState, useEffect } from "react";
import TextField from "components/common/TextField";
import { getAdmin, updateThermalAdmin } from "lib/api";
import { useLoadingContext } from "context/loading-context";
import { useForm } from "react-hook-form";
import { formatImageURL } from "helpers/formatting";
import Card from "components/common/Card";
import CardBody from "components/common/CardBody";
import CardTitle from "components/common/CardTitle";
import Button from "lunar/Button";
import { useAuthContext } from "context/auth-context";
import { ErrorAlert } from "components/common/Alerts";

export default function ChangeAvatar() {
  const authContext = useAuthContext();
  const { setLoading } = useLoadingContext();
  const [admin, setAdmin] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({});

  useEffect(() => {
    (async () => {
      populateAdminState(false);
    })();
  }, []);

  async function populateAdminState(setContext) {
    setLoading(true);
    const response = await getAdmin();
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      setAdmin(response);
      if (setContext) {
        authContext.setAvatar(response.main_image?.url);
        window.location.reload();
      }
    }
    setLoading(false);
  }

  async function handleSaveAvatar(data) {
    const response = await updateThermalAdmin(data);
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      populateAdminState(true);
    }
  }

  return (
    <Card>
      <CardBody>
        <CardTitle>Change avatar</CardTitle>
        <ErrorAlert message={errorMessage} />
        {admin?.main_image && (
          <img
            className="object-cover w-16 h-16 rounded-full"
            src={formatImageURL(admin.main_image.url)}
            alt={admin.name}
          />
        )}
        <form onSubmit={handleSubmit(handleSaveAvatar)} noValidate>
          <TextField
            name="main_image"
            type="file"
            label="Main Image"
            errors={errors}
            register={register}
          />
          <div className="mt-4">
            <Button type="submit" isSubmitting={isSubmitting}>
              Save
            </Button>
          </div>
        </form>
      </CardBody>
    </Card>
  );
}
