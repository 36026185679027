import React from "react";
import PropTypes from "prop-types";

TextInputPrice.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helper: PropTypes.string,
  errors: PropTypes.object,
  register: PropTypes.func.isRequired,
};

export default function TextInputPrice({
  name,
  label = "",
  helper = "",
  errors = {},
  register,
}) {
  return (
    <div className="mb-6">
      <label
        htmlFor="price"
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm">$</span>
        </div>
        <input
          type="text"
          name={name}
          id={name}
          {...register(name)}
          className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
          placeholder="0.00"
          aria-describedby="price-currency"
        />
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm" id="price-currency">
            USD
          </span>
        </div>
      </div>
      {errors[name] && (
        <p className="mt-2 text-sm text-red-600">{errors[name].message}</p>
      )}
      {helper && <p className="mt-2 text-sm text-gray-500">{helper}</p>}
    </div>
  );
}
