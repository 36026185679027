import React from "react";
import PropTypes from "prop-types";

ModalFooter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function ModalFooter({ children }) {
  return (
    <div className="px-4 py-3 border-t sm:px-6 sm:flex sm:flex-row-reverse">
      <div className="inline-grid grid-flow-col gap-2">{children}</div>
    </div>
  );
}
