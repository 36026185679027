import React from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "context/auth-context";
import { Popover } from "@headlessui/react";
import Avatar from "../../lunar/Avatar";

export default function NavbarProfile() {
  const user = useAuthContext().getUserData();

  return (
    <Popover className="relative">
      <Popover.Button className="flex items-center max-w-xs text-sm text-white rounded-full focus:outline-none ">
        <span className="sr-only">Open user menu</span>
        {user.name && (
          <Avatar name={user.name} imageUrl={user.avatarUrl} size="sm" />
        )}
      </Popover.Button>

      <Popover.Panel className="absolute bottom-0 left-0 w-48 py-1 mb-8 ml-8 origin-bottom-left bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
        <Link
          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:no-underline"
          to="/edit-profile"
        >
          Edit Profile
        </Link>
        <Link
          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:no-underline"
          to="/logout"
        >
          Logout
        </Link>
      </Popover.Panel>
    </Popover>
  );
}
