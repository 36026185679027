import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { getUsers, getTrips } from "lib/api";
import * as Yup from "yup";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalBody from "lunar/ModalBody";
import ModalFooter from "lunar/ModalFooter";
import Button from "lunar/Button";
import InputTextArea from "lunar/InputTextArea";
import SelectSearch from "lunar/SelectSearch";
import HiddenField from "components/common/HiddenField";

CreateConversationModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  handleSaveMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
};

export default function CreateConversationModal({
  showModal,
  setShowModal,
  handleSaveMessage,
  setErrorMessage,
}) {
  const [users, setUsers] = useState([]);
  const [trips, setTrips] = useState([]);

  const schema = Yup.object().shape({
    content: Yup.string().required("Required"),
  });

  const {
    register,
    handleSubmit,
    errors,
    reset,
    control,
    setValue,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (showModal) {
      reset();
    }
  }, [showModal]);

  useEffect(() => {
    (async () => {
      getUsersForOptions();
    })();
    (async () => {
      getTripsForOptions();
    })();
  }, []);

  async function getUsersForOptions() {
    const response = await getUsers();
    response.errorMsg ? setErrorMessage(response.errorMsg) : setUsers(response);
  }

  async function getTripsForOptions() {
    const response = await getTrips();
    response.errorMsg ? setErrorMessage(response.errorMsg) : setTrips(response);
  }

  function handleTripChange(event) {
    setValue("receiver_id", event.operator);
  }

  function sortFirstLastName(a, b) {
    if (a.first_name.toLowerCase() > b.first_name.toLowerCase()) return 1;
    if (a.first_name.toLowerCase() < b.first_name.toLowerCase()) return -1;
    if (a.last_name.toLowerCase() > b.last_name.toLowerCase()) return 1;
    if (a.last_name.toLowerCase() < b.last_name.toLowerCase()) return -1;
    if (a.email > b.email) return 1;
    if (a.email < b.email) return -1;
    return 0;
  }

  return (
    <Modal open={showModal} setOpen={setShowModal}>
      <ModalTitle>Create a conversation</ModalTitle>
      <form onSubmit={handleSubmit(handleSaveMessage)} noValidate>
        <HiddenField name="receiver_id" register={register} />
        <ModalBody>
          <SelectSearch
            name="sender_id"
            label="Traveler"
            options={users.sort(sortFirstLastName).map((user) => ({
              label: `${user.first_name} ${user.last_name} (${user.email})`,
              value: user.id,
            }))}
            errors={errors}
            control={control}
          />
          <div className="mt-4">
            <SelectSearch
              name="trip_id"
              label="Trip"
              options={trips
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((trip) => ({
                  label: trip.name,
                  value: trip.id,
                  operator: trip.operator_id,
                }))}
              errors={errors}
              control={control}
              onChange={handleTripChange}
            />
          </div>
          <InputTextArea
            name="content"
            label="Message"
            errors={errors}
            register={register}
            autoFocus
            className="mt-6"
            minRows={8}
            inputClassName="h-44"
            cacheMeasurements
          />
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={isSubmitting || isSubmitSuccessful}
            isSubmitting={isSubmitting}
          >
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
