import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { updateAdventureBooking } from "lib/api";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import Button from "lunar/Button";
import InputTextArea from "lunar/InputTextArea";

CancellationPolicy.propTypes = {
  handleError: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  bookingID: PropTypes.string.isRequired,
  policyText: PropTypes.string,
};

export default function CancellationPolicy({
  policyText = "",
  bookingID,
  handleError,
  handleSuccess,
}) {
  const schema = Yup.object().shape({});
  const {
    register,
    handleSubmit,
    errors,
    reset,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      cancellation_policy: policyText,
    });
  }, [bookingID, policyText]);

  async function handleSaveNote(data) {
    const response = await updateAdventureBooking(bookingID, data);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Policy updated");
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(handleSaveNote)} noValidate>
        <div>
          <div className="text-left">
            <InputTextArea
              name="cancellation_policy"
              errors={errors}
              register={register}
              rows={3}
            />
          </div>
          <div className="flex flex-row mt-4">
            <p className="text-sm text-gray-500">
              Describe the policy for a cancellation. Basic markdown formatting
              supported.
            </p>
            <div className="flex-grow text-right">
              <Button
                variant="primary"
                type="submit"
                isSubmitting={isSubmitting}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
