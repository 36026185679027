import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useToasts } from "react-toast-notifications";
import { changeVariantPricing } from "lib/api";
import { money, humanDate, isWeekendDate } from "helpers/formatting";
import FlexibleVariantModal from "components/products/FlexibleVariantModal";
import Button from "lunar/Button";
import Table from "lunar/Table";
import TableHead from "lunar/TableHead";
import TableRow from "lunar/TableRow";
import TableCell from "lunar/TableCell";
import TableBody from "lunar/TableBody";
import TableActions from "lunar/TableActions";

FlexibleProductVariants.propTypes = {
  variants: PropTypes.array,
  productID: PropTypes.string,
  pricingType: PropTypes.string.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  handleShowAvailabilityModal: PropTypes.func.isRequired,
};

export default function FlexibleProductVariants({
  variants = [],
  productID = "",
  pricingType,
  handleSuccess,
  handleShowAvailabilityModal,
}) {
  const [showModal, setShowModal] = useState(false);
  const { addToast } = useToasts();
  const [groupedVariants, setGroupedVariants] = useState([]);
  const [pricingToEdit, setPricingToEdit] = useState(null);

  useEffect(() => {
    const arr = [];
    let price = 0;
    let weekendPrice = 0;
    let pricePerNumGuests = null;
    let weekendPricePerNumGuests = null;
    let minNightStay = null;
    let weekendMinNightStay = null;
    let startDate = null;

    for (let idx = 0; idx < variants.length; idx++) {
      const vDate = variants[idx].usage_date;
      const vPrice = variants[idx].price;
      const vPriceGuests = variants[idx].price_per_num_guests;
      const vMinNightStay = variants[idx].min_night_stay;
      const vIsWeekendDate = isWeekendDate(variants[idx].usage_date);
      if (startDate === null) {
        vIsWeekendDate ? (weekendPrice = vPrice) : (price = vPrice);
        if (vIsWeekendDate) {
          weekendPrice = vPrice;
          weekendPricePerNumGuests = vPriceGuests;
          weekendMinNightStay = vMinNightStay;
        } else {
          price = vPrice;
          pricePerNumGuests = vPriceGuests;
          minNightStay = vMinNightStay;
        }
        startDate = vDate;
      } else if (
        (vIsWeekendDate && weekendPrice !== vPrice) ||
        (!vIsWeekendDate && price !== vPrice)
      ) {
        if (vIsWeekendDate && weekendPrice === 0) {
          weekendPrice = vPrice;
          weekendPricePerNumGuests = vPriceGuests;
          weekendMinNightStay = vMinNightStay;
        } else if (!vIsWeekendDate && price === 0) {
          price = vPrice;
          pricePerNumGuests = vPriceGuests;
          minNightStay = vMinNightStay;
        } else {
          arr.push({
            price,
            pricePerNumGuests,
            weekendPrice,
            weekendPricePerNumGuests,
            minNightStay,
            weekendMinNightStay,
            startDate,
            endDate: variants[idx - 1].usage_date,
          });
          [price, weekendPrice] = vIsWeekendDate ? [0, vPrice] : [vPrice, 0];
          if (vIsWeekendDate) {
            price = 0;
            weekendPrice = vPrice;
            pricePerNumGuests = null;
            weekendPricePerNumGuests = vPriceGuests;
            minNightStay = null;
            weekendMinNightStay = vMinNightStay;
          } else {
            price = vPrice;
            weekendPrice = 0;
            pricePerNumGuests = vPriceGuests;
            weekendPricePerNumGuests = null;
            minNightStay = vMinNightStay;
            weekendMinNightStay = null;
          }
          startDate = vDate;
        }
      }
      if (idx === variants.length - 1) {
        arr.push({
          price,
          pricePerNumGuests,
          weekendPrice,
          weekendPricePerNumGuests,
          minNightStay,
          weekendMinNightStay,
          startDate,
          endDate: vDate,
        });
      }
    }
    setGroupedVariants(arr);
  }, [variants]);

  function handleAdd() {
    setPricingToEdit(null);
    setShowModal(true);
  }

  async function handleEditPricing(pricing) {
    setPricingToEdit(pricing);
    setShowModal(true);
  }

  async function handleSavePricing(data) {
    // explicitly set nulls and 0s to empty string (broken during dependency upgrade)
    if (data.price_per_num_guests !== null) {
      data.price_per_num_guests = data.price_per_num_guests.map((elem) =>
        elem == 0 || elem === null ? "" : elem
      );
      data.weekend_price_per_num_guests = data.weekend_price_per_num_guests.map(
        (elem) => (elem == 0 || elem === null ? "" : elem)
      );
    }
    // if per_day or per_person_per_day then subtract 1 to represent it accurate as min_night_stay in DB
    if (data.min_night_stay !== null && pricingType.includes("day"))
      data.min_night_stay -= 1;
    if (data.weekend_min_night_stay !== null && pricingType.includes("day"))
      data.weekend_min_night_stay -= 1;
    const response = await changeVariantPricing(data);
    if (response.errorMsg) {
      addToast(response.errorMsg, { appearance: "error" });
    } else {
      handleSuccess("Pricing updated");
    }
    setShowModal(false);
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell head first>
              Start date
            </TableCell>
            <TableCell head>End date</TableCell>
            <TableCell head>Price</TableCell>
            <TableCell head>Weekend price</TableCell>
            <TableCell head last>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groupedVariants.map((variant, idx) => (
            <TableRow key={idx}>
              <TableCell first>{humanDate(variant.startDate)}</TableCell>
              <TableCell>{humanDate(variant.endDate)}</TableCell>
              <TableCell>
                {variant.priceGuests && variant.priceGuests[0]
                  ? `${money(variant.priceGuests[0])} (1 guest)`
                  : money(variant.price)}
              </TableCell>
              <TableCell>
                {variant.weekendPriceGuests && variant.weekendPriceGuests[0]
                  ? `${money(variant.weekendPriceGuests[0])} (1 guest)`
                  : money(variant.weekendPrice)}
              </TableCell>
              <TableCell last>
                {variant && (
                  <button
                    type="button"
                    onClick={() => handleEditPricing(variant)}
                    className="text-blue-600 hover:underline"
                  >
                    Edit
                  </button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TableActions>
        <Button variant="secondary" onClick={handleAdd}>
          Add dynamic pricing
        </Button>
        <Button
          variant="secondary"
          onClick={handleShowAvailabilityModal}
          className="ml-2"
        >
          Edit availability
        </Button>
      </TableActions>
      <FlexibleVariantModal
        productID={productID}
        pricingType={pricingType}
        handleSavePricing={handleSavePricing}
        setShowModal={setShowModal}
        showModal={showModal}
        pricing={pricingToEdit}
      />
    </>
  );
}
