import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { updateAdventureBooking } from "lib/api";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import Button from "lunar/Button";
import InputText from "lunar/InputText";
import InputTextAddon from "lunar/InputTextAddon";
import InputTextArea from "lunar/InputTextArea";

ConfirmationDetails.propTypes = {
  handleError: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  bookingID: PropTypes.string.isRequired,
  url: PropTypes.string,
  guidePayoutAmount: PropTypes.number,
  guidePayoutDate: PropTypes.string,
  guidePayoutNotes: PropTypes.string,
};

export default function ConfirmationDetails({
  url = "",
  guidePayoutAmount = null,
  guidePayoutDate = "",
  guidePayoutNotes = "",
  bookingID,
  handleError,
  handleSuccess,
}) {
  const schema = Yup.object().shape({});
  const {
    register,
    handleSubmit,
    errors,
    reset,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      details_url: url,
      guide_payout_amount: guidePayoutAmount,
      guide_payout_date: guidePayoutDate,
      guide_payout_notes: guidePayoutNotes,
    });
  }, [bookingID]);

  async function handleSaveUrl(data) {
    const response = await updateAdventureBooking(bookingID, data);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Confirmation information updated");
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(handleSaveUrl)} noValidate>
        <div>
          <div>
            <div>
              <InputText
                name="details_url"
                type="text"
                errors={errors}
                register={register}
                className="w-full"
                label="Notion page"
                helper="This link will be sent to both traveler and guide via WhatsApp as well as shown in Mavericks."
              />
            </div>
            <div className="flex gap-10 mt-10">
              <div>
                <InputTextArea
                  name="guide_payout_notes"
                  errors={errors}
                  register={register}
                  minRows={6}
                  label="Mavericks guide note"
                  helper="Additional information shared with the guide in Mavericks. Limit 500 characters."
                />
              </div>
              <div>
                <div>
                  <InputTextAddon
                    name="guide_payout_amount"
                    type="number"
                    step="0.01"
                    errors={errors}
                    register={register}
                    addonLeft="$"
                    label="Payout amount"
                    placeholder="3000.00"
                  />
                </div>
                <div className="mt-6">
                  <InputText
                    name="guide_payout_date"
                    type="date"
                    errors={errors}
                    register={register}
                    label="Payout date"
                    placeholder="yyyy-mm-dd"
                  />
                </div>
              </div>
            </div>
            <div className="mt-6">
              <Button
                variant="primary"
                type="submit"
                isSubmitting={isSubmitting}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
