import React from "react";
import PropTypes from "prop-types";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalBody from "lunar/ModalBody";
import ModalFooter from "lunar/ModalFooter";
import Button from "lunar/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import TextArea from "lunar/InputTextArea";
import Alert from "lunar/Alert";
import HiddenField from "components/common/HiddenField";

ApproveModal.propTypes = {
  showApproveModal: PropTypes.bool.isRequired,
  setShowApproveModal: PropTypes.func.isRequired,
  handleApprove: PropTypes.func.isRequired,
  senderType: PropTypes.string,
  receiverID: PropTypes.string,
  receiverType: PropTypes.string,
  senderID: PropTypes.number,
  bookingID: PropTypes.string,
};

export default function ApproveModal({
  showApproveModal,
  setShowApproveModal,
  handleApprove,
  senderID,
  senderType,
  receiverID,
  receiverType,
  bookingID,
}) {
  const schema = Yup.object().shape({});

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      receiver_id: receiverID,
      receiver_type: receiverType,
      sender_type: senderType,
      sender_id: senderID,
      booking_id: bookingID,
    },
  });

  return (
    <Modal open={showApproveModal} setOpen={setShowApproveModal}>
      <ModalTitle>Message to traveler</ModalTitle>
      <form onSubmit={handleSubmit(handleApprove)} noValidate>
        <ModalBody>
          <Alert
            type="warning"
            title="Please message when approving"
            body="You can approve a reservation without sending a message. However, in almost all cases, a traveler message should be included with every approval."
          />
          <HiddenField name="receiver_id" register={register} />
          <HiddenField name="receiver_type" register={register} />
          <HiddenField name="sender_type" register={register} />
          <HiddenField name="sender_id" register={register} />
          <HiddenField name="booking_id" register={register} />
          <TextArea
            name="content"
            errors={errors}
            register={register}
            helper="Supports basic markdown formatting"
            className="mt-6"
          />
        </ModalBody>
        <ModalFooter>
          <Button
            variant="secondary"
            onClick={() => setShowApproveModal(false)}
          >
            Cancel
          </Button>
          <Button type="submit" isSubmitting={isSubmitting}>
            Approve
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
