import React from "react";
import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";
import styles from "lunar/Markdown.module.scss";

Markdown.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function Markdown({ children }) {
  return (
    <div className={styles.component}>
      <ReactMarkdown linkTarget="_blank">{children}</ReactMarkdown>
    </div>
  );
}
