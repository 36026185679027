import React from "react";
import PropTypes from "prop-types";

CardBody.propTypes = {
  children: PropTypes.node,
};

export default function CardBody({ children = ""}) {
  return <div className="px-4 py-5 sm:p-6">{children}</div>;
}
