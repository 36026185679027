import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalBody from "lunar/ModalBody";
import ModalFooter from "lunar/ModalFooter";
import Button from "lunar/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import HiddenField from "components/common/HiddenField";
import InputText from "lunar/InputText";
import InputTextAddon from "lunar/InputTextAddon";
import InputTextArea from "lunar/InputTextArea";
import SelectField from "lunar/SelectField";

RefundModal.propTypes = {
  setShowRefundModal: PropTypes.func.isRequired,
  handleSaveRefund: PropTypes.func.isRequired,
  showRefundModal: PropTypes.bool.isRequired,
  bookingID: PropTypes.string.isRequired,
};

export default function RefundModal({
  showRefundModal,
  setShowRefundModal,
  handleSaveRefund,
  bookingID,
}) {
  const schema = Yup.object().shape({
    amount: Yup.number().notOneOf([0], "Can't be zero").required("Required"),
  });

  const {
    reset,
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      adventure_booking_id: bookingID,
    });
  }, [bookingID]);

  return (
    <Modal open={showRefundModal} setOpen={setShowRefundModal}>
      <ModalTitle>Create a refund</ModalTitle>
      <form onSubmit={handleSubmit(handleSaveRefund)} noValidate>
        <ModalBody>
          <HiddenField name="adventure_booking_id" register={register} />
          <SelectField
            name="refund_type"
            label="Refund type"
            options={[
              { id: "credit_card", name: "Credit card" },
              { id: "wire", name: "Wire" },
            ]}
            errors={errors}
            register={register}
          />
          <InputTextAddon
            name="amount"
            type="text"
            label="Amount"
            errors={errors}
            register={register}
            addonLeft="$"
            className="mt-6"
          />
          <InputText
            name="refund_reference_number"
            type="text"
            label="Reference number"
            errors={errors}
            register={register}
            className="mt-6"
          />
          <InputTextArea
            name="reason"
            label="Reason"
            errors={errors}
            register={register}
            className="mt-6"
          />
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={() => setShowRefundModal(false)}>
            Cancel
          </Button>
          <Button type="submit" isSubmitting={isSubmitting}>
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
