import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { format, eachDayOfInterval } from "date-fns";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import Toggle from "./Toggle";
import OptionList from "./OptionList";
import styles from "./EditForm.module.scss";

export default function EditForm({
  selectedDates,
  handleChangeStartDate,
  handleChangeEndDate,
  formAction,
  setFormAction,
  actionSettings,
  blockedAvailabilities,
}) {
  const [editMode, setEditMode] = useState(null);
  const [numbSelectedDates, setNumbSelectedDates] = useState(0);

  useEffect(() => {
    if (selectedDates[0] && selectedDates[1]) {
      const dateRange = eachDayOfInterval({
        start: selectedDates[0],
        end: selectedDates[1],
      });
      const formattedDateRange = dateRange.map((date) =>
        format(date, "yyyy-MM-dd")
      );

      setNumbSelectedDates(formattedDateRange.length);

      const selectedBlockedDates = blockedAvailabilities.filter((blockedDate) =>
        formattedDateRange.includes(blockedDate.usage_date)
      );

      if (selectedBlockedDates.length < 1) {
        setEditMode("open");
      } else if (
        selectedBlockedDates.length > 0 &&
        selectedBlockedDates.length === formattedDateRange.length
      ) {
        setEditMode("blocked");
      } else {
        setEditMode("mixed");
      }
    }
  }, [selectedDates, blockedAvailabilities]);

  function formatDateToYearMonthDay(date) {
    if (date) return format(date, "yyyy-MM-dd");
    return null;
  }

  return (
    <div className={styles.component}>
      <label htmlFor="start_date" className={styles.dateWrapper}>
        <span className={styles.dateLabel}>Selected dates</span>
        <div className={styles.dateInputs}>
          <input
            type="date"
            name="start_date"
            className={styles.dateInput}
            value={formatDateToYearMonthDay(selectedDates[0]) || ""}
            onChange={handleChangeStartDate}
          />
          <ArrowLongRightIcon className={styles.dateArrow} />
          <input
            type="date"
            name="end_date"
            className={styles.dateInput}
            value={formatDateToYearMonthDay(selectedDates[1]) || ""}
            onChange={handleChangeEndDate}
          />
        </div>
      </label>

      {editMode === "mixed" ? (
        <OptionList
          selectedOption={formAction}
          handleChange={setFormAction}
          actionSettings={actionSettings}
        />
      ) : (
        <Toggle
          checked={editMode === "blocked"}
          handleChange={setFormAction}
          numbDates={numbSelectedDates}
        />
      )}
    </div>
  );
}

EditForm.propTypes = {
  selectedDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  handleChangeStartDate: PropTypes.func.isRequired,
  handleChangeEndDate: PropTypes.func.isRequired,
  formAction: PropTypes.shape({
    name: PropTypes.string,
  }),
  setFormAction: PropTypes.func.isRequired,
  actionSettings: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  blockedAvailabilities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      status: PropTypes.string,
      usage_date: PropTypes.string,
    })
  ),
};
