import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useToasts } from "react-toast-notifications";
import { createBooking } from "lib/api";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import Button from "lunar/Button";
import NewForm from "components/bookings/NewForm";

NewModal.propTypes = {
  populatePageBookings: PropTypes.func.isRequired,
};

export default function NewModal({populatePageBookings}) {
  const [showNewModal, setShowNewModal] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const { addToast } = useToasts();

  async function submitForm(data) {
    const response = await createBooking(data);
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      addToast("Booking successfully created", { appearance: "success" });
      setShowNewModal(false);
      populatePageBookings();
    }
  }

  useEffect(() => {
    errorMessage && addToast(errorMessage, { appearance: "error" });
  }, [errorMessage]);

  return (
    <>
      <Button onClick={() => setShowNewModal(true)}>New reservation</Button>
      <Modal open={showNewModal} setOpen={setShowNewModal}>
        <ModalTitle>Create a new reservation</ModalTitle>
        <NewForm
          setErrorMessage={setErrorMessage}
          submitForm={submitForm}
          setShowNewModal={setShowNewModal}
        />
      </Modal>
    </>
  );
}
