import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalBody from "lunar/ModalBody";
import ModalFooter from "lunar/ModalFooter";
import Button from "lunar/Button";
import Alert from "lunar/Alert";
import InputTextArea from "lunar/InputTextArea";

EditModal.propTypes = {
  showEditModal: PropTypes.bool.isRequired,
  setShowEditModal: PropTypes.func.isRequired,
  handleSaveMessage: PropTypes.func.isRequired,
  messageContent: PropTypes.string,
};

export default function EditModal({
  showEditModal,
  setShowEditModal,
  handleSaveMessage,
  messageContent,
}) {
  const schema = Yup.object().shape({
    content: Yup.string().required("Required"),
  });

  const {
    register,
    handleSubmit,
    errors,
    reset,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      content: messageContent,
    },
  });

  useEffect(() => {
    if (showEditModal) reset();
  }, [showEditModal]);

  return (
    <Modal open={showEditModal} setOpen={setShowEditModal}>
      <ModalTitle>Edit traveler message</ModalTitle>
      <form onSubmit={handleSubmit(handleSaveMessage)} noValidate>
        <ModalBody>
          <Alert
            type="warning"
            title="Be careful"
            body="Any edits here will be visible to the traveler.  We should use this very sparingly."
          />
          <InputTextArea
            name="content"
            label="Message"
            errors={errors}
            register={register}
            autoFocus
            className="mt-6"
            inputClassName="h-48"
          />
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={isSubmitting || isSubmitSuccessful}
            isSubmitting={isSubmitting}
          >
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
