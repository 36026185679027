import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { getAdventures, getEventVariants } from "lib/api";
import * as Yup from "yup";
import InputText from "lunar/InputText";
import SelectSearch from "lunar/SelectSearch";
import SelectField from "lunar/SelectField";
import Button from "lunar/Button";
import ModalFooter from "lunar/ModalFooter";
import ModalBody from "lunar/ModalBody";
import { formatShortDateRange } from "helpers/formatting";

NewForm.propTypes = {
  submitForm: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  setShowNewModal: PropTypes.func.isRequired,
};

export default function NewForm({
  submitForm,
  setErrorMessage,
  setShowNewModal,
}) {
  const [adventures, setAdventures] = useState([]);
  const [eventVariants, setEventVariants] = useState([]);
  const [selectedTab, setSelectedTab] = useState("Adventures");

  const schema = Yup.object()
    .shape({
      first_name: Yup.string().required("Required"),
      last_name: Yup.string().required("Required"),
      email: Yup.string().required("Required"),
    })
    .test("adventure_or_event", (value, { createError }) =>
      (value.adventure_id && value.adventure_id.length > 0) ||
      (value.event_variant_id && value.event_variant_id.length > 0)
        ? true
        : createError({
            path: "adventure_or_event",
            message: "Adventure or event required",
          })
    )
    .test("num_travelers", (value, { createError }) =>
      !value.event_variant_id ||
      value.event_variant_id.length === 0 ||
      value.num_adults > 0
        ? true
        : createError({
            path: "num_travelers",
            message: "1 or more adults required",
          })
    );

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    (async () => {
      getAdventuresForOptions();
      getEventVariantsForOptions();
    })();
  }, []);

  async function getAdventuresForOptions() {
    const response = await getAdventures();
    response.errorMsg
      ? setErrorMessage(response.errorMsg)
      : setAdventures(response);
  }

  async function getEventVariantsForOptions() {
    const response = await getEventVariants();
    response.errorMsg
      ? setErrorMessage(response.errorMsg)
      : setEventVariants(response);
  }

  function processSubmitForm(data) {
    if (selectedTab === "Events") {
      delete data.adventure_id;
    } else {
      delete data.event_variant_id;
      delete data.num_adults;
      delete data.num_children;
    }
    submitForm(data);
  }

  function handleTabChange(tab) {
    setSelectedTab(tab);
  }

  return (
    <form onSubmit={handleSubmit(processSubmitForm)} noValidate>
      <ModalBody>
        <div className="grid gap-6 divide-y">
          <div>
            <div className="w-full inline-grid grid-cols-2 gap-6 mt-4">
              <InputText
                name="first_name"
                type="text"
                label="First name"
                errors={errors}
                register={register}
              />

              <InputText
                name="last_name"
                type="text"
                label="Last name"
                errors={errors}
                register={register}
              />
            </div>
            <div className="mt-4">
              <InputText
                name="email"
                type="email"
                label="Email"
                errors={errors}
                register={register}
              />
            </div>
          </div>
          <div className="pt-2">
            {errors.adventure_or_event && (
              <p className="mt-2 text-sm text-red-600">
                {errors.adventure_or_event.message}
              </p>
            )}
            {errors.num_travelers && (
              <p className="mt-2 text-sm text-red-600">
                {errors.num_travelers.message}
              </p>
            )}
            <div>
              <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                  Select a tab
                </label>
                {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                <select
                  id="tabs"
                  name="tabs"
                  className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  defaultValue="Adventures"
                  onChange={handleTabChange}
                >
                  <option key="Adventures">Adventure</option>
                  <option key="Events">Event</option>
                </select>
              </div>
              <div className="hidden sm:block">
                <div className="border-b border-gray-200">
                  <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                    <a
                      className={cn(
                        selectedTab === "Adventures"
                          ? "border-blue-500 text-blue-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer"
                      )}
                      aria-current={
                        selectedTab === "Adventures" ? "page" : undefined
                      }
                      onClick={() => handleTabChange("Adventures")}
                    >
                      Adventure
                    </a>
                    <a
                      className={cn(
                        selectedTab === "Events"
                          ? "border-blue-500 text-blue-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer"
                      )}
                      aria-current={
                        selectedTab === "Events" ? "page" : undefined
                      }
                      onClick={() => handleTabChange("Events")}
                    >
                      Event
                    </a>
                  </nav>
                </div>
              </div>
            </div>
            {selectedTab === "Adventures" && (
              <div className="mt-4">
                <SelectSearch
                  name="adventure_id"
                  label="Name"
                  options={adventures
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((adventure) => ({
                      label: adventure.name,
                      value: adventure.id,
                    }))}
                  errors={errors}
                  control={control}
                />
              </div>
            )}
            {selectedTab === "Events" && (
              <>
                <div className="mt-4">
                  <SelectSearch
                    name="event_variant_id"
                    label="Name"
                    options={eventVariants
                      .filter(
                      (eventVariant) => new Date(eventVariant.start_date) > new Date()
                      )
                      .sort((a, b) => (a.start_date > b.start_date ? 1 : -1))
                      .map((eventVariant) => ({
                        label: `${
                          eventVariant.event_name
                        }: ${formatShortDateRange(
                          eventVariant.start_date,
                          eventVariant.end_date
                        )}`,
                        value: eventVariant.id,
                      }))}
                    errors={errors}
                    control={control}
                  />
                </div>
                <div className="inline-grid grid-cols-3 gap-6 mt-4">
                  <SelectField
                    name="num_adults"
                    label="# of adults"
                    options={Array.from(Array(21).keys())}
                    errors={errors}
                    register={register}
                    className="w-24"
                  />
                  <SelectField
                    name="num_children"
                    label="# of children"
                    options={Array.from(Array(21).keys())}
                    errors={errors}
                    register={register}
                    className="w-24"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="secondary" onClick={() => setShowNewModal(false)}>
          Cancel
        </Button>
        <Button type="submit">Save</Button>
      </ModalFooter>
    </form>
  );
}
