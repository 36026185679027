import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getAdventures } from "lib/api";
import Table from "lunar/Table";
import TableHead from "lunar/TableHead";
import TableRow from "lunar/TableRow";
import TableCell from "lunar/TableCell";
import TableBody from "lunar/TableBody";
import AdventureSelect from "components/accommodations/AdventureSelect";

AdventureTable.propTypes = {
  adventures: PropTypes.array,
  handleAddAdventure: PropTypes.func.isRequired,
  handleRemoveAdventure: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
};

export default function AdventureTable({
  adventures = [],
  handleAddAdventure,
  handleRemoveAdventure,
  handleError,
}) {
  const [allAdventures, setAllAdventures] = useState([]);
  const [selectedAdventure, setSelectedAdventure] = useState(null);
  const [addCount, setAddCount] = useState(0);
  const [availabileAdventures, setAvailableAdventures] = useState([]);

  useEffect(() => {
    fetchAdventures();
  }, []);

  useEffect(() => {
    // get adventures that are not already associated
    const filteredAdventures = allAdventures.filter(
      (a) =>
        adventures.filter((e) => e.id.toString() === a.id.toString()).length ==
        0
    );

    setAvailableAdventures(filteredAdventures);
  }, [adventures, allAdventures]);

  async function fetchAdventures() {
    // fetch all adventures data
    const adventureResponse = await getAdventures();
    if (adventureResponse.errorMsg) {
      handleError(adventureResponse.errorMsg);
    } else {
      setAllAdventures(adventureResponse);
    }
  }

  function handleAddClick() {
    handleAddAdventure(selectedAdventure);
    setAddCount(addCount + 1);
  }

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell head first>
              Adventure name
            </TableCell>
            <TableCell head last>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {adventures.map((adventure) => (
            <TableRow key={adventure.id}>
              <TableCell first>{adventure.name}</TableCell>
              <TableCell last>
                <button
                  type="button"
                  onClick={() =>
                    handleRemoveAdventure(adventure.association_id)
                  }
                  className="text-gray-600 hover:underline"
                >
                  Delete
                </button>
              </TableCell>
            </TableRow>
          ))}
          {adventures.length < 1 && (
            <TableRow>
              <TableCell first>
                <div className="text-gray-400 italic">
                  No adventures associated with this accommodation yet.
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <AdventureSelect
            adventures={availabileAdventures}
            onChange={setSelectedAdventure}
            watchAdd={addCount}
          />
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-4 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
            onClick={handleAddClick}
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
}
