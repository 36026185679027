/* eslint-disable react/display-name, react/prop-types */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLoadingContext } from "context/loading-context";
import { deleteReview, getReviews } from "lib/api";
import { humanDate } from "helpers/formatting";
import { useToasts } from "react-toast-notifications";
import { ErrorAlert } from "components/common/Alerts";
import PageListNav from "components/common/PageListNav";
import DataTable from "lunar/DataTable";
import DataTableSearch from "lunar/DataTableSearch";
import Meta from "lunar/Meta";
import NewModal from "components/reviews/NewModal";
import ListStat from "components/common/ListStat";

export default function Reviews() {
  const [reviews, setReviews] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const { addToast } = useToasts();
  const [globalFilter, setGlobalFilter] = useState("");
  const { setLoading } = useLoadingContext();

  useEffect(() => {
    (async () => {
      populatePageReviews();
    })();
  }, []);

  async function confirmDeleteReview(id) {
    if (window.confirm("Are you sure you want to delete this review?")) {
      const response = await deleteReview(id);
      if (response.errorMsg) {
        setErrorMessage(response.errorMsg);
      } else {
        addToast("Review successfully deleted", { appearance: "success" });
        populatePageReviews();
      }
    }
  }

  async function populatePageReviews() {
    setLoading(true);
    const response = await getReviews();
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      setReviews(
        response.sort((a, b) => (a.created_at > b.created_at ? 1 : -1))
      );
    }
    setLoading(false);
  }

  const columns = React.useMemo(() => [
    {
      Header: "Traveler",
      accessor: "traveler",
    },
    {
      Header: "Trip",
      accessor: "trip",
    },
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "Actions",
      accessor: "actionsId",
      Cell: ({ row: { original } }) => {
        return (
          <>
            <Link
              className="text-blue-600 hover:text-blue-900"
              to={`/reviews/edit/${original.id}`}
            >
              Edit
            </Link>
            <button
              onClick={() => confirmDeleteReview(original.id)}
              className="ml-3 text-red-600 hover:text-red-900"
            >
              Delete
            </button>
          </>
        );
      },
    },
  ]);

  return (
    <>
      <Meta title={reviews && `All ${reviews.length} reviews`} />
      <div className="min-h-screen overflow-y-scroll">
        <PageListNav
          dataTableSearch={
            <DataTableSearch
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              placeholder="Search for reviews"
            />
          }
          actions={
            <div className="grid items-center grid-flow-col gap-6">
              {reviews && (
                <ListStat color="gray" count={reviews?.length} text="Total" />
              )}
              <div className="ml-4">
                <NewModal populatePageReviews={populatePageReviews} />
              </div>
            </div>
          }
        />
        <ErrorAlert message={errorMessage} />
        {reviews && (
          <DataTable
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            data={reviews.map((review) => ({
              id: review.id,
              traveler: `${review.user.first_name} ${review.user.last_name}`,
              trip: review.trip.name,
              date: humanDate(review.created_at),
            }))}
            columns={columns}
            sortOptions={{ id: "id", desc: true }}
          />
        )}
      </div>
    </>
  );
}
