import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

InputTextWithButton.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helper: PropTypes.node,
  type: PropTypes.string.isRequired,
  errors: PropTypes.object,
  register: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  icon: PropTypes.node,
  buttonUrl: PropTypes.string,
  alignButton: PropTypes.string,
};

export default function InputTextWithButton({
  name,
  label = "",
  helper = "",
  type,
  errors = {},
  register,
  disabled = false,
  placeholder = "",
  maxLength = undefined,
  onBlur = undefined,
  className = undefined,
  icon,
  buttonUrl,
  alignButton = "left",
}) {
  return (
    <div className={className}>
        <div className="flex flex-col">
            <label htmlFor={name} className="block text-sm font-medium text-gray-700">
                {label}
            </label>
            <div className="flex">
                {buttonUrl &&
                    <a
                        href={buttonUrl}
                        className={`flex items-center py-2.5 px-4 mt-1 mr-2 rounded-md border border-gray-300 shadow-sm bg-white hover:bg-gray-50 ${alignButton === "right" && "order-1 ml-2"}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <span className="block w-4 h-4 text-gray-400">
                            {icon}
                        </span>
                    </a>
                }
                <input
                    id={name}
                    name={name}
                    type={type}
                    placeholder={placeholder}
                    {...register(name)}
                    readOnly={disabled}
                    maxLength={maxLength}
                    onBlur={onBlur}
                    className={cn(
                    "mt-1 shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-400",
                    { "bg-gray-200 cursor-not-allowed": disabled }
                    )}
                />
            </div>
        </div>
      {errors[name] && (
        <p className="mt-2 text-xs text-red-600">{errors[name].message}</p>
      )}
      {helper && <p className="mt-2 text-xs text-gray-500">{helper}</p>}
    </div>
  );
}
