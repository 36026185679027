import React from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import Main from "components/common/Main";
import ChangePassword from "components/passwords/Change";

ResetPassword.propTypes = {
  token: PropTypes.string,
};

export default function ResetPassword(props) {
  const location = useLocation();
  const params = queryString.parse(location.search);
  return (
    <Main>
      <ChangePassword token={params.token} {...props} />;
    </Main>
  );
}
