import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

ListStat.propTypes = {
  color: PropTypes.string.isRequired,
  count: PropTypes.number,
  text: PropTypes.string.isRequired,
};

export default function ListStat({ color, count, text }) {
  let style = null;
  switch (color) {
    case "green":
      style = "bg-green-400";
      break;
    case "yellow":
      style = "bg-yellow-400";
      break;
    case "red":
      style = "bg-red-400";
      break;
    default:
      style = "bg-gray-400";
      break;
  }

  return (
    <div className="grid items-center grid-flow-col text-xs font-bold text-gray-400">
      <span
        className={cn("mr-2 inline-block w-2.5 h-2.5 rounded-full", style)}
      />
      {/* <span>{`${count} ${text}`}</span> */}
      {/* <span>{`${count} ${text}`}</span> */}
      <span>{count}</span>
      <span className="hidden ml-1">{text}</span>
    </div>
  );
}
