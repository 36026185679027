import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import TextField from "components/common/TextField";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import { forgotPassword } from "lib/api";
import { ErrorAlert } from "components/common/Alerts";
import Card from "components/common/Card";
import CardBody from "components/common/CardBody";
import CardTitle from "components/common/CardTitle";
import Button from "lunar/Button";

export default function ForgotPassword() {
  const [errorMessage, setErrorMessage] = useState("");
  const { addToast } = useToasts();
  const navigate = useNavigate();

  const schema = Yup.object().shape({
    email: Yup.string().required("Required").email("Invalid Email"),
  });

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  async function submitRequest(data) {
    const response = await forgotPassword(data);
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      addToast("Reset link sent", { appearance: "success" });
      navigate("/login");
    }
  }
  return (
    <>
      <Card>
        <CardBody>
          <CardTitle>Forgot Password</CardTitle>
          <ErrorAlert message={errorMessage} />
          <form onSubmit={handleSubmit(submitRequest)} noValidate>
            <TextField
              name="email"
              type="email"
              label="Email"
              errors={errors}
              register={register}
            />
            <div className="mt-4">
              <Button type="submit" isSubmitting={isSubmitting}>
                Send reset link
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    </>
  );
}
