import React from "react";
import PropTypes from "prop-types";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalBody from "lunar/ModalBody";
import ModalFooter from "lunar/ModalFooter";
import Button from "lunar/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import TextField from "components/common/TextField";

CreateAdjustmentModal.propTypes = {
  showAdjustmentModal: PropTypes.bool.isRequired,
  setShowAdjustmentModal: PropTypes.func.isRequired,
  handleSaveAdjustment: PropTypes.func.isRequired,
};

export default function CreateAdjustmentModal({
  showAdjustmentModal,
  setShowAdjustmentModal,
  handleSaveAdjustment,
}) {
  const schema = Yup.object().shape({
    label: Yup.string().required("Required"),
    amount: Yup.number().notOneOf([0], "Can't be zero").required("Required"),
  });

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <Modal open={showAdjustmentModal} setOpen={setShowAdjustmentModal}>
      <ModalTitle>Create an adjustment</ModalTitle>
      <form onSubmit={handleSubmit(handleSaveAdjustment)} noValidate>
        <ModalBody>
          <TextField
            name="label"
            type="text"
            label="Name"
            helper="No more than 5 words. This text is displayed to customers in reservation pricing breakdowns."
            errors={errors}
            register={register}
          />
          <TextField
            name="amount"
            type="text"
            helper="Can be positive or negative value. For example, use -300 to discount a reservation by $300."
            label="Amount ($)"
            errors={errors}
            register={register}
          />
          <TextField
            name="notes"
            type="text"
            helper="Not displayed to customer. Use this to include notes for customer support and reporting."
            label="Notes"
            errors={errors}
            register={register}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            variant="secondary"
            onClick={() => setShowAdjustmentModal(false)}
          >
            Cancel
          </Button>
          <Button type="submit" isSubmitting={isSubmitting}>
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
