import React from "react";
import PropTypes from "prop-types";
import GalleryCardImage from "lunar/gallery/GalleryCardImage";

const GalleryCardsDragPreview = ({ cards = [] }) => {
  return (
    <div>
      {cards.slice(0, 3).map((card, i) => (
        <div
          key={card.id}
          className="absolute origin-bottom-left"
          style={{
            zIndex: cards.length - i,
            transform: `rotateZ(${-i * 2.5}deg)`,
          }}
        >
          <GalleryCardImage imageObj={card} />
        </div>
      ))}
    </div>
  );
};

GalleryCardsDragPreview.propTypes = {
  cards: PropTypes.array,
};

export default GalleryCardsDragPreview;
