/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { Transition } from "@headlessui/react";
import PropTypes from "prop-types";

Loading.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default function Loading({ show }) {
  return (
    <>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end"
      >
        <Transition
          appear={true}
          show={show}
          as="div"
          enter="transition ease-out duration-100 "
          enterFrom="opacity-90"
          enterTo="opacity-100"
          leave="transition ease-in delay-300 duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute bottom-6 right-6 bg-blue-600 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="p-4">
              <div className="block">
                <svg
                  className="animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </>
  );
}
