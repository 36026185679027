import React from "react";
import PropTypes from "prop-types";

ModalFooter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function ModalFooter({ children }) {
  return (
    <div className="px-4 pt-5 pb-4 border-b border-gray-200 sm:p-6 sm:pb-4 bb-1">
      <h3 className="text-lg font-bold text-gray-900">{children}</h3>
    </div>
  );
}
