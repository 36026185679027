import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import {
  getAdventureReview,
  updateAdventureReview,
  deleteAdventureReviewImages,
  updateAdventureReviewImagePositions,
  getAdventures,
} from "lib/api";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useLoadingContext } from "context/loading-context";
import { useToasts } from "react-toast-notifications";
import { getCodeList } from "country-list";
import { FormProvider } from "context/form-context";
import { humanDate } from "helpers/formatting";
import InputTextArea from "lunar/InputTextArea";
import InputText from "lunar/InputText";
import Meta from "lunar/Meta";
import Form from "lunar/Form";
import FormTitle from "lunar/FormTitle";
import FormSections from "lunar/FormSections";
import FormSection from "lunar/FormSection";
import FormSectionHeader from "lunar/FormSectionHeader";
import FormSectionBody from "lunar/FormSectionBody";
import FormRow from "lunar/FormRow";
import SelectField from "lunar/SelectField";
import Gallery from "lunar/gallery/Gallery";
import SelectSearch from "lunar/SelectSearch";

export default function Edit() {
  const [review, setReview] = useState({});
  const [adventures, setAdventures] = useState([]);
  const reviewID = useParams().id;
  const { addToast } = useToasts();
  const { setLoading } = useLoadingContext();

  const schema = Yup.object().shape({
    user_name: Yup.string().required("Required"),
  });

  const {
    control,
    register,
    errors,
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (review) {
      reset({
        user_name: review.user_name,
        user_subheader: review.user_subheader,
        user_country: review.user_country,
        duration: review.duration,
        accommodation: review.accommodation,
        surf_ability: review.surf_ability,
        description: review.description,
        adventure_id: String(review.adventure_id),
      });
    }
  }, [reset, review]);

  useEffect(() => {
    (async () => {
      populateReviewState(reviewID);
    })();
  }, [reviewID]);

  useEffect(() => {
    (async () => {
      getAdventuresForOptions();
    })();
  }, []);

  async function getAdventuresForOptions() {
    const response = await getAdventures();
    response.errorMsg
      ? handleError(response.errorMsg)
      : setAdventures(response);
  }

  async function populateReviewState(reviewID) {
    setLoading(true);
    const response = await getAdventureReview(reviewID);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      setReview(response);
    }
    setLoading(false);
  }

  function handleSuccess(msg) {
    populateReviewState(reviewID);
    addToast(msg, { appearance: "success" });
  }

  function handleError(msg) {
    addToast(msg, { appearance: "error" });
  }

  async function submitForm(data) {
    const response = await updateAdventureReview(reviewID, data);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Review successfully updated");
    }
  }

  // create an array of country codes / names for the SelectField
  const countryList = Object.entries(getCodeList())
    .map((e) => ({
      id: e[0],
      name: e[1],
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  // make the US the first in the list
  const usIdx = countryList.findIndex((e) => e.id === "us");
  const usEl = countryList[usIdx];
  countryList.splice(usIdx, 1);
  countryList.unshift(usEl);

  return (
    <DndProvider backend={HTML5Backend}>
      <Meta title={review.user_name} />
      <FormProvider
        lastUpdated={review.updated_at && humanDate(review.updated_at)}
      >
        <Form
          handleSubmit={handleSubmit}
          submitForm={submitForm}
          isSubmitting={isSubmitting}
        >
          <FormTitle title={"Traveler review"} />
          <FormSections>
            <FormSection>
              <FormSectionHeader
                title="Adventure"
                description="This review relates to following adventure."
              />
              <FormSectionBody>
                <FormRow className="grid grid-flow-row grid-cols-2">
                  <SelectSearch
                    name="adventure_id"
                    label="Adventure"
                    helper="Choose the adventure to see"
                    options={adventures
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((adventure) => ({
                        label: adventure.name,
                        value: adventure.id,
                      }))}
                    errors={errors}
                    control={control}
                  />
                </FormRow>
              </FormSectionBody>
            </FormSection>
            <FormSection>
              <FormSectionHeader
                title="Traveler"
                description="Info about traveler who left the review."
              />
              <FormSectionBody className="grid gap-6">
                <FormRow className="grid grid-flow-row grid-cols-2">
                  <InputText
                    name="user_name"
                    type="text"
                    label="Traveler name"
                    errors={errors}
                    register={register}
                    className="flex-grow"
                  />
                  <InputText
                    name="user_subheader"
                    type="text"
                    label="Traveler subheader"
                    errors={errors}
                    register={register}
                    className="flex-grow"
                  />
                </FormRow>
                <FormRow className="grid grid-flow-row grid-cols-2">
                  <SelectField
                    name="user_country"
                    label="Country"
                    options={countryList}
                    errors={errors}
                    register={register}
                    className="w-full"
                  />
                  <SelectField
                    name="surf_ability"
                    label="Surf ability"
                    options={["Casual surfers", "Avid surfers"]}
                    errors={errors}
                    register={register}
                    className="w-full"
                  />
                </FormRow>
              </FormSectionBody>
            </FormSection>
            <FormSection>
              <FormSectionHeader
                title="Review"
                description="Traveler review info."
              />
              <FormSectionBody className="grid gap-6">
                <FormRow className="grid grid-flow-row grid-cols-2">
                  <InputText
                    name="duration"
                    type="text"
                    label="Duration"
                    errors={errors}
                    register={register}
                    className="flex-grow"
                  />
                  <InputText
                    name="accommodation"
                    type="text"
                    label="Accommodation"
                    errors={errors}
                    register={register}
                    className="flex-grow"
                  />
                </FormRow>
                <FormRow className="grid grid-flow-row">
                  <InputTextArea
                    name="description"
                    label="Review text"
                    errors={errors}
                    register={register}
                    rows={10}
                  />
                </FormRow>
              </FormSectionBody>
            </FormSection>
            <FormSection>
              <FormSectionHeader
                title="Photos"
                description="These will be posted with the review."
              />
              <FormSectionBody>
                <FormRow>
                  <Gallery
                    parentID={reviewID}
                    images={review.adventure_review_images}
                    handleError={handleError}
                    handleSuccess={handleSuccess}
                    updateFunc={updateAdventureReview}
                    deleteFunc={deleteAdventureReviewImages}
                    updatePositionsFunc={updateAdventureReviewImagePositions}
                    imagesAttributeName="adventure_review_images_attributes"
                    control={control}
                    setValue={setValue}
                  />
                </FormRow>
              </FormSectionBody>
            </FormSection>
          </FormSections>
        </Form>
      </FormProvider>
    </DndProvider>
  );
}
