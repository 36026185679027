import React, { useState } from "react";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import HiddenField from "components/common/HiddenField";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalBody from "lunar/ModalBody";
import ModalFooter from "lunar/ModalFooter";
import Button from "lunar/Button";
import Toggle from "lunar/Toggle";

ReminderModal.propTypes = {
  showReminderModal: PropTypes.bool.isRequired,
  setShowReminderModal: PropTypes.func.isRequired,
  handleSendReminder: PropTypes.func.isRequired,
};

export default function ReminderModal({
  showReminderModal,
  setShowReminderModal,
  handleSendReminder,
}) {
  const schema = Yup.object().shape({});

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [enabled, setEnabled] = useState(false);

  function handleToggle(val) {
    setValue("include_whatsapp", val);
    setEnabled(val);
  }

  return (
    <Modal open={showReminderModal} setOpen={setShowReminderModal}>
      <ModalTitle>Send a reminder</ModalTitle>
      <form onSubmit={handleSubmit(handleSendReminder)} noValidate>
        <ModalBody>
          <p className="text-sm text-gray-600">
            Before reminding the guide, please <strong>double check</strong> the
            the reminder is necessary. We may have received a reply and missed
            it.
          </p>
          <hr className="mt-8" />
          <HiddenField name="include_whatsapp" register={register} />
          <div className="block pt-8">
            <Toggle
              enabled={enabled}
              label="Email and WhatsApp"
              description="In addition to the email, remind the guide by messaging their WhatsApp number."
              handleToggle={handleToggle}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="secondary"
            onClick={() => setShowReminderModal(false)}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={isSubmitting || isSubmitSuccessful}
            isSubmitting={isSubmitting}
          >
            Send
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
