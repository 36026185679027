/* eslint-disable react/display-name, react/prop-types */

import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { longDate, money } from "helpers/formatting";
import { getAdventureRequests, getAdventures } from "lib/api";
import { useLoadingContext } from "context/loading-context";
import { ErrorAlert } from "components/common/Alerts";
import PageListNav from "components/common/PageListNav";
import DataTable from "lunar/DataTable";
import DataTableSearch from "lunar/DataTableSearch";
import Meta from "lunar/Meta";
import MessageModal from "./MessageModal";

export default function AdventureRequestsList() {
  const { setLoading } = useLoadingContext();
  const [adventureRequests, setAdventureRequests] = useState(null);
  const [adventures, setAdventures] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const messageCutOff = 45;

  useEffect(() => {
    (async () => {
      populatePageRequests();
    })();
  }, []);

  async function populatePageRequests() {
    setLoading(true);
    const adventureResponse = await getAdventures();
    if (adventureResponse.errorMsg) {
      setErrorMessage(adventureResponse.errorMsg);
    } else {
      setAdventures(adventureResponse);
    }

    const response = await getAdventureRequests();
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      setAdventureRequests(
        response.sort((a, b) => (a.created_at > b.created_at ? 1 : -1))
      );
    }
    setLoading(false);
  }

  function mapAdventureName(id) {
    return adventures?.find((adventure) => adventure.id === String(id))?.name;
  }

  function truncateWithModal(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    }
    return (
      <>
        {`${text.substring(0, maxLength)}...`}
        <MessageModal text={text} />
      </>
    );
  }

  const columns = React.useMemo(() => [
    {
      Header: "Created at",
      accessor: "created_at",
      Cell: ({ row: { original } }) => {
        return (
          <span className="text-gray-900">{longDate(original.created_at)}</span>
        );
      },
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row: { original } }) => {
        return (
          <div className="text-gray-900 whitespace-nowrap">
            {original.name
              ? original.name
              : `${original.first_name} ${original.last_name}`}
          </div>
        );
      },
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ row: { original } }) => {
        return <span className="text-gray-900">{original.email}</span>;
      },
    },
    {
      Header: "Adventure",
      accessor: "adventure_id",
      Cell: ({ row: { original } }) => {
        return (
          <div
            className={classNames(
              "whitespace-nowrap",
              mapAdventureName(original.adventure_id)
                ? "text-gray-900"
                : "text-gray-400"
            )}
          >
            {mapAdventureName(original.adventure_id)
              ? mapAdventureName(original.adventure_id)
              : "N/A"}
          </div>
        );
      },
    },
    {
      Header: "Travel window",
      accessor: "travel_month_and_year",
      Cell: ({ row: { original } }) => {
        return (
          <span className="text-gray-900">
            {original.travel_month_and_year}
          </span>
        );
      },
    },
    {
      Header: "Destination",
      accessor: "destination_selection",
      Cell: ({ row: { original } }) => {
        return (
          <span
            className={classNames(
              original.destination_selection ? "text-gray-900" : "text-gray-400"
            )}
          >
            {original.destination_selection
              ? original.destination_selection
              : "N/A"}
          </span>
        );
      },
    },
    {
      Header: "Number of travelers",
      accessor: "num_travelers",
      Cell: ({ row: { original } }) => {
        return <span className="text-gray-900">{original.num_travelers}</span>;
      },
    },
    {
      Header: "Desired surf",
      accessor: "desired_surf_type",
      Cell: ({ row: { original } }) => {
        return (
          <span
            className={classNames(
              original.desired_surf_type ? "text-gray-900" : "text-gray-400"
            )}
          >
            {original.desired_surf_type ? original.desired_surf_type : "N/A"}
          </span>
        );
      },
    },
    {
      Header: "Budget",
      accessor: "budget",
      Cell: ({ row: { original } }) => {
        return (
          <span
            className={classNames(
              original.budget ? "text-gray-900" : "text-gray-400"
            )}
          >
            {original.budget ? money(original.budget) : "N/A"}
          </span>
        );
      },
    },
    {
      Header: "Phone",
      accessor: "phone_number",
      Cell: ({ row: { original } }) => {
        return (
          <span
            className={classNames(
              original.phone_number ? "text-gray-900" : "text-gray-400"
            )}
          >
            {original.phone_number ? original.phone_number : "N/A"}
          </span>
        );
      },
    },
    {
      Header: "Message",
      accessor: "message",
      Cell: ({ row: { original } }) => {
        return (
          <span
            className={classNames(
              original.message ? "text-gray-900" : "text-gray-400"
            )}
          >
            {original.message
              ? truncateWithModal(original.message, messageCutOff)
              : "N/A"}
          </span>
        );
      },
    },
    {
      Header: "Source",
      accessor: "form_source",
      Cell: ({ row: { original } }) => {
        return (
          <span
            className={classNames(
              original.form_source ? "text-gray-900" : "text-gray-400"
            )}
          >
            {original.form_source ? original.form_source : "N/A"}
          </span>
        );
      },
    },
    {
      Header: "UTM campaign",
      accessor: "utm_campaign",
      Cell: ({ row: { original } }) => {
        return (
          <span
            className={classNames(
              original.utm_campaign ? "text-gray-900" : "text-gray-400"
            )}
          >
            {original.utm_campaign ? original.utm_campaign : "N/A"}
          </span>
        );
      },
    },
    {
      Header: "UTM content",
      accessor: "utm_content",
      Cell: ({ row: { original } }) => {
        return (
          <span
            className={classNames(
              original.utm_content ? "text-gray-900" : "text-gray-400"
            )}
          >
            {original.utm_content ? original.utm_content : "N/A"}
          </span>
        );
      },
    },
    {
      Header: "UTM medium",
      accessor: "utm_medium",
      Cell: ({ row: { original } }) => {
        return (
          <span
            className={classNames(
              original.utm_medium ? "text-gray-900" : "text-gray-400"
            )}
          >
            {original.utm_medium ? original.utm_medium : "N/A"}
          </span>
        );
      },
    },
    {
      Header: "UTM source",
      accessor: "utm_source",
      Cell: ({ row: { original } }) => {
        return (
          <span
            className={classNames(
              original.utm_source ? "text-gray-900" : "text-gray-400"
            )}
          >
            {original.utm_source ? original.utm_source : "N/A"}
          </span>
        );
      },
    },
    {
      Header: "UTM term",
      accessor: "utm_term",
      Cell: ({ row: { original } }) => {
        return (
          <span
            className={classNames(
              original.utm_term ? "text-gray-900" : "text-gray-400"
            )}
          >
            {original.utm_term ? original.utm_term : "N/A"}
          </span>
        );
      },
    },
  ]);

  return (
    <>
      <Meta
        title={
          adventureRequests &&
          `All ${adventureRequests.length} adventure requests`
        }
      />
      <div className="min-h-screen overflow-y-scroll">
        <PageListNav
          dataTableSearch={
            <DataTableSearch
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              placeholder="Search for adventure requests"
            />
          }
        />

        <ErrorAlert message={errorMessage} />
        {adventureRequests && (
          <div className="w-full overflow-x-scroll">
            <DataTable
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              data={adventureRequests.map((request) => ({
                id: request.id,
                created_at: request.created_at,
                adventure_id: request.adventure_id,
                name: request.name,
                first_name: request.first_name,
                last_name: request.last_name,
                email: request.email,
                travel_month_and_year: request.travel_month_and_year,
                num_travelers: request.num_travelers,
                desired_surf_type: request.desired_surf_type,
                phone_number: request.phone_number,
                destination_selection: request.destination_selection,
                form_source: request.form_source,
                budget: request.budget,
                message: request.message,
                utm_campaign: request.utm_campaign,
                utm_content: request.utm_content,
                utm_medium: request.utm_medium,
                utm_source: request.utm_source,
                utm_term: request.utm_term,
              }))}
              columns={columns}
              sortOptions={{ id: "id", desc: true }}
            />
          </div>
        )}
      </div>
    </>
  );
}
