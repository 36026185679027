import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalBody from "lunar/ModalBody";
import ModalFooter from "lunar/ModalFooter";
import Button from "lunar/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import TextField from "lunar/InputText";
import SelectField from "lunar/SelectField";
import TextArea from "lunar/InputTextArea";
import InputImage from "lunar/InputImage";
import HiddenField from "components/common/HiddenField";

CreateUpdateBreakModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  handleSaveBreak: PropTypes.func.isRequired,
  tripID: PropTypes.string,
  tripBreak: PropTypes.object,
  numBreaks: PropTypes.number,
};

export default function CreateUpdateBreakModal({
  showModal,
  setShowModal,
  handleSaveBreak,
  tripID = "",
  tripBreak = {},
  numBreaks = 0,
}) {
  const schema = Yup.object().shape({
    name: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    // direction: Yup.string().required("Required"),
    // break_type: Yup.string().required("Required"),
  });

  const {
    reset,
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (tripBreak) {
      reset({
        id: tripBreak.id,
        name: tripBreak.name,
        description: tripBreak.description,
        direction: tripBreak.direction,
        break_type: tripBreak.break_type,
        position: tripBreak.position,
        trip_id: tripID,
      });
    } else {
      reset({
        id: "",
        name: "",
        description: "",
        direction: "",
        break_type: "",
        position: "",
        trip_id: tripID,
      });
    }
  }, [reset, tripBreak]);

  return (
    <Modal open={showModal} setOpen={setShowModal}>
      <ModalTitle>Edit surf break</ModalTitle>
      <form onSubmit={handleSubmit(handleSaveBreak)} noValidate>
        <ModalBody>
          <HiddenField name="id" register={register} />
          <HiddenField name="trip_id" register={register} />
          <div className="grid gap-6">
            <TextField
              name="name"
              type="text"
              label="Name"
              errors={errors}
              register={register}
            />
            <div className="grid grid-cols-3 gap-6">
              <SelectField
                name="direction"
                label="Direction"
                options={[
                  { id: "Left", name: "Left" },
                  { id: "Right", name: "Right" },
                  { id: "Left & Right", name: "Left & Right" },
                ]}
                includeBlank={true}
                errors={errors}
                register={register}
              />
              <SelectField
                name="break_type"
                label="Break Type"
                options={[
                  { id: "Point", name: "Point" },
                  { id: "Beach", name: "Beach" },
                  { id: "Reef", name: "Reef" },
                ]}
                includeBlank={true}
                errors={errors}
                register={register}
              />
              <SelectField
                name="position"
                label="Position"
                options={Array.from({ length: numBreaks }, (_, i) => i + 1)}
                errors={errors}
                register={register}
              />
            </div>
            <TextArea
              name="description"
              type="text"
              label="Description"
              errors={errors}
              register={register}
              maxRows={5}
            />
            <InputImage
              name="image"
              label="Wave image"
              image={tripBreak?.image}
              errors={errors}
              register={register}
              className="w-3/4"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button type="submit" isSubmitting={isSubmitting}>
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
