import React, { useState, useEffect } from "react";
import cn from "classnames";
import PropTypes from "prop-types";

SelectSeason.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  register: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
  includeBlank: PropTypes.bool,
  selectClassName: PropTypes.node,
};

export default function SelectSeason({
  name,
  options,
  register,
  includeBlank = false,
  initialValue = "",
}) {
  const [colorStyle, setColorStyle] = useState(null);

  let selectOptions =
    typeof options[0] !== "object"
      ? options.map((option) => ({ id: option, name: option }))
      : options;
  if (includeBlank)
    selectOptions = [{ id: "", name: "" }].concat(selectOptions);

  function handleChange(v) {
    mapSeasonColor(v);
  }

  function mapSeasonColor(v) {
    switch (v) {
      case "H":
        return setColorStyle("text-green-500");
      case "M":
        return setColorStyle("text-yellow-500");
      default:
        return setColorStyle("text-red-500");
    }
  }

  useEffect(() => {
    mapSeasonColor(initialValue);
  }, [initialValue]);

  return (
    <div>
      <select
        id={name}
        name={name}
        {...register(name)}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        className={cn(
          "w-full p-0 bg-none border-0 outline-none font-medium focus:outline-none background-transparent border-white focus:ring-white focus:border-white",
          colorStyle
        )}
        style={{ textAlignLast: "center" }}
      >
        {selectOptions.map((option, idx) => (
          <option key={`${name}-${idx}`} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
}
