import React from "react";
import PropTypes from "prop-types";
import Button from "lunar/Button";
import { useFormContext } from "context/form-context";

FormNav.propTypes = {
  showSave: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

export default function FormNav({ showSave = true, isSubmitting = false }) {
  const link = useFormContext().link;
  const linkText = useFormContext().linkText;
  const lastUpdated = useFormContext().lastUpdated;

  return (
    <div className="grid items-center grid-flow-col px-10 py-3">
      {lastUpdated && (
        <div className="text-xs font-medium text-gray-400">
          <p>{`Last updated ${lastUpdated}`}</p>
        </div>
      )}
      <div className="text-right">
        {link && (
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="mr-6 text-sm font-medium text-blue-500 hover:underline"
          >
            {linkText}
          </a>
        )}
        {showSave && (
          <Button type="submit" isSubmitting={isSubmitting}>
            Save
          </Button>
        )}
      </div>
    </div>
  );
}
