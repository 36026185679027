import React from "react";
import PropTypes from "prop-types";

Feed.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function Feed({ children }) {
  return (
    <div className="flow-root overflow-hidden">
      <ul className="-mb-8">{children}</ul>
    </div>
  );
}
