import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

TableFoot.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.node,
};

export default function TableFoot({ children, className }) {
  return <tfoot className={cn(className)}>{children}</tfoot>;
}
