import React, { useState } from "react";
import PropTypes from "prop-types";
import { longDateTime, money } from "helpers/formatting";
import { createAdventureRefund } from "lib/api";
import Button from "lunar/Button";
import RefundModal from "./RefundModal";
import Table from "lunar/Table";
import TableHead from "lunar/TableHead";
import TableRow from "lunar/TableRow";
import TableBody from "lunar/TableBody";
import TableCell from "lunar/TableCell";

Refunds.propTypes = {
  bookingID: PropTypes.string.isRequired,
  handleError: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  refunds: PropTypes.array,
};

export default function Refunds({
  refunds,
  bookingID,
  handleError,
  handleSuccess,
}) {
  const [showRefundModal, setShowRefundModal] = useState(false);

  async function handleSaveRefund(data) {
    const response = await createAdventureRefund(data);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Refund created");
    }
    setShowRefundModal(false);
  }

  function handleRefund() {
    setShowRefundModal(true);
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell head first>
              Type
            </TableCell>
            <TableCell head>Description</TableCell>
            <TableCell head>Time</TableCell>
            <TableCell head last>
              Total
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {refunds.map((obj) => (
            <TableRow key={`${obj.id}-${obj.amount}`}>
              <TableCell first>
                {obj.refund_type
                  .replace(/_/g, " ")
                  .replace(/^./, obj.refund_type[0].toUpperCase())}
              </TableCell>
              <TableCell>{obj.reason}</TableCell>
              <TableCell>{longDateTime(obj.created_at)}</TableCell>
              <TableCell last>{money(obj.amount)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className="mt-5 text-right">
        <Button variant="secondary" onClick={handleRefund}>
          Add refund
        </Button>
      </div>
      <RefundModal
        bookingID={bookingID}
        handleSaveRefund={handleSaveRefund}
        setShowRefundModal={setShowRefundModal}
        showRefundModal={showRefundModal}
        totalRefundableAmount={5000}
        creditCardRefundableAmount={30}
      />
    </>
  );
}
