import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import InputText from "lunar/InputText";
import InputTextArea from "lunar/InputTextArea";
import ButtonText from "lunar/ButtonText";

QuestionsArea.propTypes = {
  questions: PropTypes.object,
  reset: PropTypes.func.isRequired,
  errors: PropTypes.object,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
};

export default function QuestionsArea({
  questions = {},
  reset,
  errors = {},
  register,
  setValue,
  getValues,
}) {
  const [entries, setEntries] = useState({});
  const fixedQuestions = Object.freeze([
    "What's included?",
    "What's not included?",
  ]);

  useEffect(() => {
    if (questions !== undefined) {
      fixedQuestions.map(
        (question) => (questions[question] = questions[question] || "")
      );
      setEntries(questions);
    }
  }, [reset, JSON.stringify(questions)]);

  function addEntry() {
    setEntries({ ...entries, [Date.now()]: "" });
  }

  function removeEntry(index) {
    const list = getValues()["questions"];
    // create a copy
    const newList = [...list];
    // remove by index
    newList.splice(index, 1);
    // update values
    setEntries(newList);
    for (let i = 0; i < newList.length; i++) {
      setValue(`questions[${i}][0]`, newList[i][0]);
      setValue(`questions[${i}][1]`, newList[i][1]);
    }
  }

  return (
    <div>
      <label htmlFor="questions" />
      <div className="flex flex-col gap-x-10 gap-y-6">
        {Object.keys(entries).map((key, index) => (
          <div key={`questions-${index}`}>
            <InputText
              name={`questions[${index}][0]`}
              label="Question"
              type="text"
              errors={errors}
              register={register}
              disabled={index < fixedQuestions.length && true}
              placeholder="What should I bring?"
            />
            <InputTextArea
              name={`questions[${index}][1]`}
              errors={errors}
              register={register}
              rows={6}
              className="mt-4"
              placeholder="Write the answer..."
            />
            {index >= fixedQuestions.length && (
              <div className="mt-1 text-sm">
                <ButtonText
                  onClick={() => removeEntry(index)}
                  className="text-red-600 hover:text-red-900"
                >
                  Delete
                </ButtonText>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="pt-10 mt-10 border-t">
        <button
          type="button"
          onClick={addEntry}
          className="px-3 py-2 text-sm font-medium leading-4 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Add a question
        </button>
      </div>
    </div>
  );
}
