import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { useToasts } from "react-toast-notifications";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import ButtonText from "lunar/ButtonText";
import { deleteTrip } from "lib/api";
import DialogActionPanel from "lunar/DialogActionPanel";
import { Link, useNavigate } from "react-router-dom";

EditMenu.propTypes = {
  tripID: PropTypes.string,
  submitForm: PropTypes.func.isRequired,
  tripVisible: PropTypes.bool,
  tripFeatured: PropTypes.bool,
  tripOperatorSlug: PropTypes.string,
  tripRegionID: PropTypes.number,
};

export default function EditMenu({
  tripID,
  submitForm,
  tripVisible,
  tripFeatured,
  tripOperatorSlug,
  tripRegionID,
}) {
  const { addToast } = useToasts();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  async function handleDelete() {
    if (window.confirm("Are you sure you want to delete this trip?")) {
      const response = await deleteTrip(tripID);
      if (response.errorMsg) {
        addToast(response.errorMsg, { appearance: "error" });
      } else {
        addToast("Trip successfully deleted", { appearance: "success" });
        navigate("/trips");
      }
    }
  }

  async function handlePublishTrip() {
    submitForm({ is_visible: tripVisible ? false : true });
  }

  async function handleFeatureTrip() {
    submitForm({ is_featured: tripFeatured ? false : true });
  }

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        {({ open }) => (
          <>
            <div>
              <Menu.Button className="flex items-center text-gray-400 rounded-full hover:text-gray-600 focus:outline-none">
                <span className="sr-only">Open options</span>
                <EllipsisVerticalIcon className="w-5 h-5" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div className="py-1">
                  <Menu.Item>
                    <Link
                      to={`/operators/edit/${tripOperatorSlug}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block w-full px-4 py-2 text-sm text-left focus:ring-blue-500 hover:bg-blue-50"
                    >
                      {"Edit related guide"}
                    </Link>
                  </Menu.Item>
                  {tripRegionID && (
                    <Menu.Item>
                      <Link
                        to={`/regions/${tripRegionID}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block w-full px-4 py-2 text-sm text-left focus:ring-blue-500 hover:bg-blue-50"
                      >
                        {"View region"}
                      </Link>
                    </Menu.Item>
                  )}
                </div>
                <div className="py-1">
                  <Menu.Item>
                    <ButtonText
                      onClick={() => setOpen(true)}
                      className="block w-full px-4 py-2 text-sm text-left focus:ring-blue-500 hover:bg-blue-50"
                    >
                      {tripVisible ? (
                        <span>Unpublish this trip</span>
                      ) : (
                        <span>Publish now</span>
                      )}
                    </ButtonText>
                  </Menu.Item>
                  <Menu.Item>
                    <ButtonText
                      onClick={() => handleFeatureTrip()}
                      className="block w-full px-4 py-2 text-sm text-left focus:ring-blue-500 hover:bg-blue-50"
                    >
                      {tripFeatured ? (
                        <span>Remove from just added</span>
                      ) : (
                        <span>Add to just added</span>
                      )}
                    </ButtonText>
                  </Menu.Item>
                </div>
                <div className="py-1">
                  <Menu.Item>
                    <ButtonText
                      onClick={handleDelete}
                      className="block w-full px-4 py-2 text-sm text-left focus:ring-blue-500 hover:bg-blue-50"
                    >
                      Delete this trip
                    </ButtonText>
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
      <DialogActionPanel
        title={tripVisible ? "Unpublish" : "Make visible"}
        description={
          tripVisible
            ? "This will unpublish the trip."
            : "This will make the trip viewable to the public. Are you sure it's ready for travelers to see and book?"
        }
        buttonText={tripVisible ? "Unpublish" : "Publish"}
        onClick={handlePublishTrip}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
