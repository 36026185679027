import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import Button from "lunar/Button";
import Form from "lunar/Form";
import FormTitle from "lunar/FormTitle";
import FormSections from "lunar/FormSections";
import FormSection from "lunar/FormSection";
import FormSectionHeader from "lunar/FormSectionHeader";
import FormSectionBody from "lunar/FormSectionBody";
import SelectSearch from "lunar/SelectSearch";
import InputText from "lunar/InputText";
import HiddenField from "components/common/HiddenField";
import FormRow from "lunar/FormRow";
import Map from "lunar/Map";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/solid";
import InputTextAddon from "lunar/InputTextAddon";
import InputTextArea from "lunar/InputTextArea";
import LocationModal from "components/trips/LocationModal";

LocationForm.propTypes = {
  trip: PropTypes.object,
  airports: PropTypes.array,
  submitForm: PropTypes.func.isRequired,
  regions: PropTypes.array,
};

export default function LocationForm({
  trip = {},
  submitForm,
  airports = [],
  regions = [],
}) {
  const [showModal, setShowModal] = useState(false);

  const schema = Yup.object().shape({
    // name: Yup.string().required("Required"),
  });

  const regionOptions = regions.map((region) => ({
    value: parseInt(region.id, 10),
    label: region.name,
    country: region.country,
  }));

  const {
    register,
    handleSubmit,
    errors,
    reset,
    setValue,
    control,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      region_id: trip.region_id,
      airport_id: trip.airport?.id,
      iata_code: trip.airport?.iata_code,
      airport_name: trip.airport
        ? `${trip.airport.name} (${trip.airport.iata_code})`
        : "",
      address_name: trip.address_name,
      address_line_1: trip.address_line_1,
      address_line_2: trip.address_line_2,
      address_city: trip.address_city,
      address_state_province: trip.address_state_province,
      address_postal_code: trip.address_postal_code,
      address_country: trip.address_country,
      address_lat: trip.address_lat,
      address_lng: trip.address_lng,
      reservation_contact_whatsapp_number:
        trip.reservation_contact_whatsapp_number,
      reservation_contact_phone_number: trip.reservation_contact_phone_number,
      reservation_contact_email: trip.reservation_contact_email,
      emergency_contact_whatsapp_number: trip.emergency_contact_whatsapp_number,
      emergency_contact_phone_number: trip.emergency_contact_phone_number,
      getting_there: trip.getting_there,
    });
  }, [reset, trip]);

  function handleIataOnBlur(e) {
    const code = e.target.value.toUpperCase();
    const airport = airports.find((airport) => airport.iata_code === code);
    if (airport !== undefined) {
      setValue("airport_id", airport.id);
      setValue("iata_code", code);
      setValue("airport_name", `${airport.name} (${code})`);
    } else {
      setValue("airport_id", null);
      setValue("iata_code", "");
      setValue("airport_name", "");
    }
  }

  return (
    <>
      <Form
        handleSubmit={handleSubmit}
        submitForm={submitForm}
        isSubmitting={isSubmitting}
      >
        <FormTitle title="Location and contact information" />
        <FormSections>
          <FormSection>
            <FormSectionHeader
              title="Mapping"
              description="The region of the world and closest airport"
            />
            <FormSectionBody>
              <FormRow>
                <div className="relative w-full overflow-hidden rounded-lg h-80">
                  {trip.address_lat && trip.address_lng ? (
                    <Map
                      latitude={parseFloat(trip.address_lat)}
                      longitude={parseFloat(trip.address_lng)}
                      initialZoom={
                        trip.trip_type_description === "Lodge" ? 15 : 5
                      }
                    />
                  ) : (
                    <div className="w-full h-full bg-gray-200" />
                  )}
                  <div className="absolute top-4 left-4">
                    <Button
                      variant="secondary"
                      className="text-blue-500 border-white"
                      onClick={() => setShowModal(true)}
                    >
                      Set position
                    </Button>
                  </div>
                </div>
              </FormRow>
              <FormRow className="mt-6">
                <HiddenField name="airport_id" register={register} />
                <SelectSearch
                  name="region_id"
                  label="Region"
                  options={regionOptions}
                  helper="The region where the trip takes place."
                  errors={errors}
                  control={control}
                  groupBy="country"
                />
                <InputText
                  name="iata_code"
                  type="text"
                  label="Intl. airport"
                  helper="The closest"
                  placeholder="SFO"
                  errors={errors}
                  register={register}
                  maxLength={3}
                  onBlur={handleIataOnBlur}
                  className="w-24"
                />
                <InputText
                  name="airport_name"
                  type="text"
                  label="Airport display name"
                  helper="How the code will display to travelers"
                  placeholder="San Francisco International Airport (SFO)"
                  errors={errors}
                  register={register}
                  disabled={true}
                  className="flex-grow"
                />
              </FormRow>
            </FormSectionBody>
          </FormSection>
          <FormSection>
            <FormSectionHeader
              title="Getting there"
              description="How do travelers get to the lodge, meet the boat or arrange for pickup by the guide?"
            />
            <FormSectionBody>
              <InputTextArea
                name="getting_there"
                type="text"
                helper="Displayed in the listing as well as after booking. Ideally 5 sentences maximum."
                errors={errors}
                register={register}
                rows={5}
              />
            </FormSectionBody>
          </FormSection>
          <FormSection>
            <FormSectionHeader
              title="Reservation contact info"
              description={
                <p>
                  Only shared with travelers after booking, this information is{" "}
                  <strong>not used</strong> by us for messaging.
                </p>
              }
            />
            <FormSectionBody>
              <FormRow className="grid grid-cols-3 gap-6">
                <InputTextAddon
                  name="reservation_contact_phone_number"
                  type="tel"
                  label="Phone number"
                  placeholder="14155551234"
                  errors={errors}
                  register={register}
                  addonLeft={<PhoneIcon className="w-4 h-4" />}
                />
                <InputTextAddon
                  name="reservation_contact_whatsapp_number"
                  type="tel"
                  label="WhatsApp number"
                  placeholder="14155551234"
                  errors={errors}
                  register={register}
                  addonLeft={
                    <svg
                      className="w-4 h-4"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M17.077 2.928C15.191 1.041 12.683 0.001 10.011 0C4.505 0 0.0240001 4.479 0.0220001 9.985C0.0210001 11.745 0.481 13.463 1.355 14.977L0 20L5.233 18.763C6.692 19.559 8.334 19.978 10.006 19.979H10.01C15.515 19.979 19.996 15.499 19.999 9.994C20.001 7.325 18.963 4.816 17.077 2.928ZM14.898 13.554C14.69 14.137 13.671 14.699 13.213 14.74C12.755 14.782 12.326 14.947 10.218 14.116C7.681 13.116 6.079 10.515 5.955 10.349C5.83 10.182 4.936 8.996 4.936 7.768C4.936 6.54 5.581 5.936 5.81 5.687C6.039 5.437 6.309 5.375 6.476 5.375C6.642 5.375 6.809 5.375 6.954 5.381C7.132 5.388 7.329 5.397 7.516 5.812C7.738 6.306 8.223 7.54 8.285 7.665C8.347 7.79 8.389 7.936 8.306 8.102C8.223 8.268 8.181 8.372 8.057 8.518C7.932 8.664 7.795 8.843 7.683 8.955C7.558 9.079 7.428 9.215 7.573 9.464C7.719 9.714 8.219 10.531 8.961 11.192C9.915 12.042 10.718 12.305 10.968 12.431C11.218 12.556 11.363 12.535 11.509 12.368C11.655 12.202 12.133 11.64 12.299 11.39C12.465 11.14 12.632 11.182 12.861 11.265C13.09 11.348 14.317 11.952 14.566 12.077C14.816 12.202 14.982 12.264 15.044 12.368C15.106 12.471 15.106 12.971 14.898 13.554Z" />
                    </svg>
                  }
                />
                <InputTextAddon
                  name="reservation_contact_email"
                  type="email"
                  label="Email address"
                  placeholder="14155551234"
                  errors={errors}
                  register={register}
                  addonLeft={<EnvelopeIcon className="w-4 h-4" />}
                />
              </FormRow>
            </FormSectionBody>
          </FormSection>
          <FormSection>
            <FormSectionHeader
              title="Emergency contact info"
              description="Only shared with travelers after booking, these numbers are strictly for emergencies."
            />
            <FormSectionBody>
              <FormRow className="grid grid-cols-3 gap-6">
                <InputTextAddon
                  name="emergency_contact_phone_number"
                  type="tel"
                  label="Phone number"
                  placeholder="14155551234"
                  errors={errors}
                  register={register}
                  addonLeft={<PhoneIcon className="w-4 h-4" />}
                />
                <InputTextAddon
                  name="emergency_contact_whatsapp_number"
                  type="tel"
                  label="WhatsApp number"
                  placeholder="14155551234"
                  errors={errors}
                  register={register}
                  addonLeft={
                    <svg
                      className="w-4 h-4"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M17.077 2.928C15.191 1.041 12.683 0.001 10.011 0C4.505 0 0.0240001 4.479 0.0220001 9.985C0.0210001 11.745 0.481 13.463 1.355 14.977L0 20L5.233 18.763C6.692 19.559 8.334 19.978 10.006 19.979H10.01C15.515 19.979 19.996 15.499 19.999 9.994C20.001 7.325 18.963 4.816 17.077 2.928ZM14.898 13.554C14.69 14.137 13.671 14.699 13.213 14.74C12.755 14.782 12.326 14.947 10.218 14.116C7.681 13.116 6.079 10.515 5.955 10.349C5.83 10.182 4.936 8.996 4.936 7.768C4.936 6.54 5.581 5.936 5.81 5.687C6.039 5.437 6.309 5.375 6.476 5.375C6.642 5.375 6.809 5.375 6.954 5.381C7.132 5.388 7.329 5.397 7.516 5.812C7.738 6.306 8.223 7.54 8.285 7.665C8.347 7.79 8.389 7.936 8.306 8.102C8.223 8.268 8.181 8.372 8.057 8.518C7.932 8.664 7.795 8.843 7.683 8.955C7.558 9.079 7.428 9.215 7.573 9.464C7.719 9.714 8.219 10.531 8.961 11.192C9.915 12.042 10.718 12.305 10.968 12.431C11.218 12.556 11.363 12.535 11.509 12.368C11.655 12.202 12.133 11.64 12.299 11.39C12.465 11.14 12.632 11.182 12.861 11.265C13.09 11.348 14.317 11.952 14.566 12.077C14.816 12.202 14.982 12.264 15.044 12.368C15.106 12.471 15.106 12.971 14.898 13.554Z" />
                    </svg>
                  }
                />
              </FormRow>
            </FormSectionBody>
          </FormSection>
          <FormSection>
            <FormSectionHeader
              title="Physical address"
              description="The detailed location we'll share with travelers during booking."
            />
            <FormSectionBody>
              <FormRow className="grid grid-cols-6 gap-6">
                <InputText
                  name="address_name"
                  type="text"
                  label="Name"
                  errors={errors}
                  register={register}
                  className="col-span-6"
                />
                <InputText
                  name="address_line_1"
                  type="text"
                  label="Street address"
                  errors={errors}
                  register={register}
                  className="col-span-4"
                />
                <InputText
                  name="address_city"
                  type="text"
                  label="City"
                  errors={errors}
                  register={register}
                  className="col-span-2"
                />
                <InputText
                  name="address_state_province"
                  type="text"
                  label="State/province"
                  errors={errors}
                  register={register}
                  className="col-span-3"
                />
                <InputText
                  name="address_postal_code"
                  type="text"
                  label="Postal code"
                  errors={errors}
                  register={register}
                  className="col-span-1"
                />
                <InputText
                  name="address_country"
                  type="text"
                  label="Country"
                  errors={errors}
                  register={register}
                  className="col-span-2"
                />
              </FormRow>
            </FormSectionBody>
          </FormSection>
        </FormSections>
      </Form>
      <LocationModal
        showModal={showModal}
        setShowModal={setShowModal}
        submitForm={submitForm}
        lat={trip.address_lat}
        lng={trip.address_lng}
      />
    </>
  );
}
