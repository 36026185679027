import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

NavbarLink.propTypes = {
  path: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
};

export default function NavbarLink({ path, icon }) {
  return (
    <NavLink
      className={({ isActive, isPending }) =>
        isPending
          ? "p-1 text-gray-600"
          : isActive
          ? "p-1 text-blue-600"
          : "p-1 text-gray-600"
      }
      to={path}
    >
      <span className="hover:text-blue-600">{icon}</span>
    </NavLink>
  );
}
