import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import { Switch } from "@headlessui/react";

Toggle.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  enabled: PropTypes.bool,
  handleToggle: PropTypes.func.isRequired,
  layout: PropTypes.string,
};

export default function Toggle({
  label = "",
  description = "",
  enabled = false,
  handleToggle,
  layout = "flex",
}) {
  return (
    <Switch.Group as="div" className={layout == "flex" ? "flex items-center justify-between gap-10" : "inline"}>
      <div>
        {label && (
          <Switch.Label
            as="p"
            className="text-sm font-medium text-gray-900"
            passive
          >
            {label}
          </Switch.Label>
        )}
        <Switch.Description
          as="p"
          className={cn("text-sm text-gray-500", label && "mt-1")}
        >
          {description}
        </Switch.Description>
      </div>
      <Switch
        checked={enabled}
        onChange={handleToggle}
        className={cn(
          enabled ? "bg-blue-600" : "bg-gray-200",
          "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        )}
      >
        <span
          aria-hidden="true"
          className={cn(
            enabled ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
          )}
        />
      </Switch>
    </Switch.Group>
  );
}
