import React from "react";
import PropTypes from "prop-types";
import { StarIcon, EyeIcon } from "@heroicons/react/24/solid";
import EditMenu from "components/trips/EditMenu";

NavHeader.propTypes = {
  submitForm: PropTypes.func.isRequired,
  tripID: PropTypes.string,
  tripName: PropTypes.string,
  tripVisible: PropTypes.bool,
  tripFeatured: PropTypes.bool,
  tripOperatorSlug: PropTypes.string,
  tripRegionID: PropTypes.number,
};

export default function NavHeader({
  submitForm,
  tripID,
  tripName,
  tripVisible = false,
  tripFeatured = false,
  tripOperatorSlug,
  tripRegionID,
}) {
  return (
    <>
      <header className="border-b border-gray-200">
        <div className="grid items-center grid-flow-col col-start-auto px-6 py-4 mx-auto max-w-7xl">
          <div className="flex-1 min-w-0">
            <a
              href={`https://www.google.com/search?q=${tripName}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <h1 className="text-lg font-semibold leading-6 text-gray-900">
                {tripName ? tripName : <span>&nbsp;</span>}
                {tripVisible && (
                  <EyeIcon className="inline w-5 h-5 ml-2 text-green-500" />
                )}
                {tripFeatured && (
                  <StarIcon className="inline w-5 h-5 ml-2 text-yellow-500" />
                )}
              </h1>
            </a>
          </div>
          <div className="inline-grid grid-flow-col gap-2 justify-self-end">
            <EditMenu
              tripID={tripID}
              tripVisible={tripVisible}
              tripFeatured={tripFeatured}
              tripOperatorSlug={tripOperatorSlug}
              tripRegionID={tripRegionID}
              submitForm={submitForm}
            />
          </div>
        </div>
      </header>
    </>
  );
}
