import React, { useState, useEffect } from "react";
import {
  getPromotions,
  deletePromotion,
  createPromotion,
  updatePromotion,
} from "lib/api";
import { useLoadingContext } from "context/loading-context";
import { longDateHoursMinutes } from "helpers/formatting";
import { useToasts } from "react-toast-notifications";
import { ErrorAlert } from "components/common/Alerts";
import CreateUpdatePromotionModal from "components/promotions/CreateUpdatePromotionModal";
import Meta from "lunar/Meta";
import DataTable from "lunar/DataTable";
import DataTableSearch from "lunar/DataTableSearch";
import PageListNav from "components/common/PageListNav";
import Button from "lunar/Button";

export default function Operators() {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [promotions, setPromotions] = useState(null);
  const [promotionToEdit, setPromotionToEdit] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const { addToast } = useToasts();
  const { setLoading } = useLoadingContext();
  const [globalFilter, setGlobalFilter] = useState("");

  useEffect(() => {
    (async () => {
      populatePromotions();
    })();
  }, []);

  async function confirmDeletePromotion(id) {
    if (window.confirm("Are you sure you want to delete this promotion?")) {
      const response = await deletePromotion(id);
      if (response.errorMsg) {
        setErrorMessage(response.errorMsg);
      } else {
        addToast("Promotion successfully deleted", { appearance: "success" });
        populatePromotions();
      }
    }
  }

  async function populatePromotions() {
    setLoading(true);
    const response = await getPromotions();
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      setPromotions(response);
    }
    setLoading(false);
  }

  async function handleSavePromotion(data) {
    let response;
    if (data.id !== "") {
      response = await updatePromotion(data.id, data);
    } else {
      response = await createPromotion(data);
    }
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      addToast("Promotion successfully saved", { appearance: "success" });
      populatePromotions();
    }
    setShowCreateModal(false);
  }

  function handleEditPromotion(promotion) {
    setPromotionToEdit(promotion);
    setShowCreateModal(true);
  }

  function handleAdd() {
    setPromotionToEdit(null);
    setShowCreateModal(true);
  }

  const columns = React.useMemo(() => [
    {
      Header: "Code",
      accessor: "code",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Discount",
      accessor: "discount",
    },
    {
      Header: "Start",
      accessor: "start_time",
      Cell: (tableProps) => {
        return <>{longDateHoursMinutes(tableProps.value)}</>;
      },
    },
    {
      Header: "End",
      accessor: "end_time",
      Cell: (tableProps) => {
        return <>{longDateHoursMinutes(tableProps.value)}</>;
      },
    },
    {
      Header: "Actions",
      accessor: "actionsId",
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        return (
          <>
            <button
              onClick={() => handleEditPromotion(original)}
              className="text-blue-600 hover:text-blue-900"
            >
              Edit
            </button>
            <button
              // eslint-disable-next-line react/prop-types
              onClick={() => confirmDeletePromotion(original.id)}
              className="ml-3 text-red-600 hover:text-red-900"
            >
              Delete
            </button>
          </>
        );
      },
    },
  ]);

  return (
    <>
      <Meta title={promotions && `All ${promotions?.length} promotions`} />
      <div className="min-h-screen overflow-y-scroll">
        <PageListNav
          dataTableSearch={
            <DataTableSearch
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              placeholder="Search for promotions"
            />
          }
          actions={<Button onClick={() => handleAdd()}>New promo</Button>}
        />
        <ErrorAlert message={errorMessage} />
        {promotions && (
          <DataTable
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            data={promotions.map((promotion) => ({
              id: promotion.id,
              code: promotion.code,
              name: promotion.name,
              start_time: promotion.start_time,
              end_time: promotion.end_time,
              percent_value: promotion.percent_value,
              dollar_value: promotion.dollar_value,
              max_uses: promotion.max_uses,
              min_dollar_value: promotion.min_dollar_value,
              discount:
                promotion.percent_value !== null
                  ? `${promotion.percent_value}%`
                  : `$${promotion.dollar_value}`,
              actionsId: promotion.id,
            }))}
            columns={columns}
            sortOptions={{ id: "code", desc: false }}
          />
        )}
      </div>
      <CreateUpdatePromotionModal
        handleSavePromotion={handleSavePromotion}
        setShowModal={setShowCreateModal}
        showModal={showCreateModal}
        promotion={promotionToEdit}
      />
    </>
  );
}
