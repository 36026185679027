import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { useToasts } from "react-toast-notifications";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import { resolveMessageThread, createMessage } from "lib/api";
import ButtonText from "lunar/ButtonText";
import DialogActionPanel from "lunar/DialogActionPanel";
import CreatePostTravelerModal from "components/messages/CreatePostTravelerModal";

PostsMenu.propTypes = {
  thread: PropTypes.shape({
    id: PropTypes.string,
    user_first_name: PropTypes.string,
    user_last_name: PropTypes.string,
    messages: PropTypes.array,
    operator_employees: PropTypes.array,
    user_email: PropTypes.string,
    user_id: PropTypes.number,
    operator_id: PropTypes.number,
    marked_as_resolved: PropTypes.bool,
  }).isRequired,
};

export default function PostsMenu({ thread }) {
  const { addToast } = useToasts();
  const [openResolveWarning, setOpenResolveWarning] = useState(false);
  const [showTravelerModal, setShowTravelerModal] = useState(false);

  async function handleResolveMessageThread() {
    const response = await resolveMessageThread(thread.id);
    if (response.errorMsg) {
      addToast(response.errorMsg, { appearance: "error" });
    } else {
      window.location.reload();
    }
  }

  async function handleSaveMessage(data) {
    data.receiver_id = thread.operator_id;
    data.sender_id = thread.user_id;
    data.receiver_type = "Operator";
    data.sender_type = "User";
    const response = await createMessage(data);
    if (response.errorMsg) {
      addToast(response.errorMsg, { appearance: "error" });
    } else {
      window.location.reload();
    }
  }

  return (
    <>
      <Menu as="div" className="relative inline-grid">
        {({ open }) => (
          <>
            <div>
              <Menu.Button className="flex items-center text-gray-400 rounded-full hover:text-gray-600 focus:outline-none">
                <span className="sr-only">Open options</span>
                <EllipsisVerticalIcon className="w-5 h-5" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="absolute right-0 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <Menu.Item>
                  <ButtonText
                    onClick={() => setOpenResolveWarning(true)}
                    className="block w-full px-4 py-2 text-sm text-left focus:ring-blue-500 hover:bg-blue-50"
                    disabled={thread?.marked_as_resolved}
                  >
                    Mark as resolved
                  </ButtonText>
                </Menu.Item>
                <Menu.Item>
                  <ButtonText
                    onClick={() => setShowTravelerModal(true)}
                    className="block w-full px-4 py-2 text-sm text-left focus:ring-blue-500 hover:bg-blue-50"
                  >
                    Create a traveler message
                  </ButtonText>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
      <DialogActionPanel
        open={openResolveWarning}
        setOpen={setOpenResolveWarning}
        title="Resolve all messages"
        description="Are you sure you want to resolve all messages between this traveler and guide? Only new messages from the traveler will reopen this conversation."
        buttonText="Yes, I'm sure"
        onClick={handleResolveMessageThread}
      />
      <CreatePostTravelerModal
        showModal={showTravelerModal}
        setShowModal={setShowTravelerModal}
        handleSaveMessage={handleSaveMessage}
      />
    </>
  );
}
