import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalBody from "lunar/ModalBody";
import ModalFooter from "lunar/ModalFooter";
import Button from "lunar/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import InputText from "lunar/InputText";
import SelectField from "lunar/SelectField";
import SelectSearch from "lunar/SelectSearch";
import { formatShortDateRange } from "helpers/formatting";
import { getEventVariants } from "lib/api";

EditModal.propTypes = {
  setShowEditModal: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  showEditModal: PropTypes.bool.isRequired,
  handleError: PropTypes.func.isRequired,
  booking: PropTypes.object.isRequired,
};

export default function EditModal({
  showEditModal,
  setShowEditModal,
  handleEdit,
  handleError,
  booking,
}) {
  const schema = Yup.object().shape({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    event_variant_id: Yup.string().required("Required"),
  });

  const {
    reset,
    register,
    handleSubmit,
    control,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [eventVariants, setEventVariants] = useState([]);

  useEffect(() => {
    (async () => {
      getEventVariantsForOptions();
    })();
  }, []);

  useEffect(() => {
    reset({
      first_name: booking.first_name,
      last_name: booking.last_name,
      email: booking.email,
      num_adults: booking.num_adults,
      num_children: booking.num_children,
      event_variant_id: booking.event_variant.id,
    });
  }, [booking]);

  async function getEventVariantsForOptions() {
    const response = await getEventVariants();
    response.errorMsg
      ? handleError(response.errorMsg)
      : setEventVariants(response);
  }

  return (
    <Modal open={showEditModal} setOpen={setShowEditModal}>
      <ModalTitle>Edit reservation</ModalTitle>
      <form onSubmit={handleSubmit(handleEdit)} noValidate>
        <ModalBody>
          <div className="grid gap-6 divide-y">
            <div>
              <h4 className="font-medium">Traveler information</h4>
              <div className="w-full inline-grid grid-cols-2 gap-6 mt-4">
                <InputText
                  name="first_name"
                  type="text"
                  label="First name"
                  errors={errors}
                  register={register}
                />
                <InputText
                  name="last_name"
                  type="text"
                  label="Last name"
                  errors={errors}
                  register={register}
                />
              </div>
              <div className="mt-4">
                <InputText
                  name="email"
                  type="email"
                  label="Email"
                  errors={errors}
                  register={register}
                />
              </div>
              <div className="inline-grid grid-cols-3 gap-6 mt-6">
                <SelectField
                  name="num_adults"
                  label="# of adults"
                  options={Array.from(Array(21).keys())}
                  errors={errors}
                  register={register}
                  className="w-24"
                />
                <SelectField
                  name="num_children"
                  label="# of children"
                  options={Array.from(Array(21).keys())}
                  errors={errors}
                  register={register}
                  className="w-24"
                />
              </div>
            </div>
            <div className="pt-2">
              <h4 className="font-medium">Reservation</h4>
              <div className="mt-4">
                <SelectSearch
                  name="event_variant_id"
                  label="Residency"
                  options={eventVariants
                    .sort((a, b) => (a.start_date > b.start_date ? 1 : -1))
                    .map((eventVariant) => ({
                      label: `${
                        eventVariant.event_name
                      }: ${formatShortDateRange(
                        eventVariant.start_date,
                        eventVariant.end_date
                      )}`,
                      value: eventVariant.id,
                    }))}
                  errors={errors}
                  control={control}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Cancel
          </Button>
          <Button type="submit" isSubmitting={isSubmitting}>
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
