import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import Button from "lunar/Button";
import { money, shortPricingType } from "helpers/formatting";
import { createProduct } from "lib/api";
import CreateProductModal from "./CreateProductModal";
import Table from "lunar/Table";
import TableHead from "lunar/TableHead";
import TableBody from "lunar/TableBody";
import TableRow from "lunar/TableRow";
import TableCell from "lunar/TableCell";
import TableActions from "lunar/TableActions";

ProductsTable.propTypes = {
  products: PropTypes.array,
  tripID: PropTypes.string,
  isMain: PropTypes.bool.isRequired,
};

export default function ProductsTable({ products = [], tripID = "", isMain }) {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const { addToast } = useToasts();

  async function handleCreateProduct(data) {
    const response = await createProduct(data);
    if (response.errorMsg) {
      addToast(response.errorMsg, { appearance: "error" });
    } else {
      addToast("Product created", { appearance: "success" });
    }
    setShowModal(false);
  }

  const filteredProducts = isMain
    ? products.filter((product) => product.product_type === "main")
    : products.filter((product) => product.product_type !== "main");

  const sortedProducts = filteredProducts.sort(
    (a, b) => b.is_visible - a.is_visible
  );

  const productOptions = isMain
    ? [{ id: "main", name: "Main" }]
    : [{ id: "add_on", name: "Add on" }];

  return (
    <>
      {sortedProducts && (
        <div id="#products" className="w-full">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell head first>
                  Product
                </TableCell>
                <TableCell head>Pricing Type</TableCell>
                <TableCell head>{isMain ? "Lowest Price" : "Price"}</TableCell>
                <TableCell head>Visible</TableCell>
                <TableCell head last>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedProducts.length > 0 ? (
                <>
                  {sortedProducts.map((product) => (
                    <TableRow key={product.id}>
                      <TableCell first>{product.name}</TableCell>
                      <TableCell>
                        {shortPricingType(product.pricing_type)}
                      </TableCell>
                      <TableCell>{money(product.price)}</TableCell>
                      <TableCell>{product.is_visible ? "Yes" : "No"}</TableCell>
                      <TableCell last>
                        <Link
                          className="text-blue-600 hover:text-blue-900"
                          to={`/products/edit/${product.id}`}
                        >
                          Edit
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <>
                  <TableRow>
                    <TableCell first>none</TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>

          <TableActions>
            <Button onClick={handleShowModal}>Add</Button>
          </TableActions>

          <CreateProductModal
            tripID={tripID}
            handleCreateProduct={handleCreateProduct}
            setShowModal={setShowModal}
            showModal={showModal}
            options={productOptions}
            dateSelectionTypeOptions={
              isMain
                ? [
                    { id: "flexible", name: "Flexible" },
                    { id: "fixed", name: "Fixed" },
                  ]
                : undefined
            }
            pricingTypeOptions={
              isMain
                ? [
                    { id: "per_person", name: "Per person" },
                    { id: "per_night", name: "Per night" },
                    {
                      id: "per_person_per_night",
                      name: "Per person per night",
                    },
                    { id: "per_day", name: "Per day" },
                    {
                      id: "per_person_per_day",
                      name: "Per person per day",
                    },
                  ]
                : [
                    { id: "per_day", name: "Per day" },
                    { id: "per_person", name: "Per person" },
                  ]
            }
          />
        </div>
      )}
    </>
  );
}
