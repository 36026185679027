/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react";
import { useLoadingContext } from "context/loading-context";
import { Link } from "react-router-dom";
import { getUsers } from "lib/api";
import { longDateHoursMinutes } from "helpers/formatting";
import { ErrorAlert } from "components/common/Alerts";
import PageListNav from "components/common/PageListNav";
import ListStat from "components/common/ListStat";
import DataTable from "lunar/DataTable";
import DataTableSearch from "lunar/DataTableSearch";
import Meta from "lunar/Meta";

export default function Users() {
  const [users, setUsers] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [globalFilter, setGlobalFilter] = useState("");
  const { setLoading } = useLoadingContext();

  useEffect(() => {
    (async () => {
      populateUsers();
    })();
  }, []);

  async function populateUsers() {
    setLoading(true);
    const response = await getUsers();
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      setUsers(response);
    }
    setLoading(false);
  }

  const columns = React.useMemo(() => [
    {
      Header: "Name",
      accessor: "name",
      Cell: (c) => {
        return <span className="text-gray-900">{c.value}</span>;
      },
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: (c) => {
        return <span className="text-gray-500">{c.value}</span>;
      },
    },
    {
      Header: "Created",
      accessor: "created_at",
      Cell: (c) => <span>{longDateHoursMinutes(c.value)}</span>,
    },
    {
      Header: "Actions",
      accessor: "id",
      Cell: (c) => (
        <Link
          className="text-blue-600 hover:text-blue-900"
          to={`/users/${c.value}`}
        >
          View
        </Link>
      ),
    },
  ]);

  return (
    <>
      <Meta title={users && `All ${users.length} travelers`} />
      <div className="min-h-screen overflow-y-scroll">
        <PageListNav
          dataTableSearch={
            <DataTableSearch
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              placeholder="Search for users"
            />
          }
          actions={<ListStat color="gray" count={users?.length} text="Total" />}
        />
        <ErrorAlert message={errorMessage} />
        {users && (
          <DataTable
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            data={users.map((user) => ({
              id: user.id,
              name: `${user.first_name} ${user.last_name}`,
              email: user.email,
              created_at: user.created_at,
            }))}
            columns={columns}
            sortOptions={{ id: "created_at", desc: true }}
          />
        )}
      </div>
    </>
  );
}
