import {
  IdentificationIcon,
  TicketIcon,
  HomeModernIcon,
  CalendarIcon,
  FingerPrintIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import NavbarLink from "./NavbarLink";
import NavbarProfile from "./NavbarProfile";
import NavbarMenu from "./NavbarMenu";

export default function Navbar() {
  return (
    <nav className="flex flex-col items-center h-screen gap-4 px-3 py-4 border-r border-gray-200 bg-gray-50">
      <div className="flex flex-col items-center flex-1 gap-2">
        <NavbarLink
          path="/adventure_bookings"
          icon={<TicketIcon className="w-6 h-6" />}
        />
        <NavbarLink
          path="/accommodations"
          icon={<HomeModernIcon className="w-6 h-6" />}
        />
        <NavbarLink
          path="/adventures"
          icon={<FingerPrintIcon className="w-6 h-6" />}
        />
        <NavbarLink
          path="/events"
          icon={<CalendarIcon className="w-6 h-6" />}
        />
        <NavbarLink
          path="/guides"
          icon={<IdentificationIcon className="w-6 h-6" />}
        />
        <NavbarMenu />
      </div>
      <div className="flex flex-col ">
        <NavbarProfile />
      </div>
    </nav>
  );
}
