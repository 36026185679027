/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import styles from "./Reservation.module.scss";

export default function Reservation({
  reservation,
  handleResMouseEnter,
  isHovered,
  handleReservationClick,
  disabled,
}) {
  return (
    <>
      {reservation.isFirstInWeek && (
        <button
          type="button"
          className={cn(
            styles.reservationWrapper,
            styles[`span-${reservation.spanThisWeek}`],
            disabled && styles.disabled
          )}
          onMouseEnter={() => handleResMouseEnter(reservation.id)}
          onMouseLeave={() => handleResMouseEnter(null)}
          onClick={(e) => {
            e.stopPropagation(); // prevent click from bubbling up to day element
            handleReservationClick(reservation.transaction_number);
          }}
        >
          <div
            className={cn(
              styles.reservation,
              styles[`stack-${reservation.position}`],
              reservation.containsFirstDay && styles.hasStart,
              reservation.containsLastDay && styles.hasEnd,
              isHovered && styles.isHovered
            )}
          >
            <span className={styles.label}>{reservation.name}</span>
          </div>
        </button>
      )}
    </>
  );
}

Reservation.propTypes = {
  reservation: PropTypes.shape({
    id: PropTypes.string,
    transaction_number: PropTypes.string,
    name: PropTypes.string,
    additional_guests: PropTypes.number,
    containsFirstDay: PropTypes.bool,
    containsLastDay: PropTypes.bool,
    isStartOfWeek: PropTypes.bool,
    isFirstInWeek: PropTypes.bool,
    spanThisWeek: PropTypes.number,
    position: PropTypes.number,
  }),
  handleResMouseEnter: PropTypes.func.isRequired,
  isHovered: PropTypes.bool,
  handleReservationClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
