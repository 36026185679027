import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import TextField from "components/common/TextField";
import HiddenField from "components/common/HiddenField";
import { useToasts } from "react-toast-notifications";
import { useAuthContext } from "context/auth-context";
import { resetPassword, changePassword } from "lib/api";
import * as Yup from "yup";
import Card from "components/common/Card";
import CardBody from "components/common/CardBody";
import CardTitle from "components/common/CardTitle";
import CardFooter from "components/common/CardFooter";
import Button from "lunar/Button";
import { ErrorAlert } from "components/common/Alerts";

ChangePassword.propTypes = {
  forceChange: PropTypes.bool,
  token: PropTypes.string,
};

export default function ChangePassword({
  forceChange = false,
  token = "",
}) {
  const schema = Yup.object().shape({
    password: Yup.string().required("Required"),
    password_confirmation: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const [errorMessage, setErrorMessage] = useState("");
  const { addToast } = useToasts();
  const authContext = useAuthContext();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { token },
  });

  async function submitChange(data) {
    const passwordChangeCall = data.token ? resetPassword : changePassword;
    const response = await passwordChangeCall(data);
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      addToast("Password successfully changed", { appearance: "success" });
      authContext.setChangedPassword(true);
      if (forceChange) window.location.href = "/";
      else navigate("/");
    }
  }
  return (
    <Card>
      <CardBody>
        <CardTitle>Change password</CardTitle>
        <ErrorAlert message={errorMessage} />
        <form onSubmit={handleSubmit(submitChange)} noValidate>
          <HiddenField name="token" register={register} />
          <TextField
            name="password"
            type="password"
            label="Password"
            errors={errors}
            register={register}
          />
          <TextField
            name="password_confirmation"
            type="password"
            label="Confirm Password"
            errors={errors}
            register={register}
          />
          <div className="mt-4">
            <Button type="submit" isSubmitting={isSubmitting}>
              Save
            </Button>
          </div>
        </form>
      </CardBody>
      {forceChange && (
        <CardFooter>
          As a security measure, please change your password.
        </CardFooter>
      )}
    </Card>
  );
}
