import React, { useState } from "react";
import PropTypes from "prop-types";
import { longDateTime, money } from "helpers/formatting";
import { createEventWirePayment } from "lib/api";
import Button from "lunar/Button";
import WirePaymentModal from "./WirePaymentModal";
import Table from "lunar/Table";
import TableHead from "lunar/TableHead";
import TableRow from "lunar/TableRow";
import TableBody from "lunar/TableBody";
import TableCell from "lunar/TableCell";

Payments.propTypes = {
  bookingID: PropTypes.string.isRequired,
  handleError: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  payments: PropTypes.array,
};

export default function Payments({
  payments = [],
  bookingID,
  handleError,
  handleSuccess,
}) {
  const [showWirePaymentModal, setShowWirePaymentModal] = useState(false);

  function handleWirePayment() {
    setShowWirePaymentModal(true);
  }

  async function handleSaveWirePayment(data) {
    const response = await createEventWirePayment(bookingID, data);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Wire payment created");
    }
    setShowWirePaymentModal(false);
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell head first>
              Type
            </TableCell>
            <TableCell head>Reference #</TableCell>
            <TableCell head>Time</TableCell>
            <TableCell head last>
              Total
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.map((obj) => (
            <TableRow key={`${obj.id}-${obj.amount}`}>
              <TableCell first>
                {obj.payment_type
                  .replace(/_/g, " ")
                  .replace(/^./, obj.payment_type[0].toUpperCase())}
              </TableCell>
              <TableCell>{obj.payment_reference_number}</TableCell>
              <TableCell>{longDateTime(obj.created_at)}</TableCell>
              <TableCell last>{money(obj.amount)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className="mt-5 text-right">
        <div className="inline-grid grid-flow-col gap-1">
          <Button variant="secondary" onClick={handleWirePayment}>
            Add wire payment
          </Button>
        </div>
      </div>
      <WirePaymentModal
        handleSaveWirePayment={handleSaveWirePayment}
        setShowWirePaymentModal={setShowWirePaymentModal}
        showWirePaymentModal={showWirePaymentModal}
      />
    </>
  );
}
