import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalBody from "lunar/ModalBody";
import ModalFooter from "lunar/ModalFooter";
import Button from "lunar/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import TextField from "lunar/InputText";
import SelectField from "lunar/SelectField";
import TextArea from "lunar/InputTextArea";
import InputImage from "lunar/InputImage";
import HiddenField from "components/common/HiddenField";

CreateUpdateHighlightModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  handleSaveHighlight: PropTypes.func.isRequired,
  eventID: PropTypes.string,
  highlight: PropTypes.object,
  numHighlights: PropTypes.number,
};

export default function CreateUpdateHighlightModal({
  showModal,
  setShowModal,
  handleSaveHighlight,
  eventID = "",
  highlight = {},
  numHighlights = 0,
}) {
  const schema = Yup.object().shape({
    title: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    // direction: Yup.string().required("Required"),
    // break_type: Yup.string().required("Required"),
  });

  const {
    reset,
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (highlight) {
      reset({
        id: highlight.id,
        title: highlight.title,
        description: highlight.description,
        position: highlight.position,
        event_id: eventID,
      });
    } else {
      reset({
        id: "",
        title: "",
        description: "",
        position: "",
        event_id: eventID,
      });
    }
  }, [reset, highlight]);

  return (
    <Modal open={showModal} setOpen={setShowModal}>
      <ModalTitle>Edit experience</ModalTitle>
      <form onSubmit={handleSubmit(handleSaveHighlight)} noValidate>
        <ModalBody>
          <HiddenField name="id" register={register} />
          <HiddenField name="event_id" register={register} />
          <div className="grid gap-y-6">
            <div className="flex gap-x-6">
              <TextField
                name="title"
                type="text"
                label="Title"
                errors={errors}
                register={register}
                className="flex-grow"
              />
              <SelectField
                name="position"
                label="Position"
                options={Array.from({ length: numHighlights }, (_, i) => i + 1)}
                errors={errors}
                register={register}
              />
            </div>
            <TextArea
              name="description"
              type="text"
              label="Description"
              errors={errors}
              register={register}
              helper={"Limit 255 characters"}
              maxRows={3}
            />
            <InputImage
              name="image"
              label="Image"
              image={highlight?.image}
              errors={errors}
              register={register}
              className="w-3/4"
              previewAspectRatio="4/3"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button type="submit" isSubmitting={isSubmitting}>
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
