import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import Form from "lunar/Form";
import FormTitle from "lunar/FormTitle";
import FormSections from "lunar/FormSections";
import FormSection from "lunar/FormSection";
import FormSectionHeader from "lunar/FormSectionHeader";
import FormSectionBody from "lunar/FormSectionBody";
import FormRow from "lunar/FormRow";
import InputText from "lunar/InputText";
import InputTextArea from "lunar/InputTextArea";

StoryForm.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  submitForm: PropTypes.func.isRequired,
};

export default function StoryForm({
  name = "",
  description = "",
  submitForm,
}) {
  const {
    register,
    errors,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  useEffect(() => {
    if (name) {
      reset({
        story_header: name,
        story_body: description,
      });
    }
  }, [reset, name, description]);

  return (
    <Form
      handleSubmit={handleSubmit}
      submitForm={submitForm}
      isSubmitting={isSubmitting}
    >
      <FormTitle title="Headline and story" />
      <FormSections>
        <FormSection>
          <FormSectionHeader
            title="Introduction"
            description="This is a first person introduction to the trip from the guide. Marketing and sales language should be avoided. This should feel like a person to person story of what makes the trip special."
          />
          <FormSectionBody>
            <FormRow className="grid grid-flow-row">
              <InputText
                name="story_header"
                type="text"
                label="Headline"
                helper="One line that teases or sums up the trip. Ideally 3-6 words. Should be differentiated and attention getting."
                errors={errors}
                register={register}
              />
              <InputTextArea
                name="story_body"
                type="text"
                label="Story"
                helper="One paragraph written as a quote from the guide. Ideally 3-5 sentences."
                errors={errors}
                register={register}
                rows={5}
              />
            </FormRow>
          </FormSectionBody>
        </FormSection>
      </FormSections>
    </Form>
  );
}
