/* eslint-disable react/display-name, react/prop-types */

import React, { useState, useRef } from "react";
import useDetectOutsideClick from "helpers/useDetectOutsideClick";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalFooter from "lunar/ModalFooter";
import ModalBody from "lunar/ModalBody";
import Button from "lunar/Button";

export default function MessageModal({ text }) {
  const buttonRef = useRef(null);
  const menuRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  useDetectOutsideClick(buttonRef, menuRef, () => setIsOpen(false));

  return (
    <>
      <button
        type="button"
        className="inline-block relative top-1 left-2"
        onClick={() => setIsOpen(!isOpen)}
        ref={buttonRef}
      >
        <InformationCircleIcon className="w-4 h-4" />
      </button>
      <Modal open={isOpen} setOpen={setIsOpen}>
        <ModalTitle>Traveler message</ModalTitle>
        <ModalBody>
          <p>{text}</p>
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={() => setIsOpen(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
