/* eslint-disable react/display-name, react/prop-types */
import React, { useState, useEffect } from "react";
import { getAdmins } from "lib/api";
import { useLoadingContext } from "context/loading-context";
import { formatImageURL, longDateHoursMinutes } from "helpers/formatting";
import { ErrorAlert } from "components/common/Alerts";
import PageHeading from "components/common/PageHeading";
import Table from "components/common/Table";
import Main from "components/common/Main";
import Card from "components/common/Card";

export default function Admins() {
  const [admins, setAdmins] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const { setLoading } = useLoadingContext();

  useEffect(() => {
    (async () => {
      populateAdmins();
    })();
  }, []);

  async function populateAdmins() {
    setLoading(true);
    const response = await getAdmins();
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      setAdmins(response);
    }
    setLoading(false);
  }

  const columns = React.useMemo(() => [
    {
      Header: "Image",
      accessor: "main_image",
      Cell: ({ row: { original } }) =>
        original.main_image?.url ? (
          <img
            className="h-8 w-8 rounded-full object-cover"
            src={formatImageURL(original.main_image?.url)}
            alt={original.name}
          />
        ) : (
          <span></span>
        ),
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: (c) => {
        return <span className="text-gray-900">{c.value}</span>;
      },
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: (c) => {
        return <span className="text-gray-900">{c.value}</span>;
      },
    },
    {
      Header: "Created",
      accessor: "created_at",
      Cell: (c) => <span>{longDateHoursMinutes(c.value)}</span>,
    },
  ]);

  return (
    <>
      <PageHeading title="Every user" />
      <ErrorAlert message={errorMessage} />
      <Main>
        {admins && (
          <Card>
            <Table
              data={admins.map((admin) => ({
                id: admin.id,
                name: admin.name,
                email: admin.email,
                created_at: admin.created_at,
                main_image: admin.main_image,
              }))}
              columns={columns}
              sortOptions={{ id: "created_at", desc: true }}
            />
          </Card>
        )}
      </Main>
    </>
  );
}
