import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextField from "components/common/TextField";
import { useAuthContext } from "context/auth-context";
import { loginAdmin } from "lib/api";
import * as Yup from "yup";
import { ErrorAlert } from "components/common/Alerts";
import Card from "components/common/Card";
import CardBody from "components/common/CardBody";
import Button from "lunar/Button";
import CardFooter from "components/common/CardFooter";
import CardTitle from "components/common/CardTitle";

export default function Login() {
  const authContext = useAuthContext();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const schema = Yup.object().shape({
    email: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  async function submitForm(data) {
    const response = await loginAdmin(data);
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      authContext.setOneTimePassword(response.id);
      navigate("/two-factor");
    }
  }

  return (
    <Card>
      <CardBody>
        <CardTitle>Admin</CardTitle>
        <ErrorAlert message={errorMessage} />
        <form onSubmit={handleSubmit(submitForm)} noValidate>
          <TextField
            name="email"
            type="email"
            label="Email"
            errors={errors}
            register={register}
          />
          <TextField
            name="password"
            type="password"
            label="Password"
            errors={errors}
            register={register}
          />
          <div className="mt-4">
            <Button type="submit" isSubmitting={isSubmitting}>
              Login
            </Button>
          </div>
        </form>
      </CardBody>
      <CardFooter>
        <Link to="/forgot-password">Forgot your password?</Link>
      </CardFooter>
    </Card>
  );
}
