/* eslint-disable react/display-name, react/prop-types */
import React, { useState, useEffect } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useLoadingContext } from "context/loading-context";
import { getOperators, deleteOperator } from "lib/api";
import { useToasts } from "react-toast-notifications";
import { ErrorAlert } from "components/common/Alerts";
import PageListNav from "components/common/PageListNav";
import DataTable from "lunar/DataTable";
import DataTableSearch from "lunar/DataTableSearch";
import Meta from "lunar/Meta";
import NewModal from "components/operators/NewModal";

export default function Operators() {
  const [operators, setOperators] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const { addToast } = useToasts();
  const [globalFilter, setGlobalFilter] = useState("");
  const { setLoading } = useLoadingContext();

  useEffect(() => {
    (async () => {
      populatePageOperators();
    })();
  }, []);

  async function confirmDeleteOperator(id) {
    if (window.confirm("Are you sure you want to delete this guide?")) {
      const response = await deleteOperator(id);
      if (response.errorMsg) {
        setErrorMessage(response.errorMsg);
      } else {
        addToast("Guide successfully deleted", { appearance: "success" });
        populatePageOperators();
      }
    }
  }

  async function populatePageOperators() {
    setLoading(true);
    const response = await getOperators();
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      setOperators(response.sort((a, b) => (a.name > b.name ? 1 : -1)));
    }
    setLoading(false);
  }

  const columns = React.useMemo(() => [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row: { original } }) => {
        return <span className="text-gray-900 truncate">{original.name}</span>;
      },
    },
    {
      Header: "Trips",
      accessor: "trips",
      Cell: ({ row: { original } }) => {
        return original.trips.map((trip, index) => (
          <React.Fragment key={`${trip.id}-${index}`}>
            <div
              className={cn(
                "mr-2 inline-block w-2.5 h-2.5 rounded-full",
                trip.is_visible ? "bg-green-200" : "bg-yellow-200"
              )}
            />
            <Link
              key={trip.id}
              className="underline hover:no-underline"
              to={`/trips/edit/${trip.slug}`}
            >
              {trip.name}
            </Link>
            {index !== original.trips.length - 1 && (
              <div className="inline-block ml-4" />
            )}
          </React.Fragment>
        ));
      },
    },
    {
      Header: <div className="whitespace-nowrap">CS Warning</div>,
      accessor: "warning",
      Cell: ({ row: { original } }) => {
        return original.warning ? (
          <div className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
            Yes
          </div>
        ) : (
          <div />
        );
      },
    },
    {
      Header: "Actions",
      accessor: "actionsId",
      Cell: ({ row: { original } }) => {
        return (
          <div className="whitespace-nowrap">
            <Link
              className="text-blue-600 hover:text-blue-900"
              to={`/operators/edit/${original.id}`}
            >
              Edit
            </Link>
            <button
              onClick={() => confirmDeleteOperator(original.id)}
              className="ml-3 text-red-600 hover:text-red-900"
            >
              Delete
            </button>
          </div>
        );
      },
    },
  ]);

  return (
    <>
      <Meta title={operators && `All ${operators.length} guides`} />
      <div className="min-h-screen overflow-y-scroll">
        <PageListNav
          dataTableSearch={
            <DataTableSearch
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              placeholder="Search for guides"
            />
          }
          actions={<NewModal populatePageOperators={populatePageOperators} />}
        />
        <ErrorAlert message={errorMessage} />
        {operators && (
          <DataTable
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            data={operators.map((operator) => ({
              id: operator.id,
              name: operator.name,
              trips: operator.trips,
              warning: operator.alert_header,
              actionsId: operator.id,
              slug: operator.slug,
            }))}
            columns={columns}
            sortOptions={{ id: "name", desc: false }}
          />
        )}
      </div>
    </>
  );
}
