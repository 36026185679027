import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import QuestionsArea from "./QuestionsArea";
import * as Yup from "yup";
import Form from "lunar/Form";
import FormTitle from "lunar/FormTitle";
import FormSections from "lunar/FormSections";
import FormSection from "lunar/FormSection";
import FormSectionHeader from "lunar/FormSectionHeader";
import FormSectionBody from "lunar/FormSectionBody";
import FormRow from "lunar/FormRow";

QuestionsForm.propTypes = {
  questions: PropTypes.object,
  submitForm: PropTypes.func.isRequired,
};

export default function QuestionsForm({
  questions = {},
  submitForm,
}) {
  const schema = Yup.object().shape({
    // name: Yup.string().required("Required"),
  });

  const {
    register,
    handleSubmit,
    errors,
    reset,
    setValue,
    getValues,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  function preProcessForm(data) {
    // transform overview 2D arrays to key/value pairs for JSON
    const questionsObj = {};
    if (data.questions !== undefined) {
      for (let entry of data.questions) {
        questionsObj[entry[0]] = entry[1];
      }
    }
    data.questions = questionsObj;
    return submitForm(data);
  }

  useEffect(() => {
    reset({
      questions: Object.entries(questions || {}),
    });
  }, [reset, JSON.stringify(questions)]);

  return (
    <Form
      handleSubmit={handleSubmit}
      submitForm={preProcessForm}
      isSubmitting={isSubmitting}
    >
      <FormTitle title="FAQs" />
      <FormSections>
        <FormSection>
          <FormSectionHeader
            title="Common questions"
            description="What is / is not included are required. Additional questions can be added to provide more helpful information."
          />
          <FormSectionBody>
            <FormRow className="grid grid-flow-row">
              <QuestionsArea
                questions={questions}
                reset={reset}
                errors={errors}
                register={register}
                setValue={setValue}
                getValues={getValues}
              />
            </FormRow>
          </FormSectionBody>
        </FormSection>
      </FormSections>
    </Form>
  );
}
