import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLoadingContext } from "context/loading-context";
import { getUser } from "lib/api";
import { useToasts } from "react-toast-notifications";
import { ErrorAlert } from "components/common/Alerts";
import PageHeading from "components/common/PageHeading";
import Main from "components/common/Main";
import Summary from "./sections/Summary";
import Messages from "./sections/Messages";
import Reservations from "./sections/Reservations";
import Credits from "./sections/Credits";
import Meta from "lunar/Meta";

export default function ViewUser() {
  const [user, setUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const userID = useParams().id;
  const { addToast } = useToasts();
  const { setLoading } = useLoadingContext();

  useEffect(() => {
    (async () => {
      populateUserState(userID);
    })();
  }, [userID]);

  async function populateUserState(userID) {
    setLoading(true);
    const response = await getUser(userID);
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      setUser(response);
    }
    setLoading(false);
  }

  function handleSuccess(msg) {
    populateUserState(userID);
    addToast(msg, { appearance: "success" });
  }

  return (
    <>
      <Meta title={user && `${user.first_name} ${user.last_name}`} />
      <PageHeading title={user && `${user.first_name} ${user.last_name}`} />
      <ErrorAlert message={errorMessage} />
      {user && (
        <Main>
          <div className="grid grid-flow-col gap-10">
            <Summary user={user} />
            <Credits
              credits={user.credits}
              handleError={setErrorMessage}
              handleSuccess={handleSuccess}
              userID={userID}
            />
          </div>
          <Messages threads={user.message_threads} />
          <Reservations
            reservations={user.bookings.filter(
              (booking) => booking.status !== "ready_for_instant_booking"
            )}
          />
        </Main>
      )}
    </>
  );
}
