import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useToasts } from "react-toast-notifications";
import { createAdventureBooking, createEventBooking } from "lib/api";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import Button from "lunar/Button";
import NewForm from "components/adventure_bookings/NewForm";

NewModal.propTypes = {
  populatePageBookings: PropTypes.func.isRequired,
};

export default function NewModal({ populatePageBookings }) {
  const [showNewModal, setShowNewModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { addToast } = useToasts();

  async function submitForm(data) {
    let response = null;
    if (data.event_variant_id !== undefined) {
      response = await createEventBooking(data);
    } else {
      response = await createAdventureBooking(data);
    }
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      addToast("Booking successfully created", { appearance: "success" });
      setShowNewModal(false);
      populatePageBookings();
    }
  }

  useEffect(() => {
    errorMessage && addToast(errorMessage, { appearance: "error" });
  }, [errorMessage]);

  return (
    <>
      <Button onClick={() => setShowNewModal(true)}>New reservation</Button>
      <Modal open={showNewModal} setOpen={setShowNewModal}>
        <ModalTitle>Create a new reservation</ModalTitle>
        <NewForm
          setErrorMessage={setErrorMessage}
          submitForm={submitForm}
          setShowNewModal={setShowNewModal}
        />
      </Modal>
    </>
  );
}
