/* eslint-disable react/display-name, react/prop-types */

import React, { useState, useEffect } from "react";
import { getGuides } from "lib/api";
import { useLoadingContext } from "context/loading-context";
import { ErrorAlert } from "components/common/Alerts";
import PageListNav from "components/common/PageListNav";
import DataTable from "lunar/DataTable";
import DataTableSearch from "lunar/DataTableSearch";
import NewModal from "components/guides/NewModal";
import Meta from "lunar/Meta";
import { useNavigate } from "react-router-dom";
import Button from "lunar/Button";

export default function Guides() {
  const { setLoading } = useLoadingContext();
  const [guides, setGuides] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      populatePageGuides();
    })();
  }, []);

  async function populatePageGuides() {
    setLoading(true);
    const response = await getGuides();
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      setGuides(response.sort((a, b) => (a.name > b.name ? 1 : -1)));
    }
    setLoading(false);
  }

  const columns = React.useMemo(() => [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row: { original } }) => {
        return (
          <span className="font-medium text-gray-900">{original.name}</span>
        );
      },
    },
    {
      Header: "",
      accessor: "actions",
      Cell: ({ row: { original } }) => {
        return (
          <div className="flex justify-end">
            {original.has_calendar && (
              <Button
                variant="secondary"
                onClick={(e) => {
                  e.preventDefault(),
                    navigate(
                      `/guide_calendars/${original.id}`
                    );
                }}
              >
                Availability
              </Button>
            )}
          </div>
        );
      },
    },
  ]);

  return (
    <>
      <Meta title={guides && `All ${guides.length} adventures`} />
      <div className="min-h-screen overflow-y-scroll bg-white">
        <PageListNav
          dataTableSearch={
            <DataTableSearch
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              placeholder="Search for guides"
            />
          }
          actions={
            <div className="grid items-center grid-flow-col gap-6">
              <div className="ml-4">
                <NewModal populatePageGuides={populatePageGuides} />
              </div>
            </div>
          }
        />

        <ErrorAlert message={errorMessage} />
        {guides && (
          <DataTable
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            data={guides.map((guide) => ({
              id: guide.id,
              name: guide.name,
              actions: guide.id,
              slug: guide.slug,
              has_calendar: guide.has_calendar,
              rowLink: `edit/${guide.id}`
            }))}
            columns={columns}
            rowLinkAttribute="rowLink"
            sortOptions={{ id: "name", desc: false }}
          />
        )}
      </div>
    </>
  );
}
