import React from "react";
import PropTypes from "prop-types";
import {
  humanDateTime,
  money,
  getDifferenceInSeconds,
} from "helpers/formatting";
import Feed from "components/bookings/sections/Feed";
import FeedItem from "components/bookings/sections/FeedItem";

Activity.propTypes = {
  booking: PropTypes.object,
};

const defaultBooking = {};

export default function Activity({ booking = defaultBooking }) {
  const entries = [];
  const userName = booking.name;
  let bookingCreatedFlag = false;
  let bookingCreatedTimestamp = null;

  for (let idx = 0; idx < booking.changes.length; idx += 1) {
    const entry = booking.changes[idx];
    const feedItem = {
      id: entry.id,
      summary: {
        person: entry.admin_name || "Admin",
        str: null,
        time: humanDateTime(entry.created_at),
      },
      details: [],
    };
    const changes = entry.object_changes;
    const attrs = entry.item_associated_attributes;

    if (entry.event === "create") {
      if (entry.item_type === "EventBooking") {
        if (!entry.admin_name) feedItem.summary.person = userName;
        feedItem.summary.str = "created booking";
        bookingCreatedFlag = true;
        bookingCreatedTimestamp = entry.created_at;
      } else if (entry.item_type === "EventLineItem") {
        let lineItemDetail = `${changes.description[1]} for ${money(
          changes.price[1]
        )}`;
        if (
          bookingCreatedFlag &&
          getDifferenceInSeconds(entry.created_at, bookingCreatedTimestamp) < 1
        ) {
          entries[entries.length - 1].details.push(lineItemDetail);
          continue;
        } else {
          feedItem.summary.str = "created line item";
          feedItem.details.push(lineItemDetail);
        }
      } else if (entry.item_type === "EventPayment") {
        if (!entry.admin_name) feedItem.summary.person = userName;
        feedItem.summary.str = `made a ${
          changes.payment_type[1]
        } payment for ${money(changes.amount[1])}`;
      } else if (entry.item_type === "EventRefund") {
        feedItem.summary.str = `made a ${
          changes.refund_type[1]
        } refund for ${money(changes.amount[1])}`;
      }
    } else if (entry.event === "update" && entry.item_type === "EventBooking") {
      if (changes.status) {
        feedItem.summary.str = "changed status";
        feedItem.details.push(
          `Status changed from ${changes.status[0]} to ${changes.status[1]}`
        );
      } else {
        if (
          changes.first_name ||
          changes.last_name ||
          changes.email ||
          changes.num_adults ||
          changes.num_children ||
          changes.event_variant_id
        )
          feedItem.summary.str = "changed booking details";
        if (changes.first_name) {
          feedItem.details.push(
            `First name changed from ${changes.first_name[0]} to ${changes.first_name[1]}`
          );
        }
        if (changes.last_name) {
          feedItem.details.push(
            `Last name changed from ${changes.last_name[0]} to ${changes.last_name[1]}`
          );
        }
        if (changes.email) {
          feedItem.details.push(
            `Email changed from ${changes.email[0]} to ${changes.email[1]}`
          );
        }
        if (changes.num_adults) {
          feedItem.details.push(
            `Num adults changed from ${changes.num_adults[0]} to ${changes.num_adults[1]}`
          );
        }
        if (changes.num_children) {
          feedItem.details.push(
            `Num children changed from ${changes.num_children[0]} to ${changes.num_children[1]}`
          );
        }
        if (changes.event_variant_id) {
          feedItem.details.push(`Event changed to ${attrs.event_name}`);
        }
      }
    } else if (entry.event === "destroy") {
      if (entry.item_type === "EventLineItem") {
        feedItem.summary.str = "removed a line item";
        feedItem.details.push(
          `Line item removed: ${changes.description[0]} for ${money(
            changes.price[0]
          )}`
        );
      }
    }
    if (feedItem.summary.str) entries.push(feedItem);
    if (!(entry.event === "create" && entry.item_type === "EventBooking")) {
      bookingCreatedFlag = false;
      bookingCreatedTimestamp = null;
    }
  }

  return (
    <Feed>
      {entries.reverse().map((entry) => (
        <FeedItem
          key={entry.id}
          person={entry.summary.person}
          summary={entry.summary.str}
          date={entry.summary.time}
        >
          <div key={`entry-${entry.id}`} className="mt-2">
            {entry.details.map((detail) => (
              <div key={`detail-${entry.id}-${detail}`}>{detail}</div>
            ))}
          </div>
        </FeedItem>
      ))}
    </Feed>
  );
}
