import React, { useState, useEffect } from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import { useLoadingContext } from "context/loading-context";
import { useToasts } from "react-toast-notifications";
import { getInineraryAttributes, updateAdventureBooking } from "lib/api";
import Table from "lunar/Table";
import TableHead from "lunar/TableHead";
import TableRow from "lunar/TableRow";
import TableCell from "lunar/TableCell";
import TableBody from "lunar/TableBody";

export default function AttributesTable({
  bookingID,
  bookingAttributes,
  repopulateBookingState,
}) {
  const { setLoading } = useLoadingContext();
  const { addToast } = useToasts();
  const [attributes, setAttributes] = useState(null);

  function handleError(msg) {
    addToast(msg, { appearance: "error" });
  }

  function handleSuccess(msg) {
    populateAttributesState();
    addToast(msg, { appearance: "success" });
  }

  useEffect(() => {
    if (attributes) {
      generateMissingAttributes();
    }
  }, [attributes]);

  useEffect(() => {
    populateAttributesState();
  }, [bookingID]);

  async function populateAttributesState() {
    setLoading(true);
    const response = await getInineraryAttributes();
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      setAttributes(response);
    }
    setLoading(false);
  }

  async function handleUpdateAttribute(savedId, referenceId, isIncluded) {
    const data = {
      adventure_bookings_itinerary_attributes_attributes: [
        {
          id: savedId,
          itinerary_attribute_id: referenceId,
          is_included: isIncluded,
        },
      ],
    };
    const response = await updateAdventureBooking(bookingID, data);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      repopulateBookingState(bookingID);
      handleSuccess("Attribute updated");
    }
  }

  async function cloneAttributes(attributesToAdd) {
    const data = {
      adventure_bookings_itinerary_attributes_attributes: attributesToAdd,
    };

    const response = await updateAdventureBooking(bookingID, data);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      repopulateBookingState(bookingID);
    }
  }

  function generateMissingAttributes() {
    const attributesToAdd = [];

    attributes.forEach((attr) => {
      const matchingAttributes = bookingAttributes.filter((obj) => {
        return obj.itinerary_attribute_id === parseInt(attr.id);
      });

      if (matchingAttributes.length < 1) {
        attributesToAdd.push({
          itinerary_attribute_id: attr.id,
          position: attr.priority,
          is_included: "",
        });
      }
    });
    cloneAttributes(attributesToAdd);
  }

  function getAttributeProp(itinerary_attribute_id, prop) {
    const attributesArr = attributes?.filter((obj) => {
      return obj.id === String(itinerary_attribute_id);
    });

    if (attributesArr?.length > 0) {
      return attributesArr[0][prop];
    }

    return "";
  }

  function comparePositions(a, b) {
    if (a.position < b.position) {
      return -1;
    }
    if (a.position > b.position) {
      return 1;
    }
    return 0;
  }

  return (
    <>
      {bookingAttributes && (
        <div>
          <Table fixed>
            <TableHead>
              <TableRow>
                <TableCell head first className="w-48">
                  Headline
                </TableCell>
                <TableCell head>Subhead</TableCell>
                <TableCell head last className="w-56">
                  Included
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bookingAttributes.sort(comparePositions).map((attribute) => (
                <TableRow key={attribute.id}>
                  <TableCell first wrap className="text-gray-900">
                    {getAttributeProp(
                      attribute.itinerary_attribute_id,
                      "header"
                    ) || " "}
                  </TableCell>
                  <TableCell className="truncate">
                    {getAttributeProp(
                      attribute.itinerary_attribute_id,
                      "subheader"
                    ) || " "}
                  </TableCell>
                  <TableCell last>
                    <span className="inline-flex rounded-md shadow-sm isolate">
                      <button
                        type="button"
                        className={cn(
                          "relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-medium ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10",
                          String(attribute.is_included) === "true"
                            ? "bg-white text-gray-900"
                            : "bg-gray-200 text-gray-500"
                        )}
                        onClick={() =>
                          handleUpdateAttribute(
                            attribute.id,
                            getAttributeProp(
                              attribute.itinerary_attribute_id,
                              "id"
                            ),
                            true
                          )
                        }
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        className={cn(
                          "relative -ml-px inline-flex items-center px-3 py-2 text-sm font-medium ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10",
                          String(attribute.is_included) === "false"
                            ? "bg-white text-gray-900"
                            : "bg-gray-200 text-gray-500"
                        )}
                        onClick={() =>
                          handleUpdateAttribute(
                            attribute.id,
                            getAttributeProp(
                              attribute.itinerary_attribute_id,
                              "id"
                            ),
                            false
                          )
                        }
                      >
                        No
                      </button>
                      <button
                        type="button"
                        className={cn(
                          "relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-medium ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10",
                          String(attribute.is_included) === "null"
                            ? "bg-white text-gray-900"
                            : "bg-gray-200 text-gray-500"
                        )}
                        onClick={() =>
                          handleUpdateAttribute(
                            attribute.id,
                            getAttributeProp(
                              attribute.itinerary_attribute_id,
                              "id"
                            ),
                            ""
                          )
                        }
                      >
                        Hidden
                      </button>
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </>
  );
}

AttributesTable.propTypes = {
  bookingID: PropTypes.string,
  bookingAttributes: PropTypes.arrayOf(
    PropTypes.shape({
      adventure_booking_id: PropTypes.number,
      id: PropTypes.string,
      is_included: PropTypes.bool,
      itinerary_attribute_id: PropTypes.number,
      position: PropTypes.number,
    })
  ),
  repopulateBookingState: PropTypes.func.isRequired,
};
