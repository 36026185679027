import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

ButtonText.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.node,
};

export default function ButtonText({
  children,
  type = "button",
  disabled = false,
  onClick = null,
  className = null,
}) {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={cn(
        "focus:ring-0",
        disabled && "focus:outline-none disabled:opacity-50 cursor-not-allowed",
        className
      )}
    >
      {children}
    </button>
  );
}
