import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { Controller } from "react-hook-form";

GalleryInput.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  handleFilesAdded: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
};

export default function GalleryInput({
  name,
  control,
  handleFilesAdded,
  multiple = false,
}) {
  Dropzone.propTypes = {
    onChange: PropTypes.func.isRequired,
  };

  function Dropzone({ onChange }) {
    const onDrop = useCallback(
      (acceptedFiles) => {
        onChange(acceptedFiles);
      },
      [onChange]
    );
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
      onDrop,
      multiple: multiple,
      //maxFiles: 1,
    });
    const files = acceptedFiles.map((file) => (
      <div
        key={file.path}
        className="grid grid-cols-2 mt-1 text-xs font-medium text-gray-500 auto-cols-fr"
      >
        <div className="truncate">{file.path}</div>
        <div>{`${parseInt(file.size / 1000)}kb`}</div>
      </div>
    ));
    return (
      <div className="mt-6">
        <div className="pt-4 border-t" {...getRootProps()}>
          <div className="text-sm text-gray-500">
            <label
              htmlFor="file-upload"
              className="relative text-sm font-medium cursor-pointer"
            >
              <span>
                <span className="text-blue-500">Upload</span>
                {" or drag and drop"}
              </span>
              <input {...getInputProps()} />
            </label>
          </div>
        </div>
        {files.length > 0 && (
          <div className="overflow-hidden">
            <div className="mt-6 text-sm font-medium">
              Photo{multiple && "s"} ready to upload
            </div>
            <div className="mt-2">{files}</div>
          </div>
        )}
      </div>
    );
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      render={(
        { onChange } // eslint-disable-line no-unused-vars
      ) => <Dropzone multiple={multiple} onChange={handleFilesAdded} />}
    />
  );
}
