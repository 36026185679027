import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalBody from "lunar/ModalBody";
import ModalFooter from "lunar/ModalFooter";
import Button from "lunar/Button";
import { parse, isValid } from "date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import TextField from "components/common/TextField";
import HiddenField from "components/common/HiddenField";

CreateUpdatePromotionModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  handleSavePromotion: PropTypes.func.isRequired,
  promotion: PropTypes.object,
};

export default function CreateUpdatePromotionModal({
  showModal,
  setShowModal,
  handleSavePromotion,
  promotion = {},
}) {
  const schema = Yup.object().shape({
    code: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    start_time: Yup.string()
      .required("Required")
      .test("is-date", "Valid date required", (value) =>
        isValid(parse(value, "yyyy-MM-dd", new Date()))
      ),
    end_time: Yup.string()
      .required("Required")
      .test("is-date", "Valid date required", (value) =>
        isValid(parse(value, "yyyy-MM-dd", new Date()))
      ),
    max_uses: Yup.number()
      .integer()
      .min(1)
      .nullable(true)
      .transform((v, o) => (o === "" ? null : v)),
    min_dollar_value: Yup.number()
      .integer()
      .min(1)
      .nullable(true)
      .transform((v, o) => (o === "" ? null : v)),
    percent_value: Yup.string().test(
      "integer-if-no-dollar-value",
      "Integer for either percent_value or dollar_value required",
      function (value) {
        const { dollar_value } = this.parent;
        if (dollar_value === "") {
          const percent_num = parseInt(value, 10);
          if (isNaN(percent_num) || percent_num <= 0) return false;
          return true;
        }
        return value === "";
      }
    ),
    dollar_value: Yup.string().test(
      "integer-if-no-percent-value",
      "Integer for either percent_value or dollar_value required",
      function (value) {
        const { percent_value } = this.parent;
        if (percent_value === "") {
          const dollar_num = parseInt(value, 10);
          if (isNaN(dollar_num) || dollar_num <= 0) return false;
          return true;
        }
        return value === "";
      }
    ),
  });

  const {
    reset,
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (promotion) {
      reset({
        id: promotion.id,
        code: promotion.code,
        name: promotion.name,
        start_time: promotion.start_time.slice(0, 10),
        end_time: promotion.end_time.slice(0, 10),
        percent_value: promotion.percent_value,
        dollar_value: promotion.dollar_value,
        max_uses: promotion.max_uses,
        min_dollar_value: promotion.min_dollar_value,
      });
    } else {
      reset({
        id: "",
        promotion: "",
        name: "",
        start_time: "",
        end_time: "",
        percent_value: "",
        dollar_value: "",
        max_uses: "",
        min_dollar_value: "",
      });
    }
  }, [reset, promotion]);

  return (
    <Modal open={showModal} setOpen={setShowModal}>
      <ModalTitle>Create promotion</ModalTitle>
      <form onSubmit={handleSubmit(handleSavePromotion)} noValidate>
        <ModalBody>
          <HiddenField name="id" register={register} />
          <div className="grid grid-cols-2 gap-x-6 ">
            <TextField
              name="code"
              type="text"
              label="Code"
              errors={errors}
              register={register}
            />
            <TextField
              name="name"
              type="text"
              label="Name"
              errors={errors}
              register={register}
            />
            <TextField
              name="start_time"
              type="date"
              label="Start date"
              errors={errors}
              register={register}
            />
            <TextField
              name="end_time"
              type="date"
              label="End date"
              errors={errors}
              register={register}
            />
            <TextField
              name="percent_value"
              type="text"
              label="Percent value"
              errors={errors}
              register={register}
            />
            <TextField
              name="dollar_value"
              type="text"
              label="Dollar value"
              errors={errors}
              register={register}
            />
            <TextField
              name="max_uses"
              type="text"
              label="Max uses"
              errors={errors}
              register={register}
            />
            <TextField
              name="min_dollar_value"
              type="text"
              label="Min order value"
              errors={errors}
              register={register}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button type="submit" isSubmitting={isSubmitting}>
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
