import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import HiddenField from "components/common/HiddenField";
import Form from "lunar/Form";
import FormTitle from "lunar/FormTitle";
import FormSections from "lunar/FormSections";
import FormSection from "lunar/FormSection";
import FormSectionHeader from "lunar/FormSectionHeader";
import FormSectionBody from "lunar/FormSectionBody";
import FormRow from "lunar/FormRow";
import SelectField from "lunar/SelectField";
import InputText from "lunar/InputText";
import InputTextAddon from "lunar/InputTextAddon";
import InputImage from "lunar/InputImage";
import InputCheckbox from "lunar/InputCheckbox";

DetailsForm.propTypes = {
  trip: PropTypes.object,
  submitForm: PropTypes.func.isRequired,
  operators: PropTypes.array,
  collections: PropTypes.array,
  surfFeatures: PropTypes.array,
  accommodationFeatures: PropTypes.array,
  categories: PropTypes.array,
};

export default function DetailsForm({
  trip = {},
  submitForm,
  operators = [],
  collections = [],
  surfFeatures = [],
  accommodationFeatures = [],
  categories = [],
}) {
  const schema = Yup.object().shape({});

  const {
    register,
    handleSubmit,
    errors,
    reset,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      id: trip.id,
      name: trip.name,
      slug: trip.slug,
      trip_type_description: trip.trip_type_description,
      operator_id: trip.operator?.id,
      cancellation_policy: trip.cancellation_policy,
      tax_percentage: trip.tax_percentage,
      thermal_take_rate: trip.thermal_take_rate,
      overview: formatOverviewInputEntries(trip.overview),
      tag_list: trip.tag_list,
      surf_feature_list: trip.surf_feature_list,
      accommodation_feature_list: trip.accommodation_feature_list,
      category_list: trip.category_list,
      tax_id: trip.tax_id,
      check_in_time: trip.check_in_time,
      check_out_time: trip.check_out_time,
    });
  }, [reset, trip]);

  const overviewOptions = new Map();
  overviewOptions.set("Accommodation", [
    "Strive for consistency across trips",
    "Private bungalows",
  ]);
  overviewOptions.set("Duration", [
    "An approximation based on all inventory",
    "6+ nights",
  ]);

  function formatOverviewInputEntries(overview) {
    if (overview !== undefined) {
      const result = {};
      overviewOptions.forEach((val, key) => {
        result[key] = overview[key];
      });
      return Object.entries(result);
    }
    return Object.entries({});
  }

  function preProcessForm(data) {
    // transform overview 2D arrays to key/value pairs for JSON
    const overviewObj = {};
    if (data.overview !== undefined) {
      for (let entry of data.overview) {
        overviewObj[entry[0]] = entry[1];
      }
    }
    data.overview = overviewObj;
    return submitForm(data);
  }

  function mapCategoryHelper(c) {
    switch (c) {
      case "Boats":
        return "The traveler is sleeping on the boat";
      case "Hostels":
        return "At least one shared room option";
      case "Lodges":
        return "At least one private room/bungalow option";
      case "Villas":
        return "Private homes stays";
      case "Guiding":
        return "Local, experts providing transportation and advice";
      case "Coaching":
        return "Private instruction for intermediate+ surfers";
      case "Lessons":
        return "Private and group instruction for anyone";
      case "Camping":
        return "At least one outdoor sleeping option";
      case "Road trips":
        return "Multi-day land-based spanning multiple locations";
      case "Luxe":
        return "Very nice accommodations and excellent service";
      default:
        return "";
    }
  }

  function mapFeatureHelper(f) {
    switch (f) {
      case "Boat trips to nearby waves":
        return "Provides daily boat rides to waves";
      case "Friendly waves nearby":
        return "Ask Ryan. Kinda tricky.";
      case "Great instruction":
        return "Instruction on site or provided through partner";
      case "Photo and/or video crew on site":
        return "Doesn't have to be included, but it's mentioned";
      case "Beachfront":
        return "Nothing between the property and the sand";
      case "Private bungalows":
        return "At least a couple detached rooms";
      case "Solid wifi for remote working":
        return "Fast wifi noted in independent reviews";
      default:
        return "";
    }
  }

  return (
    <Form
      handleSubmit={handleSubmit}
      submitForm={preProcessForm}
      isSubmitting={isSubmitting}
    >
      <FormTitle title="Details" />
      <FormSections>
        <FormSection>
          <FormSectionHeader
            title="Settings"
            description="The settings and required information to form the listing view."
          />
          <FormSectionBody className="flex flex-col gap-y-6">
            <FormRow>
              <InputText
                name="name"
                type="text"
                label="Trip name"
                errors={errors}
                register={register}
                className="flex-grow"
                disabled={trip.is_visible}
              />
              <InputText
                name="slug"
                type="text"
                label="URL slug"
                errors={errors}
                register={register}
                className="flex-grow"
                disabled={trip.is_visible}
              />
              <InputText
                name="id"
                type="text"
                label="ID"
                errors={errors}
                register={register}
                className="w-20"
                disabled={true}
              />
            </FormRow>
            <FormRow>
              <SelectField
                name="trip_type_description"
                label="Trip type"
                options={["Lodge", "Boat", "Adventure"]}
                errors={errors}
                register={register}
                className="flex-grow"
              />
              <SelectField
                name="operator_id"
                label="Guide"
                options={operators}
                errors={errors}
                register={register}
                className="flex-grow"
              />
              <SelectField
                name="cancellation_policy"
                label="Cancellation policy"
                options={[
                  { id: "Flexible", name: "Flexible (30)" },
                  { id: "Strict", name: "Strict (90)" },
                ]}
                errors={errors}
                register={register}
                className="w-40"
              />
            </FormRow>
          </FormSectionBody>
        </FormSection>
        <FormSection>
          <FormSectionHeader
            title="Billing"
            description="Information for payouts and billing"
          />
          <FormSectionBody className="flex flex-col gap-y-6">
            <FormRow>
              <InputTextAddon
                name="thermal_take_rate"
                type="text"
                label="Take rate"
                errors={errors}
                register={register}
                addonRight="%"
                className="w-20"
                disabled={true}
              />
              <InputTextAddon
                name="tax_percentage"
                type="text"
                label="Tax perc."
                errors={errors}
                register={register}
                addonRight={<span className="text-gray-500 sm:text-sm">%</span>}
                className="w-20"
                placeholder="7.5"
              />
              <InputText
                name={"tax_id"}
                type="text"
                label={"Tax Id"}
                errors={errors}
                register={register}
              />
            </FormRow>
          </FormSectionBody>
        </FormSection>
        <FormSection>
          <FormSectionHeader
            title="Check-in/out"
            description="Display when travelers can arrive and depart by"
          />
          <FormSectionBody className="flex flex-col gap-y-6">
            <FormRow>
              <InputText
                name={"check_in_time"}
                label={"Check-in time"}
                type="time"
                errors={errors}
                register={register}
              />
              <InputText
                name={"check_out_time"}
                label={"Check-out time"}
                type="time"
                errors={errors}
                register={register}
              />
            </FormRow>
          </FormSectionBody>
        </FormSection>
        <FormSection>
          <FormSectionHeader
            title="Overview"
            description="The hero image as well as structured information used for comparing trips."
          />
          <FormSectionBody>
            <FormRow>
              <InputImage
                name="main_image"
                label="Hero image"
                register={register}
                image={trip.main_image}
                className="flex-grow mr-10"
              />
              <div className="flex flex-col gap-6">
                {formatOverviewInputEntries(trip.overview).map(
                  // eslint-disable-next-line no-unused-vars
                  ([key, value], index) => (
                    <React.Fragment key={`overview-${index}`}>
                      <HiddenField
                        name={`overview[${index}][0]`}
                        register={register}
                      />
                      <InputText
                        name={`overview[${index}][1]`}
                        type="text"
                        label={key}
                        helper={overviewOptions.get(key)[0]}
                        placeholder={overviewOptions.get(key)[1]}
                        errors={errors}
                        register={register}
                      />
                    </React.Fragment>
                  )
                )}
              </div>
            </FormRow>
          </FormSectionBody>
        </FormSection>
        <FormSection>
          <FormSectionHeader
            title="Categories"
            description="Most listings will belong to multiple categories"
          />
          <FormSectionBody>
            <FormRow className="grid grid-cols-2">
              {categories.map((category) => (
                <InputCheckbox
                  key={category.name}
                  value={category.name}
                  name="category_list"
                  register={register}
                  helper={mapCategoryHelper(category.name)}
                />
              ))}
            </FormRow>
          </FormSectionBody>
        </FormSection>
        <FormSection>
          <FormSectionHeader
            title="Collections"
            description="Groupings of listings primarily used for marketing purposes"
          />
          <FormSectionBody>
            <FormRow className="grid grid-cols-2">
              {collections?.map((collection) => (
                <InputCheckbox
                  key={collection.name}
                  value={collection.name}
                  name="surf_feature_list"
                  register={register}
                  helper={
                    <Link
                      className="truncate hover:no-underline"
                      to={`/collections/edit/${collection.collections[0].id}`}
                    >
                      {collection.collections[0].name}
                    </Link>
                  }
                />
              ))}
            </FormRow>
          </FormSectionBody>
        </FormSection>
        <FormSection>
          <FormSectionHeader
            title="Features"
            description="The ameneties and attributes of the trips"
          />
          <FormSectionBody>
            <h3 className="mb-3 font-medium text-md">Surf</h3>
            <FormRow className="grid grid-cols-2">
              {surfFeatures.map((f) => (
                <InputCheckbox
                  key={f.name}
                  value={f.name}
                  name="surf_feature_list"
                  register={register}
                  helper={mapFeatureHelper(f.name)}
                />
              ))}
            </FormRow>
            <h3 className="mt-10 mb-3 font-medium text-md">Accommodations</h3>
            <FormRow className="grid grid-cols-2">
              {accommodationFeatures.map((f) => (
                <InputCheckbox
                  key={f.name}
                  value={f.name}
                  register={register}
                  name="accommodation_feature_list"
                  helper={mapFeatureHelper(f.name)}
                />
              ))}
            </FormRow>
          </FormSectionBody>
        </FormSection>
      </FormSections>
    </Form>
  );
}
