export function setAccessToken(token) {
  localStorage.setItem("access_token", token);
}

export function setRefreshToken(token) {
  localStorage.setItem("refresh_token", token);
}

export function getAccessToken() {
  return localStorage.getItem("access_token");
}

export function getRefreshToken() {
  return localStorage.getItem("refresh_token");
}

export function clearLocalStorage() {
  localStorage.removeItem("thermal_admin_id");
  localStorage.removeItem("thermal_admin_name");
  localStorage.removeItem("thermal_admin_email");
  localStorage.removeItem("thermal_admin_has_changed_password");
  localStorage.removeItem("thermal_admin_avatar_url");
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
}

export function isLoggedIn() {
  return localStorage.getItem("thermal_admin_id");
}
