import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Button from "lunar/Button";
import styles from "./SlideOver.module.scss";

export default function SlideOver({
  open,
  handleClose,
  handleSave,
  children,
  isSubmitting,
  formAction,
  showControls = false,
}) {
  return (
    <Dialog
      as="div"
      className={styles.dilog}
      onClose={handleClose}
      open={open}
      static
    >
      <div className={cn(styles.scrim, open ? styles.isOpen : "")} />
      <div
        className={cn(styles.contentOutterWrapper, open ? styles.isOpen : "")}
      >
        <Dialog.Panel className={styles.contentInnerWrapper}>
          <div className={styles.contentInner}>
            <div className={styles.contentScroll}>
              <div className={styles.contentHeader}>
                <div className={styles.contentCloseWrapper}>
                  <button
                    type="button"
                    className={styles.contentCloseButton}
                    onClick={handleClose}
                  >
                    <span className="sr-only">Close panel</span>
                    <XMarkIcon
                      className={styles.contentCloseIcon}
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>

              <div className={styles.contentBody}>{children}</div>
            </div>
            {showControls && (
              <div className={styles.controlsWrapper}>
                <Button type="button" onClick={handleClose} variant="secondary">
                  Cancel
                </Button>

                <Button
                  onClick={handleSave}
                  type="submit"
                  className={cn(
                    formAction === null && styles.buttonInactive,
                    styles.buttonSubmit
                  )}
                  isSubmitting={isSubmitting}
                  disabled={formAction === null}
                >
                  Save
                </Button>
              </div>
            )}
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

SlideOver.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  children: PropTypes.node,
  isSubmitting: PropTypes.bool,
  formAction: PropTypes.shape({
    name: PropTypes.string,
  }),
  showControls: PropTypes.bool,
};
