/* eslint-disable react/display-name */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Table from "components/common/Table";
import { longDateTime, money, formatShortDateRange } from "helpers/formatting";
import Badge from "lunar/Badge";
import Card from "components/common/Card";
import CardHeading from "components/common/CardHeading";

Reservations.propTypes = {
  reservations: PropTypes.array,
};

const defaultReservations = [];

export default function Reservations({ reservations = defaultReservations }) {
  const columns = React.useMemo(() => [
    {
      Header: "Status",
      accessor: "status",
      Cell: (tableProps) => {
        return <Badge status={tableProps.value} />;
      },
    },
    {
      Header: "Trip",
      accessor: "trip",
    },
    {
      Header: "Dates",
      accessor: "dates",
    },
    {
      Header: "Total",
      accessor: "total",
    },
    {
      Header: "Created",
      accessor: "created_at",
    },
    {
      Header: "Actions",
      accessor: "actionsId",
      Cell: (tableProps) => {
        return (
          <>
            <Link
              className="text-blue-600 hover:text-blue-900"
              to={`/bookings/${tableProps.value}`}
            >
              View
            </Link>
          </>
        );
      },
    },
  ]);

  return (
    <Card>
      <CardHeading heading="Reservations" />
      <Table
        data={reservations.map((booking) => ({
          status: booking.status,
          trip: booking.trip_name,
          dates: formatShortDateRange(booking.start_date, booking.end_date),
          total: money(booking.total),
          created_at: longDateTime(booking.created_at),
          actionsId: booking.transaction_number,
        }))}
        columns={columns}
        sortOptions={{ id: "created_at", desc: true }}
      />
    </Card>
  );
}
