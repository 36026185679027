import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import BreaksTable from "components/trips/BreaksTable";
import Form from "lunar/Form";
import FormTitle from "lunar/FormTitle";
import FormSections from "lunar/FormSections";
import FormSection from "lunar/FormSection";
import FormSectionHeader from "lunar/FormSectionHeader";
import FormSectionBody from "lunar/FormSectionBody";
import FormRow from "lunar/FormRow";
import InputTextArea from "lunar/InputTextArea";

SurfForm.propTypes = {
  tripID: PropTypes.string,
  surfBody: PropTypes.string,
  submitForm: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  breaks: PropTypes.array,
};

export default function SurfForm({
  tripID,
  surfBody = "",
  submitForm,
  breaks,
  handleSuccess,
  handleError,
}) {
  const {
    register,
    errors,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  useEffect(() => {
    if (surfBody) {
      reset({
        surf_body: surfBody,
      });
    }
  }, [reset, surfBody]);

  return (
    <Form
      handleSubmit={handleSubmit}
      submitForm={submitForm}
      isSubmitting={isSubmitting}
    >
      <FormTitle title="Surf conditions and breaks" />
      <FormSections>
        <FormSection>
          <FormSectionHeader
            title="Conditions"
            description="All text required to sum up the surf trip. May include information about conditions, seasons, trip-required, appropriate surf gear, etc. Sections within the body copy may be broken up with markdown headers."
          />
          <FormSectionBody>
            <FormRow className="grid grid-flow-row">
              <InputTextArea
                name="surf_body"
                type="text"
                label="Description"
                helper="Basic markdown formatting supported."
                errors={errors}
                register={register}
                rows={10}
              />
            </FormRow>
          </FormSectionBody>
        </FormSection>
        <FormSection>
          <FormSectionHeader
            title="Waves"
            description="Photos and descriptions of specific surf breaks found on this trip."
          />
          <FormSectionBody>
            <BreaksTable
              tripID={tripID}
              breaks={breaks}
              handleError={handleError}
              handleSuccess={handleSuccess}
            />
          </FormSectionBody>
        </FormSection>
      </FormSections>
    </Form>
  );
}
