/* eslint-disable react/display-name, react/prop-types */
import React, { useState, useEffect } from "react";
import cn from "classnames";
import { getCollections } from "lib/api";
import { useLoadingContext } from "context/loading-context";
import { Link } from "react-router-dom";
import { ErrorAlert } from "components/common/Alerts";
import PageListNav from "components/common/PageListNav";
import DataTable from "lunar/DataTable";
import DataTableSearch from "lunar/DataTableSearch";
import NewModal from "components/collections/NewModal";
import Meta from "lunar/Meta";

export default function Collections() {
  const { setLoading } = useLoadingContext();
  const [collections, setCollections] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    (async () => {
      populatePageCollections();
    })();
  }, []);

  async function populatePageCollections() {
    setLoading(true);
    const response = await getCollections();
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      setCollections(response.sort((a, b) => (a.name > b.name ? 1 : -1)));
    }
    setLoading(false);
  }

  const columns = React.useMemo(() => [
    {
      Header: "Status",
      accessor: "visibility",
      Cell: ({ row: { original } }) => {
        return (
          <div
            className={cn(
              "inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium",
              original.visibility === "Live"
                ? "bg-green-100 text-green-800 "
                : "bg-yellow-100 text-yellow-800 "
            )}
          >
            {original.visibility}
          </div>
        );
      },
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row: { original } }) => {
        return <span className="text-gray-900">{original.name}</span>;
      },
    },
    {
      Header: "Slug",
      accessor: "slug",
      Cell: ({ row: { original } }) => {
        return <span className="text-gray-500">{original.slug}</span>;
      },
    },
    {
      Header: "Num Trips",
      accessor: "num_trips",
      Cell: ({ row: { original } }) => {
        return <span className="text-gray-500">{original.numTrips}</span>;
      },
    },
    {
      Header: "",
      accessor: "actions",
      Cell: ({ row: { original } }) => {
        return (
          <>
            <Link
              className="text-blue-600 hover:text-blue-900 "
              to={`/collections/edit/${original.id}`}
            >
              Edit
            </Link>
            <a
              className="ml-3 text-gray-600 hover:text-gray-900"
              href={`${process.env.REACT_APP_WEBSITE_URL}/collections/${original.slug}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View
            </a>
          </>
        );
      },
    },
  ]);

  return (
    <>
      <Meta title={collections && `All ${collections.length} collections`} />
      <div className="min-h-screen overflow-y-scroll">
        <PageListNav
          dataTableSearch={
            <DataTableSearch
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              placeholder="Search for collections"
            />
          }
          actions={
            <div className="grid items-center grid-flow-col gap-6">
              <div className="ml-4">
                <NewModal populatePageCollections={populatePageCollections} />
              </div>
            </div>
          }
        />

        <ErrorAlert message={errorMessage} />
        {collections && (
          <DataTable
            // clickPath={"/trips/edit"}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            data={collections.map((collection) => ({
              id: collection.id,
              visibility: collection.is_visible ? "Live" : "In progress",
              name: collection.name,
              actions: collection.id,
              slug: collection.slug,
              numTrips: collection.num_trips,
            }))}
            columns={columns}
            sortOptions={{ id: "name", desc: false }}
          />
        )}
      </div>
    </>
  );
}
