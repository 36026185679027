import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { getOperator, updateOperator, sendOperatorTestEmail } from "lib/api";
import { useLoadingContext } from "context/loading-context";
import { useToasts } from "react-toast-notifications";
import CreateUpdateEmployeeModal from "./CreateEmployeeModal";
import { FormProvider } from "context/form-context";
import { humanDate, formatImageURL } from "helpers/formatting";
import InputText from "lunar/InputText";
import InputTextArea from "lunar/InputTextArea";
import InputImage from "lunar/InputImage";
import Table from "lunar/Table";
import TableHead from "lunar/TableHead";
import TableRow from "lunar/TableRow";
import TableCell from "lunar/TableCell";
import TableBody from "lunar/TableBody";
import TableActions from "lunar/TableActions";
import Button from "lunar/Button";
import Meta from "lunar/Meta";
import Form from "lunar/Form";
import FormTitle from "lunar/FormTitle";
import FormSections from "lunar/FormSections";
import FormSection from "lunar/FormSection";
import FormSectionHeader from "lunar/FormSectionHeader";
import FormSectionBody from "lunar/FormSectionBody";
import FormRow from "lunar/FormRow";
import InputTextAddon from "lunar/InputTextAddon";
import { EnvelopeIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

export default function Edit() {
  const [operator, setOperator] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [employeeToEdit, setEmployeeToEdit] = useState(null);
  const operatorID = useParams().id;
  const { addToast } = useToasts();
  const { setLoading } = useLoadingContext();

  const {
    register,
    errors,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  useEffect(() => {
    if (operator) {
      reset({
        name: operator.name,
        language: operator.language,
        established_date: operator.established_date,
        email: operator.email,
        whatsapp_number: operator.whatsapp_number,
        story_title: operator.story_title,
        story_body: operator.story_body,
        alert_header: operator.alert_header,
        alert_subheader: operator.alert_subheader,
      });
    }
  }, [reset, operator]);

  useEffect(() => {
    (async () => {
      populateOperatorState(operatorID);
    })();
  }, [operatorID]);

  async function populateOperatorState(operatorID) {
    setLoading(true);
    const response = await getOperator(operatorID);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      setOperator(response);
    }
    setLoading(false);
  }

  function handleSuccess(msg) {
    populateOperatorState(operatorID);
    addToast(msg, { appearance: "success" });
  }

  function handleError(msg) {
    addToast(msg, { appearance: "error" });
  }

  async function submitForm(data) {
    const response = await updateOperator(operatorID, data);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Guide successfully updated");
    }
  }

  async function handleSendTestEmail() {
    if (
      window.confirm(
        `Are you sure you want to send a test email to ${operator.email}?`
      )
    ) {
      const response = await sendOperatorTestEmail(operatorID);
      if (response.errorMsg) {
        handleError(response.errorMsg);
      } else {
        handleSuccess(`Test email sent to ${operator.email}`);
      }
    }
  }

  async function handleSaveEmployee(data) {
    const response = await updateOperator(operatorID, {
      operator_employees_attributes: [data],
    });
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Employee saved");
    }
    setShowModal(false);
  }

  async function handleDeleteEmployee(id) {
    const response = await updateOperator(operatorID, {
      operator_employees_attributes: [{ id, _destroy: id }],
    });
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Employee deleted");
    }
  }

  async function makePrimaryEmployee(id) {
    const response = await updateOperator(operatorID, {
      primary_employee_id: id,
    });
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Primary employee updated");
    }
  }

  function handleEditEmployee(employee) {
    setEmployeeToEdit(employee);
    setShowModal(true);
  }

  function handleAdd() {
    setEmployeeToEdit(null);
    setShowModal(true);
  }

  return (
    <>
      <Meta title={operator.name} />
      <FormProvider
        lastUpdated={operator.updated_at && humanDate(operator.updated_at)}
      >
        <Form
          handleSubmit={handleSubmit}
          submitForm={submitForm}
          isSubmitting={isSubmitting}
        >
          <FormTitle title={operator.name || ""} />
          <FormSections>
            <FormSection>
              <FormSectionBody className="flex flex-col gap-y-6">
                <FormRow>
                  <InputText
                    name="name"
                    type="text"
                    label="Guide name"
                    errors={errors}
                    register={register}
                    className="w-96"
                  />
                </FormRow>
              </FormSectionBody>
            </FormSection>
            <FormSection>
              <FormSectionHeader
                title="Related trips"
                description="The trips currently associated with this guide."
              />
              <FormSectionBody>
                {operator.trips?.length > 0 ? (
                  <div>
                    {operator.trips.map((trip) => (
                      <p key={trip.id}>
                        <Link
                          to={`/trips/edit/${trip.id}`}
                          className="text-sm text-blue-600 hover:underline"
                        >
                          {trip.name}
                        </Link>
                      </p>
                    ))}
                  </div>
                ) : (
                  <div className="text-sm italic text-gray-600">
                    No related trips
                  </div>
                )}
              </FormSectionBody>
            </FormSection>
            <FormSection>
              <FormSectionHeader
                title="Reservation contact information"
                description="The information we use to contact guides. Not visible to travelers."
              />
              <FormSectionBody>
                <FormRow className="grid items-center grid-flow-col gap-6">
                  <InputTextAddon
                    name="email"
                    type="email"
                    label="Email"
                    errors={errors}
                    register={register}
                    helper="Traveler messages and request will go here"
                    addonLeft={<EnvelopeIcon className="w-4 h-4" />}
                  />
                  <InputTextAddon
                    name="whatsapp_number"
                    type="text"
                    label="WhatsApp"
                    errors={errors}
                    register={register}
                    helper="Where we send notifications"
                    addonLeft={
                      <svg
                        className="w-4 h-4"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M17.077 2.928C15.191 1.041 12.683 0.001 10.011 0C4.505 0 0.0240001 4.479 0.0220001 9.985C0.0210001 11.745 0.481 13.463 1.355 14.977L0 20L5.233 18.763C6.692 19.559 8.334 19.978 10.006 19.979H10.01C15.515 19.979 19.996 15.499 19.999 9.994C20.001 7.325 18.963 4.816 17.077 2.928ZM14.898 13.554C14.69 14.137 13.671 14.699 13.213 14.74C12.755 14.782 12.326 14.947 10.218 14.116C7.681 13.116 6.079 10.515 5.955 10.349C5.83 10.182 4.936 8.996 4.936 7.768C4.936 6.54 5.581 5.936 5.81 5.687C6.039 5.437 6.309 5.375 6.476 5.375C6.642 5.375 6.809 5.375 6.954 5.381C7.132 5.388 7.329 5.397 7.516 5.812C7.738 6.306 8.223 7.54 8.285 7.665C8.347 7.79 8.389 7.936 8.306 8.102C8.223 8.268 8.181 8.372 8.057 8.518C7.932 8.664 7.795 8.843 7.683 8.955C7.558 9.079 7.428 9.215 7.573 9.464C7.719 9.714 8.219 10.531 8.961 11.192C9.915 12.042 10.718 12.305 10.968 12.431C11.218 12.556 11.363 12.535 11.509 12.368C11.655 12.202 12.133 11.64 12.299 11.39C12.465 11.14 12.632 11.182 12.861 11.265C13.09 11.348 14.317 11.952 14.566 12.077C14.816 12.202 14.982 12.264 15.044 12.368C15.106 12.471 15.106 12.971 14.898 13.554Z" />
                      </svg>
                    }
                  />
                  <div>
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={handleSendTestEmail}
                    >
                      Send test to guide
                    </Button>
                  </div>
                </FormRow>
              </FormSectionBody>
            </FormSection>
            <FormSection>
              <FormSectionHeader
                title="Customer success warning"
                description="Warning message displayed before sending anything to a guide"
              />
              <FormSectionBody>
                <FormRow className="grid gap-6">
                  <InputText
                    name="alert_header"
                    type="text"
                    label="Header"
                    errors={errors}
                    register={register}
                  />
                  <InputTextArea
                    name="alert_subheader"
                    label="Description"
                    errors={errors}
                    register={register}
                    helper="Supports basic markdown formatting"
                  />
                </FormRow>
              </FormSectionBody>
            </FormSection>
            <FormSection>
              <FormSectionHeader
                title="Settings"
                description="The settings and required information to form the guide area in related trip pages."
              />
              <FormSectionBody>
                <FormRow>
                  <InputImage
                    name="main_image"
                    label="Hero image"
                    register={register}
                    image={operator.main_image}
                    className="flex-grow mr-10"
                  />
                  <div className="flex flex-col gap-6">
                    <InputText
                      name="established_date"
                      type="date"
                      label="Established Date"
                      placeholder="yyyy-mm-dd"
                      errors={errors}
                      register={register}
                      helper="Only the year is currently visible"
                    />
                    <InputText
                      name="language"
                      type="text"
                      label="Languages"
                      errors={errors}
                      register={register}
                      helper="Visible to the traveler in trip page guide areas"
                    />
                  </div>
                </FormRow>
              </FormSectionBody>
            </FormSection>
            <FormSection>
              <FormSectionHeader
                title="Story"
                description="The guide's story used in trip guide areas to provide background and humanize our community."
              />
              <FormSectionBody>
                <FormRow className="grid grid-flow-row">
                  <InputText
                    name="story_title"
                    type="text"
                    label="Title"
                    errors={errors}
                    register={register}
                  />
                  <InputTextArea
                    name="story_body"
                    label="Description"
                    helper="Supports basic markdown formatting."
                    errors={errors}
                    register={register}
                    rows={10}
                  />
                </FormRow>
              </FormSectionBody>
            </FormSection>
            <FormSection>
              <FormSectionHeader
                title="Team"
                description="Guide staff. All are visible to travelers."
              />
              <FormSectionBody>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell head first>
                        Image
                      </TableCell>
                      <TableCell head>Full name</TableCell>
                      <TableCell head>Title</TableCell>
                      <TableCell head>Primary</TableCell>
                      <TableCell head last>
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {operator.operator_employees && (
                    <TableBody>
                      {operator.operator_employees.map((employee) => (
                        <TableRow key={employee.id}>
                          <TableCell>
                            {employee.main_image && (
                              <a
                                href={formatImageURL(employee.main_image.url)}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={formatImageURL(employee.main_image.url, {
                                    resize: { height: 50 },
                                  })}
                                  style={{ maxHeight: 50 }}
                                  className="object-cover w-8 h-8 rounded-full"
                                  alt={employee.main_name}
                                />
                              </a>
                            )}
                          </TableCell>
                          <TableCell>{employee.name}</TableCell>
                          <TableCell>{employee.title}</TableCell>
                          <TableCell>
                            {operator.primary_employee_id ===
                            parseInt(employee.id, 10)
                              ? "Yes"
                              : "No"}
                          </TableCell>
                          <TableCell>
                            <button
                              type="button"
                              onClick={() => handleEditEmployee(employee)}
                              className="text-blue-600 hover:text-blue-900"
                            >
                              Edit
                            </button>
                            <button
                              type="button"
                              onClick={() => handleDeleteEmployee(employee.id)}
                              className="ml-3 text-red-600 hover:text-red-900"
                            >
                              Delete
                            </button>
                            {operator.primary_employee_id !==
                              parseInt(employee.id, 10) && (
                              <button
                                className="ml-3 text-green-600 hover:text-orange-900"
                                onClick={() => makePrimaryEmployee(employee.id)}
                              >
                                Make Primary
                              </button>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
                <TableActions>
                  <Button onClick={handleAdd}>Add</Button>
                </TableActions>
                <CreateUpdateEmployeeModal
                  handleSaveEmployee={handleSaveEmployee}
                  setShowModal={setShowModal}
                  showModal={showModal}
                  employee={employeeToEdit}
                />
              </FormSectionBody>
            </FormSection>
          </FormSections>
        </Form>
      </FormProvider>
    </>
  );
}
