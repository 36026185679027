import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useForm } from "react-hook-form";
import { updateRegion } from "lib/api";
import { ErrorAlert } from "components/common/Alerts";
import { humanDate } from "helpers/formatting";
import PropTypes from "prop-types";
import FormTitle from "lunar/FormTitle";
import FormSections from "lunar/FormSections";
import FormSectionHeader from "lunar/FormSectionHeader";
import FormSectionBody from "lunar/FormSectionBody";
import FormSection from "lunar/FormSection";
import FormRow from "lunar/FormRow";
import FormFooter from "lunar/FormFooter";
import Button from "lunar/Button";
import Main from "components/common/Main";
import SelectSeason from "components/regions/SelectSeason";

FormRegions.propTypes = {
  region: PropTypes.object.isRequired,
  populateRegions: PropTypes.func.isRequired,
};

export default function FormRegions({ region, populateRegions }) {
  const [errorMessage, setErrorMessage] = useState("");

  const { addToast } = useToasts();
  const {
    register,
    errors,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  function staticBlock(name, value, label, description) {
    return (
      <div>
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
        <div className="mt-1">
          <input
            readOnly={true}
            type="text"
            name={name}
            id={name}
            className="block w-full bg-gray-200 border-gray-300 rounded-md shadow-sm cursor-not-allowed focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder={value}
          />
        </div>
        <p className="mt-2 text-sm text-gray-500">{description}</p>
      </div>
    );
  }

  function handleSuccess(msg) {
    populateRegions();
    addToast(msg, { appearance: "success" });
  }

  async function submitForm(data) {
    const response = await updateRegion(region.id, data);
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      handleSuccess("Region successfully updated");
    }
  }

  useEffect(() => {
    reset({
      seasons: region.seasons,
      water_temperatures: region.water_temperatures,
    });
  }, [reset, region]);

  return (
    <Main>
      <div className="max-w-4xl mx-auto">
        <form onSubmit={handleSubmit(submitForm)} noValidate>
          <ErrorAlert message={errorMessage} />
          <FormTitle title={region.name} />
          <FormSections>
            <FormSection>
              <FormSectionHeader
                title="Location"
                description="Where in the world is this region?"
              />
              <FormSectionBody>
                <FormRow>
                  <div className="grid grid-cols-3 gap-6">
                    {staticBlock(
                      "country",
                      region.country,
                      "Country",
                      "Not used for physical trip address"
                    )}
                    {staticBlock(
                      "worldarea",
                      region.world_area,
                      "World Area",
                      "Referenced in SEO pages"
                    )}
                    {staticBlock(
                      "usarea",
                      region.us_area,
                      "US Area",
                      "Only available within the USA"
                    )}
                  </div>
                </FormRow>
              </FormSectionBody>
            </FormSection>
            <FormSection>
              <FormSectionHeader
                title="Surf conditions"
                description="The seasonal conditions by calendar month."
              />
              <FormSectionBody>
                <FormRow>
                  <div className="overflow-hidden bg-white border border-gray-300 rounded-md shadow-sm">
                    <div className="grid grid-cols-12 bg-gray-200 divide-x divide-gray-300">
                      {months.map((month) => (
                        <div
                          key={month}
                          className="w-full py-3 text-xs font-bold text-center text-gray-500 uppercase border-b"
                        >
                          {month}
                        </div>
                      ))}
                    </div>
                    <div className="grid grid-cols-12 divide-x">
                      {months.map((_, index) => (
                        <div
                          key={`surfseason-${index}`}
                          className="w-full px-2 py-4 bg-white border-0"
                        >
                          <SelectSeason
                            name={`seasons[${index}]`}
                            options={["L", "M", "H"]}
                            errors={errors}
                            register={register}
                            initialValue={
                              region.seasons && region.seasons[index]
                            }
                          />
                        </div>
                      ))}
                    </div>
                    <div className="grid grid-cols-12 divide-x">
                      {months.map((_, index) => (
                        <div
                          key={`watertemp-${index}`}
                          className="py-3 border-t"
                        >
                          <input
                            name={`water_temperatures[${index}]`}
                            type="text"
                            {...register(`water_temperatures[${index}]`)}
                            className="block w-full text-sm font-medium text-center text-gray-900 placeholder-gray-500 bg-transparent border-transparent focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </FormRow>
              </FormSectionBody>
            </FormSection>
            <FormSection>
              <div className="grid grid-cols-2 gap-10">
                <div>
                  <FormSectionHeader
                    title="Breaks related"
                    description="Thermal surf breaks associated with this region."
                  />
                  <FormSectionBody>
                    <FormRow>
                      <p className="italic text-gray-500">TBD</p>
                    </FormRow>
                  </FormSectionBody>
                </div>
                <div>
                  <FormSectionHeader
                    title="Trips related"
                    description="Live and in production trips associated with this region."
                  />
                  <FormSectionBody>
                    <FormRow>
                      <div className="text-sm">
                        {region.trips.length > 0 ? (
                          region.trips.map((trip) => (
                            <div key={trip.slug}>
                              <Link
                                className="font-medium text-blue-500 hover:underline"
                                to={`/trips/edit/${trip.slug}`}
                              >
                                {trip.name}
                              </Link>
                            </div>
                          ))
                        ) : (
                          <p className="italic text-gray-500">
                            No related trips
                          </p>
                        )}
                      </div>
                    </FormRow>
                  </FormSectionBody>
                </div>
              </div>
            </FormSection>
          </FormSections>
          <FormFooter>
            <span className="pr-4 text-sm text-gray-500">
              Last updated {humanDate(region.updated_at)}
            </span>
            <Button type="submit" isSubmitting={isSubmitting}>
              Save
            </Button>
          </FormFooter>
        </form>
      </div>
    </Main>
  );
}
