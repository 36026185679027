import React, { useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import GalleryCardDragLayer from "lunar/gallery/GalleryCardDragLayer";
import GalleryCard from "lunar/gallery/GalleryCard";

const cardReducer = (state, action) => {
  switch (action.type) {
    case "POPULATE_CARDS":
      return {
        ...state,
        cards: action.cards,
      };
    case "CLEAR_SELECTION":
      return {
        ...state,
        selectedCards: initState.selectedCards,
        lastSelectedIndex: initState.lastSelectedIndex,
      };
    case "UPDATE_SELECTION":
      return {
        ...state,
        selectedCards: action.newSelectedCards,
        lastSelectedIndex: action.newLastSelectedIndex,
      };
    case "REARRANGE_CARDS":
      return { ...state, cards: action.newCards };
    case "SET_INSERTINDEX":
      return {
        ...state,
        dragIndex: action.dragIndex,
        hoverIndex: action.hoverIndex,
        insertIndex: action.insertIndex,
      };
    default:
      throw new Error();
  }
};

const initState = {
  cards: [],
  selectedCards: [],
  lastSelectedIndex: -1,
  dragIndex: -1,
  hoverIndex: -1,
  insertIndex: -1,
  isDragging: false,
};

GalleryContainer.propTypes = {
  images: PropTypes.array,
  updatePositions: PropTypes.func.isRequired,
  deleteMediaImage: PropTypes.func.isRequired,
  deleteImages: PropTypes.func.isRequired,
};

export default function GalleryContainer({
  images = [],
  updatePositions,
  deleteMediaImage,
  deleteImages,
}) {
  const [state, dispatch] = useReducer(cardReducer, initState);

  useEffect(() => {
    if (images) {
      dispatch({
        type: "POPULATE_CARDS",
        cards: images,
      });
    }
  }, [JSON.stringify(images)]);

  useEffect(() => {
    const onKeyDown = ({ key }) => {
      if (
        (key === "Backspace" || key === "Delete") &&
        state.selectedCards.length > 0
      ) {
        if (window.confirm("Are you sure you want to delete these images?")) {
          deleteImages(state.selectedCards);
        }
      }
    };
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [state]);

  const clearItemSelection = () => {
    dispatch({ type: "CLEAR_SELECTION" });
  };

  const handleItemSelection = (index, cmdKey, shiftKey) => {
    let newSelectedCards;
    const cards = state.cards;
    const card = index < 0 ? "" : cards[index];
    const newLastSelectedIndex = index;

    if (!cmdKey && !shiftKey) {
      newSelectedCards = [card];
    } else if (shiftKey) {
      if (state.lastSelectedIndex >= index) {
        newSelectedCards = [].concat.apply(
          state.selectedCards,
          cards.slice(index, state.lastSelectedIndex)
        );
      } else {
        newSelectedCards = [].concat.apply(
          state.selectedCards,
          cards.slice(state.lastSelectedIndex + 1, index + 1)
        );
      }
    } else if (cmdKey) {
      const foundIndex = state.selectedCards.findIndex((f) => f === card);
      // If found remove it to unselect it.
      if (foundIndex >= 0) {
        newSelectedCards = [
          ...state.selectedCards.slice(0, foundIndex),
          ...state.selectedCards.slice(foundIndex + 1),
        ];
      } else {
        newSelectedCards = [...state.selectedCards, card];
      }
    }
    const finalList = cards
      ? cards.filter((f) => newSelectedCards.find((a) => a === f))
      : [];
    dispatch({
      type: "UPDATE_SELECTION",
      newSelectedCards: finalList,
      newLastSelectedIndex: newLastSelectedIndex,
    });
  };

  const rearrangeCards = (dragItem) => {
    let cards = state.cards.slice();
    const draggedCards = dragItem.cards;

    let dividerIndex;
    if ((state.insertIndex >= 0) & (state.insertIndex < cards.length)) {
      dividerIndex = state.insertIndex;
    } else {
      // If missing insert index, put the dragged cards to the end of the queue
      dividerIndex = cards.length;
    }
    const upperHalfRemainingCards = cards
      .slice(0, dividerIndex)
      .filter((c) => !draggedCards.find((dc) => dc.id === c.id));
    const lowerHalfRemainingCards = cards
      .slice(dividerIndex)
      .filter((c) => !draggedCards.find((dc) => dc.id === c.id));
    const newCards = [
      ...upperHalfRemainingCards,
      ...draggedCards,
      ...lowerHalfRemainingCards,
    ];
    dispatch({ type: "REARRANGE_CARDS", newCards: newCards });
    updatePositions(newCards);
  };

  const setInsertIndex = (dragIndex, hoverIndex, newInsertIndex) => {
    if (
      state.dragIndex === dragIndex &&
      state.hoverIndex === hoverIndex &&
      state.insertIndex === newInsertIndex
    ) {
      return;
    }
    dispatch({
      type: "SET_INSERTINDEX",
      dragIndex: dragIndex,
      hoverIndex: hoverIndex,
      insertIndex: newInsertIndex,
    });
  };

  return (
    <div className="relative">
      <GalleryCardDragLayer />
      <div className="grid grid-cols-4 gap-6">
        {state.cards.map((card, i) => {
          const insertLineStart =
            state.hoverIndex === i && state.insertIndex === i;
          const insertLineEnd =
            state.hoverIndex === i && state.insertIndex === i + 1;
          return (
            <GalleryCard
              key={"card-" + card.id}
              id={card.id}
              index={i}
              imageObj={card}
              deleteMediaImage={deleteMediaImage}
              selectedCards={state.selectedCards}
              rearrangeCards={rearrangeCards}
              setInsertIndex={setInsertIndex}
              onSelectionChange={handleItemSelection}
              clearItemSelection={clearItemSelection}
              isSelected={state.selectedCards.includes(card)}
              insertLineStart={insertLineStart}
              insertLineEnd={insertLineEnd}
            />
          );
        })}
      </div>
    </div>
  );
}
