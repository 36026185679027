import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { getEvent, updateEvent } from "lib/api";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useLoadingContext } from "context/loading-context";
import { useToasts } from "react-toast-notifications";
import { FormProvider } from "context/form-context";
import { humanDate } from "helpers/formatting";
import EventVariantsTable from "./EventVariantsTable";
import HighlightsTable from "./HighlightsTable";
import Meta from "lunar/Meta";
import Form from "lunar/Form";
import FormTitle from "lunar/FormTitle";
import FormSections from "lunar/FormSections";
import FormSection from "lunar/FormSection";
import FormSectionHeader from "lunar/FormSectionHeader";
import FormSectionBody from "lunar/FormSectionBody";
import FormRow from "lunar/FormRow";
import InputText from "lunar/InputText";
import InputImage from "lunar/InputImage";
import InputTextArea from "lunar/InputTextArea";
import ButtonText from "lunar/ButtonText";
import SelectField from "lunar/SelectField";
import InputVideo from "lunar/InputVideo";

export default function Edit() {
  const [event, setEvent] = useState({});
  const [faqs, setFaqs] = useState({});
  const [attributes, setAttributes] = useState([]);
  const eventID = useParams().id;
  const { addToast } = useToasts();
  const { setLoading } = useLoadingContext();

  const {
    register,
    errors,
    reset,
    handleSubmit,
    setValue,
    getValues,
    // control,
    resetField,
    formState: { isSubmitting },
  } = useForm();

  useEffect(() => {
    if (event) {
      reset({
        name: event.name,
        slug: event.slug,
        subtitle: event.subtitle,
        description: event.description,
        theme_color: event.theme_color,
        skill_level: event.skill_level,
        max_group_size: event.max_group_size,
        testimonial: event.testimonial,
        testimonial_byline: event.testimonial_byline,
        destination_name: event.destination_name,
        accommodations_title: event.accommodations_title,
        accommodations_subtitle: event.accommodations_subtitle,
        trip_highlights_subtitle: event.trip_highlights_subtitle,
        sub_hero_title: event.sub_hero_title,
        sub_hero_subtitle: event.sub_hero_subtitle,
        guide_title: event.guide_title,
        guide_subtitle: event.guide_subtitle,
        surf_title: event.surf_title,
        surf_subtitle: event.surf_subtitle,
        event_type: event.event_type,
        adventure_name: event.adventure?.name,
        guide_name: event.guide?.name,
        accommodations_name:
          event.accommodations && event.accommodations[0].name,
        faqs: Object.entries(event.faqs || {}),
        getting_there: event.getting_there,
        summary_attributes: event.summary_attributes || [],
      });
    }
  }, [reset, event]);

  useEffect(() => {
    (async () => {
      populateEventState(eventID);
    })();
  }, [eventID]);

  useEffect(() => {
    if (event.faqs !== undefined) {
      setFaqs(event.faqs);
    }
  }, [reset, JSON.stringify(event.faqs)]);

  useEffect(() => {
    if (event.summary_attributes !== undefined) {
      setAttributes(event.summary_attributes);
    }
  }, [reset, JSON.stringify(event.summary_attributes)]);

  async function populateEventState(eventID) {
    setLoading(true);
    const response = await getEvent(eventID);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      setEvent(response);
    }
    setLoading(false);
  }

  function handleSuccess(msg) {
    populateEventState(eventID);
    addToast(msg, { appearance: "success" });
  }

  function handleError(msg) {
    addToast(msg, { appearance: "error" });
  }

  function addFaq() {
    setFaqs({ ...faqs, [Date.now()]: "" });
  }

  function removeFaq(index) {
    const list = getValues()["faqs"];
    // create a copy
    const newList = [...list];
    // remove by index
    newList.splice(index, 1);
    // update values
    setFaqs(newList);
    for (let i = 0; i < newList.length; i++) {
      setValue(`faqs[${i}][0]`, newList[i][0]);
      setValue(`faqs[${i}][1]`, newList[i][1]);
    }
  }

  function addAttribute() {
    // setFaqs({ ...faqs, [Date.now()]: "" });
    setAttributes([...attributes, { icon: "", header: "", subheader: "" }]);
  }

  function removeAttribute(index) {
    const attrs = getValues()["summary_attributes"];
    const newAttrs = [...attrs];
    newAttrs.splice(index, 1);
    setAttributes(newAttrs);
    setValue("summary_attributes", newAttrs);
  }

  function preProcessForm(data) {
    // transform overview 2D arrays to key/value pairs for JSON
    const faqsObj = {};
    if (data.faqs !== undefined) {
      for (let entry of data.faqs) {
        faqsObj[entry[0]] = entry[1];
      }
    }
    data.faqs = faqsObj;

    return submitForm(data);
  }

  async function submitForm(data) {
    const response = await updateEvent(eventID, data);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Event successfully updated");
      // remove images/videos from input type file so no re-uploaded on next submission
      for (const [key, val] of Object.entries(data)) {
        if (val instanceof FileList) resetField(key);
      }
    }
  }

  return (
    <>
      <Meta title={event.name} />
      <FormProvider
        lastUpdated={event.updated_at && humanDate(event.updated_at)}
      >
        <DndProvider backend={HTML5Backend}>
          <Form
            handleSubmit={handleSubmit}
            submitForm={preProcessForm}
            isSubmitting={isSubmitting}
          >
            <FormTitle title={event.name || ""} />
            <FormSections>
              <FormSection>
                <FormSectionHeader
                  title="Settings"
                  description="The primary settings forming event presentations."
                />
                <FormSectionBody className="flex flex-col gap-y-6">
                  <FormRow>
                    <InputText
                      name="name"
                      type="text"
                      label="Event name"
                      errors={errors}
                      register={register}
                      className="flex-grow"
                      disabled={true}
                    />
                    <InputText
                      name="slug"
                      type="text"
                      label="URL slug"
                      errors={errors}
                      register={register}
                      className="flex-grow"
                      disabled={true}
                    />
                    <InputText
                      name="destination_name"
                      type="text"
                      label="Event destination name"
                      errors={errors}
                      register={register}
                      className="flex-grow"
                      disabled={true}
                    />
                  </FormRow>
                  <FormRow>
                    {event.event_type === "group_trip" && (
                      <>
                        <InputText
                          name="adventure_name"
                          type="text"
                          label="Adventure"
                          errors={errors}
                          register={register}
                          className="flex-grow"
                          disabled={true}
                        />
                        <InputText
                          name="guide_name"
                          type="text"
                          label="Guide"
                          errors={errors}
                          register={register}
                          className="flex-grow"
                          disabled={true}
                        />
                        <InputText
                          name="accommodations_name"
                          type="text"
                          label="Accommodation"
                          errors={errors}
                          register={register}
                          className="flex-grow"
                          disabled={true}
                        />
                      </>
                    )}
                    <InputText
                      name="event_type"
                      type="text"
                      label="Event type"
                      errors={errors}
                      register={register}
                      className="flex-grow"
                      disabled={true}
                    />
                  </FormRow>
                  <FormRow>
                    <InputText
                      name="skill_level"
                      type="text"
                      label="Skill level"
                      errors={errors}
                      register={register}
                      className="flex-grow"
                    />
                    <SelectField
                      name="theme_color"
                      label="Theme color"
                      options={["navy", "purple", "brown", "gold", "forest"]}
                      errors={errors}
                      register={register}
                      className="flex-grow"
                    />
                    <InputText
                      name="max_group_size"
                      type="text"
                      label="Max group size"
                      errors={errors}
                      register={register}
                      className="flex-grow"
                    />
                  </FormRow>
                </FormSectionBody>
              </FormSection>
              <FormSection>
                <FormSectionHeader
                  title="Description"
                  description="Trip description"
                />
                <FormSectionBody className="grid gap-y-6">
                  <InputTextArea
                    name="description"
                    type="text"
                    label="Trip description"
                    helper="Limit 500 characters."
                    errors={errors}
                    register={register}
                    maxRows={3}
                    maxLength={500}
                  />
                </FormSectionBody>
              </FormSection>
              <FormSection>
                <FormSectionHeader
                  title="Hero"
                  description="The first things a traveler sees including listing hero, event cards and open graph images."
                />
                <FormSectionBody className="grid gap-y-6">
                  <FormRow>
                    <InputTextArea
                      name="subtitle"
                      type="text"
                      label="Subtitle"
                      helper="Limit 255 characters."
                      errors={errors}
                      register={register}
                      maxRows={2}
                      maxLength={255}
                      className="w-full"
                    />
                  </FormRow>
                  <div className="p-8 border rounded-lg bg-gray-50">
                    <p className="pb-4 mb-6 text-sm font-medium text-gray-600 border-b">
                      Images for listing hero and event cards
                    </p>
                    <FormRow>
                      <InputImage
                        name="hero_land"
                        label="Land cover"
                        register={register}
                        image={event.hero_land}
                        previewAspectRatio="3/2"
                      />
                      <InputImage
                        name="hero_card"
                        label="Port cover"
                        register={register}
                        image={event.hero_card}
                        previewAspectRatio="5/6"
                      />
                    </FormRow>
                  </div>
                  {event.event_type === "training_trip" && (
                    <div className="p-8 border rounded-lg bg-gray-50">
                      <p className="pb-4 mb-6 text-sm font-medium text-gray-600 border-b">
                        Video for large screens
                      </p>
                      <FormRow>
                        <InputImage
                          name="hero_montage_first_frame_land"
                          label="Land first frame"
                          register={register}
                          image={event.hero_montage_first_frame_land}
                          previewAspectRatio="16/9"
                        />
                        <div className="flex flex-col gap-y-6">
                          <InputVideo
                            name="hero_montage_land_mp4"
                            label="Land mp4"
                            register={register}
                            video={event.hero_montage_land_mp4}
                          />
                          <InputVideo
                            name="hero_montage_land_webm"
                            label="Land webm"
                            register={register}
                            video={event.hero_montage_land_webm}
                          />
                        </div>
                      </FormRow>
                      <FormRow>
                        <InputImage
                          name="hero_montage_image"
                          label="Montage image (if no video)"
                          register={register}
                          image={event.hero_montage_image}
                          previewAspectRatio="16/9"
                        />
                      </FormRow>
                    </div>
                  )}
                </FormSectionBody>
              </FormSection>
              {event.event_type === "training_trip" && (
                <>
                  <FormSection>
                    <FormSectionHeader
                      title="Subhero"
                      description="Title and subtitle underneath the hero"
                    />
                    <FormSectionBody className="grid gap-y-6">
                      <InputText
                        name="sub_hero_title"
                        label="Title"
                        type="text"
                        errors={errors}
                        register={register}
                        className="flex-grow mt-4"
                      />
                      <InputTextArea
                        name="sub_hero_subtitle"
                        type="text"
                        label="Subhero description"
                        helper="Limit 500 characters."
                        errors={errors}
                        register={register}
                        maxRows={3}
                        maxLength={500}
                      />
                    </FormSectionBody>
                  </FormSection>
                  <FormSection>
                    <FormSectionHeader
                      title="Guide"
                      description="Guide title and subtitle"
                    />
                    <FormSectionBody className="grid gap-y-6">
                      <InputText
                        name="guide_title"
                        label="Title"
                        type="text"
                        errors={errors}
                        register={register}
                        className="flex-grow mt-4"
                      />
                      <InputTextArea
                        name="guide_subtitle"
                        type="text"
                        label="Guide description"
                        helper="Limit 500 characters."
                        errors={errors}
                        register={register}
                        maxRows={3}
                        maxLength={500}
                      />
                      <InputImage
                        name="guide_image"
                        label="Guide image"
                        register={register}
                        image={event.guide_image}
                        previewAspectRatio="5/6"
                      />
                    </FormSectionBody>
                  </FormSection>
                  <FormSection>
                    <FormSectionHeader
                      title="Surf"
                      description="Surf title and subtitle"
                    />
                    <FormSectionBody className="grid gap-y-6">
                      <InputText
                        name="surf_title"
                        label="Title"
                        type="text"
                        errors={errors}
                        register={register}
                        className="flex-grow mt-4"
                      />
                      <InputTextArea
                        name="surf_subtitle"
                        type="text"
                        label="Surf description"
                        helper="Limit 500 characters."
                        errors={errors}
                        register={register}
                        maxRows={3}
                        maxLength={500}
                      />
                      <InputImage
                        name="surf_image"
                        label="Surf image"
                        register={register}
                        image={event.surf_image}
                        previewAspectRatio="5/6"
                      />
                    </FormSectionBody>
                  </FormSection>
                </>
              )}
              {event.event_type === "group_trip" && (
                <>
                  <FormSection>
                    <FormSectionHeader
                      title="Experiences"
                      description="Specific special things including surfing that travelers might do on this adventure."
                    />
                    <FormSectionBody className="grid gap-y-6">
                      <InputText
                        name="trip_highlights_subtitle"
                        type="text"
                        label="Subtitle"
                        errors={errors}
                        register={register}
                        className="flex-grow mt-4"
                      />
                      <HighlightsTable
                        eventID={eventID}
                        highlights={event.event_highlights}
                        handleError={handleError}
                        handleSuccess={handleSuccess}
                      />
                    </FormSectionBody>
                  </FormSection>
                  <FormSection>
                    <FormSectionHeader
                      title="Testimonial"
                      description="The primary review and/or testimonial from a traveler"
                    />
                    <FormSectionBody className="grid gap-y-6">
                      <InputTextArea
                        name="testimonial"
                        type="text"
                        helper="Limit 300 characters."
                        errors={errors}
                        register={register}
                        maxRows={2}
                        maxLength={300}
                      />
                      <InputText
                        name="testimonial_byline"
                        type="text"
                        label="Byline"
                        helper="e.g. Tess, United States"
                        errors={errors}
                        register={register}
                        className="flex-grow mt-4"
                      />
                    </FormSectionBody>
                  </FormSection>
                </>
              )}
              <FormSection>
                <FormSectionHeader
                  title="Departures"
                  description="The different dates for this event."
                />
                <FormSectionBody className="grid gap-y-6">
                  <EventVariantsTable
                    eventID={eventID}
                    variants={event.event_variants}
                    handleError={handleError}
                    handleSuccess={handleSuccess}
                  />
                </FormSectionBody>
              </FormSection>
              <FormSection>
                <FormSectionHeader
                  title="Accommodations"
                  description="Details on the recommended accommodation with a focus on beautiful unique places to stay. "
                />
                <FormSectionBody className="grid gap-y-6">
                  <InputText
                    name="accommodations_title"
                    label="Subtitle"
                    type="text"
                    errors={errors}
                    register={register}
                    className="flex-grow mt-4"
                  />
                  <InputTextArea
                    name="accommodations_subtitle"
                    type="text"
                    label="Accommodations description"
                    helper="Limit 600 characters."
                    errors={errors}
                    register={register}
                    maxRows={3}
                    maxLength={600}
                  />
                  <div className="p-8 border rounded-lg bg-gray-50">
                    <p className="pb-4 mb-6 text-sm font-medium text-gray-600 border-b">
                      Accommodations images
                    </p>
                    <FormRow>
                      <InputImage
                        name="accommodations_image_1"
                        label="Image 1"
                        register={register}
                        image={event.accommodations_image_1}
                        previewAspectRatio="3/2"
                      />
                    </FormRow>
                    <FormRow>
                      <InputImage
                        name="accommodations_image_2"
                        label="Image 2"
                        register={register}
                        image={event.accommodations_image_2}
                        previewAspectRatio="3/2"
                      />
                    </FormRow>
                    <FormRow>
                      <InputImage
                        name="accommodations_image_3"
                        label="Image 3"
                        register={register}
                        image={event.accommodations_image_3}
                        previewAspectRatio="3/2"
                      />
                    </FormRow>
                  </div>
                </FormSectionBody>
              </FormSection>
              <FormSection>
                <FormSectionHeader
                  title="FAQs"
                  description="The most common questions we get about adventures and this one in particular."
                />
                <FormSectionBody className="grid gap-y-6">
                  <InputImage
                    name="faq_image"
                    label="Faq background image"
                    register={register}
                    image={event.faq_image}
                    className="flex-grow mr-10"
                    previewAspectRatio="1512/569"
                  />
                  <div className="flex flex-col gap-x-10 gap-y-6">
                    {Object.keys(faqs).map((key, index) => (
                      <div key={`faqs-${index}`}>
                        <InputText
                          name={`faqs[${index}][0]`}
                          label="Question"
                          type="text"
                          errors={errors}
                          register={register}
                          placeholder="How well do I need to surf?"
                        />
                        <InputTextArea
                          name={`faqs[${index}][1]`}
                          errors={errors}
                          register={register}
                          rows={6}
                          className="mt-4"
                          placeholder="Write the answer..."
                        />
                        <div className="mt-1 text-sm">
                          <ButtonText
                            onClick={() => removeFaq(index)}
                            className="text-red-600 hover:text-red-900"
                          >
                            Delete
                          </ButtonText>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="pt-10 mt-10 border-t">
                    <button
                      type="button"
                      onClick={addFaq}
                      className="px-3 py-2 text-sm font-medium leading-4 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Add an FAQ
                    </button>
                  </div>
                </FormSectionBody>
              </FormSection>
              <FormSection>
                <FormSectionHeader
                  title="Getting there"
                  description="How do travelers get there? Can include which airport to take, airport transfer info, drive time from airport to accommodations, etc."
                />
                <FormSectionBody className="grid gap-y-6">
                  <InputTextArea
                    name="getting_there"
                    type="text"
                    helper="Limit 500 characters."
                    errors={errors}
                    register={register}
                    maxRows={3}
                    maxLength={500}
                  />
                </FormSectionBody>
              </FormSection>
              <FormSection>
                <FormSectionHeader
                  title="Attributes"
                  description="Attributes of the event"
                />
                <FormSectionBody className="grid gap-y-6">
                  <div className="flex flex-col gap-x-10 gap-y-6">
                    {attributes.map((key, index) => (
                      <div key={`sa-${index}`}>
                        <InputText
                          name={`summary_attributes[${index}][icon]`}
                          label="Icon"
                          type="text"
                          errors={errors}
                          register={register}
                          placeholder="Streamline icon name"
                        />
                        <InputText
                          name={`summary_attributes[${index}][header]`}
                          label="Header"
                          type="text"
                          errors={errors}
                          register={register}
                          placeholder="header"
                        />
                        <InputTextArea
                          name={`summary_attributes[${index}][subheader]`}
                          errors={errors}
                          register={register}
                          rows={6}
                          className="mt-4"
                          placeholder="Write the subheader..."
                        />
                        <div className="mt-1 text-sm">
                          <ButtonText
                            onClick={() => removeAttribute(index)}
                            className="text-red-600 hover:text-red-900"
                          >
                            Delete
                          </ButtonText>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="pt-10 mt-10 border-t">
                    <button
                      type="button"
                      onClick={addAttribute}
                      className="px-3 py-2 text-sm font-medium leading-4 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Add an attribute
                    </button>
                  </div>
                </FormSectionBody>
              </FormSection>
            </FormSections>
          </Form>
        </DndProvider>
      </FormProvider>
    </>
  );
}
