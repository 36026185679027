import React, { useState, useEffect } from "react";
import {
  getProduct,
  updateProduct,
  deleteProduct,
  deleteProductImages,
  updateProductImagePositions,
  changeVariantAvailability,
} from "lib/api";
import { useLoadingContext } from "context/loading-context";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { FormProvider } from "context/form-context";
import { humanDate } from "helpers/formatting";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useNavigate, useParams } from "react-router-dom";
import FixedVariantsTable from "components/products/FixedVariantsTable";
import FlexibleVariantsTable from "components/products/FlexibleVariantsTable";
import FlexibleAvailabilityModal from "components/products/FlexibleAvailabilityModal";
import FormTitle from "lunar/FormTitle";
import InputText from "lunar/InputText";
import SelectField from "lunar/SelectField";
import InputTextArea from "lunar/InputTextArea";
import FormSection from "lunar/FormSection";
import FormSectionHeader from "lunar/FormSectionHeader";
import FormSectionBody from "lunar/FormSectionBody";
import FormRow from "lunar/FormRow";
import Form from "lunar/Form";
import Gallery from "lunar/gallery/Gallery";
import FormSections from "lunar/FormSections";
import Meta from "lunar/Meta";
import ButtonText from "lunar/ButtonText";

export default function EditProduct() {
  const [product, setProduct] = useState({});
  const [showAvailabilityModal, setShowAvailabilityModal] = useState(false);
  const navigate = useNavigate();
  const productID = useParams().id;
  const { addToast } = useToasts();
  const { setLoading } = useLoadingContext();

  const schema = Yup.object().shape({
    name: Yup.string().required("Required"),
  });

  const {
    control,
    register,
    handleSubmit,
    errors,
    reset,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (product) {
      reset({
        name: product.name,
        product_type: product.product_type,
        pricing_type: product.pricing_type,
        date_selection_type: product.date_selection_type,
        description: product.description,
        price: product.price,
        is_visible: product.is_visible,
        total_quantity: product.total_quantity,
        min_guests: product.min_guests,
        max_guests: product.max_guests,
      });
    }
  }, [reset, product]);

  useEffect(() => {
    (async () => {
      populateProductState(productID);
    })();
  }, [productID]);

  async function populateProductState(productID) {
    setLoading(true);
    const response = await getProduct(productID);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      setProduct(response);
    }
    setLoading(false);
  }

  function handleError(msg) {
    addToast(msg, { appearance: "error" });
  }

  function handleSuccess(msg) {
    populateProductState(productID);
    addToast(msg, { appearance: "success" });
  }

  async function submitForm(data) {
    const response = await updateProduct(productID, data);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Product successfully updated");
    }
  }

  async function submitFormAndClearImages(data) {
    await submitForm(data);
    setValue("product_images_attributes", []);
  }

  async function handleDeleteProduct(id) {
    const response = await deleteProduct(id);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      navigate(`/trips/edit/${product.trip_id}`);
    }
  }

  async function handleSaveAvailability(data) {
    const response = await changeVariantAvailability(data);
    if (response.errorMsg) {
      addToast(response.errorMsg, { appearance: "error" });
    } else {
      handleSuccess("Availability updated");
    }
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Meta title={product.name} />
      <FormProvider
        lastUpdated={product.updated_at && humanDate(product.updated_at)}
      >
        <Form
          handleSubmit={handleSubmit}
          submitForm={submitFormAndClearImages}
          isSubmitting={isSubmitting}
        >
          <FormTitle title={product.name || ""} />
          <FormSections>
            <FormSection>
              <FormSectionHeader
                title="Settings"
                description="Based on guide participation agreements. Every trip associated with this guide will use this information."
              />
              <FormSectionBody>
                <FormRow className="grid grid-cols-3">
                  <InputText
                    name="name"
                    type="text"
                    label="Name"
                    errors={errors}
                    register={register}
                  />
                  {product.product_type === "main" ? (
                    <>
                      {product.pricing_type === "per_person" ? (
                        <InputText
                          name="pricing_type"
                          type="text"
                          label="Pricing type"
                          disabled={true}
                          errors={errors}
                          register={register}
                        />
                      ) : (
                        <SelectField
                          name="pricing_type"
                          label="Pricing type"
                          options={[
                            "per_night",
                            "per_person_per_night",
                            "per_day",
                            "per_person_per_day",
                          ]}
                          errors={errors}
                          register={register}
                        />
                      )}
                    </>
                  ) : (
                    <SelectField
                      name="pricing_type"
                      label="Pricing Type"
                      options={["per_person", "per_day"]}
                      errors={errors}
                      register={register}
                    />
                  )}
                  <SelectField
                    name="is_visible"
                    label="Visible?"
                    options={[
                      { id: "false", name: "No" },
                      { id: "true", name: "Yes" },
                    ]}
                    errors={errors}
                    register={register}
                  />
                </FormRow>
                <FormRow className="grid grid-flow-col mt-6">
                  <InputText
                    name="product_type"
                    type="text"
                    label="Product type"
                    disabled={true}
                    errors={errors}
                    register={register}
                  />
                  {product.product_type === "main" && (
                    <InputText
                      name="date_selection_type"
                      type="text"
                      label="Availability type"
                      disabled={true}
                      errors={errors}
                      register={register}
                    />
                  )}

                  <InputText
                    name="price"
                    type="text"
                    label={
                      product.product_type === "main" ? "Lowest price" : "Price"
                    }
                    disabled={product.product_type === "main" ? true : false}
                    errors={errors}
                    register={register}
                  />
                </FormRow>
              </FormSectionBody>
            </FormSection>
            {product.product_type === "main" && (
              <FormSection>
                <FormSectionHeader
                  title="Limits"
                  description={
                    product.date_selection_type === "fixed"
                      ? "Set the minimum and maximum travelers allowed as well as the total quantity (likely the same as max travelers)."
                      : "Set the minimum and maximum travelers allowed."
                  }
                />
                <FormSectionBody>
                  <FormRow>
                    <InputText
                      name="min_guests"
                      type="text"
                      label="Min. travelers"
                      errors={errors}
                      register={register}
                    />
                    <InputText
                      name="max_guests"
                      type="text"
                      label="Max. travelers"
                      errors={errors}
                      register={register}
                    />
                    {product.date_selection_type === "fixed" && (
                      <InputText
                        name="total_quantity"
                        type="text"
                        label="Total quantity"
                        errors={errors}
                        register={register}
                      />
                    )}
                  </FormRow>
                </FormSectionBody>
              </FormSection>
            )}
            {product.product_type === "main" && (
              <FormSection>
                <FormSectionHeader
                  title="Pricing and availability"
                  description="It's important we always strive for accurate pricing. When faced with a decision, choose the higher price."
                />
                <FormSectionBody>
                  {product.date_selection_type === "fixed" && (
                    <FixedVariantsTable
                      handleSuccess={handleSuccess}
                      productID={productID}
                      variants={product.variants}
                    />
                  )}
                  {product.date_selection_type === "flexible" && (
                    <>
                      <FlexibleVariantsTable
                        handleSuccess={handleSuccess}
                        productID={productID}
                        pricingType={product.pricing_type}
                        variants={product.variants}
                        handleShowAvailabilityModal={() =>
                          setShowAvailabilityModal(true)
                        }
                      />
                    </>
                  )}
                </FormSectionBody>
              </FormSection>
            )}
            <FormSection>
              <FormSectionHeader
                title="Description"
                description="All the relevant information for this product including any helpful callouts for travelers."
              />
              <FormSectionBody>
                <FormRow>
                  <InputTextArea
                    name="description"
                    type="text"
                    errors={errors}
                    register={register}
                    rows={10}
                    className="w-full"
                    helper="Basic markdown formatting supported"
                  />
                </FormRow>
              </FormSectionBody>
            </FormSection>
            <FormSection>
              <FormSectionHeader
                title="Photos"
                description="The first image will be used in a number of places such as rooms, routes and availability."
              />
              <FormSectionBody>
                <FormRow>
                  <Gallery
                    parentID={productID}
                    images={product.product_images}
                    handleError={handleError}
                    handleSuccess={handleSuccess}
                    updateFunc={updateProduct}
                    deleteFunc={deleteProductImages}
                    updatePositionsFunc={updateProductImagePositions}
                    imagesAttributeName="product_images_attributes"
                    control={control}
                    setValue={setValue}
                  />
                </FormRow>
              </FormSectionBody>
            </FormSection>

            {!product.has_associated_line_items && (
              <FormSection>
                <p className="text-sm text-gray-500">
                  When a product has not yet been associated with a reservation
                  it can be deleted.
                </p>
                <ButtonText
                  className="mt-2 text-sm text-red-600 hover:underline"
                  onClick={() => handleDeleteProduct(productID)}
                >
                  Delete this product
                </ButtonText>
              </FormSection>
            )}
          </FormSections>
        </Form>
        {product.product_type === "main" &&
          product.date_selection_type === "flexible" && (
            <FlexibleAvailabilityModal
              productID={productID}
              handleSave={handleSaveAvailability}
              setShowModal={setShowAvailabilityModal}
              showModal={showAvailabilityModal}
              variants={product.variants}
            />
          )}
      </FormProvider>
    </DndProvider>
  );
}
