import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "lunar/Button";
import EditModal from "./EditModal";
import { updateEventBooking } from "lib/api";
import { formatShortDateRange } from "helpers/formatting";
import { formatTravelersString } from "helpers/naming";

Summary.propTypes = {
  handleError: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  booking: PropTypes.object.isRequired,
};

const dtClass = "text-sm font-medium text-gray-500";
const ddClass = "mt-1 text-sm font-medium text-gray-900";

export default function Summary({ booking, handleError, handleSuccess }) {
  const [showEditModal, setShowEditModal] = useState(false);

  async function handleEdit(data) {
    const response = await updateEventBooking(booking.transaction_number, data);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Reservation edited");
    }
    setShowEditModal(false);
  }
  return (
    <>
      <dl className="grid grid-flow-row gap-6">
        <div>
          <dt className={dtClass}>Customer</dt>
          <dd className={ddClass}>
            {booking.first_name} {booking.last_name}
          </dd>
        </div>
        <div>
          <dt className={dtClass}>Email</dt>
          <dd className={ddClass}>{booking.email}</dd>
        </div>
        <div>
          <dt className={dtClass}>Residency</dt>
          <dd className={ddClass}>
            <a
              href={`https://www.thermal.travel/residencies/${booking.event_variant.event.slug}`}
              rel="noreferrer"
              className="text-blue-600 hover:text-blue-900"
              target="_blank"
            >
              {booking.event_variant.event.name}
            </a>
          </dd>
        </div>
        <div>
          <dt className={dtClass}>Dates</dt>
          <dd className={ddClass}>
            {formatShortDateRange(
              booking.event_variant.start_date,
              booking.event_variant.end_date
            )}
          </dd>
        </div>
        <div>
          <dt className={dtClass}>Num Travelers</dt>
          <dd className={ddClass}>
            {booking.num_adults
              ? formatTravelersString({
                  adults: booking.num_adults,
                  children: booking.num_children,
                })
              : "TBD"}
          </dd>
        </div>

        <div>
          <Button variant="secondary" onClick={() => setShowEditModal(true)}>
            Edit
          </Button>
          <EditModal
            handleEdit={handleEdit}
            setShowEditModal={setShowEditModal}
            showEditModal={showEditModal}
            handleError={handleError}
            booking={booking}
          />
        </div>
      </dl>
    </>
  );
}
