import React from "react";
import PropTypes from "prop-types";

Main.propTypes = {
  children: PropTypes.node,
};

export default function Main({ children = null }) {
  return (
    <div className="w-full mt-10 pb-10 max-w-7xl mx-auto sm:px-6 lg:px-8">
      {children}
    </div>
  );
}
