import React from "react";
import PropTypes from "prop-types";
import Form from "lunar/Form";
import FormTitle from "lunar/FormTitle";
import FormSections from "lunar/FormSections";
import FormSection from "lunar/FormSection";
import FormRow from "lunar/FormRow";
import FormSectionHeader from "lunar/FormSectionHeader";
import FormSectionBody from "lunar/FormSectionBody";
import ProductsTable from "components/trips/ProductsTable";

PricingAvailabilityForm.propTypes = {
  products: PropTypes.array,
  tripID: PropTypes.string,
  tripTypeDescription: PropTypes.string,
};

export default function PricingAvailabilityForm({
  products = [],
  tripID = "",
  tripTypeDescription = "",
}) {
  function mainProductsCardHeading(type) {
    switch (type) {
      case "Lodge":
        return "Rooms";
      case "Boat":
        return "Routes";
      case "Adventure":
        return "Dates";
      default:
        return "Dates";
    }
  }

  return (
    <Form as="div">
      <FormTitle title="Pricing and availability" />
      <FormSections>
        <FormSection>
          <FormSectionHeader
            title={mainProductsCardHeading(tripTypeDescription)}
            description="The main products available for this trip."
          />
          <FormSectionBody>
            <FormRow>
              <ProductsTable
                tripID={tripID}
                products={products}
                isMain={true}
              />
            </FormRow>
          </FormSectionBody>
        </FormSection>
        <FormSection>
          <FormSectionHeader
            title="Add ons"
            description="Add-on products like airport transfers are added here."
          />
          <FormSectionBody>
            <FormRow>
              <ProductsTable
                tripID={tripID}
                products={products}
                isMain={false}
              />
            </FormRow>
          </FormSectionBody>
        </FormSection>
      </FormSections>
    </Form>
  );
}
