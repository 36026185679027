import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { RadioGroup } from "@headlessui/react";
import styles from "./OptionList.module.scss";

export default function OptionList({
  selectedOption,
  handleChange,
  actionSettings,
}) {
  return (
    <div className={styles.component}>
      <span className={styles.availabilityLabel}>Edit availability</span>
      <span className={styles.availabilityDescription}>
        Selected days have mixed availability.
      </span>
      <RadioGroup
        value={selectedOption}
        onChange={handleChange}
        className={styles.radios}
      >
        {actionSettings.map((setting) => (
          <RadioGroup.Option
            key={setting.name}
            value={setting}
            className={styles.option}
          >
            {({ active }) => (
              <>
                <RadioGroup.Label as="span" className={styles.optionLabel}>
                  {setting.label}
                </RadioGroup.Label>
                <span
                  className={cn(
                    setting.name === selectedOption?.name
                      ? styles.optionSelected
                      : styles.optionDefault,
                    active ? styles.optionActive : "",
                    styles.optionBase
                  )}
                  aria-hidden="true"
                >
                  <span className={styles.optionDot} />
                </span>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </RadioGroup>
    </div>
  );
}

OptionList.propTypes = {
  selectedOption: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  handleChange: PropTypes.func,
  actionSettings: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};
