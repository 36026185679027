import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import { formatImageURL } from "helpers/formatting";
import { TrashIcon } from "@heroicons/react/24/solid";

GalleryCardContent.propTypes = {
  deleteMediaImage: PropTypes.func.isRequired,
  imageObj: PropTypes.object,
  tooSmall: PropTypes.bool,
  hoverCard: PropTypes.bool,
};

export default function GalleryCardContent({
  imageObj = {},
  deleteMediaImage,
  tooSmall = false,
  hoverCard = false,
}) {
  return (
    <>
      {imageObj && (
        <div>
          <div>
            <div className="text-xs font-medium text-gray-800">
              {`${imageObj.file.width}x${imageObj.file.height}`}
              {`, ${imageObj.file.content_type?.replace("image/", "")}`}
              {`, ${parseInt(imageObj.file.byte_size / 1000)}kb`}
            </div>
          </div>
          <div className="flex flex-row gap-2">
            <div className="flex-grow">
              <a
                href={formatImageURL(imageObj.file.url)}
                target="_blank"
                rel="noopener noreferrer"
                className="block mt-0.5 text-xs font-medium text-gray-500 hover:underline"
              >
                Open
              </a>
              <div className="text-xs font-medium text-red-600 mt-0.5">
                {tooSmall && "Too small"}
              </div>
            </div>
            <div
              className={cn(
                "text-right text-gray-300 transition-opacity hover:text-red-500",
                hoverCard
                  ? "opacity-100 pointer-events-auto"
                  : "opacity-0 pointer-events-none"
              )}
            >
              <button
                type="button"
                onClick={() => deleteMediaImage(imageObj.id)}
              >
                <TrashIcon className="w-4 h-4" />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
