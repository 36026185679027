import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "lunar/Button";
import { createTripBreak, updateTripBreak, deleteTripBreak } from "lib/api";
import { formatImageURL } from "helpers/formatting";
import CreateUpdateBreakModal from "components/trips/CreateUpdateBreakModal";
import Table from "lunar/Table";
import TableHead from "lunar/TableHead";
import TableRow from "lunar/TableRow";
import TableCell from "lunar/TableCell";
import TableBody from "lunar/TableBody";
import TableActions from "lunar/TableActions";

BreaksForm.propTypes = {
  breaks: PropTypes.array,
  tripID: PropTypes.string,
  handleError: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
};

export default function BreaksForm({
  breaks = [],
  tripID = "",
  handleError,
  handleSuccess,
}) {
  const [showModal, setShowModal] = useState(false);
  const [breakToEdit, setBreakToEdit] = useState(null);

  async function handleDeleteBreak(id) {
    const response = await deleteTripBreak(id);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Break deleted");
    }
    setShowModal(false);
  }

  function handleEditBreak(tripBreak) {
    setBreakToEdit(tripBreak);
    setShowModal(true);
  }

  async function handleSaveBreak(data) {
    let response;
    if (data.id !== "") {
      response = await updateTripBreak(data.id, data);
    } else {
      response = await createTripBreak(data);
    }
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Break saved");
    }
    setShowModal(false);
  }

  function handleAdd() {
    setBreakToEdit(null);
    setShowModal(true);
  }

  return (
    <>
      {breaks && (
        <div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell head first>
                  Image
                </TableCell>
                <TableCell head>Name</TableCell>
                <TableCell head>Direction</TableCell>
                <TableCell head>Break Type</TableCell>
                <TableCell head last>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {breaks.map((tripBreak) => (
                <TableRow key={tripBreak.id}>
                  <TableCell first>
                    {tripBreak.image && (
                      <a
                        href={formatImageURL(tripBreak.image.url)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={formatImageURL(tripBreak.image.url, {
                            resize: { height: 50 },
                          })}
                          style={{ maxHeight: 50 }}
                          className="img-fluid"
                          alt={tripBreak.name}
                        />
                      </a>
                    )}
                  </TableCell>
                  <TableCell>{tripBreak.name}</TableCell>
                  <TableCell>{tripBreak.direction}</TableCell>
                  <TableCell>{tripBreak.break_type}</TableCell>
                  <TableCell last>
                    <button
                      type="button"
                      onClick={() => handleDeleteBreak(tripBreak.id)}
                      className="text-gray-600 hover:underline"
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      onClick={() => handleEditBreak(tripBreak)}
                      className="ml-4 text-blue-600 hover:text-blue-900"
                    >
                      Edit
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TableActions>
            <Button variant="secondary" onClick={handleAdd}>
              Add wave
            </Button>
          </TableActions>
          <CreateUpdateBreakModal
            tripID={tripID}
            handleSaveBreak={handleSaveBreak}
            setShowModal={setShowModal}
            showModal={showModal}
            tripBreak={breakToEdit}
            numBreaks={breakToEdit ? breaks.length : breaks.length + 1}
          />
        </div>
      )}
    </>
  );
}
