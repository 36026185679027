import React, { useState } from "react";

const LoadingContext = React.createContext();

function LoadingProvider(props) {
  const [loading, setLoading] = useState(false);
  const value = { loading, setLoading };

  return <LoadingContext.Provider value={value} {...props} />;
}

function useLoadingContext() {
  const context = React.useContext(LoadingContext);
  if (context === undefined) {
    throw new Error(`useLoadingContext must be used within a LoadingProvider`);
  }
  return context;
}

export { LoadingProvider, useLoadingContext };
