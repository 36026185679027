import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { formatImageURL } from "helpers/formatting";

Avatar.propTypes = {
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  size: PropTypes.string,
};

export default function Avatar({ name = "", imageUrl = "", size = "" }) {
  function mapSizeCircle() {
    switch (size) {
      case "sm":
        return "h-8 w-8";
      case "lg":
        return "h-20 w-20";
      default:
        return "h-11 w-11";
    }
  }

  function mapSizeText() {
    switch (size) {
      case "sm":
        return "text-xs";
      case "lg":
        return "text-3xl";
      default:
        return "text-sm";
    }
  }

  function getInitials() {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
    let initials = [...name.matchAll(rgx)] || [];

    return (
      (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
    ).toUpperCase();
  }

  return (
    <>
      {imageUrl ? (
        <img
          className={cn("rounded-full object-cover", mapSizeCircle())}
          src={formatImageURL(imageUrl)}
          alt=""
        />
      ) : (
        <span
          className={cn(
            "inline-flex items-center justify-center rounded-full bg-gray-500",
            mapSizeCircle()
          )}
        >
          <span
            className={cn("font-medium leading-none text-white", mapSizeText())}
          >
            {getInitials()}
          </span>
        </span>
      )}
    </>
  );
}
