import React from "react";
import PropTypes from "prop-types";

CardTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function CardTitle({ children }) {
  return (
    <div className="py-3">
      <h2 className="text-xl leading-6 font-medium text-gray-900">
        {children}
      </h2>
    </div>
  );
}
