import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { longDateTime } from "helpers/formatting";
import Card from "components/common/Card";
import CardHeading from "components/common/CardHeading";

Summary.propTypes = {
  user: PropTypes.object,
};

const defaultUser = {};

export default function Summary({ user = defaultUser }) {
  function listMarkup(label, content, link = undefined) {
    const formattedContent = link ? (
      <Link to={link} className="text-blue-600 hover:text-blue-900">
        {content}
      </Link>
    ) : (
      content
    );
    return (
      <div className="py-4 flex justify-between text-sm">
        <dt className="font-medium text-gray-500">{label}</dt>
        <dd className="text-gray-900">{formattedContent}</dd>
      </div>
    );
  }

  return (
    <Card>
      <CardHeading heading="Summary" />
      <dl className="px-6 sm:divide-y sm:divide-gray-200">
        {listMarkup("Email", user.email)}
        {listMarkup("Phone", user.phone_number)}
        {listMarkup("First name", user.first_name)}
        {listMarkup("Last name", user.last_name)}
        {listMarkup("ID", user.id)}
        {listMarkup("UTM Source", user.utm_source)}
        {listMarkup("Created", longDateTime(user.created_at))}
      </dl>
    </Card>
  );
}
