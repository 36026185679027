import React from "react";
import PropTypes from "prop-types";
import { nameDescription } from "helpers/naming";
import NavItem from "components/trips/NavItem";
import {
  BookOpenIcon,
  Squares2X2Icon,
  CogIcon,
  HomeIcon,
  MoonIcon,
  QuestionMarkCircleIcon,
  CurrencyDollarIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
// import { preflightDetails } from "helpers/preflight";

Nav.propTypes = {
  trip: PropTypes.object,
  tripTypeDescription: PropTypes.string,
  activeTab: PropTypes.string,
};

export default function Nav({ tripTypeDescription, activeTab }) {
  function accommodationCardHeading(type) {
    switch (type) {
      case "Lodge":
        return "Accommodation";
      case "Boat":
        return "Berths";
      case "Adventure":
        return "Accommodation";
      default:
        return "Accommodation";
    }
  }

  return (
    <div className="overflow-y-auto divide-y auto-rows-auto">
      <NavItem
        icon={<CogIcon />}
        anchor="#details"
        text="Details"
        description="The essential information travelers will use to compare and pick trips."
        active={activeTab === "details"}
        // check={preflightDetails(trip)}
      />
      <NavItem
        icon={<MapPinIcon />}
        anchor="#location"
        text="Location and contact information"
        description="Trip address and closest airport for maps and post-purchase booking information."
        active={activeTab === "location"}
      />
      <NavItem
        icon={<BookOpenIcon />}
        anchor="#story"
        text="Headline and story"
        description="A unique headline and short narrative description of the entirety of the trip."
        active={activeTab === "story"}
      />
      <NavItem
        icon={<Squares2X2Icon />}
        anchor="#gallery"
        text="Main gallery"
        description="A complete visual overview of the trip to get travelers excited about the experience."
        active={activeTab === "gallery"}
      />
      <NavItem
        icon={<MoonIcon />}
        anchor="#surf"
        text="Surf conditions and breaks"
        description="Information about conditions, appropriate surf gear, and most importantly, the waves."
        active={activeTab === "surf"}
      />
      <NavItem
        icon={<HomeIcon />}
        anchor="#desc"
        text={nameDescription(tripTypeDescription)}
        description="The primary focus of the experience. Pretend you're there. Describe it to a friend."
        active={activeTab === "desc"}
      />
      {tripTypeDescription === "Adventure" && (
        <NavItem
          icon={<MoonIcon />}
          anchor="#accommodations"
          text={accommodationCardHeading(tripTypeDescription)}
          description="Where they'll sleep. Describe the nature and standard of the lodging provided on this trip. "
          active={activeTab === "accommodations"}
        />
      )}
      <NavItem
        icon={<CurrencyDollarIcon />}
        anchor="#products"
        text="Pricing and availability"
        description="The purchaseable rooms, routes and add-ons that travelers request."
        active={activeTab === "products"}
      />
      <NavItem
        icon={<QuestionMarkCircleIcon />}
        anchor="#questions"
        text="FAQs"
        description="The frequently asked questions. For example, how to get there and what's included."
        active={activeTab === "questions"}
      />
    </div>
  );
}
