import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import { Controller } from "react-hook-form";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

SelectSearch.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helper: PropTypes.node,
  options: PropTypes.array.isRequired,
  errors: PropTypes.object,
  control: PropTypes.object.isRequired,
  showLabel: PropTypes.bool,
  placeholder: PropTypes.string,
  groupBy: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default function SelectSearch({
  name,
  label = "",
  helper = null,
  options,
  errors = {},
  control,
  showLabel = true,
  placeholder = "Select...",
  groupBy = null,
  className = "",
  onChange = undefined,
}) {
  const [selectOptions, setSelectOptions] = useState(options);

  useEffect(() => {
    if (options) {
      formatOptions();
    }
  }, [options]);

  function formatOptions() {
    if (groupBy) {
      const groupedOptions = Object.entries(
        options.reduce((acc, elem) => {
          if (!acc[elem[groupBy]]) {
            acc[elem[groupBy]] = [];
          }
          acc[elem[groupBy]].push({ value: elem.value, label: elem.label });

          return acc;
        }, {})
      ).map(([label, options]) => ({ label, options }));

      setSelectOptions(groupedOptions);
    } else {
      setSelectOptions(options);
    }
  }

  const filterOption = ({ label, value }, string) => {
    // make not case sensitive
    label = label.toLocaleLowerCase();
    string = string.toLocaleLowerCase();

    // default search
    if (label.includes(string)) return true;

    // check if a group as the filter string as label
    if (groupBy) {
      const filterGroupOptions = selectOptions.filter((group) =>
        group.label.toLocaleLowerCase().includes(string)
      );

      if (filterGroupOptions) {
        for (const filterGroupOption of filterGroupOptions) {
          // check if current option is in group
          const option = filterGroupOption.options.find(
            (opt) => opt.value === value
          );
          if (option) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused
        ? "rgba(59, 130, 246, 0.5)"
        : "rgb(209, 213, 219)",
      "&:hover": {
        borderColor: state.isFocused
          ? "rgba(59, 130, 246, 0.5)"
          : "rgb(209, 213, 219)",
      },
    }),
    input: (base) => ({
      ...base,
      "input:focus": { boxShadow: "none" },
    }),
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ChevronDownIcon className="w-5 h-5" />
      </components.DropdownIndicator>
    );
  };

  return (
    <div className={className}>
      {showLabel && (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <Controller
        control={control}
        name={name}
        defaultValue={{}}
        render={({ field }) => (
          <Select
            options={selectOptions}
            filterOption={filterOption}
            components={{ DropdownIndicator, IndicatorSeparator: () => null }}
            styles={customStyles}
            placeholder={placeholder}
            className="mt-1 text-sm shadow-sm"
            onChange={(e) => {
              field.onChange(e.value);
              if (onChange !== undefined) onChange(e);
            }}
            value={options.find((option) => option.value === field.value)}
          />
        )}
      />
      {errors[name] && (
        <p className="mt-2 text-xs text-red-600">{errors[name].message}</p>
      )}
      {helper && <p className="mt-2 text-xs text-gray-500">{helper}</p>}
    </div>
  );
}
