import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalBody from "lunar/ModalBody";
import ModalFooter from "lunar/ModalFooter";
import Button from "lunar/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import HiddenField from "components/common/HiddenField";
import InputTextAddon from "lunar/InputTextAddon";
import InputTextArea from "lunar/InputTextArea";

RefundModal.propTypes = {
  setShowRefundModal: PropTypes.func.isRequired,
  handleSaveRefund: PropTypes.func.isRequired,
  showRefundModal: PropTypes.bool.isRequired,
  paymentID: PropTypes.string.isRequired,
};

export default function RefundModal({
  showRefundModal,
  setShowRefundModal,
  handleSaveRefund,
  paymentID,
}) {
  const schema = Yup.object().shape({
    amount: Yup.number().notOneOf([0], "Can't be zero").required("Required"),
  });

  const {
    reset,
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      payment_id: paymentID,
    });
  }, [paymentID]);

  return (
    <Modal open={showRefundModal} setOpen={setShowRefundModal}>
      <ModalTitle>Create a refund</ModalTitle>
      <form onSubmit={handleSubmit(handleSaveRefund)} noValidate>
        <ModalBody>
          <HiddenField name="payment_id" register={register} />
          <InputTextAddon
            name="amount"
            type="text"
            label="Amount"
            errors={errors}
            register={register}
            addonLeft="$"
          />
          <InputTextArea
            name="reason"
            label="Reason"
            errors={errors}
            register={register}
            className="mt-6"
          />
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={() => setShowRefundModal(false)}>
            Cancel
          </Button>
          <Button type="submit" isSubmitting={isSubmitting}>
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
