import React from "react";
import PropTypes from "prop-types";
import { formatImageURL } from "helpers/formatting";
import { parseISO } from "date-fns";
import { createBookingNote } from "lib/api";
import TimeAgo from "timeago-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import Button from "lunar/Button";
import InputTextArea from "lunar/InputTextArea";

Notes.propTypes = {
  handleError: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  bookingID: PropTypes.string.isRequired,
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      admin_name: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    })
  ),
};

export default function Notes({
  notes,
  bookingID,
  handleError,
  handleSuccess,
}) {
  const schema = Yup.object().shape({});
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  async function handleSaveNote(data) {
    const response = await createBookingNote(bookingID, data);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Note created");
    }
  }

  return (
    <>
      {notes.length > 0 && (
        <div>
          <ul className="grid gap-4 pb-4 -mt-4 divide-y divide-gray-200">
            {notes
              .sort((x, y) => parseISO(x.created_at) - parseISO(y.created_at))
              .map((note) => (
                <li key={note.id}>
                  <div className="flex pt-4 space-x-3">
                    <img
                      className="w-6 h-6 rounded-full"
                      src={formatImageURL(note.admin_image_url)}
                      alt=""
                    />
                    <div className="flex-1 space-y-1 overflow-hidden break-words">
                      <h3 className="text-sm font-bold">{note.admin_name}</h3>
                      <p className="text-sm text-gray-600">{note.content}</p>
                      <p className="text-xs text-gray-400">
                        <TimeAgo datetime={note.created_at} />
                      </p>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      )}
      <form onSubmit={handleSubmit(handleSaveNote)} noValidate>
        <div>
          <div className="text-left">
            <InputTextArea
              name="note"
              errors={errors}
              register={register}
              rows={3}
            />
          </div>
          <div className="flex flex-row mt-4">
            <p className="text-sm text-gray-500">
              Please make a note when you want keep a record of an update,
              conversation, etc.
            </p>
            <div className="flex-grow text-right">
              <Button
                variant="secondary"
                type="submit"
                isSubmitting={isSubmitting}
              >
                Add note
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
