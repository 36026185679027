import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helper: PropTypes.string,
  type: PropTypes.string.isRequired,
  errors: PropTypes.object,
  register: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  maxlength: PropTypes.string,
};

export default function TextField({
  name,
  label = "",
  helper = "",
  type,
  errors = {},
  register,
  disabled = false,
  className = "",
  placeholder = "",
  maxlength = "",
}) {
  const inputClass =
    type === "file"
      ? "form-control-file"
      : cn(
          "mt-1 shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md",
          { "bg-gray-200 cursor-not-allowed": disabled }
        );
  return (
    <div className={cn("mb-6", className)}>
      {label.length > 0 && (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <input
        id={name}
        name={name}
        type={type}
        placeholder={placeholder}
        readOnly={disabled}
        className={inputClass}
        maxLength={maxlength}
        {...register(name)}
      />
      {errors[name] && (
        <p className="mt-2 text-sm text-red-600">{errors[name].message}</p>
      )}
      {helper && <p className="mt-2 text-sm text-gray-500">{helper}</p>}
    </div>
  );
}
