import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import {
  ExclamationTriangleIcon,
  XCircleIcon,
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";

Alert.propTypes = {
  body: PropTypes.node.isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.node,
};

export default function Alert({
  body,
  title = "",
  type = "info",
  className = null,
}) {
  let backgroundClass = null;
  let titleClass = null;
  let bodyClass = null;

  switch (type) {
    case "warning":
      backgroundClass = "bg-yellow-50";
      titleClass = "text-yellow-800";
      bodyClass = "text-yellow-700";
      break;
    case "error":
      backgroundClass = "bg-red-50";
      titleClass = "text-red-800";
      bodyClass = "text-red-700";
      break;
    case "success":
      backgroundClass = "bg-green-50";
      titleClass = "text-green-800";
      bodyClass = "text-green-700";
      break;
    default:
      backgroundClass = "bg-blue-50";
      titleClass = "text-blue-800";
      bodyClass = "text-blue-700";
      break;
  }
  return (
    <div className={cn("rounded-md p-4", backgroundClass, className)}>
      <div className="flex">
        <div className="flex-shrink-0">
          {type === "error" && (
            <XCircleIcon className="w-5 h-5 text-red-400" aria-hidden="true" />
          )}
          {type === "warning" && (
            <ExclamationTriangleIcon
              className="w-5 h-5 text-yellow-400"
              aria-hidden="true"
            />
          )}
          {type === "success" && (
            <CheckCircleIcon
              className="w-5 h-5 text-green-400"
              aria-hidden="true"
            />
          )}
          {type === "info" && (
            <InformationCircleIcon
              className="w-5 h-5 text-blue-400"
              aria-hidden="true"
            />
          )}
        </div>
        <div className="ml-3">
          {title && (
            <h3 className={cn("text-sm font-bold mb-1", titleClass)}>
              {title}
            </h3>
          )}
          <div className={cn("text-sm", bodyClass)}>
            <p>{body}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
