import { useEffect } from "react";
import { useAuthContext } from "context/auth-context";

export default function Logout() {
  const authContext = useAuthContext();
  useEffect(() => {
    authContext.clearUserData();
    window.location.href = "/";
  }, [authContext]);

  return null;
}
