import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

FormSectionBody.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.node,
};

export default function FormSectionBody({ children, className = null }) {
  return <div className={cn("pt-6", className)}>{children}</div>;
}
