import React from "react";
import PropTypes from "prop-types";

FeedItem.propTypes = {
  person: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default function FeedItem({ person, summary, date, children }) {
  return (
    <li>
      <div className="relative pb-8">
        <div
          className="absolute h-full -ml-px bg-gray-200 border-l top-5 left-5"
          aria-hidden="true"
        />
        <div className="relative flex items-start space-x-3">
          <div>
            <div className="relative px-1">
              <span className="flex items-center justify-center w-8 h-8 bg-gray-400 rounded-full ring-8 ring-transparent">
                <svg
                  className="w-5 h-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="flex-1 min-w-0">
            <div className="mt-1">
              <span className="text-sm font-semibold text-gray-900">
                {person}
              </span>
              <span className="text-sm font-semibold text-gray-500">{` ${summary} on ${date}`}</span>
            </div>
            <div className="text-sm text-gray-600">
              <div>{children}</div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
