import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

TableCell.propTypes = {
  children: PropTypes.node.isRequired,
  head: PropTypes.bool,
  first: PropTypes.bool,
  last: PropTypes.bool,
  colSpan: PropTypes.string,
  className: PropTypes.node,
  wrap: PropTypes.bool,
};

export default function TableCell({
  children,
  head = false,
  first = false,
  last = false,
  colSpan = null,
  className = "",
  wrap = false,
}) {
  const ElementType = head ? "th" : "td";

  return (
    <ElementType
      colSpan={colSpan}
      scope={head !== false ? "col" : undefined}
      className={cn(
        "px-3",
        !wrap && "whitespace-nowrap",
        head
          ? "px-3 py-3 text-sm font-medium text-left text-gray-500 whitespace-nowrap"
          : "px-3 py-4 text-sm font-medium text-gray-500 border-t",
        first && "pl-0 whitespace-normal",
        last && "pr-0 text-right",
        className
      )}
    >
      {children}
    </ElementType>
  );
}
