import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { formatShortDateRange } from "helpers/formatting";
import { formatTravelersString } from "helpers/naming";
import Markdown from "lunar/Markdown";

Summary.propTypes = {
  booking: PropTypes.object.isRequired,
};

const dtClass = "text-sm font-medium text-gray-500";
const ddClass = "mt-1 text-sm font-medium text-gray-900";

export default function Summary({ booking }) {
  return (
    <>
      <dl className="grid grid-flow-row gap-6">
        <div>
          <dt className={dtClass}>Customer</dt>
          <dd className={ddClass}>
            <Link
              className="text-blue-600 hover:text-blue-900"
              to={`/users/${booking.user.id}`}
            >{`${booking.user.first_name} ${booking.user.last_name}`}</Link>
          </dd>
        </div>
        <div>
          <dt className={dtClass}>Trip</dt>
          <dd className={ddClass}>
            <Link
              className="text-blue-600 hover:text-blue-900"
              to={`/trips/edit/${booking.trip.id}`}
            >
              {booking.trip.name}
            </Link>
          </dd>
        </div>
        <div>
          <dt className={dtClass}>Guide</dt>
          <dd className={ddClass}>
            <Link
              className="text-blue-600 hover:text-blue-900"
              to={`/operators/edit/${booking.operator.id}`}
            >
              {booking.operator.name}
            </Link>
          </dd>
        </div>
        <div>
          <dt className={dtClass}>Dates</dt>
          <dd className={ddClass}>
            {formatShortDateRange(booking.start_date, booking.end_date)}
          </dd>
        </div>
        <div>
          <dt className={dtClass}>Travelers</dt>
          <dd className="w-full mt-1 text-sm font-medium text-gray-900">
            {formatTravelersString({
              adults: booking.num_adults,
              children: booking.num_children,
              infants: booking.num_infants,
            })}
          </dd>
        </div>
        <div>
          <dt className={dtClass}>Reservation code</dt>
          <dd className={ddClass}>{booking.confirmation_code}</dd>
        </div>
      </dl>
      {booking.messages.initial_message && (
        <div className="pt-6 mt-6 text-sm border-t border-gray-200">
          <div className="font-medium text-gray-500">Most recent message</div>
          <div className="pt-2 text-gray-900">
            <Markdown>{booking.messages.initial_message}</Markdown>
          </div>
          <div className="mt-2">
            <Link
              to={`/messages/${booking.messages.thread_id}`}
              className="text-blue-600 hover:text-blue-900"
            >
              Full conversation
            </Link>
          </div>
        </div>
      )}
    </>
  );
}
