import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Switch } from "@headlessui/react";
import cn from "classnames";
import styles from "./Toggle.module.scss";

export default function Toggle({
  checked = false,
  handleChange = null,
  numbDates = null,
}) {
  const [state, setState] = useState(false);

  useEffect(() => {
    setState(checked);
  }, [checked]);

  function handleState() {
    setState(!state);
  }

  return (
    <Switch
      checked={state}
      onClick={handleState}
      onChange={() => {
        handleChange(!state ? { name: "Blocked" } : { name: "Available" });
      }}
      className={styles.component}
    >
      <span className="sr-only">Edit availability</span>
      <span className={styles.container}>
        <span className={cn(styles.button, state && styles.buttonEnabled)} />
        <span className={styles.textContainer}>
          <span className={cn(styles.text, state && styles.textEnabled)}>
            {checked ? `Open day${numbDates > 1 ? "s" : ""}` : "Open"}
          </span>
          <span className={cn(styles.text, !state && styles.textEnabled)}>
            {!checked ? `Block day${numbDates > 1 ? "s" : ""}` : "Blocked"}
          </span>
        </span>
      </span>
    </Switch>
  );
}

Toggle.propTypes = {
  checked: PropTypes.bool,
  handleChange: PropTypes.func,
  numbDates: PropTypes.number,
};
