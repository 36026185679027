import React from "react";
import PropTypes from "prop-types";

Header.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function Header({ children }) {
  return (
    <div className="p-4 border-b border-gray-200 font-bold">{children}</div>
  );
}
