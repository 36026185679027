import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import {
  getReview,
  updateReview,
  deleteReviewImages,
  updateReviewImagePositions,
} from "lib/api";
import { Link } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useLoadingContext } from "context/loading-context";
import { useToasts } from "react-toast-notifications";
import { getCodeList } from "country-list";
import { FormProvider } from "context/form-context";
import { humanDate } from "helpers/formatting";
import InputTextArea from "lunar/InputTextArea";
import Meta from "lunar/Meta";
import Form from "lunar/Form";
import FormTitle from "lunar/FormTitle";
import FormSections from "lunar/FormSections";
import FormSection from "lunar/FormSection";
import FormSectionHeader from "lunar/FormSectionHeader";
import FormSectionBody from "lunar/FormSectionBody";
import FormRow from "lunar/FormRow";
import SelectField from "lunar/SelectField";
import Gallery from "lunar/gallery/Gallery";

export default function Edit() {
  const [review, setReview] = useState({});
  const reviewID = useParams().id;
  const { addToast } = useToasts();
  const { setLoading } = useLoadingContext();

  const schema = Yup.object().shape({
    recommendation_score: Yup.number().required("Required"),
    user_about: Yup.string().required("Required"),
  });

  const {
    control,
    register,
    errors,
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (review) {
      reset({
        description: review.description,
        user_country: review.user_country,
        user_about: review.user_about,
        recommendation_score: review.recommendation_score,
      });
    }
  }, [reset, review]);

  useEffect(() => {
    (async () => {
      populateReviewState(reviewID);
    })();
  }, [reviewID]);

  async function populateReviewState(reviewID) {
    setLoading(true);
    const response = await getReview(reviewID);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      setReview(response);
    }
    setLoading(false);
  }

  function handleSuccess(msg) {
    populateReviewState(reviewID);
    addToast(msg, { appearance: "success" });
  }

  function handleError(msg) {
    addToast(msg, { appearance: "error" });
  }

  async function submitForm(data) {
    const response = await updateReview(reviewID, data);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Review successfully updated");
    }
  }

  // create an array of country codes / names for the SelectField
  const countryList = Object.entries(getCodeList())
    .map((e) => ({
      id: e[0],
      name: e[1],
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  // make the US the first in the list
  const usIdx = countryList.findIndex((e) => e.id === "us");
  const usEl = countryList[usIdx];
  countryList.splice(usIdx, 1);
  countryList.unshift(usEl);

  const mockInput = (label, value) => {
    return (
      <>
        <div className="block text-sm font-medium text-gray-700">{label}</div>
        <div className="block w-full px-3 py-2 mt-1 bg-gray-200 border border-gray-300 rounded-md shadow-sm sm:text-sm">
          {value}
        </div>
      </>
    );
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Meta title={review.user && review.user.first_name} />
      <FormProvider
        lastUpdated={review.updated_at && humanDate(review.updated_at)}
      >
        <Form
          handleSubmit={handleSubmit}
          submitForm={submitForm}
          isSubmitting={isSubmitting}
        >
          <FormTitle title={"Traveler review"} />
          <FormSections>
            <FormSection>
              <FormSectionHeader
                title="Trip"
                description="This review relates to following booking."
              />
              <FormSectionBody>
                <FormRow className="">
                  <div className="w-1/2">
                    {mockInput("Name", review.trip?.name)}
                  </div>
                  <div className="w-1/4">
                    {mockInput("Travel date", review.booking?.travel_date)}
                  </div>
                  <div className="w-1/4">
                    {mockInput(
                      "Confirmation code",
                      review.booking?.confirmation_code
                    )}
                  </div>
                </FormRow>
                <FormRow className="mt-3">
                  <div>
                    <Link
                      className="text-blue-600 hover:text-blue-900"
                      to={`/bookings/${review.booking?.transaction_number}`}
                    >
                      View booking
                    </Link>
                    <span className="px-2 opacity-30">|</span>
                    <a
                      className="text-blue-600 hover:text-blue-900"
                      href={`https://thermal.travel/trips/${review.trip?.slug}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View listing
                    </a>
                  </div>
                </FormRow>
              </FormSectionBody>
            </FormSection>
            <FormSection>
              <FormSectionHeader
                title="Traveler"
                description="Info about traveler who left the review."
              />
              <FormSectionBody className="grid gap-6">
                <FormRow className="grid grid-flow-row grid-cols-2">
                  <div className="w-full">
                    {mockInput(
                      "Name",
                      `${review.user?.first_name} ${review.user?.last_name}`
                    )}
                  </div>
                  <div className="flex items-center pt-5">
                    <Link
                      className="text-blue-600 hover:text-blue-900"
                      to={`/users/${review.user?.id}`}
                    >
                      View user
                    </Link>
                  </div>
                </FormRow>
                <FormRow className="grid grid-flow-row grid-cols-2">
                  <SelectField
                    name="user_country"
                    label="Country"
                    options={countryList}
                    errors={errors}
                    register={register}
                    className="w-full"
                  />
                  <SelectField
                    name="user_about"
                    label="About"
                    options={["Casual surfer", "Avid surfer"]}
                    errors={errors}
                    register={register}
                    className="w-full"
                  />
                </FormRow>
              </FormSectionBody>
            </FormSection>
            <FormSection>
              <FormSectionHeader
                title="Review"
                description="Traveler review info."
              />
              <FormSectionBody className="grid gap-6">
                <FormRow className="grid grid-flow-row grid-cols-2">
                  <div className="w-full">
                    {mockInput(
                      "Date created",
                      review.created_at && humanDate(review.created_at)
                    )}
                  </div>
                  <SelectField
                    name="recommendation_score"
                    label="Recommendation score"
                    options={[0, 1, 2]}
                    errors={errors}
                    register={register}
                    className="w-full"
                  />
                </FormRow>
                <FormRow className="grid grid-flow-row">
                  <InputTextArea
                    name="description"
                    label="Review text"
                    errors={errors}
                    register={register}
                    rows={10}
                  />
                </FormRow>
              </FormSectionBody>
            </FormSection>
            <FormSection>
              <FormSectionHeader
                title="Photos"
                description="These will be posted with the review."
              />
              <FormSectionBody>
                <FormRow>
                  <Gallery
                    parentID={reviewID}
                    images={review.trip_review_images}
                    handleError={handleError}
                    handleSuccess={handleSuccess}
                    updateFunc={updateReview}
                    deleteFunc={deleteReviewImages}
                    updatePositionsFunc={updateReviewImagePositions}
                    imagesAttributeName="trip_review_images_attributes"
                    control={control}
                    setValue={setValue}
                  />
                </FormRow>
              </FormSectionBody>
            </FormSection>
          </FormSections>
        </Form>
      </FormProvider>
    </DndProvider>
  );
}
