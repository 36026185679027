import React from "react";
import PropTypes from "prop-types";

FormFooter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function FormFooter({ children }) {
  return <div className="pt-10 text-right border-t">{children}</div>;
}
