import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalFooter from "lunar/ModalFooter";
import Button from "lunar/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import TextField from "components/common/TextField";
import SelectField from "components/common/SelectField";
import TextArea from "components/common/TextArea";
import HiddenField from "components/common/HiddenField";
import ModalBody from "lunar/ModalBody";

CreateProductModal.propTypes = {
  tripID: PropTypes.string,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func.isRequired,
  handleCreateProduct: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  pricingTypeOptions: PropTypes.array.isRequired,
  dateSelectionTypeOptions: PropTypes.array,
};

export default function CreateProductModal({
  tripID = "",
  showModal = false,
  setShowModal,
  handleCreateProduct,
  options,
  pricingTypeOptions,
  dateSelectionTypeOptions = null,
}) {
  const schema = Yup.object().shape({
    name: Yup.string().required("Required"),
  });

  const {
    register,
    handleSubmit,
    errors,
    reset,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      trip_id: tripID,
    });
  }, [reset, tripID]);

  return (
    <Modal open={showModal} setOpen={setShowModal}>
      <>
        <ModalTitle>Add a product</ModalTitle>
        <form onSubmit={handleSubmit(handleCreateProduct)} noValidate>
          <ModalBody>
            <TextField
              name="name"
              type="text"
              label="Name"
              errors={errors}
              register={register}
            />
            <TextArea
              name="description"
              type="text"
              label="Description"
              errors={errors}
              register={register}
            />
            <SelectField
              name="product_type"
              label="Product Type"
              options={options}
              errors={errors}
              register={register}
            />
            <SelectField
              name="pricing_type"
              label="Pricing Type"
              options={pricingTypeOptions}
              errors={errors}
              register={register}
            />
            {dateSelectionTypeOptions && (
              <SelectField
                name="date_selection_type"
                label="Date Selection Type"
                options={dateSelectionTypeOptions}
                errors={errors}
                register={register}
              />
            )}
            <TextField
              name="price"
              type="text"
              label="Price"
              errors={errors}
              register={register}
            />
            <HiddenField name="trip_id" register={register} />
            {/*
            <SelectField
              name="is_visible"
              label="Visible?"
              options={[
                { id: "false", name: "No" },
                { id: "true", name: "Yes" },
              ]}
              errors={errors}
              register={register}
            />
            */}
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button type="submit" isSubmitting={isSubmitting}>
              Save
            </Button>
          </ModalFooter>
        </form>
      </>
    </Modal>
  );
}
