import React from "react";
import PropTypes from "prop-types";
import Button from "lunar/Button";
import { Link } from "react-router-dom";

PageHeading.propTypes = {
  title: PropTypes.string,
  actionName: PropTypes.string,
  actionTo: PropTypes.string,
};

export default function PageHeading({ title, actionName = "", actionTo = "" }) {
  return (
    <header className="border-b border-gray-200">
      <div className="grid items-center grid-cols-2 px-4 py-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex-1 min-w-0">
          <h1 className="text-lg font-semibold leading-6 text-gray-900">
            {title}
          </h1>
        </div>

        {actionName && (
          <div className="justify-self-end">
            <Link to={actionTo}>
              <Button>{actionName}</Button>
            </Link>
          </div>
        )}
      </div>
    </header>
  );
}
