import React from "react";
import PropTypes from "prop-types";
import {
  humanDateTime,
  money,
  getDifferenceInSeconds,
} from "helpers/formatting";
import Feed from "components/bookings/sections/Feed";
import FeedItem from "components/bookings/sections/FeedItem";

Activity.propTypes = {
  booking: PropTypes.object,
};

const defaultBooking = {};

export default function Activity({ booking = defaultBooking }) {
  const entries = [];
  const userName = `${booking.user.first_name} ${booking.user.last_name}`;
  let bookingCreatedFlag = false;
  let bookingCreatedTimestamp = null;

  for (let idx = 0; idx < booking.changes.length; idx += 1) {
    const entry = booking.changes[idx];
    const feedItem = {
      id: entry.id,
      summary: {
        person: entry.admin_name || "Admin",
        str: null,
        time: humanDateTime(entry.created_at),
      },
      details: [],
    };
    const changes = entry.object_changes;
    const attrs = entry.item_associated_attributes;

    if (entry.event === "create") {
      if (entry.item_type === "Booking") {
        if (!changes.thermal_admin_id) feedItem.summary.person = userName;
        feedItem.summary.str = "created booking";
        bookingCreatedFlag = true;
        bookingCreatedTimestamp = entry.created_at;
      } else if (entry.item_type === "LineItem") {
        let lineItemDetail = `${changes.quantity[1]} X ${
          attrs.product_name
        } @ ${money(changes.price_per_item[1])}`;
        if (attrs.variant_usage_date)
          lineItemDetail += ` (${attrs.variant_usage_date})`;
        if (
          bookingCreatedFlag &&
          getDifferenceInSeconds(entry.created_at, bookingCreatedTimestamp) < 1
        ) {
          entries[entries.length - 1].details.push(lineItemDetail);
          continue;
        } else {
          feedItem.summary.str = "created product";
          feedItem.details.push(lineItemDetail);
        }
      } else if (entry.item_type === "Adjustment") {
        const adjustmentDetail = `${changes.label[1]} for ${money(
          changes.amount[1]
        )}`;
        if (
          bookingCreatedFlag &&
          getDifferenceInSeconds(entry.created_at, bookingCreatedTimestamp) < 1
        ) {
          entries[entries.length - 1].details.push(adjustmentDetail);
          continue;
        } else {
          feedItem.summary.str = "created adjustment";
          feedItem.details.push(adjustmentDetail);
        }
      } else if (entry.item_type === "Payment") {
        feedItem.summary.person = userName;
        feedItem.summary.str = "made a payment";
      }
    } else if (entry.event === "update" && entry.item_type === "Booking") {
      if (changes.status) {
        feedItem.summary.str = "changed status";
        feedItem.details.push(
          `Status changed from ${changes.status[0]} to ${changes.status[1]}`
        );
      } else if (changes.expiring_email_sent_at) {
        feedItem.summary.str = `emailed ${userName} a notification`;
        feedItem.details.push("Approved reservation expiring soon");
      } else if (changes.sent_to_guide_at) {
        feedItem.summary.str = `emailed guide a notification`;
        feedItem.details.push("New reservation request");
      } else if (changes.review_request_sent_at) {
        feedItem.summary.str = `emailed user a review request`;
        feedItem.details.push("Review request");
      }
    } else if (entry.event === "destroy") {
      if (entry.item_type === "LineItem") {
        feedItem.summary.str = "removed a product";
        feedItem.details.push(
          `Product removed: ${changes.quantity[0]} X ${
            attrs.product_name
          } @ ${money(changes.price_per_item[0])} ${
            attrs.variant_usage_date && `(${attrs.variant_usage_date})`
          }`
        );
      } else if (entry.item_type === "Adjustment") {
        feedItem.summary.str = "removed an adjustment";
        feedItem.details.push(
          `Adjustment removed: ${changes.label[0]} for ${money(
            changes.amount[0]
          )}`
        );
      }
    }
    if (feedItem.summary.str) entries.push(feedItem);
    if (!(entry.event === "create" && entry.item_type === "Booking")) {
      bookingCreatedFlag = false;
      bookingCreatedTimestamp = null;
    }
  }

  return (
    <Feed>
      {entries.reverse().map((entry) => (
        <FeedItem
          key={entry.id}
          person={entry.summary.person}
          summary={entry.summary.str}
          date={entry.summary.time}
        >
          <div key={`entry-${entry.id}`} className="mt-2">
            {entry.details.map((detail) => (
              <div key={`detail-${entry.id}-${detail}`}>{detail}</div>
            ))}
          </div>
        </FeedItem>
      ))}
    </Feed>
  );
}
