import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helper: PropTypes.node,
  options: PropTypes.array.isRequired,
  errors: PropTypes.object,
  register: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  includeBlank: PropTypes.bool,
  inline: PropTypes.bool,
  showLabel: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default function SelectField({
  name,
  label = "",
  helper = "",
  options,
  errors = {},
  register,
  onChange = null,
  includeBlank = false,
  inline = false,
  showLabel = true,
  className = null,
  disabled = false,
}) {
  let selectOptions =
    typeof options[0] !== "object"
      ? options.map((option) => ({ id: option, name: option }))
      : options;
  if (includeBlank)
    selectOptions = [{ id: "", name: "" }].concat(selectOptions);

  return (
    <div className={className}>
      {showLabel && (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <select
        id={name}
        name={name}
        {...register(name)}
        onChange={onChange}
        disabled={disabled}
        className={cn(
          "focus:outline-none rounded-md",
          disabled && "bg-gray-200 text-black cursor-not-allowed",
          inline
            ? "pl-0 py-0 pr-8 border-white focus:ring-white focus:border-white text-sm font-bold"
            : "block w-full px-3 py-2 mt-1 border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        )}
      >
        {selectOptions.map((option, idx) => (
          <option
            key={`${name}-${idx}`}
            value={option.id}
            disabled={option.disabled}
          >
            {option.name}
          </option>
        ))}
      </select>
      {errors[name] && (
        <p className="mt-2 text-xs text-red-600">{errors[name].message}</p>
      )}
      {helper && <p className="mt-2 text-xs text-gray-500">{helper}</p>}
    </div>
  );
}
