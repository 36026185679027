import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

Tab.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  count: PropTypes.number,
  active: PropTypes.bool,
};

export default function Tab({ name, count, onClick, active = false }) {
  return (
    <div
      onClick={onClick}
      className={cn(
        "p-4 border-b-4 text-sm font-bold text-center",
        active
          ? "border-blue-500 text-blue-600"
          : "cursor-pointer border-gray-300 text-gray-500"
      )}
    >
      {name}
      <span
        className={cn(
          "ml-2 align-middle inline-flex items-center px-1 py-0.5 rounded font-semibold",
          active ? "bg-blue-100 text-blue-600" : "bg-gray-200 text-gray-600"
        )}
        style={{ fontSize: "12px" }}
      >
        {count}
      </span>
    </div>
  );
}
