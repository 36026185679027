import React, { useState } from "react";
import PropTypes from "prop-types";
import { TrashIcon } from "@heroicons/react/24/solid";
import { humanDate, money, shortPricingType } from "helpers/formatting";
import { updateBooking } from "lib/api";
import Button from "lunar/Button";
import CreateAdjustmentModal from "components/bookings/sections/CreateAdjustmentModal";
import CreateLineItemModal from "components/bookings/sections/CreateLineItemModal";
import Table from "lunar/Table";
import TableHead from "lunar/TableHead";
import TableRow from "lunar/TableRow";
import TableBody from "lunar/TableBody";
import TableCell from "lunar/TableCell";
import TableFoot from "lunar/TableFoot";
import TableActions from "lunar/TableActions";

Items.propTypes = {
  handleItemsError: PropTypes.func.isRequired,
  handleItemsSuccess: PropTypes.func.isRequired,
  booking: PropTypes.object.isRequired,
};

export default function Items({
  booking,
  handleItemsError,
  handleItemsSuccess,
}) {
  const [editMode, setEditMode] = useState(false);
  const [showAdjustmentModal, setShowAdjustmentModal] = useState(false);
  const [showLineItemModal, setShowLineItemModal] = useState(false);

  const bookingID = booking.transaction_number;

  const handleEdit = () => setEditMode(true);
  const handleCancelEdit = () => setEditMode(false);
  const handleShowAdjustment = () => setShowAdjustmentModal(true);
  const handleShowLineItem = () => setShowLineItemModal(true);

  async function handleSaveAdjustment(data) {
    const response = await updateBooking(bookingID, {
      adjustments_attributes: [data],
    });
    if (response.errorMsg) {
      handleItemsError(response.errorMsg);
    } else {
      handleItemsSuccess("Adjustment created");
    }
    setShowAdjustmentModal(false);
  }

  async function handleSaveLineItem(data) {
    const response = await updateBooking(bookingID, {
      products: [data],
    });
    if (response.errorMsg) {
      handleItemsError(response.errorMsg);
    } else {
      handleItemsSuccess("Line Item created");
    }
    setShowLineItemModal(false);
  }

  async function deleteItem(id, attributesName, successMsg) {
    const response = await updateBooking(bookingID, {
      [attributesName]: [{ id, _destroy: id }],
    });
    if (response.errorMsg) {
      handleItemsError(response.errorMsg);
    } else {
      handleItemsSuccess(successMsg);
    }
    setEditMode(false);
  }

  async function deleteAdjustment(id) {
    deleteItem(id, "adjustments_attributes", "Adjustment deleted");
  }

  async function deleteLineItem(id) {
    deleteItem(id, "line_items_attributes", "Line Item deleted");
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell head first>
              Product
            </TableCell>
            <TableCell head>Date</TableCell>
            <TableCell head>Qty</TableCell>
            <TableCell head>Type</TableCell>
            <TableCell head># of T</TableCell>
            <TableCell head>Price</TableCell>
            <TableCell head last>
              Total
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {booking.line_items.map((li) => (
            <TableRow key={`lineitem-${li.id}`}>
              <TableCell first className="text-gray-900">
                {li.product_name}
              </TableCell>
              <TableCell>
                {li.variant_usage_date && humanDate(li.variant_usage_date)}
              </TableCell>
              <TableCell>{li.quantity}</TableCell>
              <TableCell>{shortPricingType(li.pricing_type)}</TableCell>
              <TableCell>
                {li.num_paying_guests ? li.num_paying_guests : "~"}
              </TableCell>
              <TableCell>{money(li.price_per_item)}</TableCell>
              <TableCell last>
                {editMode ? (
                  <button onClick={() => deleteLineItem(li.id)}>
                    <TrashIcon className="w-4 h-4" />
                  </button>
                ) : (
                  <>{money(li.quantity * li.price_per_item)}</>
                )}
              </TableCell>
            </TableRow>
          ))}
          {booking.adjustments.map((adj) => (
            <TableRow key={`adjustment-${adj.id}`}>
              <TableCell first colSpan="6" className="text-gray-900">
                {adj.label}
              </TableCell>
              <TableCell last>
                {editMode && adj.label !== "Occupancy tax" ? (
                  <button onClick={() => deleteAdjustment(adj.id)}>
                    <TrashIcon className="w-4 h-4" />
                  </button>
                ) : (
                  <>{money(adj.amount)}</>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFoot>
          <TableRow>
            <TableCell first colSpan="6" className="font-bold text-gray-900">
              Total
            </TableCell>
            <TableCell last className="font-bold text-gray-900">
              {money(booking.total)}
            </TableCell>
          </TableRow>
        </TableFoot>
      </Table>

      {(booking?.status === "admin_created" ||
        booking?.status === "created" ||
        booking?.status === "modified") && (
        <TableActions>
          {editMode ? (
            <>
              <Button variant="secondary" onClick={handleCancelEdit}>
                Cancel
              </Button>
            </>
          ) : (
            <div className="inline-grid grid-flow-col gap-1">
              <Button variant="secondary" onClick={handleEdit}>
                Edit
              </Button>
              <Button variant="secondary" onClick={handleShowAdjustment}>
                Make adjustment
              </Button>
              <Button onClick={handleShowLineItem}>Add product</Button>
            </div>
          )}
        </TableActions>
      )}
      <CreateAdjustmentModal
        handleSaveAdjustment={handleSaveAdjustment}
        setShowAdjustmentModal={setShowAdjustmentModal}
        showAdjustmentModal={showAdjustmentModal}
      />
      <CreateLineItemModal
        handleSaveLineItem={handleSaveLineItem}
        setShowLineItemModal={setShowLineItemModal}
        showLineItemModal={showLineItemModal}
        tripID={booking?.trip?.id}
        setErrorMessage={handleItemsError}
      />
    </>
  );
}
