import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import {
  updateTrip,
  updateTripDescriptionImagePositions,
  deleteTripDescriptionImages,
} from "lib/api";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Form from "lunar/Form";
import FormTitle from "lunar/FormTitle";
import FormSections from "lunar/FormSections";
import FormSection from "lunar/FormSection";
import FormSectionHeader from "lunar/FormSectionHeader";
import FormSectionBody from "lunar/FormSectionBody";
import FormRow from "lunar/FormRow";
import Gallery from "lunar/gallery/Gallery";
import InputTextArea from "lunar/InputTextArea";

DescriptionForm.propTypes = {
  tripType: PropTypes.string,
  description: PropTypes.string,
  submitForm: PropTypes.func.isRequired,
  parentID: PropTypes.string.isRequired,
  images: PropTypes.array,
  handleError: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
};

export default function DescriptionForm({
  parentID,
  tripType = "",
  description = "",
  submitForm,
  images,
  handleError,
  handleSuccess,
}) {
  const {
    control,
    register,
    errors,
    reset,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm();

  useEffect(() => {
    if (description) {
      reset({
        description: description,
      });
    }
  }, [reset, description]);

  function sectionDescription(type) {
    switch (type) {
      case "Lodge":
        return "Include any information about the lodge, other than detailed rooms info. This is where we describe the lodge grounds, location and overall vibe, facilities (pool, game room, gym, etc.), restaurants, bars, food, and nearby sights and activities.";
      case "Boat":
        return "Include a general description of the boat, as well as the history of the vessel, if possible. List all safety features and equipment. Describe the berths, showers, toilets, etc. This is where we highlight the boat’s features and facilities, common spaces, amenities, info about dinghies/tenders, food, and other activities available (i.e.- snorkeling, SCUBA, etc.).";
      case "Adventure":
        return "Include a general description of the adventure and the location(s) explored while on this trip. Include a rough itinerary or overview of the adventure, if relevant, including a description of the transportation. Include adventure highlights and special trips found on this trip, as well as information about the local food and culture.";
      default:
        return "Description";
    }
  }

  async function submitFormAndClearImages(data) {
    await submitForm(data);
    setValue("trip_description_images_attributes", []);
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Form
        handleSubmit={handleSubmit}
        submitForm={submitFormAndClearImages}
        isSubmitting={isSubmitting}
      >
        <FormTitle title={tripType} />
        <FormSections>
          <FormSection>
            <FormSectionHeader
              title="Description"
              description={sectionDescription(tripType)}
            />
            <FormSectionBody>
              <InputTextArea
                name="description"
                type="text"
                label="Description"
                helper="Supports basic markdown formatting."
                errors={errors}
                register={register}
                rows={10}
              />
            </FormSectionBody>
          </FormSection>
          <FormSection>
            <FormSectionHeader
              title="Photos"
              description="Photos that bring to life everything described in the text above."
            />
            <FormSectionBody>
              <FormRow className="grid grid-flow-row">
                <Gallery
                  parentID={parentID}
                  images={images}
                  handleError={handleError}
                  handleSuccess={handleSuccess}
                  updateFunc={updateTrip}
                  deleteFunc={deleteTripDescriptionImages}
                  updatePositionsFunc={updateTripDescriptionImagePositions}
                  imagesAttributeName="trip_description_images_attributes"
                  control={control}
                  setValue={setValue}
                />
              </FormRow>
            </FormSectionBody>
          </FormSection>
        </FormSections>
      </Form>
    </DndProvider>
  );
}
