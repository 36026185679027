import pluralize from "pluralize";

export function nameDescription(type) {
  switch (type) {
    case "Lodge":
      return "Lodge";
    case "Boat":
      return "Boat";
    case "Adventure":
      return "Adventure";
    default:
      return "Description";
  }
}

export function formatTravelersString({ adults, children, infants }) {
  const arr = [];
  if (adults > 0) arr.push(pluralize("adults", adults, true));
  if (children > 0) arr.push(pluralize("children", children, true));
  if (infants > 0) arr.push(pluralize("infants", infants, true));
  return arr.join(", ");
}
