import React from "react";
import PropTypes from "prop-types";

PageListNav.propTypes = {
  filter: PropTypes.node,
  dataTableSearch: PropTypes.node,
  actions: PropTypes.node,
};

export default function PageListNav({
  filter,
  dataTableSearch,
  actions = null,
}) {
  return (
    <header className="sticky top-0 z-10 bg-white border-b border-gray-200">
      <div className="flex flex-row items-center px-4 py-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        {filter}
        {dataTableSearch}
        {actions && <div className="justify-self-end">{actions}</div>}
      </div>
    </header>
  );
}
