import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import styles from "lunar/Button.module.scss";

Button.propTypes = {
  variant: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  isSubmitting: PropTypes.bool,
  icon: PropTypes.node,
  className: PropTypes.node,
};

export default function Button({
  children,
  type = "button",
  variant = "primary",
  disabled = false,
  onClick = null,
  isSubmitting = false,
  icon = null,
  className = null,
}) {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={cn(
        "rounded-md px-3.5 py-2.5 text-sm font-semibold shadow-sm relative inline-grid items-center",
        variant === "primary" &&
          "bg-blue-600 text-white hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600",
        variant === "secondary" &&
          "bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
        disabled && "focus:outline-none disabled:opacity-50 cursor-not-allowed",
        className
      )}
    >
      {isSubmitting && (
        <div className="absolute left-0 right-0 grid pointer-events-none place-items-center">
          <span className={styles.dots}>
            <span className={styles.dot} />
            <span className={styles.dot} />
            <span className={styles.dot} />
          </span>
        </div>
      )}
      <span className={isSubmitting ? "inline opacity-0" : ""}>
        {icon && <span className="inline-block mr-1 align-middle">{icon}</span>}
        {children}
      </span>
    </button>
  );
}
