import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import TextAreaAutoSize from "react-textarea-autosize";

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helper: PropTypes.string,
  errors: PropTypes.object,
  register: PropTypes.func.isRequired,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  className: PropTypes.string,
};

export default function TextArea({
  name,
  label = "",
  helper = "",
  errors = {},
  register,
  minRows = 3,
  maxRows = 10,
  className = "",
}) {
  return (
    <div className={cn("mb-6", className)}>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1">
        <TextAreaAutoSize
          id={name}
          name={name}
          {...register(name)}
          className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          minRows={minRows}
          maxRows={maxRows}
        />
        {errors[name] && (
          <p className="mt-2 text-sm text-red-600">{errors[name].message}</p>
        )}
        {helper && <p className="mt-2 text-sm text-gray-500">{helper}</p>}
      </div>
    </div>
  );
}
