import React from "react";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import {
  updateTrip,
  updateTripMediaImagePositions,
  deleteTripMediaImages,
} from "lib/api";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Form from "lunar/Form";
import FormTitle from "lunar/FormTitle";
import FormSections from "lunar/FormSections";
import FormSection from "lunar/FormSection";
import FormSectionHeader from "lunar/FormSectionHeader";
import FormSectionBody from "lunar/FormSectionBody";
import FormRow from "lunar/FormRow";
import Gallery from "lunar/gallery/Gallery";

MainGalleryForm.propTypes = {
  submitForm: PropTypes.func.isRequired,
  parentID: PropTypes.string.isRequired,
  images: PropTypes.array,
  handleError: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
};

export default function MainGalleryForm({ submitForm, parentID, images, handleError, handleSuccess }) {
  const schema = Yup.object().shape({
    //name: Yup.string().required("Required"),
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  async function submitFormAndClearImages(data) {
    await submitForm(data);
    setValue("trip_media_images_attributes", []);
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Form
        handleSubmit={handleSubmit}
        submitForm={submitFormAndClearImages}
        isSubmitting={isSubmitting}
      >
        <FormTitle title="Main gallery" />
        <FormSections>
          <FormSection>
            <FormSectionHeader
              title="Photos"
              description="These images should provide a complete visual overview of the trip to get travelers excited. Only include duplicate images from other sections like surf or lodge if the image is needed."
            />
            <FormSectionBody>
              <FormRow className="grid grid-flow-row">
                <Gallery
                  parentID={parentID}
                  images={images}
                  handleError={handleError}
                  handleSuccess={handleSuccess}
                  updateFunc={updateTrip}
                  deleteFunc={deleteTripMediaImages}
                  updatePositionsFunc={updateTripMediaImagePositions}
                  imagesAttributeName="trip_media_images_attributes"
                  control={control}
                  setValue={setValue}
                />
              </FormRow>
            </FormSectionBody>
          </FormSection>
        </FormSections>
      </Form>
    </DndProvider>
  );
}
