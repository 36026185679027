import React, { useState } from "react";
import PropTypes from "prop-types";
import { useToasts } from "react-toast-notifications";
import { createVariant, updateVariant, deleteVariant } from "lib/api";
import { money, humanDate } from "helpers/formatting";
import FixedVariantModal from "components/products/FixedVariantModal";
import Button from "lunar/Button";
import Table from "lunar/Table";
import TableHead from "lunar/TableHead";
import TableRow from "lunar/TableRow";
import TableCell from "lunar/TableCell";
import TableBody from "lunar/TableBody";
import TableActions from "lunar/TableActions";

FixedVariantsTable.propTypes = {
  variants: PropTypes.array,
  productID: PropTypes.string,
  handleSuccess: PropTypes.func.isRequired,
};

export default function FixedVariantsTable({
  variants = [],
  productID = "",
  handleSuccess,
}) {
  const [showModal, setShowModal] = useState(false);
  const [variantToEdit, setVariantToEdit] = useState(null);
  const { addToast } = useToasts();

  async function handleDeleteVariant(id) {
    const response = await deleteVariant(id);
    if (response.errorMsg) {
      addToast(response.errorMsg, { appearance: "error" });
    } else {
      handleSuccess("Variant deleted");
    }
    setShowModal(false);
  }

  async function handleEditVariant(variant) {
    setVariantToEdit(variant);
    setShowModal(true);
  }

  async function handleDuplicateVariant(variant) {
    variant.id = "";
    setVariantToEdit(variant);
    setShowModal(true);
  }

  async function handleSaveVariant(data) {
    let response;
    if (data.id !== "") {
      response = await updateVariant(data.id, data);
    } else {
      response = await createVariant(data);
    }
    if (response.errorMsg) {
      addToast(response.errorMsg, { appearance: "error" });
    } else {
      handleSuccess("Variant saved");
    }
    setShowModal(false);
  }

  function handleAdd() {
    setVariantToEdit(null);
    setShowModal(true);
  }

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell head first>
              Price
            </TableCell>
            <TableCell head>Usage date</TableCell>
            <TableCell head>Qty available</TableCell>
            <TableCell head>Min night</TableCell>
            <TableCell head last>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {variants.map((variant) => (
            <TableRow key={variant.id}>
              <TableCell first>{money(variant.price)}</TableCell>
              <TableCell>{humanDate(variant.usage_date)}</TableCell>
              <TableCell>{variant.quantity_available}</TableCell>
              <TableCell>{variant.min_night_stay}</TableCell>
              <TableCell last>
                <button
                  onClick={() => handleDuplicateVariant(variant)}
                  className="ml-3 text-gray-600 hover:underline"
                >
                  Duplicate
                </button>
                {!variant.has_associated_line_items ? (
                  <button
                    onClick={() => handleDeleteVariant(variant.id)}
                    className="ml-3 text-gray-600 hover:underline"
                  >
                    Delete
                  </button>
                ) : (
                  <span className="ml-3 text-gray-300">Delete</span>
                )}
                <button
                  type="button"
                  onClick={() => handleEditVariant(variant)}
                  className="ml-6 text-blue-600 hover:underline"
                >
                  Edit
                </button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TableActions>
        <Button variant="secondary" onClick={handleAdd}>
          Add
        </Button>
      </TableActions>
      <FixedVariantModal
        productID={productID}
        handleSaveVariant={handleSaveVariant}
        setShowModal={setShowModal}
        showModal={showModal}
        variant={variantToEdit}
      />
    </div>
  );
}
