import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalBody from "lunar/ModalBody";
import ModalFooter from "lunar/ModalFooter";
import Button from "lunar/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import HiddenField from "components/common/HiddenField";
import Toggle from "lunar/Toggle";

ExpireModal.propTypes = {
  showExpireModal: PropTypes.bool.isRequired,
  setShowExpireModal: PropTypes.func.isRequired,
  handleExpire: PropTypes.func.isRequired,
};

export default function ExpireModal({
  showExpireModal,
  setShowExpireModal,
  handleExpire,
}) {
  const schema = Yup.object().shape({});
  const defaultValue = false;

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      send_to_guide: defaultValue,
    },
  });

  const [enabled, setEnabled] = useState(defaultValue);

  function handleToggle(val) {
    setValue("send_to_guide", val);
    setEnabled(val);
  }

  return (
    <Modal open={showExpireModal} setOpen={setShowExpireModal}>
      <ModalTitle>Expire reservation</ModalTitle>
      <form onSubmit={handleSubmit(handleExpire)} noValidate>
        <HiddenField name="send_to_guide" register={register} />
        <ModalBody>
          <Toggle
            enabled={enabled}
            label="Send expired reservation email to guide"
            description="Tell the guide it's no longer necessary to hold space for this approved reservation."
            handleToggle={handleToggle}
          />
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={() => setShowExpireModal(false)}>
            Cancel
          </Button>
          <Button type="submit" isSubmitting={isSubmitting}>
            Expire
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
