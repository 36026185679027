export function mergeArrays(arr1, arr2) {
  if (arr1 && !arr2) return arr1.slice();
  if (arr1 && arr2) {
    let merged = [];
    let index1 = 0;
    let index2 = 0;
    let current = 0;

    while (current < arr1.length + arr2.length) {
      let isArr1Depleted = index1 >= arr1.length;
      let isArr2Depleted = index2 >= arr2.length;
      if (
        !isArr1Depleted &&
        (isArr2Depleted ||
          Date.parse(arr1[index1].created_at) <
            Date.parse(arr2[index2].created_at))
      ) {
        merged[current] = arr1[index1];
        index1++;
      } else {
        merged[current] = arr2[index2];
        index2++;
      }
      current++;
    }
    return merged;
  }
  return [];
}
