import React from "react";
import PropTypes from "prop-types";

FormSection.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function FormSection({ children }) {
  return <div className="py-10">{children}</div>;
}
