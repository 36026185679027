import React from "react";
import PropTypes from "prop-types";

TableRow.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.node,
};

export default function TableRow({ children, className }) {
  return <tr className={className}>{children}</tr>;
}
