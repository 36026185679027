import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/solid";

ListFilter.propTypes = {
  filterValue: PropTypes.string.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  allFilterValues: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function ListFilter({
  filterValue,
  handleFilterChange,
  allFilterValues,
}) {
  return (
    <div className="pr-6 mr-6 border-r border-gray-300">
      <Listbox value={{ name: filterValue }} onChange={handleFilterChange}>
        <div className="relative">
          <Listbox.Button className="relative inline-flex flex-row items-center pl-1 text-lg font-medium text-gray-900 cursor-pointer focus:outline-none">
            <span className="inline">{filterValue}</span>
            <span className="inline-flex pt-0.5 pl-1">
              <ChevronDownIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute w-40 py-1 mt-1 overflow-auto font-medium bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {allFilterValues.map((filter, filterIdx) => (
                <Listbox.Option
                  key={filterIdx}
                  className={({ active }) =>
                    `${
                      active && "text-gray-900 bg-gray-100"
                    } select-none relative py-2 pl-10 pr-4 cursor-pointer`
                  }
                  value={{ name: filter }}
                >
                  <span className="block truncate">{filter}</span>
                  {filterValue === filter ? (
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <CheckIcon className="w-5 h-5" aria-hidden="true" />
                    </span>
                  ) : null}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
