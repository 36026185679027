import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

TableHead.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.node,
};

export default function TableHead({ children, className }) {
  return (
    <thead className={cn("border-b", className)}>
      {children}
    </thead>
  );
}
