import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalBody from "lunar/ModalBody";
import ModalFooter from "lunar/ModalFooter";
import Button from "lunar/Button";
import Alert from "lunar/Alert";
import InputTextArea from "lunar/InputTextArea";

CreatePostTravelerModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  handleSaveMessage: PropTypes.func.isRequired,
};

export default function CreatePostTravelerModal({
  showModal,
  setShowModal,
  handleSaveMessage,
}) {
  const schema = Yup.object().shape({
    content: Yup.string().required("Required"),
  });

  const {
    register,
    handleSubmit,
    errors,
    reset,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (showModal) reset();
  }, [showModal]);

  return (
    <Modal open={showModal} setOpen={setShowModal}>
      <ModalTitle>Create a traveler message</ModalTitle>
      <form onSubmit={handleSubmit(handleSaveMessage)} noValidate>
        <ModalBody>
          <Alert
            type="warning"
            // title="Be careful"
            body="Travelers will be able to see this message, but only when logged in and viewing their inbox."
          />
          <InputTextArea
            name="content"
            label="Message"
            errors={errors}
            register={register}
            autoFocus
            className="mt-6"
            minRows={8}
            inputClassName="h-44"
            cacheMeasurements
          />
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={isSubmitting || isSubmitSuccessful}
            isSubmitting={isSubmitting}
          >
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
