import React from "react";
import Main from "components/common/Main";
import ChangePassword from "components/passwords/Change";
import ChangeAvatar from "components/profile/ChangeAvatar";

export default function EditProfile() {
  return (
    <Main>
      <ChangePassword />
      <ChangeAvatar />
    </Main>
  );
}
