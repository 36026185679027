/* eslint-disable react/display-name, react/prop-types */

import React, { useState, useEffect } from "react";
import cn from "classnames";
import { getTrips } from "lib/api";
import { useLoadingContext } from "context/loading-context";
import { Link } from "react-router-dom";
import { minutesToHours } from "date-fns";
import pluralize from "pluralize";
import { ErrorAlert } from "components/common/Alerts";
import PageListNav from "components/common/PageListNav";
import DataTable from "lunar/DataTable";
import DataTableSearch from "lunar/DataTableSearch";
import NewModal from "components/trips/NewModal";
import ListStat from "components/common/ListStat";
import Meta from "lunar/Meta";

export default function Trips() {
  const { setLoading } = useLoadingContext();
  const [trips, setTrips] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    (async () => {
      populatePageTrips();
    })();
  }, []);

  async function populatePageTrips() {
    setLoading(true);
    const response = await getTrips();
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      setTrips(response.sort((a, b) => (a.name > b.name ? 1 : -1)));
    }
    setLoading(false);
  }

  const columns = React.useMemo(() => [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "",
      accessor: "visibility",
      Cell: ({ row: { original } }) => {
        return (
          <div
            className={cn(
              "inline-flex w-2.5 h-2.5 rounded-full",
              original.visibility === "Live" ? "bg-green-400" : "bg-yellow-400"
            )}
          />
        );
      },
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row: { original } }) => {
        return <span className="text-gray-900">{original.name}</span>;
      },
    },
    {
      Header: "Country",
      accessor: "country",
      Cell: ({ row: { original } }) => {
        return <span className="text-gray-500">{original.country}</span>;
      },
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: ({ row: { original } }) => {
        return (
          <span className="text-gray-500">{original.type?.charAt(0)}</span>
        );
      },
    },
    {
      Header: "Resp. time",
      accessor: "response",
      Cell: ({ row: { original } }) => {
        return (
          <>
            {original.response && (
              <span className="text-gray-500">
                {pluralize("hrs", minutesToHours(original.response), true)}
              </span>
            )}
          </>
        );
      },
    },
    {
      Header: "Guide",
      accessor: "operator",
      Cell: ({ row: { original } }) => {
        return (
          <Link
            className="underline hover:no-underline"
            to={`/operators/edit/${original.operatorSlug}`}
          >
            {original.operator}
          </Link>
        );
      },
    },
    {
      Header: "",
      accessor: "actions",
      Cell: ({ row: { original } }) => {
        return (
          <>
            <Link
              className="text-blue-600 hover:text-blue-900 "
              to={`/trips/edit/${original.id}`}
            >
              Edit
            </Link>
            <a
              className="ml-3 text-gray-600 hover:text-gray-900"
              href={`${process.env.REACT_APP_WEBSITE_URL}/api/preview?secret=${process.env.REACT_APP_PREVIEW_SECRET}&type=trip&slug=${original.slug}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View
            </a>
          </>
        );
      },
    },
  ]);

  return (
    <>
      <Meta title={trips && `All ${trips.length} trips`} />
      <div className="min-h-screen overflow-y-scroll">
        <PageListNav
          dataTableSearch={
            <DataTableSearch
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              placeholder="Search for trips"
            />
          }
          actions={
            <div className="grid items-center grid-flow-col gap-6">
              {trips && (
                <>
                  <ListStat
                    color="green"
                    count={trips?.filter((trip) => trip.is_visible).length}
                    text="Live"
                  />
                  <ListStat
                    color="yellow"
                    count={trips?.filter((trip) => !trip.is_visible).length}
                    text="In progress"
                  />
                  <ListStat color="gray" count={trips?.length} text="Total" />
                </>
              )}
              <div className="ml-4">
                <NewModal populatePageTrips={populatePageTrips} />
              </div>
            </div>
          }
        />

        <ErrorAlert message={errorMessage} />
        {trips && (
          <DataTable
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            data={trips.map((trip) => ({
              visibility: trip.is_visible ? "Live" : "In progress",
              id: trip.id,
              name: trip.name,
              country: trip.country,
              response: trip.avg_response_time_in_minutes,
              type: trip.trip_type_description,
              operator: trip.operator_name,
              operatorSlug: trip.operator_slug,
              operatorVisible: trip.operator_is_visible,
              actions: trip.id,
              slug: trip.slug,
            }))}
            columns={columns}
            sortOptions={{ id: "id", desc: true }}
            hiddenColumns={["id"]}
          />
        )}
      </div>
    </>
  );
}
