import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "lunar/Button";
import {
  createEventHighlight,
  updateEventHighlight,
  deleteEventHighlight,
} from "lib/api";
import { formatImageURL } from "helpers/formatting";
import CreateUpdateHighlightModal from "./CreateUpdateHighlightModal";
import Table from "lunar/Table";
import TableHead from "lunar/TableHead";
import TableRow from "lunar/TableRow";
import TableCell from "lunar/TableCell";
import TableBody from "lunar/TableBody";
import TableActions from "lunar/TableActions";

HighlightsTable.propTypes = {
  highlights: PropTypes.array,
  eventID: PropTypes.string,
  handleError: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
};

export default function HighlightsTable({
  highlights = [],
  eventID = "",
  handleError,
  handleSuccess,
}) {
  const [showModal, setShowModal] = useState(false);
  const [highlightToEdit, setHighlightToEdit] = useState(null);

  async function handleDeleteHighlight(id) {
    const response = await deleteEventHighlight(id);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Highlight deleted");
    }
    setShowModal(false);
  }

  function handleEditHighlight(eventHighlight) {
    setHighlightToEdit(eventHighlight);
    setShowModal(true);
  }

  async function handleSaveHighlight(data) {
    let response;
    if (data.id !== "") {
      response = await updateEventHighlight(data.id, data);
    } else {
      response = await createEventHighlight(data);
    }
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Highlight saved");
    }
    setShowModal(false);
  }

  function handleAdd() {
    setHighlightToEdit(null);
    setShowModal(true);
  }

  return (
    <>
      {highlights && (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell head first>
                  Image
                </TableCell>
                <TableCell head>Title</TableCell>
                <TableCell head>Description</TableCell>
                <TableCell head last>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {highlights.map((highlight) => (
                <TableRow key={highlight.id}>
                  <TableCell first>
                    {highlight.image && (
                      <a
                        href={formatImageURL(highlight.image.url)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={formatImageURL(highlight.image.url, {
                            resize: { height: 50 },
                          })}
                          style={{ maxHeight: 50 }}
                          className="img-fluid"
                          alt={highlight.name}
                        />
                      </a>
                    )}
                  </TableCell>
                  <TableCell>{highlight.title}</TableCell>
                  <TableCell wrap>{highlight.description}</TableCell>
                  <TableCell last>
                    <button
                      type="button"
                      onClick={() => handleDeleteHighlight(highlight.id)}
                      className="text-gray-600 hover:underline"
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      onClick={() => handleEditHighlight(highlight)}
                      className="ml-4 text-blue-600 hover:text-blue-900"
                    >
                      Edit
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TableActions>
            <Button variant="secondary" onClick={handleAdd}>
              Add highlight
            </Button>
          </TableActions>
          {showModal && (
            <CreateUpdateHighlightModal
              eventID={eventID}
              handleSaveHighlight={handleSaveHighlight}
              setShowModal={setShowModal}
              showModal={showModal}
              highlight={highlightToEdit}
              numHighlights={
                highlightToEdit ? highlights.length : highlights.length + 1
              }
            />
          )}
        </>
      )}
    </>
  );
}
