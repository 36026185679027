import React from "react";
import PropTypes from "prop-types";
import { humanDate } from "helpers/formatting";
import { timeAgo } from "helpers/formatting";
import { Link } from "react-router-dom";
import Avatar from "lunar/Avatar";

Details.propTypes = {
  thread: PropTypes.shape({
    id: PropTypes.string,
    user_first_name: PropTypes.string,
    user_last_name: PropTypes.string,
    user_created_at: PropTypes.string,
    user_id: PropTypes.number,
    operator_name: PropTypes.string,
    operator_slug: PropTypes.string,
    last_trip: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
    last_booking: PropTypes.shape({
      trip_name: PropTypes.string,
      created_at: PropTypes.string,
      transaction_number: PropTypes.string,
    }),
  }),
};

export default function Details({ thread }) {
  return (
    <div className="w-64">
      {thread && (
        <>
          <div className="p-10 text-center">
            <Link to={`/users/${thread.user_id}`}>
              <Avatar
                name={`${thread.user_first_name} ${thread.user_last_name}`}
                size="lg"
              />
            </Link>
            <div className="mt-3">
              <Link to={`/users/${thread.user_id}`}>
                <div className="font-bold">{`${thread.user_first_name} ${thread.user_last_name}`}</div>
              </Link>
              <div className="text-xs font-medium text-gray-600">
                Joined {humanDate(thread.user_created_at)}
              </div>
            </div>
          </div>
          <div className="grid gap-4 p-6 border-t border-gray-200 grid-auto-rows">
            <div>
              <div className="text-sm font-bold text-gray-600">Guide</div>
              <a
                className="text-sm font-medium text-blue-500 hover:underline"
                href={`/operators/edit/${thread.operator_slug}`}
              >
                {thread.operator_name}
              </a>
            </div>
            <div>
              <div className="text-sm font-bold text-gray-600">
                Last related trip
              </div>
              {thread.last_trip && (
                <a
                  className="text-sm font-medium text-blue-500 hover:underline"
                  href={`/trips/edit/${thread.last_trip.slug}`}
                >
                  {thread.last_trip.name}
                </a>
              )}
            </div>
          </div>
          <div className="grid gap-4 p-6 border-t border-gray-200 grid-auto-rows">
            <div>
              <div className="text-sm font-bold text-gray-600">
                Related reservations
              </div>
              <ul className="mt-1">
                {thread.last_booking ? (
                  <li className="grid grid-flow-col gap-4 mt-1 auto-cols-auto">
                    <a
                      className="block text-sm font-medium text-blue-500 hover:underline"
                      href={`/bookings/${thread.last_booking.transaction_number}`}
                    >
                      {thread.last_booking.trip_name}
                    </a>
                    <div className="text-sm font-medium text-right text-gray-600">
                      {timeAgo(thread.last_booking.created_at)}
                    </div>
                  </li>
                ) : (
                  <div className="text-sm italic font-medium text-gray-500">
                    None
                  </div>
                )}
              </ul>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
