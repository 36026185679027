import Messages from "components/messages/Index";
import BookingsList from "components/bookings/List";
import BookingsView from "components/bookings/View";
import TripsList from "components/trips/List";
import TripsEdit from "components/trips/Edit";
import UsersList from "components/users/List";
import UsersView from "components/users/View";
import PromotionsList from "components/promotions/List";
import AdminsList from "components/admins/List";
import OperatorsList from "components/operators/List";
import OperatorsEdit from "components/operators/Edit";
import AccommodationsList from "components/accommodations/List";
import AccommodationsEdit from "components/accommodations/Edit";
import ProductsEdit from "components/products/Edit";
import EditProfile from "components/profile/Edit";
import Regions from "components/regions/Index";
import ReviewsList from "components/reviews/List";
import ReviewsEdit from "components/reviews/Edit";
import Scouts from "components/scouts/Index";
import CollectionsList from "components/collections/List";
import CollectionsEdit from "components/collections/Edit";
import Logout from "components/logout/Show";
import TwoFactor from "components/twofactor/Show";
import Login from "components/login/Show";
import ForgotPassword from "components/passwords/Forgot";
import ResetPassword from "components/passwords/Reset";
import AdventureBookingsList from "components/adventure_bookings/List";
import AdventureBookingsView from "components/adventure_bookings/View";
import AdventuresList from "components/adventures/List";
import AdventuresEdit from "components/adventures/Edit";
import GuidesList from "components/guides/List";
import GuidesEdit from "components/guides/Edit";
import AdventureRequestsList from "components/adventure_requests/List";
import AdventureReviewsList from "components/adventure_reviews/List";
import AdventureReviewsEdit from "components/adventure_reviews/Edit";
import GuideCalendarsShow from "components/guide_calendars/Show";
import EventBookingsView from "components/event_bookings/View";
import EventsList from "components/events/List";
import EventsEdit from "components/events/Edit";

const routes = [
  {
    path: "/bookings",
    component: BookingsList,
    authorized: true,
  },
  {
    path: "/bookings/:id",
    component: BookingsView,
    authorized: true,
  },
  {
    path: "/messages",
    component: Messages,
    authorized: true,
  },
  {
    path: "/messages/:id",
    component: Messages,
    authorized: true,
  },
  {
    path: "/trips",
    component: TripsList,
    authorized: true,
  },
  {
    path: "/trips/edit/:id",
    component: TripsEdit,
    authorized: true,
  },
  {
    path: "/operators",
    component: OperatorsList,
    authorized: true,
  },
  {
    path: "/operators/edit/:id",
    component: OperatorsEdit,
    authorized: true,
  },
  {
    path: "/accommodations",
    component: AccommodationsList,
    authorized: true,
  },
  {
    path: "/accommodations/edit/:id",
    component: AccommodationsEdit,
    authorized: true,
  },
  {
    path: "/products/edit/:id",
    component: ProductsEdit,
    authorized: true,
  },
  {
    path: "/users",
    component: UsersList,
    authorized: true,
  },
  {
    path: "/users/:id",
    component: UsersView,
    authorized: true,
  },
  {
    path: "/admins",
    component: AdminsList,
    authorized: true,
  },
  {
    path: "/promotions",
    component: PromotionsList,
    authorized: true,
  },
  {
    path: "/regions",
    component: Regions,
    authorized: true,
  },
  {
    path: "/regions/:id",
    component: Regions,
    authorized: true,
  },
  {
    path: "/reviews",
    component: ReviewsList,
    authorized: true,
  },
  {
    path: "/reviews/edit/:id",
    component: ReviewsEdit,
    authorized: true,
  },
  {
    path: "/adventure_reviews",
    component: AdventureReviewsList,
    authorized: true,
  },
  {
    path: "/adventure_reviews/edit/:id",
    component: AdventureReviewsEdit,
    authorized: true,
  },
  {
    path: "/scouts",
    component: Scouts,
    authorized: true,
  },
  {
    path: "/collections",
    component: CollectionsList,
    authorized: true,
  },
  {
    path: "/collections/edit/:id",
    component: CollectionsEdit,
    authorized: true,
  },
  {
    path: "/edit-profile",
    component: EditProfile,
    authorized: true,
  },
  {
    path: "/logout",
    component: Logout,
    authorized: true,
  },
  {
    path: "/login",
    component: Login,
    authorized: false,
  },
  {
    path: "/two-factor",
    component: TwoFactor,
    authorized: false,
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    authorized: false,
  },
  {
    path: "/reset-password",
    component: ResetPassword,
    authorized: false,
  },
  {
    path: "/adventure_bookings",
    component: AdventureBookingsList,
    authorized: true,
  },
  {
    path: "/adventure_bookings/:id",
    component: AdventureBookingsView,
    authorized: true,
  },
  {
    path: "/adventures",
    component: AdventuresList,
    authorized: true,
  },
  {
    path: "/adventures/edit/:id",
    component: AdventuresEdit,
    authorized: true,
  },
  {
    path: "/guides",
    component: GuidesList,
    authorized: true,
  },
  {
    path: "/guides/edit/:id",
    component: GuidesEdit,
    authorized: true,
  },
  {
    path: "/adventure_requests",
    component: AdventureRequestsList,
    authorized: true,
  },
  {
    path: "/guide_calendars/:id",
    component: GuideCalendarsShow,
    authorized: true,
  },
  {
    path: "/event_bookings/:id",
    component: EventBookingsView,
    authorized: true,
  },
  {
    path: "/events",
    component: EventsList,
    authorized: true,
  },
  {
    path: "/events/edit/:id",
    component: EventsEdit,
    authorized: true,
  },
];

export default routes;
