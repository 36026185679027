import React, { useState, useRef } from "react";
import classNames from "classnames";
import useDetectOutsideClick from "helpers/useDetectOutsideClick";
import { NavLink } from "react-router-dom";
import { EllipsisHorizontalCircleIcon } from "@heroicons/react/24/outline";

export default function NavbarMenu() {
  const buttonRef = useRef(null);
  const menuRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  useDetectOutsideClick(buttonRef, menuRef, () => setIsOpen(false));

  return (
    <div className="relative p-1">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        ref={buttonRef}
        className="hover:text-blue-600"
      >
        <EllipsisHorizontalCircleIcon className="w-6 h-6" />
      </button>
      <div
        ref={menuRef}
        className={classNames(
          isOpen ? "absolute" : "hidden",
          "z-20 w-56 p-5 rounded-2xl bg-white font-medium drop-shadow-md grid grid-cols-1 gap-y-1.5 left-9 top-0 text-sm"
        )}
      >
        <div className="text-xs font-semibold leading-6 text-gray-400">
          Stays
        </div>
        <NavLink reloadDocument to="/messages" className="hover:text-blue-600">
          Messages
        </NavLink>
        <NavLink reloadDocument to="/bookings" className="hover:text-blue-600">
          Reservations
        </NavLink>
        <NavLink reloadDocument to="/users" className="hover:text-blue-600">
          Travelers
        </NavLink>
        <NavLink reloadDocument to="/trips" className="hover:text-blue-600">
          Listings
        </NavLink>
        <NavLink reloadDocument to="/operators" className="hover:text-blue-600">
          Hosts
        </NavLink>
        <hr className="mt-2 mb-2" />
        <NavLink
          reloadDocument
          to="/promotions"
          className="hover:text-blue-600"
        >
          Promotions
        </NavLink>
        <NavLink reloadDocument to="/reviews" className="hover:text-blue-600">
          Reviews
        </NavLink>
        <NavLink
          reloadDocument
          to="/collections"
          className="hover:text-blue-600"
        >
          Collections
        </NavLink>
        <NavLink reloadDocument to="/regions" className="hover:text-blue-600">
          Regions
        </NavLink>
      </div>
    </div>
  );
}
