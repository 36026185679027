import React, { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { useParams } from "react-router-dom";
import { getBooking } from "lib/api";
import { useLoadingContext } from "context/loading-context";
import Header from "./sections/Header";
import Summary from "./sections/Summary";
import Items from "./sections/Items";
import Activity from "./sections/Activity";
import Payments from "./sections/Payments";
import Notes from "./sections/Notes";
import Meta from "lunar/Meta";

export default function Bookings() {
  const [booking, setBooking] = useState({});
  const bookingID = useParams().id;
  const { addToast } = useToasts();
  const { setLoading } = useLoadingContext();

  useEffect(() => {
    (async () => {
      populateBookingState(bookingID);
    })();
  }, [bookingID]);

  async function populateBookingState(bookingID) {
    setLoading(true);
    const response = await getBooking(bookingID);
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      setBooking(response);
    }
    setLoading(false);
  }

  function setErrorMessage(msg) {
    addToast(msg, { appearance: "error" });
  }

  function handleSaveSuccess(msg) {
    populateBookingState(bookingID);
    addToast(msg, { appearance: "success" });
  }

  return (
    <div className="">
      <Meta title={booking && `Reservation #${booking.confirmation_code}`} />
      {booking?.user && (
        <div className="px-20 py-10 mx-auto max-w-screen-2xl">
          <Header
            booking={booking}
            handleError={setErrorMessage}
            handleSuccess={handleSaveSuccess}
          />
          <div className="flex flex-row pt-10 mt-10 border-t">
            <div className="max-w-xs">
              <Summary
                booking={booking}
                handleError={setErrorMessage}
                handleSuccess={handleSaveSuccess}
              />
            </div>
            <div className="grid flex-grow gap-10 pl-10 ml-10 border-l divide-y auto-flow-row">
              <div>
                <h2 className="mb-3 text-xl font-medium">Notes</h2>
                <Notes
                  notes={booking.notes}
                  handleError={setErrorMessage}
                  handleSuccess={handleSaveSuccess}
                  bookingID={booking.id}
                />
              </div>
              <div className="pt-10">
                <h2 className="mb-2 text-xl font-medium">Pricing</h2>
                <Items
                  booking={booking}
                  handleItemsError={setErrorMessage}
                  handleItemsSuccess={handleSaveSuccess}
                />
              </div>
              {booking.payments.length > 0 && (
                <div className="pt-10">
                  <h2 className="mb-4 text-xl font-medium">Payments</h2>
                  <Payments
                    payments={booking.payments}
                    handleError={setErrorMessage}
                    handleSuccess={handleSaveSuccess}
                  />
                </div>
              )}
              <div className="pt-10">
                <h2 className="mb-4 text-xl font-medium">Activity</h2>
                <Activity booking={booking} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
