/* eslint-disable react/display-name, react/prop-types */
import React, { useState, useEffect } from "react";
import cn from "classnames";
import { getScouts, expireScoutSpot } from "lib/api";
import { useLoadingContext } from "context/loading-context";
import { longDateHoursMinutes } from "helpers/formatting";
import { useToasts } from "react-toast-notifications";
import { Link } from "react-router-dom";
import PageListNav from "components/common/PageListNav";
import DataTable from "lunar/DataTable";
import DataTableSearch from "lunar/DataTableSearch";
import Meta from "lunar/Meta";

export default function Scouts() {
  const { setLoading } = useLoadingContext();
  const [scouts, setScouts] = useState(null);
  const { addToast } = useToasts();
  const [globalFilter, setGlobalFilter] = useState("");

  useEffect(() => {
    (async () => {
      populateScouts();
    })();
  }, []);

  async function populateScouts() {
    setLoading(true);
    const response = await getScouts();
    if (response.errorMsg) {
      addToast(response.errorMsg, { appearance: "error" });
    } else {
      setScouts(response);
    }
    setLoading(false);
  }

  async function expireScout(id) {
    if (window.confirm("Are you sure you want to expire this scout?")) {
      const response = await expireScoutSpot(id);
      if (response.errorMsg) {
        addToast(response.errorMsg, { appearance: "error" });
      } else {
        addToast("Scout successfully expired", { appearance: "success" });
        populateScouts();
      }
    }
  }

  function statusStyle(status) {
    switch (status) {
      case "expired":
        return "bg-gray-200 text-gray-800";
      case "used":
        return "bg-green-100 text-green-800";
      default:
        return "bg-yellow-100 text-yellow-800";
    }
  }

  const columns = React.useMemo(() => [
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row: { original } }) => {
        return (
          <div
            className={cn(
              "inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium capitalize",
              statusStyle(original.status)
            )}
          >
            {original.status}
          </div>
        );
      },
    },
    {
      Header: "Traveler",
      Cell: ({ row: { original } }) => {
        return (
          <Link
            key={`user-${original.user_id}`}
            className="underline hover:no-underline"
            to={`/users/${original.user_id}`}
          >
            {original.user_name}
          </Link>
        );
      },
    },
    {
      Header: "Trip",
      accessor: "trip_name",
    },
    {
      Header: "Reservation",
      Cell: ({ row: { original } }) => {
        return (
          <Link
            key={`booking-${original.booking_confirmation_code}`}
            className="underline hover:no-underline"
            to={`/bookings/${original.booking_transaction_number}`}
          >
            {original.booking_confirmation_code}
          </Link>
        );
      },
    },
    {
      Header: "Created",
      accessor: "created_at",
      Cell: (tableProps) => {
        return <>{longDateHoursMinutes(tableProps.value)}</>;
      },
    },
    {
      Header: "Actions",
      accessor: "actions_id",
      Cell: ({ row: { original } }) => {
        return (
          <>
            {original.status === "open" &&
              original.booking_confirmation_code === null && (
                <button
                  onClick={() => expireScout(original.actions_id)}
                  className="text-blue-600 hover:text-blue-900"
                >
                  Expire
                </button>
              )}
          </>
        );
      },
    },
  ]);

  return (
    <>
      <Meta title={scouts && `All ${scouts.length} scouts`} />
      <div className="min-h-screen overflow-y-scroll">
        <PageListNav
          dataTableSearch={
            <DataTableSearch
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              placeholder="Search for scouts"
            />
          }
        />
        {/* <ErrorAlert message={errorMessage} /> */}
        {scouts && (
          <DataTable
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            data={scouts.map((scout) => ({
              status: scout.status,
              trip_name: scout.trip.name,
              user_name: `${scout.user.first_name} ${scout.user.last_name}`,
              user_id: scout.user.id,
              booking_confirmation_code: scout.booking.confirmation_code,
              booking_transaction_number: scout.booking.transaction_number,
              created_at: scout.created_at,
              actions_id: scout.id,
            }))}
            columns={columns}
            sortOptions={{ id: "created_at", desc: true }}
          />
        )}
      </div>
    </>
  );
}
