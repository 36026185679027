import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalBody from "lunar/ModalBody";
import ModalFooter from "lunar/ModalFooter";
import Button from "lunar/Button";
import { parse, isValid } from "date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import TextField from "components/common/TextField";
import HiddenField from "components/common/HiddenField";
import WeekdayWeekendTableRow from "components/products/WeekdayWeekendTableRow";
import TextInputPrice from "components/common/TextInputPrice";

FlexibleVariantModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  handleSavePricing: PropTypes.func.isRequired,
  productID: PropTypes.string,
  pricingType: PropTypes.string.isRequired,
  pricing: PropTypes.object,
};

export default function FlexibleVariantModal({
  showModal,
  setShowModal,
  handleSavePricing,
  productID = "",
  pricingType,
  pricing = {},
}) {
  const schema = Yup.object().shape({
    // TODO: validation for price, price_per_num_guests, weekend_price, weekend_price_per_num_guests
    // price: Yup.number()
    //  .typeError("Number required")
    //  .positive("Positive decimal required")
    //  .required("Required"),
    // weekend_price: Yup.number()
    //  .typeError("Number required")
    //  .positive("Positive decimal required")
    //  .required("Required"),
    start_date: Yup.string()
      .required("Required")
      .test("is-date", "Valid date required", (value) =>
        isValid(parse(value, "yyyy-MM-dd", new Date()))
      ),
    end_date: Yup.string()
      .required("Required")
      .test("is-date", "Valid date required", (value) =>
        isValid(parse(value, "yyyy-MM-dd", new Date()))
      ),
  });

  const {
    reset,
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (pricing) {
      const minNightAdjustment = pricingType.includes("day") ? 1 : 0;
      reset({
        price: pricing.price,
        weekend_price: pricing.weekendPrice,
        price_per_num_guests: pricing.pricePerNumGuests,
        weekend_price_per_num_guests: pricing.weekendPricePerNumGuests,
        min_night_stay: pricing.minNightStay + minNightAdjustment,
        weekend_min_night_stay:
          pricing.weekendMinNightStay + minNightAdjustment,
        start_date: pricing.startDate,
        end_date: pricing.endDate,
        product_id: productID,
      });
    } else {
      reset({
        price: "",
        weekend_price: "",
        start_date: "",
        end_date: "",
        product_id: productID,
      });
    }
  }, [reset, pricing]);

  return (
    <Modal open={showModal} setOpen={setShowModal}>
      <ModalTitle>Create pricing</ModalTitle>
      <form onSubmit={handleSubmit(handleSavePricing)} noValidate>
        <ModalBody>
          <HiddenField name="product_id" register={register} />
          <div className="grid grid-cols-2 gap-x-6 ">
            <div>
              <TextField
                name="start_date"
                type="date"
                label="Start date"
                placeholder="yyyy-mm-dd"
                errors={errors}
                register={register}
              />
            </div>
            <div>
              <TextField
                name="end_date"
                type="date"
                label="End date"
                placeholder="yyyy-mm-dd"
                errors={errors}
                register={register}
              />
            </div>
          </div>
          <hr />
          <div className="mt-6">
            <h2 className="text-sm font-medium text-gray-900">
              {`Min ${pricingType.includes("day") ? "days" : "night stay"}`}
            </h2>
            <p className="mt-1 mb-5 text-sm text-gray-500">
              Given a start date, the minimum number of{" "}
              {pricingType.includes("day") ? "days" : "nights"} the reservation
              must last.
            </p>
          </div>
          <div className="grid grid-cols-2 gap-x-6 ">
            <div>
              <TextField
                name="min_night_stay"
                label="Weekday min."
                type="text"
                errors={errors}
                register={register}
              />
            </div>
            <div>
              <TextField
                name="weekend_min_night_stay"
                label="Weekend min."
                type="text"
                errors={errors}
                register={register}
              />
            </div>
          </div>
          <hr />
          <div className="mt-6">
            <h2 className="text-sm font-medium text-gray-900">
              {`Per ${
                pricingType.includes("per_person") ? "traveler per" : ""
              } ${pricingType.includes("day") ? "day" : "night"} pricing`}
            </h2>
            <p className="mt-1 mb-5 text-sm text-gray-500">
              {pricingType.includes("per_person")
                ? `The base price per traveler per ${
                    pricingType.includes("day") ? "day" : "night"
                  } for adults and children, not infants.`
                : `The ${
                    pricingType.includes("day") ? "daily" : "nightly"
                  } rates for room, route, etc.`}
            </p>
            <div className="grid grid-cols-2 gap-x-6 ">
              <div>
                <TextInputPrice
                  name="price"
                  label="Weekday"
                  errors={errors}
                  register={register}
                />
              </div>
              <div>
                <TextInputPrice
                  name="weekend_price"
                  label="Weekend"
                  errors={errors}
                  register={register}
                />
              </div>
            </div>
          </div>
          {(pricingType === "per_person_per_night" ||
            pricingType === "per_person_per_day") && (
            <>
              <hr />
              <div className="mt-6">
                <h2 className="text-sm font-medium text-gray-900">
                  Volume discount pricing
                </h2>
                <p className="mt-1 mb-5 text-sm text-gray-500">
                  Enter pricing for multiple travelers only if there is a
                  discount. Use the total amount for all travelers. E.g. $300
                  for 3 travelers
                </p>
              </div>
              <div className="overflow-hidden border-b border-l border-r border-gray-200 rounded-md shadow-sm">
                <table className="min-w-full">
                  <tbody>
                    {[...Array(15).keys()].map((idx) => (
                      <WeekdayWeekendTableRow
                        key={`price-group-${idx}`}
                        count={idx + 1}
                        register={register}
                        weekdayName={`price_per_num_guests[${idx}]`}
                        weekendName={`weekend_price_per_num_guests[${idx}]`}
                        errors={errors}
                        hidden={idx === 0}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button type="submit" isSubmitting={isSubmitting}>
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
