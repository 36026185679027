import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

TableActions.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.node,
};

export default function TableActions({ children, className }) {
  return (
    <div className={cn("pt-5 text-right border-t", className)}>{children}</div>
  );
}
