import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

Meta.propTypes = {
  title: PropTypes.string,
};

export default function Meta({ title = "" }) {
  return (
    <Helmet>
      <title>{title ? `${title} — Bonzai` : "Bonzai"}</title>
    </Helmet>
  );
}
