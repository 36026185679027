import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Link } from "react-router-dom";
import Button from "lunar/Button";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";

DataTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  sortOptions: PropTypes.object,
  preGlobalFilteredRows: PropTypes.object,
  globalFilter: PropTypes.string,
  testFunction: PropTypes.func,
  hiddenColumns: PropTypes.arrayOf(PropTypes.object),
  rowLinkAttribute: PropTypes.string,
};

const defaultSortOptions = {};

export default function DataTable({
  columns = [],
  data = [],
  sortOptions = defaultSortOptions,
  globalFilter,
  hiddenColumns = [],
  rowLinkAttribute = "",
}) {
  const numEntriesPerPage = 50;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    // pageOptions,
    // pageCount,
    // gotoPage,
    nextPage,
    previousPage,
    // setPageSize,
    state: { pageIndex },
    // state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [sortOptions],
        hiddenColumns,
        pageSize: numEntriesPerPage,
        pageIndex: 0,
      },
      useControlledState: (state) => {
        return React.useMemo(
          () => ({
            ...state,
            globalFilter,
          }),
          [state, globalFilter]
        );
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div className="relative w-full pl-5 pr-5 mx-auto mb-10 max-w-7xl">
      <table className="w-full h-full" {...getTableProps()}>
        <thead className="border-b-2">
          {headerGroups.map((headerGroup, idx) => (
            <tr
              key={`group-${idx}`}
              {...headerGroup.getHeaderGroupProps()}
              className="w-full"
            >
              {headerGroup.headers.map((column, idy) => (
                <th
                  key={`column-${idx}-${idy}`}
                  className={cn(
                    "h-4 px-4 pt-6 pb-4 text-sm font-semibold tracking-wider text-center text-gray-900 whitespace-nowrap"
                  )}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <div className="flex">
                    <span>{column.render("Header")}</span>
                    <span className="flex-none ml-2 text-gray-900 bg-gray-100 rounded group-hover:bg-gray-200">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ChevronUpIcon className="w-5 h-5" />
                        ) : (
                          <ChevronDownIcon className="w-5 h-5" />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          className="h-full divide-y divide-gray-200"
          {...getTableBodyProps()}
        >
          {page.map((row, idx) => {
            prepareRow(row);
            return (
              <tr
                key={`row-${idx}`}
                {...row.getRowProps()}
                className={cn(
                  "w-full h-full",
                  rowLinkAttribute && "hover:bg-gray-50"
                )}
              >
                {row.cells.map((cell, idy) => {
                  return (
                    <td
                      key={`cell-${idx}-${idy}`}
                      {...cell.getCellProps()}
                      className="z-0 p-0"
                    >
                      {rowLinkAttribute ? (
                        <Link
                          to={`${row.original[rowLinkAttribute]}`}
                          className="flex items-center h-full px-4 py-4 text-sm text-gray-500"
                        >
                          <div className="block w-full">
                            {cell.render("Cell")}
                          </div>
                        </Link>
                      ) : (
                        <div className="block h-full px-4 py-4 text-sm text-gray-500">
                          {cell.render("Cell")}
                        </div>
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <nav
        className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
        aria-label="Pagination"
      >
        <div className="hidden sm:block">
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{pageIndex * numEntriesPerPage + 1}</span> to{" "}
            <span className="font-medium">
              {Math.min((pageIndex + 1) * numEntriesPerPage, rows.length)}
            </span>{" "}
            of <span className="font-medium">{rows.length}</span> results
          </p>
        </div>
        <div className="flex flex-1 justify-between sm:justify-end">
          <Button
            variant="secondary"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Previous
          </Button>
          <Button
            variant="secondary"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Next
          </Button>
        </div>
      </nav>
      {/*
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[25, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      */}
    </div>
  );
}
