import React from "react";
import cn from "classnames";
import pluralize from "pluralize";
import PropTypes from "prop-types";

WeekdayWeekendTableRow.propTypes = {
  count: PropTypes.number.isRequired,
  weekdayName: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  weekendName: PropTypes.string.isRequired,
  errors: PropTypes.object,
  hidden: PropTypes.bool.isRequired,
};

export default function WeekdayWeekendTableRow({
  count,
  weekdayName,
  register,
  weekendName,
  errors,
  hidden,
}) {
  return (
    <>
      <tr
        className={cn(
          "divide-x divide-gray-200 border-t border-gray-200",
          hidden && "hidden border-t-0"
        )}
      >
        <td className="bg-gray-50 text-gray-500 text-sm">
          <div className="px-3">{pluralize("traveler", count, true)}</div>
        </td>
        <td>
          <div>
            <label
              htmlFor={weekdayName}
              className="text-sm font-medium text-gray-700 hidden"
            >
              Weekday
            </label>
            <input
              type="text"
              name={weekdayName}
              id={weekdayName}
              {...register(weekdayName)}
              className="w-full px-3 bg-white focus:ring-blue-500 focus:border-blue-500 relative rounded-none bg-transparent focus:z-10 sm:text-sm border-transparent"
              placeholder="Weekday"
            />
          </div>
        </td>
        <td>
          <div>
            <label
              htmlFor={weekendName}
              className="text-sm font-medium text-gray-700 hidden"
            >
              Weekend
            </label>
            <input
              type="text"
              name={weekendName}
              id={weekendName}
              {...register(weekendName)}
              className="w-full px-3 bg-white focus:ring-blue-500 focus:border-blue-500 relative rounded-none bg-transparent focus:z-10 sm:text-sm border-transparent"
              placeholder="Weekend"
            />
          </div>
        </td>
      </tr>
      {errors && errors[weekdayName] && (
        <tr className="text-sm text-red-600">
          <td>{errors[weekdayName].message}</td>
        </tr>
      )}
      {errors && errors[weekendName] && (
        <tr className="text-sm text-red-600">
          <td> {errors[weekendName].message}</td>
        </tr>
      )}
    </>
  );
}
