import React, { useState, Fragment } from "react";
import cn from "classnames";
import TimeAgo from "timeago-react";
import { parseISO, isAfter } from "date-fns";
import { useToasts } from "react-toast-notifications";
import PropTypes from "prop-types";
import {
  sendMessageToGuide,
  sendReminderMessageToGuide,
  updateMessage,
} from "lib/api";
import { humanDateTimeVersionThree } from "helpers/formatting";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import Avatar from "lunar/Avatar";
import DialogActionPanelCenter from "lunar/DialogActionPanelCenter";
import Markdown from "lunar/Markdown";
import ButtonText from "lunar/ButtonText";
import EditModal from "./EditModal";
import ReminderModal from "components/common/ReminderModal";

Post.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.string,
    sender_type: PropTypes.string,
    employee_name: PropTypes.string,
    content: PropTypes.string,
    created_at: PropTypes.string,
  }),
  userName: PropTypes.string,
  setErrorMessage: PropTypes.func.isRequired,
  reloadActiveThread: PropTypes.func.isRequired,
  isLastTravelerMessage: PropTypes.bool,
};

export default function Post({
  message = {},
  userName = "",
  setErrorMessage,
  reloadActiveThread,
  isLastTravelerMessage = false,
}) {
  const { addToast } = useToasts();
  const postFromGuide = message.sender_type === "OperatorEmployee";
  const [showEditModal, setShowEditModal] = useState(false);
  const [showReminderModal, setShowReminderModal] = useState(false);
  const [openSendToGuide, setOpenSendToGuide] = useState(false);
  const handleShowEditModal = () => setShowEditModal(true);
  const handleShowReminderModal = () => setShowReminderModal(true);

  async function handleSendToGuide() {
    const response = await sendMessageToGuide(message.id);
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      addToast("Message sent to guide", { appearance: "success" });
      reloadActiveThread();
    }
  }

  async function handleSendReminder(data) {
    const response = await sendReminderMessageToGuide(message.id, data);
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      addToast("Reminder message sent to guide", { appearance: "success" });
      setShowReminderModal(false);
      reloadActiveThread();
    }
  }

  async function handleSaveMessage(data) {
    const response = await updateMessage(message.id, data);
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      reloadActiveThread();
      setShowEditModal(false);
      addToast("Message edited", { appearance: "success" });
    }
  }

  return (
    <div
      className={cn(
        "flex gap-3 pl-10 pr-10 pt-10",
        postFromGuide ? "flex-row-reverse ml-20" : "mr-20"
      )}
    >
      <div>
        <Avatar
          name={postFromGuide ? message.employee_name : userName}
          size="sm"
        />
      </div>
      <div
        className={cn(
          "p-6 max-w-xl border border-gray-200 rounded-md ",
          postFromGuide && "bg-gray-200"
        )}
      >
        <Markdown className="text-sm">{message.content}</Markdown>
        <div className="grid items-end grid-flow-col gap-4 mt-3 auto-cols-auto">
          {message.sent_to_guide_at ? (
            <div className="flex flex-row items-center">
              {message.is_edited === true && (
                <div className="pr-2 text-sm font-medium text-gray-400">
                  {"Edited"}
                  <span className="pl-2">&bull;</span>
                </div>
              )}
              <div className="text-xs font-medium text-gray-400">
                {"Forwarded on "}
                {humanDateTimeVersionThree(message.sent_to_guide_at)}
                {isLastTravelerMessage && (
                  <span className="pl-1">
                    &bull;
                    <button
                      onClick={handleShowReminderModal}
                      className="pl-1 font-medium text-blue-500 hover:underline"
                    >
                      Send reminder
                    </button>
                  </span>
                )}
              </div>
            </div>
          ) : (
            <>
              {!postFromGuide &&
                message.booking_id === null &&
                isAfter(
                  parseISO(message.created_at),
                  new Date(2021, 5, 16, 12, 0, 0)
                ) && (
                  <>
                    <div className="flex flex-row items-center">
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        {({ open }) => (
                          <>
                            <div>
                              <Menu.Button className="flex items-center text-gray-400 hover:text-gray-600 focus:outline-none">
                                <span className="sr-only">Open options</span>
                                <EllipsisHorizontalIcon
                                  className="w-5 h-5 mr-3"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </div>
                            <Transition
                              show={open}
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items
                                static
                                className="absolute left-0 w-56 mt-2 origin-top-right bg-white divide-y rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                              >
                                <div className="py-1">
                                  <Menu.Item>
                                    <ButtonText
                                      onClick={handleShowEditModal}
                                      className="block w-full px-4 py-2 text-sm text-left focus:ring-blue-500 hover:bg-blue-50"
                                    >
                                      <span>Edit this message</span>
                                    </ButtonText>
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </>
                        )}
                      </Menu>
                      {message.is_edited === true && (
                        <div className="pr-2 text-sm font-medium text-gray-400">
                          {"Edited"}
                          <span className="pl-2">&bull;</span>
                        </div>
                      )}
                      <button
                        onClick={
                          message.operator_alert_header
                            ? () => setOpenSendToGuide(true)
                            : handleSendToGuide
                        }
                        className="block text-sm font-medium text-blue-500 hover:underline"
                      >
                        Forward
                      </button>
                    </div>
                  </>
                )}
            </>
          )}
          <div className="text-sm font-medium text-right text-gray-600">
            <TimeAgo datetime={message.created_at} />
          </div>
        </div>
      </div>
      <EditModal
        handleSaveMessage={handleSaveMessage}
        setShowEditModal={setShowEditModal}
        showEditModal={showEditModal}
        messageContent={message.content}
      />
      <ReminderModal
        handleSendReminder={handleSendReminder}
        setShowReminderModal={setShowReminderModal}
        showReminderModal={showReminderModal}
      />
      {message.operator_alert_header && (
        <DialogActionPanelCenter
          title={message.operator_alert_header}
          description={message.operator_alert_subheader}
          buttonText="Done, send to guide"
          onClick={handleSendToGuide}
          open={openSendToGuide}
          setOpen={setOpenSendToGuide}
        />
      )}
    </div>
  );
}
