import React from "react";
import PropTypes from "prop-types";

FormSectionHeader.propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
};

export default function FormSectionHeader({ title, description }) {
  return (
    <div>
      <div className="text-xl font-medium">{title}</div>
      <div className="text-sm text-gray-500">{description}</div>
    </div>
  );
}
