import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import GalleryInput from "lunar/gallery/GalleryInput";
import GalleryContainer from "lunar/gallery/GalleryContainer.js";

Gallery.propTypes = {
  parentID: PropTypes.string,
  images: PropTypes.array,
  handleError: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  updateFunc: PropTypes.func.isRequired,
  deleteFunc: PropTypes.func.isRequired,
  updatePositionsFunc: PropTypes.func.isRequired,
  imagesAttributeName: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired
};

export default function Gallery({
  parentID = "",
  images = [],
  imagesAttributeName,
  handleError,
  handleSuccess,
  updateFunc,
  deleteFunc,
  updatePositionsFunc,
  control,
  setValue
}) {
  const [mediaImages, setMediaImages] = useState([]);

  useEffect(() => {
    if (images) {
      setMediaImages(images);
    }
  }, [JSON.stringify(images)]);

  function handleFilesAdded(files) {
    const fileObjects = files.map((file) => ({ file }));
    setValue(imagesAttributeName, fileObjects);
  }

  async function updatePositions(images) {
    const response = await updatePositionsFunc({
      items: images.map((image) => image.id),
    });
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Image repositioned");
    }
  }

  async function deleteMediaImage(id) {
    const response = await updateFunc(parentID, {
      [imagesAttributeName]: [{ id, _destroy: id }],
    });
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Image deleted");
    }
  }

  async function deleteImages(images) {
    const response = await deleteFunc({
      ids: images.map((image) => image.id),
    });
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Images deleted");
    }
  }

  return (
    <div>
      <GalleryContainer
        images={mediaImages}
        updatePositions={updatePositions}
        deleteMediaImage={deleteMediaImage}
        deleteImages={deleteImages}
      />
      <GalleryInput
        name={imagesAttributeName}
        control={control}
        handleFilesAdded={handleFilesAdded}
        multiple={true}
      />
    </div>
  );
}
