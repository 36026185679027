import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalBody from "lunar/ModalBody";
import ModalFooter from "lunar/ModalFooter";
import Button from "lunar/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import TextField from "lunar/InputText";
import HiddenField from "components/common/HiddenField";
import InputTextAddon from "lunar/InputTextAddon";

CreateUpdateVariantModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  handleSaveVariant: PropTypes.func.isRequired,
  eventID: PropTypes.string,
  variant: PropTypes.object,
};

export default function CreateUpdateVariantModal({
  showModal,
  setShowModal,
  handleSaveVariant,
  eventID = "",
  variant = {},
}) {
  const schema = Yup.object().shape({
    start_date: Yup.string().required("Required"),
    end_date: Yup.string().required("Required"),
    price: Yup.string().required("Required"),
    // direction: Yup.string().required("Required"),
    // break_type: Yup.string().required("Required"),
  });

  const {
    reset,
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (variant) {
      reset({
        id: variant.id,
        start_date: variant.start_date,
        end_date: variant.end_date,
        price: variant.price,
        total_quantity: variant.total_quantity,
        single_room_supplement_price: variant.single_room_supplement_price,
        event_id: eventID,
      });
    } else {
      reset({
        id: "",
        start_date: "",
        end_date: "",
        price: "",
        total_quantity: "",
        single_room_supplement_price: "",
        event_id: eventID,
      });
    }
  }, [reset, variant]);

  return (
    <Modal open={showModal} setOpen={setShowModal}>
      <ModalTitle>Add/Edit date</ModalTitle>
      <form onSubmit={handleSubmit(handleSaveVariant)} noValidate>
        <ModalBody>
          <HiddenField name="id" register={register} />
          <HiddenField name="event_id" register={register} />
          <div className="grid gap-y-6">
            <div className="flex gap-x-6">
              <TextField
                name="start_date"
                type="date"
                label="Start date"
                placeholder="yyyy-mm-dd"
                errors={errors}
                register={register}
              />
              <TextField
                name="end_date"
                type="date"
                label="End date"
                placeholder="yyyy-mm-dd"
                errors={errors}
                register={register}
              />
            </div>
            <div className="flex gap-x-4">
              <InputTextAddon
                name="price"
                type="text"
                label="Price"
                errors={errors}
                register={register}
                addonLeft="$"
              />
              <TextField
                name="total_quantity"
                type="text"
                label="# available"
                errors={errors}
                register={register}
              />
              <InputTextAddon
                name="single_room_supplement_price"
                type="text"
                label="Single room +"
                errors={errors}
                register={register}
                addonLeft="$"
                helper="Additional fee for single room in group trips"
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button type="submit" isSubmitting={isSubmitting}>
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
