import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalBody from "lunar/ModalBody";
import ModalFooter from "lunar/ModalFooter";
import Button from "lunar/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import TextField from "lunar/InputText";
import SelectField from "lunar/SelectField";
import TextArea from "lunar/InputTextArea";
import InputImage from "lunar/InputImage";
import HiddenField from "components/common/HiddenField";

CreateUpdateAccommodationModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  handleSaveAccommodation: PropTypes.func.isRequired,
  adventureID: PropTypes.string,
  accommodation: PropTypes.object,
  numAccommodations: PropTypes.number,
};

export default function CreateUpdateAccommodationModal({
  showModal,
  setShowModal,
  handleSaveAccommodation,
  adventureID = "",
  accommodation = {},
  numAccommodations = 0,
}) {
  const schema = Yup.object().shape({
    title: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    // direction: Yup.string().required("Required"),
    // break_type: Yup.string().required("Required"),
  });

  const {
    reset,
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (accommodation) {
      reset({
        id: accommodation.id,
        title: accommodation.title,
        description: accommodation.description,
        position: accommodation.position,
        adventure_id: adventureID,
      });
    } else {
      reset({
        id: "",
        title: "",
        description: "",
        position: "",
        adventure_id: adventureID,
      });
    }
  }, [reset, accommodation]);

  return (
    <Modal open={showModal} setOpen={setShowModal}>
      <ModalTitle>Edit accommodation</ModalTitle>
      <form onSubmit={handleSubmit(handleSaveAccommodation)} noValidate>
        <ModalBody>
          <HiddenField name="id" register={register} />
          <HiddenField name="adventure_id" register={register} />
          <div className="grid gap-y-6">
            <div className="flex gap-x-6">
              <TextField
                name="title"
                type="text"
                label="Title"
                errors={errors}
                register={register}
                className="flex-grow"
              />
              <SelectField
                name="position"
                label="Position"
                options={Array.from(
                  { length: numAccommodations },
                  (_, i) => i + 1
                )}
                errors={errors}
                register={register}
              />
            </div>

            <TextArea
              name="description"
              type="text"
              label="Description"
              errors={errors}
              register={register}
            />
            <InputImage
              name="image"
              label="Image"
              image={accommodation?.image}
              errors={errors}
              register={register}
              previewAspectRatio="1506/1200"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button type="submit" isSubmitting={isSubmitting}>
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
