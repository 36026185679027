import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";
import PropTypes from "prop-types";

const TWO_HUNDRED_MS = 100;

DataTableSearch.propTypes = {
  globalFilter: PropTypes.node,
  setGlobalFilter: PropTypes.func,
  testFunction: PropTypes.func,
  placeholder: PropTypes.string,
};

export default function DataTableSearch({
  globalFilter,
  setGlobalFilter,
  placeholder = "Search",
}) {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, TWO_HUNDRED_MS);

  return (
    <div className="flex items-center flex-grow">
      <input
        value={value || ""}
        placeholder={placeholder}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        className="block w-full h-full text-gray-900 placeholder-gray-400 bg-transparent border-transparent text-md focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent"
      />
    </div>
  );
}
