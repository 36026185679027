import React from "react";
import PropTypes from "prop-types";
import Post from "components/messages/Post";
import BookingChange from "components/messages/BookingChange";
import CreatePostGuide from "components/messages/CreatePostGuide";
import PostsMenu from "components/messages/PostsMenu";
import { mergeArrays } from "helpers/merge";

Posts.propTypes = {
  thread: PropTypes.shape({
    id: PropTypes.string,
    user_first_name: PropTypes.string,
    user_last_name: PropTypes.string,
    messages: PropTypes.array,
    booking_changes: PropTypes.array,
    operator_employees: PropTypes.array,
    user_email: PropTypes.string,
    user_id: PropTypes.number,
    marked_as_resolved: PropTypes.bool,
  }),
  status: PropTypes.shape({
    color: PropTypes.string,
    message: PropTypes.string,
  }),
  setErrorMessage: PropTypes.func.isRequired,
  reloadActiveThread: PropTypes.func.isRequired,
  handleSaveReply: PropTypes.func.isRequired,
};

export default function Posts({
  thread,
  status = {},
  setErrorMessage,
  reloadActiveThread,
  handleSaveReply,
}) {
  const messagesAndBookings = mergeArrays(
    thread?.messages,
    thread?.booking_changes
  ).reverse();

  return (
    <>
      <div className="flex flex-col h-full p-4 overflow-hidden">
        <div className="flex flex-col h-full overflow-hidden bg-white rounded-md">
          <div className="grid items-center grid-flow-col p-4 border-b border-gray-100 auto-cols-fr">
            <div className="inline-flex items-center">
              <span
                className={`px-3 py-0.5 rounded-full text-sm font-medium bg-${status?.color}-100 text-${status?.color}-800`}
              >
                {status?.message}
              </span>
            </div>
            {thread && (
              <div className="inline-grid items-center justify-end">
                <PostsMenu thread={thread} />
              </div>
            )}
          </div>
          <div className="flex flex-col-reverse h-full pb-10 pl-10 pr-10 overflow-y-auto">
            {messagesAndBookings?.map((obj) =>
              obj.sender_type ? (
                <Post
                  message={obj}
                  setErrorMessage={setErrorMessage}
                  reloadActiveThread={reloadActiveThread}
                  key={obj.id}
                  userName={`${thread.user_first_name} ${thread.user_last_name}`}
                  isLastTravelerMessage={messagesAndBookings[0].sender_type === 'User' && messagesAndBookings[0].id === obj.id}
                />
              ) : (
                <BookingChange
                  key={obj.id}
                  change={obj}
                  userName={`${thread.user_first_name} ${thread.user_last_name}`}
                />
              )
            )}
          </div>
          {thread && (
            <CreatePostGuide
              userEmail={thread.user_email}
              employees={thread.operator_employees}
              senderType={"OperatorEmployee"}
              receiverID={thread.user_id}
              receiverType={"User"}
              threadID={thread.id}
              handleSaveReply={handleSaveReply}
            />
          )}
        </div>
      </div>
    </>
  );
}
