import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { longDateTime, money } from "helpers/formatting";
import { mergeArrays } from "helpers/merge";
import { createRefund } from "lib/api";
import Button from "lunar/Button";
import RefundModal from "components/bookings/sections/RefundModal";
import Table from "lunar/Table";
import TableHead from "lunar/TableHead";
import TableRow from "lunar/TableRow";
import TableBody from "lunar/TableBody";
import TableCell from "lunar/TableCell";

Payments.propTypes = {
  handleError: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  payments: PropTypes.array,
};

const defaultPayments = [];

export default function Payments({
  payments = defaultPayments,
  handleError,
  handleSuccess,
}) {
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [paymentID, setPaymentID] = useState(null);
  const [chargesAndRefunds, setChargesAndRefunds] = useState([]);

  useEffect(() => {
    const refunds = payments.map((payment) => payment.refunds).flat();
    setChargesAndRefunds(mergeArrays(payments, refunds));
  }, [payments]);

  async function handleSaveRefund(data) {
    const response = await createRefund(data);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Refund created");
    }
    setShowRefundModal(false);
  }

  function handleRefund() {
    if (payments.length > 0) {
      // NOTE: assuming only 1 payment per reservation right now
      setPaymentID(payments[0].id);
      setShowRefundModal(true);
    }
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell head first>
              Type
            </TableCell>
            <TableCell head>Description</TableCell>
            <TableCell head>Time</TableCell>
            <TableCell head last>
              Total
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {chargesAndRefunds.map((obj) => (
            <TableRow key={`${obj.id}-${obj.amount}`}>
              <TableCell first>
                {obj.payment !== undefined && "Refund"}
                {obj.card_type != null && "Charge"}
                {obj.wire_transfer_transaction_number != null && "Wire"}
              </TableCell>
              <TableCell>
                {obj.payment != undefined && obj.reason}
                {obj.card_type != null &&
                  `${obj.card_type} ending in ${obj.card_last_four}`}
              </TableCell>
              <TableCell>{longDateTime(obj.created_at)}</TableCell>
              <TableCell last>{money(obj.amount)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className="mt-5 text-right">
        <Button variant="secondary" onClick={handleRefund}>
          Refund traveler
        </Button>
      </div>
      <RefundModal
        paymentID={paymentID}
        handleSaveRefund={handleSaveRefund}
        setShowRefundModal={setShowRefundModal}
        showRefundModal={showRefundModal}
      />
    </>
  );
}
