import React, { useState, useEffect, useCallback } from "react";
import { useToasts } from "react-toast-notifications";
import { getGuideAvailabilities, getAdventureBookings, getGuide, getEventVariants } from "lib/api";
import { useParams } from "react-router-dom";
import Calendar from "components/guide_calendars/Calendar";

export default function GuideCalendarsShow() {
  const [dataLoaded, setDataLoaded] = useState(true);
  const [availabilities, setAvailabilities] = useState([]);
  const [reservations, setReservations] = useState(null);
  const [calendarLastUpdatedAt, setCalendarLastUpdatedAt] = useState(null);
  const [calendarLastViewedAt, setCalendarLastViewedAt] = useState(null);
  const [guideName, setGuideName] = useState(null);
  const { addToast } = useToasts();
  const id = useParams().id;

  const fetchAvailability = useCallback(async () => {
    setDataLoaded(false);
    const response = await getGuideAvailabilities(id);
    if (response.errorMsg) {
      addToast(response.errorMsg, { appearance: "error" });
      setDataLoaded(true);
    } else {
      setAvailabilities(response.data);
      setCalendarLastUpdatedAt(response.meta.calendar_last_updated_at);
      setCalendarLastViewedAt(response.meta.calendar_last_viewed_at);
      // only set reservations one time on initial page load and
      // only after possible refresh token usage on guide availabilities call
      if (reservations === null) {
        const resResponse = await getAdventureBookings(id);
        if (resResponse.errorMsg) {
          addToast(resResponse.errorMsg, { appearance: "error" });
        } else {
          const unsortedReservations = resResponse.filter((res) => (res.status === "fully_paid" || res.status === "partially_paid"));
          const res2Response = await getEventVariants(id);
          if (res2Response.errorMsg) {
            addToast(res2Response.errorMsg, { appearance: "error" });
          } else {
            if (res2Response.length > 0) {
              const variantReservations = res2Response.filter((variant) => variant.total_quantity !== 0).map((res) => ({id: res.id, transaction_number: null, start_date: res.start_date, end_date: res.end_date, first_name: res.event_name, last_name: `(${res.total_quantity - res.quantity_available} of ${res.total_quantity} booked)`}));
              unsortedReservations.push(...variantReservations);
            }
          }
          setReservations(unsortedReservations.sort((a,b) => new Date(a.start_date) - new Date(b.start_date)));
        }
      }
      const guideResponse = await getGuide(id);
      if (guideResponse.errorMsg) {
        addToast(guideResponse.errorMsg, { appearance: "error" });
      } else {
        setGuideName(guideResponse.name);
      }
      setDataLoaded(true);
    }
  }, [reservations]);

  useEffect(() => {
    (async () => {
      fetchAvailability();
    })();
  }, [fetchAvailability]);

  return (
    <div className="flex flex-col flex-1 min-h-screen overflow-y-scroll bg-white">
      <Calendar
        blockedDates={availabilities}
        handleSuccess={fetchAvailability}
        dataLoaded={dataLoaded}
        reservations={reservations}
        guideID={id}
        guideName={guideName}
        lastUpdatedAt={calendarLastUpdatedAt}
        lastViewedAt={calendarLastViewedAt}
      />
    </div>
  );
}
