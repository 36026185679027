import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

NavItem.propTypes = {
  icon: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  anchor: PropTypes.string,
  active: PropTypes.bool,
  // check: PropTypes.bool,
};

export default function NavItem({
  icon,
  text,
  description,
  anchor = "",
  active = false,
  // check = false,
}) {
  return (
    <a
      className={cn("flex p-6", active && "bg-gray-200")}
      aria-current="page"
      href={anchor !== "" ? anchor : undefined}
    >
      <div className="text-sm">
        <div className="font-medium">{text}</div>
        <div className="mt-1 text-gray-500">{description}</div>
      </div>
      <div className="flex-shrink-0 w-6 h-6 ml-3 text-gray-400">{icon}</div>
    </a>
  );
}
