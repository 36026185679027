import React from "react";
import PropTypes from "prop-types";

FormSections.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function FormSections({ children }) {
  return <div className="divide-y">{children}</div>;
}
