/* eslint-disable react/display-name, react/prop-types */

import React, { useState, useEffect } from "react";
import { getAdventures } from "lib/api";
import { useLoadingContext } from "context/loading-context";
import { ErrorAlert } from "components/common/Alerts";
import PageListNav from "components/common/PageListNav";
import DataTable from "lunar/DataTable";
import DataTableSearch from "lunar/DataTableSearch";
import Meta from "lunar/Meta";
import Button from "lunar/Button";

export default function Adventures() {
  const { setLoading } = useLoadingContext();
  const [adventures, setAdventures] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    (async () => {
      populatePageAdventures();
    })();
  }, []);

  async function populatePageAdventures() {
    setLoading(true);
    const response = await getAdventures();
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      setAdventures(response.sort((a, b) => (a.name > b.name ? 1 : -1)));
    }
    setLoading(false);
  }

  const columns = React.useMemo(() => [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row: { original } }) => {
        return (
          <span className="font-medium text-gray-900">{original.name}</span>
        );
      },
    },
    {
      Header: "Status",
      accessor: "is_visible",
      Cell: ({ row: { original } }) => {
        return (
          <>
            {original.is_visible && (
              <div className="w-2.5 h-2.5 bg-green-500 rounded-full" />
            )}
          </>
        );
      },
    },
    {
      Header: "",
      accessor: "slug",
      Cell: ({ row: { original } }) => {
        return (
          <div className="flex justify-end">
            {original.slug && (
              <Button
                variant="secondary"
                onClick={(e) => {
                  e.preventDefault(),
                    window.open(
                      `http://www.thermal.travel/adventures/${original.slug}`
                    );
                }}
              >
                View listing
              </Button>
            )}
          </div>
        );
      },
    },
  ]);

  return (
    <>
      <Meta title={adventures && `All ${adventures.length} adventures`} />
      <div className="min-h-screen overflow-y-scroll">
        <PageListNav
          dataTableSearch={
            <DataTableSearch
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              placeholder="Search for adventures"
            />
          }
        />

        <ErrorAlert message={errorMessage} />
        {adventures && (
          <DataTable
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            data={adventures.map((adventure) => ({
              id: adventure.id,
              name: adventure.name,
              slug: adventure.slug,
              is_visible: adventure.is_visible,
              rowLink: `edit/${adventure.id}`
            }))}
            columns={columns}
            rowLinkAttribute="rowLink"
            sortOptions={{ id: "name", desc: false }}
          />
        )}
      </div>
    </>
  );
}
