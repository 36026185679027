import React, { useState } from "react";
import PropTypes from "prop-types";
import Table from "components/common/Table";
import { money, longDateTime, humanDate } from "helpers/formatting";
import { createCredit } from "lib/api";
import Button from "lunar/Button";
import Card from "components/common/Card";
import CardHeading from "components/common/CardHeading";
import CreateCreditModal from "./CreateCreditModal";

Credits.propTypes = {
  credits: PropTypes.array,
  handleError: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  userID: PropTypes.string.isRequired,
};

const defaultCredits = [];

export default function Credits({
  credits = defaultCredits,
  handleError,
  handleSuccess,
  userID,
}) {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);

  const columns = React.useMemo(() => [
    {
      Header: "Amount",
      accessor: "amount",
    },
    {
      Header: "Amount Used",
      accessor: "amount_used",
    },
    {
      Header: "Notes",
      accessor: "notes",
    },
    {
      Header: "Expires",
      accessor: "expiration_date",
      Cell: (c) => <span>{humanDate(c.value)}</span>, // eslint-disable-line react/display-name
    },
    {
      Header: "Created",
      accessor: "created_at",
      Cell: (c) => <span>{longDateTime(c.value)}</span>, // eslint-disable-line react/display-name
    },
  ]);

  async function handleCreateCredit(data) {
    data.user_id = userID;
    const response = await createCredit(data);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Credit created");
    }
    setShowModal(false);
  }

  return (
    <Card>
      <CardHeading
        heading="Credits"
        action={<Button onClick={handleShowModal}>Create</Button>}
      />
      <Table
        data={credits.map((credit) => ({
          amount: money(credit.amount),
          amount_used: money(credit.amount_used),
          notes: credit.notes,
          expiration_date: credit.expiration_date,
          created_at: credit.created_at,
          actionsId: credit.id,
        }))}
        columns={columns}
        sortOptions={{ id: "expiration_date", desc: false }}
      />
      <CreateCreditModal
        handleCreateCredit={handleCreateCredit}
        setShowModal={setShowModal}
        showModal={showModal}
      />
    </Card>
  );
}
