import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import {
  updateTrip,
  updateTripAccommodationImagePositions,
  deleteTripAccommodationImages,
} from "lib/api";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Form from "lunar/Form";
import FormTitle from "lunar/FormTitle";
import FormSections from "lunar/FormSections";
import FormSection from "lunar/FormSection";
import FormSectionHeader from "lunar/FormSectionHeader";
import FormSectionBody from "lunar/FormSectionBody";
import FormRow from "lunar/FormRow";
import Gallery from "lunar/gallery/Gallery";
import InputTextArea from "lunar/InputTextArea";

AccommodationsForm.propTypes = {
  description: PropTypes.string,
  submitForm: PropTypes.func.isRequired,
  parentID: PropTypes.string.isRequired,
  images: PropTypes.array,
  handleError: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
};

export default function AccommodationsForm({
  parentID,
  description = "",
  submitForm,
  images,
  handleError,
  handleSuccess,
}) {
  const {
    control,
    register,
    errors,
    reset,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm();

  useEffect(() => {
    if (description) {
      reset({
        accommodation_description: description,
      });
    }
  }, [reset, description]);

  async function submitFormAndClearImages(data) {
    await submitForm(data);
    setValue("trip_accommodation_attributes", []);
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Form
        handleSubmit={handleSubmit}
        submitForm={submitFormAndClearImages}
        isSubmitting={isSubmitting}
      >
        <FormTitle title="Accommodations" />
        <FormSections>
          <FormSection>
            <FormSectionHeader
              title="Where they'll sleep"
              description="Describe the nature and standard of the lodging provided on this trip. Is this camping in tents? Will you be staying in a mix of rentals properties and hotel rooms? How fancy is the accommodation and what amenities should travelers expect?"
            />
            <FormSectionBody>
              <InputTextArea
                name="accommodation_description"
                type="text"
                label="Description"
                helper="Supports basic markdown formatting."
                errors={errors}
                register={register}
                rows={10}
              />
            </FormSectionBody>
          </FormSection>
          <FormSection>
            <FormSectionHeader
              title="Photos"
              description="Photos that bring to life everything described in the text above."
            />
            <FormSectionBody>
              <FormRow className="grid grid-flow-row">
                <Gallery
                  parentID={parentID}
                  images={images}
                  handleError={handleError}
                  handleSuccess={handleSuccess}
                  updateFunc={updateTrip}
                  deleteFunc={deleteTripAccommodationImages}
                  updatePositionsFunc={updateTripAccommodationImagePositions}
                  imagesAttributeName="trip_accommodation_images_attributes"
                  control={control}
                  setValue={setValue}
                />
              </FormRow>
            </FormSectionBody>
          </FormSection>
        </FormSections>
      </Form>
    </DndProvider>
  );
}
