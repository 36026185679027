import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useToasts } from "react-toast-notifications";
import { createCollection, getCollectionTags } from "lib/api";
import { ErrorAlert } from "components/common/Alerts";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import TextField from "components/common/TextField";
import SelectField from "components/common/SelectField";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalFooter from "lunar/ModalFooter";
import ModalBody from "lunar/ModalBody";
import Button from "lunar/Button";

NewModal.propTypes = {
  populatePageCollections: PropTypes.func.isRequired,
};

export default function NewModal({ populatePageCollections }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [tags, setTags] = useState([]);
  const { addToast } = useToasts();
  const [showNewModal, setShowNewModal] = useState(false);

  useEffect(() => {
    (async () => {
      getCollectionTagsForOptions();
    })();
  }, []);

  async function getCollectionTagsForOptions() {
    const response = await getCollectionTags();
    response.errorMsg ? setErrorMessage(response.errorMsg) : setTags(response);
  }

  async function submitForm(data) {
    const response = await createCollection(data);
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      addToast("Collection successfully created", { appearance: "success" });
      setShowNewModal(false);
      populatePageCollections();
    }
  }

  const schema = Yup.object().shape({
    name: Yup.string().required("Required"),
    tag_id: Yup.string().required("Required"),
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <>
      <Button onClick={() => setShowNewModal(true)}>New collection</Button>
      <Modal open={showNewModal} setOpen={setShowNewModal}>
        <ModalTitle>Add a new collection</ModalTitle>
        <ErrorAlert message={errorMessage} />
        <form onSubmit={handleSubmit(submitForm)} noValidate>
          <ModalBody>
            <TextField
              name="name"
              type="text"
              label="Name"
              errors={errors}
              register={register}
            />
            <SelectField
              name="tag_id"
              label="Tag"
              options={tags.sort((a, b) => (a.name > b.name ? 1 : -1))}
              errors={errors}
              register={register}
              includeBlank={true}
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" onClick={() => setShowNewModal(false)}>
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
}
