import React from "react";
import PropTypes from "prop-types";

FormProvider.propTypes = {
  lastUpdated: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  children: PropTypes.node.isRequired,
};

const FormContext = React.createContext();

function FormProvider({
  lastUpdated = "",
  link = "",
  linkText = "",
  children,
}) {
  const value = { lastUpdated, link, linkText };
  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
}

function useFormContext() {
  const context = React.useContext(FormContext);
  if (context === undefined) {
    throw new Error(`useFormContext must be used within a FormProvider`);
  }
  return context;
}

export { FormProvider, useFormContext };
