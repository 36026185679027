import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useLoadingContext } from "context/loading-context";
import {
  getTrip,
  getOperators,
  getRegions,
  getAirports,
  getCollectionTags,
  getSurfFeatureTags,
  getAccommodationFeatureTags,
  getCategoryTags,
  updateTrip,
} from "lib/api";
import { humanDate } from "helpers/formatting";
import { useToasts } from "react-toast-notifications";
import DetailsForm from "components/trips/DetailsForm";
import LocationForm from "components/trips/LocationForm";
import AccommodationsForm from "components/trips/AccommodationsForm";
import DescriptionForm from "components/trips/DescriptionForm";
import StoryForm from "components/trips/StoryForm";
import SurfForm from "components/trips/SurfForm";
import QuestionsForm from "components/trips/QuestionsForm";
import Nav from "components/trips/Nav";
import NavHeader from "components/trips/NavHeader";
import { FormProvider } from "context/form-context";
import MainGalleryForm from "components/trips/MainGalleryForm";
import PricingAvailabilityForm from "components/trips/PricingAvailabilityForm";
import Meta from "lunar/Meta";

export default function EditTrip() {
  const [trip, setTrip] = useState({});
  const [operators, setOperators] = useState([]);
  const [regions, setRegions] = useState([]);
  const [airports, setAirports] = useState([]);
  const [collections, setCollections] = useState([]);
  const [surfFeatures, setSurfFeatures] = useState([]);
  const [accommodationFeatures, setAccommodationFeatures] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const location = useLocation();
  const tripID = useParams().id;
  const { addToast } = useToasts();
  const { setLoading } = useLoadingContext();

  useEffect(() => {
    (async () => {
      populateTripState(tripID);
    })();
  }, [tripID]);

  useEffect(() => {
    setActiveTab(location.hash.slice(1) || "details");
  }, [location]);

  useEffect(() => {
    (async () => {
      const noError = await getCollectionsForOptions();
      if (noError) {
        getSurfFeaturesForOptions();
        getAccommodationFeaturesForOptions();
        getCategoriesForOptions();
        getRegionsForOptions();
        getAirportsForOptions();
      }
    })();
  }, []);

  async function getRegionsForOptions() {
    const response = await getRegions();
    response.errorMsg
      ? handleError(response.errorMsg)
      : setRegions(response.sort((a, b) => (a.name > b.name ? 1 : -1)));
  }

  async function getAirportsForOptions() {
    const response = await getAirports();
    response.errorMsg
      ? handleError(response.errorMsg)
      : setAirports(response.sort((a, b) => (a.name > b.name ? 1 : -1)));
  }

  async function getCollectionsForOptions() {
    const response = await getCollectionTags();
    response.errorMsg
      ? handleError(response.errorMsg)
      : setCollections(response.sort((a, b) => (a.name > b.name ? 1 : -1)));
    return response.errorMsg === undefined;
  }

  async function getSurfFeaturesForOptions() {
    const response = await getSurfFeatureTags();
    response.errorMsg
      ? handleError(response.errorMsg)
      : setSurfFeatures(response.sort((a, b) => (a.name > b.name ? 1 : -1)));
    return response.errorMsg === undefined;
  }

  async function getAccommodationFeaturesForOptions() {
    const response = await getAccommodationFeatureTags();
    response.errorMsg
      ? handleError(response.errorMsg)
      : setAccommodationFeatures(response.sort((a, b) => (a.name > b.name ? 1 : -1)));
    return response.errorMsg === undefined;
  }

  async function getCategoriesForOptions() {
    const response = await getCategoryTags();
    response.errorMsg
      ? handleError(response.errorMsg)
      : setCategories(response.sort((a, b) => (a.name > b.name ? 1 : -1)));
    return response.errorMsg === undefined;
  }

  async function populateTripState(tripID) {
    setLoading(true);
    if (operators.length === 0) getOperatorsForOptions();
    const response = await getTrip(tripID);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      setTrip(response);
    }
    setLoading(false);
  }

  function handleSuccess(msg) {
    populateTripState(tripID);
    addToast(msg, { appearance: "success" });
  }

  function handleError(msg) {
    addToast(msg, { appearance: "error" });
  }

  async function getOperatorsForOptions() {
    const response = await getOperators();
    response.errorMsg ? handleError(response.errorMsg) : setOperators(response);
  }

  async function submitForm(data) {
    const response = await updateTrip(tripID, data);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Trip successfully updated");
    }
  }

  return (
    <>
      <Meta title={trip.name} />
      <FormProvider
        link={`${process.env.REACT_APP_WEBSITE_URL}/api/preview?secret=${process.env.REACT_APP_PREVIEW_SECRET}&type=trip&slug=${trip.slug}`}
        linkText="Preview"
        lastUpdated={trip.updated_at && humanDate(trip.updated_at)}
      >
        <div className="flex flex-row h-screen overflow-hidden">
          <div className="flex flex-col overflow-hidden w-96">
            <NavHeader
              tripID={trip.id}
              tripVisible={trip.is_visible}
              tripFeatured={trip.is_featured}
              tripName={trip.name}
              tripOperatorSlug={trip.operator?.slug}
              tripRegionID={trip.region_id}
              submitForm={submitForm}
            />
            <Nav
              trip={trip}
              tripTypeDescription={trip.trip_type_description}
              activeTab={activeTab}
            />
          </div>
          <div className="flex flex-col flex-1 border-l border-gray-200">
            <div
              className={
                activeTab === "" || activeTab === "details"
                  ? undefined
                  : "hidden"
              }
            >
              <DetailsForm
                submitForm={submitForm}
                operators={operators}
                collections={collections}
                surfFeatures={surfFeatures}
                accommodationFeatures={accommodationFeatures}
                trip={trip}
                categories={categories}
              />
            </div>
            <div className={activeTab === "location" ? undefined : "hidden"}>
              <LocationForm
                submitForm={submitForm}
                trip={trip}
                airports={airports}
                regions={regions}
              />
            </div>
            <div className={activeTab === "story" ? undefined : "hidden"}>
              <StoryForm
                submitForm={submitForm}
                name={trip.story_header}
                description={trip.story_body}
              />
            </div>
            <div className={activeTab === "gallery" ? undefined : "hidden"}>
              <MainGalleryForm
                submitForm={submitForm}
                parentID={tripID}
                images={trip.trip_media_images}
                handleError={handleError}
                handleSuccess={handleSuccess}
              />
            </div>
            <div className={activeTab === "surf" ? undefined : "hidden"}>
              <SurfForm
                tripID={tripID}
                submitForm={submitForm}
                surfBody={trip.surf_body}
                breaks={trip.trip_breaks}
                handleSuccess={handleSuccess}
                handleError={handleError}
              />
            </div>
            <div className={activeTab === "desc" ? undefined : "hidden"}>
              <DescriptionForm
                tripType={trip.trip_type_description}
                submitForm={submitForm}
                parentID={tripID}
                description={trip.description}
                images={trip.trip_description_images}
                handleError={handleError}
                handleSuccess={handleSuccess}
              />
            </div>
            {trip.trip_type_description !== "Lodge" && (
              <div
                className={
                  activeTab === "accommodations" ? undefined : "hidden"
                }
              >
                <AccommodationsForm
                  submitForm={submitForm}
                  parentID={tripID}
                  description={trip.accommodation_description}
                  images={trip.trip_accommodation_images}
                  handleError={handleError}
                  handleSuccess={handleSuccess}
                />
              </div>
            )}
            <div className={activeTab === "questions" ? undefined : "hidden"}>
              <QuestionsForm
                submitForm={submitForm}
                questions={trip.questions}
                tripSlug={trip.id}
              />
            </div>
            <div className={activeTab === "products" ? undefined : "hidden"}>
              <PricingAvailabilityForm
                tripID={tripID}
                products={trip.products}
                tripTypeDescription={trip.trip_type_description}
              />
            </div>
          </div>
        </div>
      </FormProvider>
    </>
  );
}
