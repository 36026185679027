/* eslint-disable react/display-name, react/prop-types */

import React, { useState, useEffect } from "react";
import { getEvents } from "lib/api";
import { useLoadingContext } from "context/loading-context";
import { ErrorAlert } from "components/common/Alerts";
import PageListNav from "components/common/PageListNav";
import DataTable from "lunar/DataTable";
import DataTableSearch from "lunar/DataTableSearch";
import Meta from "lunar/Meta";
// import Button from "lunar/Button";

export default function Events() {
  const { setLoading } = useLoadingContext();
  const [events, setEvents] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    (async () => {
      populatePageEvents();
    })();
  }, []);

  async function populatePageEvents() {
    setLoading(true);
    const response = await getEvents();
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      setEvents(response.sort((a, b) => (a.name > b.name ? 1 : -1)));
    }
    setLoading(false);
  }

  function eventType(str) {
    switch (str) {
      case "residency":
        return "Residency";
      case "group_trip":
        return "Group trip";
      case "training_trip":
        return "SDP";
    }
  }

  const columns = React.useMemo(() => [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row: { original } }) => {
        return (
          <span className="font-medium text-gray-900">{original.name}</span>
        );
      },
    },
    {
      Header: "Type",
      accessor: "event_type",
      Cell: ({ row: { original } }) => {
        return (
          <span className="font-medium text-gray-900">
            {eventType(original.event_type)}
          </span>
        );
      },
    },
  ]);

  return (
    <>
      <Meta title={events && `All ${events.length} events`} />
      <div className="min-h-screen overflow-y-scroll">
        <PageListNav
          dataTableSearch={
            <DataTableSearch
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              placeholder="Search for events"
            />
          }
        />

        <ErrorAlert message={errorMessage} />
        {events && (
          <DataTable
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            data={events.map((event) => ({
              id: event.id,
              name: event.name,
              slug: event.slug,
              event_type: event.event_type,
              rowLink: `edit/${event.id}`,
            }))}
            columns={columns}
            rowLinkAttribute="rowLink"
            sortOptions={{ id: "name", desc: false }}
          />
        )}
      </div>
    </>
  );
}
