import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "lunar/Button";
import {
  createEventVariant,
  updateEventVariant,
  deleteEventVariant,
} from "lib/api";
// import { formatImageURL } from "helpers/formatting";
import CreateUpdateVariantModal from "./CreateUpdateVariantModal";
import Table from "lunar/Table";
import TableHead from "lunar/TableHead";
import TableRow from "lunar/TableRow";
import TableCell from "lunar/TableCell";
import TableBody from "lunar/TableBody";
import TableActions from "lunar/TableActions";
import { humanDate, money } from "helpers/formatting";

EventVariantsTable.propTypes = {
  variants: PropTypes.array,
  eventID: PropTypes.string,
  handleError: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
};

export default function EventVariantsTable({
  variants = [],
  eventID = "",
  handleError,
  handleSuccess,
}) {
  const [showModal, setShowModal] = useState(false);
  const [variantToEdit, setVariantToEdit] = useState(null);

  async function handleDeleteVariant(id) {
    if (window.confirm("Are you sure you want to delete this departure?")) {
      const response = await deleteEventVariant(id);
      if (response.errorMsg) {
        handleError(response.errorMsg);
      } else {
        handleSuccess("Variant deleted");
      }
      setShowModal(false);
    }
  }

  function handleEditVariant(variant) {
    setVariantToEdit(variant);
    setShowModal(true);
  }

  async function handleSaveVariant(data) {
    let response;
    if (data.id !== "") {
      response = await updateEventVariant(data.id, data);
    } else {
      response = await createEventVariant(data);
    }
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Variant saved");
    }
    setShowModal(false);
  }

  function handleAdd() {
    setVariantToEdit(null);
    setShowModal(true);
  }

  return (
    <>
      {variants && (
        <div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell head first>
                  Start
                </TableCell>
                <TableCell head>End</TableCell>
                <TableCell head>$</TableCell>
                <TableCell head># taken</TableCell>
                <TableCell head># available</TableCell>
                <TableCell head>Single room +</TableCell>
                <TableCell head last>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {variants.map((variant) => (
                <TableRow key={variant.id}>
                  <TableCell first>{humanDate(variant.start_date)}</TableCell>
                  <TableCell>{humanDate(variant.end_date)}</TableCell>
                  <TableCell>{money(variant.price)}</TableCell>
                  <TableCell>{variant.total_quantity - variant.quantity_available}</TableCell>
                  <TableCell>{variant.total_quantity}</TableCell>
                  <TableCell>{money(variant.single_room_supplement_price)}</TableCell>
                  <TableCell last>
                    <button
                      type="button"
                      onClick={() => handleDeleteVariant(variant.id)}
                      className="text-gray-600 hover:underline"
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      onClick={() => handleEditVariant(variant)}
                      className="ml-4 text-blue-600 hover:text-blue-900"
                    >
                      Edit
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TableActions>
            <Button variant="secondary" onClick={handleAdd}>
              Add date
            </Button>
          </TableActions>
          {showModal && (
            <CreateUpdateVariantModal
              eventID={eventID}
              handleSaveVariant={handleSaveVariant}
              setShowModal={setShowModal}
              showModal={showModal}
              variant={variantToEdit}
            />
          )}
        </div>
      )}
    </>
  );
}
