import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import TextAreaAutoSize from "react-textarea-autosize";

InputTextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helper: PropTypes.string,
  errors: PropTypes.object,
  register: PropTypes.func.isRequired,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  cacheMeasurements: PropTypes.bool,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
};

export default function InputTextArea({
  name,
  label = "",
  helper = "",
  errors = {},
  register,
  minRows = 3,
  maxRows = 10,
  maxLength = null,
  placeholder = "",
  autoFocus = false,
  cacheMeasurements = false,
  className = "",
  inputClassName = "",
}) {
  return (
    <div className={className}>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1">
        <TextAreaAutoSize
          id={name}
          name={name}
          {...register(name)}
          className={cn(
            "block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm placeholder-gray-400",
            inputClassName
          )}
          minRows={minRows}
          maxRows={maxRows}
          placeholder={placeholder}
          autoFocus={autoFocus}
          cacheMeasurements={cacheMeasurements}
          maxLength={maxLength}
        />
        {errors[name] && (
          <p className="mt-2 text-xs text-red-600">{errors[name].message}</p>
        )}
        {helper && <p className="mt-2 text-xs text-gray-500">{helper}</p>}
      </div>
    </div>
  );
}
