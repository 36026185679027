import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { getUsers, getTrip, getTrips } from "lib/api";
import * as Yup from "yup";
import InputText from "lunar/InputText";
import InputTextArea from "lunar/InputTextArea";
import SelectField from "lunar/SelectField";
import SelectSearch from "lunar/SelectSearch";
import Button from "lunar/Button";
import ModalFooter from "lunar/ModalFooter";
import ModalBody from "lunar/ModalBody";

NewForm.propTypes = {
  submitForm: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  setShowNewModal: PropTypes.func.isRequired,
};

export default function NewForm({
  submitForm,
  setErrorMessage,
  setShowNewModal,
}) {
  const [users, setUsers] = useState([]);
  const [trips, setTrips] = useState([]);
  const [selectedTrip, setSelectedTrip] = useState(null);
  const [mainProductsForTrip, setMainProductsForTrip] = useState([]);
  const schema = Yup.object().shape({
    // user_id: Yup.number().integer().required("Required"),
  });

  //const { register, handleSubmit, errors, reset } = useForm({
  const { register, handleSubmit, control, errors } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    (async () => {
      populateUsers();
    })();
    (async () => {
      getTripsForOptions();
    })();
  }, []);

  async function populateUsers() {
    const response = await getUsers();
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      setUsers(response);
    }
  }

  async function getTripsForOptions() {
    const response = await getTrips();
    response.errorMsg ? setErrorMessage(response.errorMsg) : setTrips(response);
  }

  async function handleTripChange(event) {
    const response = await getTrip(event.value);
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      setSelectedTrip(response);
      setMainProductsForTrip(
        response.products
          .filter((product) => product.product_type === "main")
          .sort(
            (a, b) =>
              b.is_visible - a.is_visible || a.name.localeCompare(b.name)
          )
      );
    }
  }

  function processSubmitForm(data) {
    data.products = [{ quantity: 1, id: data.product_id }];
    submitForm(data);
  }

  function sortFirstLastName(a, b) {
    if (a.first_name.toLowerCase() > b.first_name.toLowerCase()) return 1;
    if (a.first_name.toLowerCase() < b.first_name.toLowerCase()) return -1;
    if (a.last_name.toLowerCase() > b.last_name.toLowerCase()) return 1;
    if (a.last_name.toLowerCase() < b.last_name.toLowerCase()) return -1;
    if (a.email > b.email) return 1;
    if (a.email < b.email) return -1;
    return 0;
  }

  return (
    <form onSubmit={handleSubmit(processSubmitForm)} noValidate>
      <ModalBody>
        <div className="grid gap-6 divide-y">
          <div>
            <h4 className="font-medium">Traveler information</h4>
            <div className="mt-4">
              <SelectSearch
                name="user_id"
                label="User"
                options={users.sort(sortFirstLastName).map((user) => ({
                  label: `${user.first_name} ${user.last_name} (${user.email})`,
                  value: user.id,
                }))}
                errors={errors}
                control={control}
                helper="The traveler who will own this reservation."
              />
            </div>
            <div className="inline-grid grid-cols-3 gap-6 mt-6">
              <SelectField
                name="num_adults"
                label="Adults"
                options={Array.from(Array(21).keys())}
                errors={errors}
                register={register}
                className="w-24"
              />
              <SelectField
                name="num_children"
                label="Children"
                options={Array.from(Array(11).keys())}
                errors={errors}
                register={register}
                className="w-24"
              />
              <SelectField
                name="num_infants"
                label="Infants"
                options={Array.from(Array(11).keys())}
                errors={errors}
                register={register}
                className="w-24"
              />
            </div>
          </div>
          <div className="pt-2">
            <InputTextArea
              name="message"
              label="Message (optional)"
              errors={errors}
              register={register}
              autoFocus
              className="mt-6"
              minRows={8}
              inputClassName="h-44"
              cacheMeasurements
            />
          </div>
          <div className="pt-5">
            <h4 className="font-medium">Reservation</h4>
            <div className="mt-4">
              <SelectSearch
                name="trip_id"
                label="Trip"
                helper="Choose your trip to see and set more reservation information"
                options={trips
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((trip) => ({
                    label: trip.name,
                    value: trip.id,
                  }))}
                errors={errors}
                control={control}
                onChange={handleTripChange}
              />
              {selectedTrip && mainProductsForTrip.length > 0 && (
                <SelectField
                  name="product_id"
                  label="Main product"
                  options={mainProductsForTrip.map((product) => ({
                    name: `${product.name} (${
                      product.is_visible ? "visible" : "not visible"
                    })`,
                    id: product.id,
                  }))}
                  errors={errors}
                  register={register}
                  helper="The room or route this reservation is based on."
                  className="mt-6"
                />
              )}
              {selectedTrip && (
                <div className="inline-grid grid-cols-2 gap-6 mt-6">
                  <InputText
                    name="start_date"
                    type="date"
                    label="Start date"
                    placeholder="yyyy-mm-dd"
                    errors={errors}
                    register={register}
                  />
                  {mainProductsForTrip.length > 0 &&
                    mainProductsForTrip[0].date_selection_type ===
                      "flexible" && (
                      <InputText
                        name="end_date"
                        type="date"
                        label="End date"
                        placeholder="yyyy-mm-dd"
                        errors={errors}
                        register={register}
                      />
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="secondary" onClick={() => setShowNewModal(false)}>
          Cancel
        </Button>
        <Button type="submit">Save</Button>
      </ModalFooter>
    </form>
  );
}
