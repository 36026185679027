import React from "react";
import PropTypes from "prop-types";

FormTitle.propTypes = {
  title: PropTypes.node.isRequired,
};

export default function FormTitle({ title }) {
  return <h1 className="text-4xl font-bold">{title}</h1>;
}
