import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import TextField from "components/common/TextField";
import HiddenField from "components/common/HiddenField";
import { useAuthContext } from "context/auth-context";
import { postTwoFactorCode } from "lib/api";
import * as Yup from "yup";
import { ErrorAlert } from "components/common/Alerts";
import Card from "components/common/Card";
import CardBody from "components/common/CardBody";
import Button from "lunar/Button";
import CardTitle from "components/common/CardTitle";

export default function TwoFactor() {
  const authContext = useAuthContext();
  const [errorMessage, setErrorMessage] = useState("");
  const schema = Yup.object().shape({
    one_time_password: Yup.string().required("OTP is required"),
    two_factor_code: Yup.string().required("Two factor code is required"),
  });

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { one_time_password: authContext.user.oneTimePassword },
  });

  async function submitForm(data) {
    const response = await postTwoFactorCode(data);
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      authContext.setUserData(response);
      window.location.href = "/";
    }
  }

  return (
    <Card>
      <CardBody>
        <CardTitle>Admin</CardTitle>
        <ErrorAlert message={errorMessage} />
        <div className="mb-2">
          <p>Check your phone and enter the number texted to you.</p>
        </div>
        <form onSubmit={handleSubmit(submitForm)} noValidate>
          <TextField
            name="two_factor_code"
            type="text"
            label="Two Factor Code"
            errors={errors}
            register={register}
          />
          <HiddenField name="one_time_password" register={register} />

          <div className="mt-4">
            <Button type="submit" isSubmitting={isSubmitting}>
              Save
            </Button>
          </div>
        </form>
      </CardBody>
    </Card>
  );
}
