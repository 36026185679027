import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Table from "components/common/Table";
import { longDateTime } from "helpers/formatting";
import Card from "components/common/Card";
import CardHeading from "components/common/CardHeading";

Messages.propTypes = {
  threads: PropTypes.array,
};

const defaultThreads = [];

export default function Messages({ threads = defaultThreads }) {
  const columns = React.useMemo(() => [
    {
      Header: "Status",
      accessor: "last_message_sender_type",
      Cell: (props) => { // eslint-disable-line react/display-name
        return props.value === "User" ? ( // eslint-disable-line react/prop-types
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800">
            Awaiting a reply
          </span>
        ) : (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
            Waiting for customer
          </span>
        );
      },
    },
    {
      Header: "Guide",
      accessor: "operator",
    },
    {
      Header: "Last message",
      accessor: "last_message_at",
    },
    {
      Header: "Actions",
      accessor: "actionsId",
      Cell: (props) => { // eslint-disable-line react/display-name
        return (
          <>
            <Link
              className="text-blue-600 hover:text-blue-900"
              to={`/messages/${props.value}`} // eslint-disable-line react/prop-types
            >
              View
            </Link>
          </>
        );
      },
    },
  ]);
  
  return (
    <Card>
      <CardHeading heading="Messages" />
      <Table
        data={threads.map((thread) => ({
          last_message_sender_type: thread.last_message_sender_type,
          operator: thread.operator_name,
          last_message_at: longDateTime(thread.last_message_at),
          actionsId: thread.id,
        }))}
        columns={columns}
        sortOptions={{id: "last_message_at", desc: true}}
      />
    </Card>
  );
}
