import React from "react";
import PropTypes from "prop-types";

CardFooter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function CardFooter({ children }) {
  return (
    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
      <div>{children}</div>
    </div>
  );
}
