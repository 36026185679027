import React from "react";
import Navbar from "../common/Navbar";
import Loading from "lunar/Loading";
import { Outlet } from "react-router-dom";
import { useLoadingContext } from "context/loading-context";

export default function Authenticated() {
  const { loading } = useLoadingContext();
  return (
    <div className="flex flex-row max-h-screen overflow-hidden">
      <Navbar />
      <div className="flex flex-col flex-1 min-h-screen overflow-y-scroll">
        <Outlet />
        <Loading show={loading} />
      </div>
    </div>
  );
}
