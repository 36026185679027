import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import {
  getAdventure,
  updateAdventure,
  getGuide,
  deleteAdventureMediaImages,
  updateAdventureMediaImagePositions,
  deleteAdventureSurfImages,
  updateAdventureSurfImagePositions,
} from "lib/api";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useLoadingContext } from "context/loading-context";
import { useToasts } from "react-toast-notifications";
import { FormProvider } from "context/form-context";
import { humanDate } from "helpers/formatting";
import HighlightsTable from "./HighlightsTable";
import AccommodationsTable from "./AccommodationsTable";
import InputTextArea from "lunar/InputTextArea";
import InputText from "lunar/InputText";
import InputImage from "lunar/InputImage";
import Meta from "lunar/Meta";
import Form from "lunar/Form";
import FormTitle from "lunar/FormTitle";
import FormSections from "lunar/FormSections";
import FormSection from "lunar/FormSection";
import FormSectionHeader from "lunar/FormSectionHeader";
import FormSectionBody from "lunar/FormSectionBody";
import FormRow from "lunar/FormRow";
import Gallery from "lunar/gallery/Gallery";
import ButtonText from "lunar/ButtonText";
import InputVideo from "lunar/InputVideo";
import SelectField from "lunar/SelectField";

export default function Edit() {
  const [adventure, setAdventure] = useState({});
  const [faqs, setFaqs] = useState({});
  const [relatedGuide, setRelatedGuide] = useState([]);
  const adventureID = useParams().id;
  const { addToast } = useToasts();
  const { setLoading } = useLoadingContext();

  const {
    register,
    errors,
    reset,
    handleSubmit,
    setValue,
    getValues,
    control,
    resetField,
    formState: { isSubmitting },
  } = useForm();

  useEffect(() => {
    if (adventure) {
      reset({
        name: adventure.name,
        slug: adventure.slug,
        destination_name: adventure.destination_name,
        destination_short_name: adventure.destination_short_name,
        destination_people_adjective: adventure.destination_people_adjective,
        country: adventure.country,
        guide_name: adventure.guide?.name,
        getting_there: adventure.getting_there,
        subtitle: adventure.subtitle,
        description: adventure.description,
        testimonial: adventure.testimonial,
        testimonial_byline: adventure.testimonial_byline,
        your_adventure_who: adventure.your_adventure_who,
        your_adventure_when: adventure.your_adventure_when,
        your_adventure_yours: adventure.your_adventure_yours,
        guide_picks_description: adventure.guide_picks_description,
        accommodations_subtitle: adventure.accommodations_subtitle,
        faqs: Object.entries(adventure.faqs || {}),
        theme_color: adventure.theme_color,
      });
    }
  }, [reset, adventure]);

  useEffect(() => {
    (async () => {
      populateAdventureState(adventureID);
    })();
  }, [adventureID]);

  async function populateAdventureState(adventureID) {
    setLoading(true);
    const response = await getAdventure(adventureID);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      setAdventure(response);
      populateRelatedGuide(response.guide_id);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (adventure.faqs !== undefined) {
      setFaqs(adventure.faqs);
    }
  }, [reset, JSON.stringify(adventure.faqs)]);

  async function populateRelatedGuide(guideID) {
    const response = await getGuide(guideID);
    response.errorMsg
      ? handleError(response.errorMsg)
      : setRelatedGuide(response);
  }

  function handleSuccess(msg) {
    populateAdventureState(adventureID);
    addToast(msg, { appearance: "success" });
  }

  function handleError(msg) {
    addToast(msg, { appearance: "error" });
  }

  function addFaq() {
    setFaqs({ ...faqs, [Date.now()]: "" });
  }

  function removeFaq(index) {
    const list = getValues()["faqs"];
    // create a copy
    const newList = [...list];
    // remove by index
    newList.splice(index, 1);
    // update values
    setFaqs(newList);
    for (let i = 0; i < newList.length; i++) {
      setValue(`faqs[${i}][0]`, newList[i][0]);
      setValue(`faqs[${i}][1]`, newList[i][1]);
    }
  }

  function preProcessForm(data) {
    // transform overview 2D arrays to key/value pairs for JSON
    const faqsObj = {};
    if (data.faqs !== undefined) {
      for (let entry of data.faqs) {
        faqsObj[entry[0]] = entry[1];
      }
    }
    data.faqs = faqsObj;
    return submitForm(data);
  }

  async function submitForm(data) {
    const response = await updateAdventure(adventureID, data);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Adventure successfully updated");
      // remove images/videos from input type file so no re-uploaded on next submission
      for (const [key, val] of Object.entries(data)) {
        if (val instanceof FileList) resetField(key);
      }
    }
  }

  return (
    <>
      <Meta title={adventure.name} />
      <FormProvider
        lastUpdated={adventure.updated_at && humanDate(adventure.updated_at)}
      >
        <DndProvider backend={HTML5Backend}>
          <Form
            handleSubmit={handleSubmit}
            submitForm={preProcessForm}
            isSubmitting={isSubmitting}
          >
            <FormTitle title={adventure.name || ""} />
            <FormSections>
              <FormSection>
                <FormSectionHeader
                  title="Settings"
                  description="The primary settings forming adventure presentations."
                />
                <FormSectionBody className="flex flex-col gap-y-6">
                  <FormRow>
                    <InputText
                      name="name"
                      type="text"
                      label="Adventure name"
                      errors={errors}
                      register={register}
                      className="flex-grow"
                      disabled={true}
                    />
                    <InputText
                      name="guide_name"
                      type="text"
                      label="Guide"
                      errors={errors}
                      register={register}
                      className="flex-grow"
                      disabled={true}
                    />
                    <InputText
                      name="slug"
                      type="text"
                      label="URL slug"
                      errors={errors}
                      register={register}
                      className="flex-grow"
                      disabled={true}
                    />
                  </FormRow>
                  <FormRow>
                    <InputText
                      name="destination_name"
                      type="text"
                      label="Adventure destination name"
                      errors={errors}
                      register={register}
                      className="flex-grow"
                      disabled={true}
                    />
                    <InputText
                      name="destination_short_name"
                      type="text"
                      label="Adventure destination short name"
                      errors={errors}
                      register={register}
                      className="flex-grow"
                      disabled={true}
                    />
                    <InputText
                      name="destination_people_adjective"
                      type="text"
                      label="Adventure people adjective"
                      errors={errors}
                      register={register}
                      className="flex-grow"
                      disabled={true}
                    />
                  </FormRow>
                  <FormRow>
                    <InputText
                      name="country"
                      type="text"
                      label="Country"
                      errors={errors}
                      register={register}
                    />
                    <SelectField
                      name="theme_color"
                      label="Theme color"
                      options={["navy", "purple", "brown", "gold", "forest"]}
                      errors={errors}
                      register={register}
                    />
                  </FormRow>
                  <div>
                    <p className="text-sm font-medium text-gray-700">
                      Related guide
                    </p>
                    <p className="mt-2 text-sm">
                      <Link
                        className="text-blue-600 hover:text-blue-900"
                        to={`/guides/edit/${relatedGuide.id}`}
                      >
                        {relatedGuide.name}
                      </Link>
                    </p>
                  </div>
                </FormSectionBody>
              </FormSection>
              <FormSection>
                <FormSectionHeader
                  title="Hero"
                  description="The first things a traveler sees including listing hero, adventure cards and open graph images."
                />
                <FormSectionBody className="grid gap-y-6">
                  <FormRow>
                    <InputTextArea
                      name="subtitle"
                      type="text"
                      label="Subtitle"
                      helper="Limit 255 characters."
                      errors={errors}
                      register={register}
                      maxRows={2}
                      maxLength={255}
                      className="w-full"
                    />
                  </FormRow>
                  <div className="p-8 border rounded-lg bg-gray-50">
                    <p className="pb-4 mb-6 text-sm font-medium text-gray-600 border-b">
                      Images for listing hero and adventure cards
                    </p>
                    <FormRow>
                      <InputImage
                        name="main_image"
                        label="Land cover"
                        register={register}
                        image={adventure.main_image}
                        previewAspectRatio="3/2"
                      />
                      <InputImage
                        name="hero_card"
                        label="Port cover"
                        register={register}
                        image={adventure.hero_card}
                        previewAspectRatio="5/6"
                      />
                    </FormRow>
                  </div>
                  <div className="p-8 border rounded-lg bg-gray-50">
                    <p className="pb-4 mb-6 text-sm font-medium text-gray-600 border-b">
                      Video for large screens
                    </p>
                    <FormRow>
                      <InputImage
                        name="hero_montage_first_frame_land"
                        label="Land first frame"
                        register={register}
                        image={adventure.hero_montage_first_frame_land}
                        previewAspectRatio="16/9"
                      />
                      <div className="flex flex-col gap-y-6">
                        <InputVideo
                          name="hero_montage_land_mp4"
                          label="Land mp4"
                          register={register}
                          video={adventure.hero_montage_land_mp4}
                        />
                        <InputVideo
                          name="hero_montage_land_webm"
                          label="Land webm"
                          register={register}
                          video={adventure.hero_montage_land_webm}
                        />
                      </div>
                    </FormRow>
                  </div>
                  <div className="p-8 border rounded-lg bg-gray-50">
                    <p className="pb-4 mb-6 text-sm font-medium text-gray-600 border-b">
                      Video for small screens
                    </p>
                    <FormRow>
                      <InputImage
                        name="hero_montage_first_frame_port"
                        label="Port first frame"
                        register={register}
                        image={adventure.hero_montage_first_frame_port}
                        previewAspectRatio="9/16"
                      />
                      <div className="flex flex-col gap-y-6">
                        <InputVideo
                          name="hero_montage_port_mp4"
                          label="Port mp4"
                          register={register}
                          video={adventure.hero_montage_port_mp4}
                        />
                        <InputVideo
                          name="hero_montage_port_webm"
                          label="Port webm"
                          register={register}
                          video={adventure.hero_montage_port_webm}
                        />
                      </div>
                    </FormRow>
                  </div>
                </FormSectionBody>
              </FormSection>
              <FormSection>
                <FormSectionHeader
                  title="The surf"
                  description="The things that make surfing in this destination special."
                />
                <FormSectionBody className="grid gap-y-6">
                  <InputTextArea
                    name="description"
                    type="text"
                    label="Why surf there?"
                    helper="Limit 500 characters."
                    errors={errors}
                    register={register}
                    maxRows={3}
                    maxLength={500}
                  />
                  <div className="p-8 border rounded-lg bg-gray-50">
                    <p className="pb-4 mb-6 text-sm font-medium text-gray-600 border-b">
                      Video montage of the surf
                    </p>
                    <FormRow>
                      <InputImage
                        name="surf_montage_first_frame"
                        label="Land first frame"
                        register={register}
                        image={adventure.surf_montage_first_frame}
                        previewAspectRatio="16/9"
                      />
                      <div className="flex flex-col gap-y-6">
                        <InputVideo
                          name="surf_montage_land_mp4"
                          label="Land mp4"
                          register={register}
                          video={adventure.surf_montage_land_mp4}
                        />
                        <InputVideo
                          name="surf_montage_land_webm"
                          label="Land webm"
                          register={register}
                          video={adventure.surf_montage_land_webm}
                        />
                      </div>
                    </FormRow>
                  </div>
                  <div className="p-8 border rounded-lg bg-gray-50">
                    <p className="pb-4 mb-6 text-sm font-medium text-gray-600 border-b">
                      Images when no surf video montage
                    </p>
                    <FormRow className="grid grid-flow-row">
                      <Gallery
                        parentID={adventure.id}
                        images={adventure.adventure_surf_images}
                        handleError={handleError}
                        handleSuccess={handleSuccess}
                        updateFunc={updateAdventure}
                        deleteFunc={deleteAdventureSurfImages}
                        updatePositionsFunc={updateAdventureSurfImagePositions}
                        imagesAttributeName="adventure_surf_images_attributes"
                        control={control}
                        setValue={setValue}
                      />
                    </FormRow>
                  </div>
                </FormSectionBody>
              </FormSection>
              <FormSection>
                <FormSectionHeader
                  title="Testimonial"
                  description="The primary review and/or testimonial from a traveler"
                />
                <FormSectionBody className="grid gap-y-6">
                  <InputTextArea
                    name="testimonial"
                    type="text"
                    helper="Limit 255 characters."
                    errors={errors}
                    register={register}
                    maxRows={2}
                    maxLength={255}
                  />
                  <InputText
                    name="testimonial_byline"
                    type="text"
                    label="Byline"
                    helper="e.g. Tess, United States"
                    errors={errors}
                    register={register}
                    className="flex-grow mt-4"
                  />
                </FormSectionBody>
              </FormSection>
              <FormSection>
                <FormSectionHeader
                  title="Your private adventure"
                  description="The nuances of this adventure including who it works well for and when to go."
                />
                <FormSectionBody className="grid gap-y-6">
                  <InputText
                    name="your_adventure_who"
                    type="text"
                    label="Who's it for"
                    errors={errors}
                    register={register}
                    className="flex-grow mt-4"
                  />
                  <InputText
                    name="your_adventure_when"
                    type="text"
                    label="When to go"
                    errors={errors}
                    register={register}
                    className="flex-grow mt-4"
                  />
                  <InputText
                    name="your_adventure_yours"
                    type="text"
                    label="Make it yours"
                    errors={errors}
                    register={register}
                    className="flex-grow mt-4"
                  />
                </FormSectionBody>
              </FormSection>
              <FormSection>
                <FormSectionHeader title="Guide" />
                <p className="mt-2 text-sm text-gray-600">
                  <span>
                    The following is listing specific, please check out{" "}
                  </span>
                  <Link
                    className="font-medium text-blue-600 hover:text-blue-900"
                    to={`/guides/edit/${relatedGuide.id}`}
                  >
                    {relatedGuide.name}&apos;s{" "}
                  </Link>
                  page for more widely used guide details.
                </p>
                <div className="p-8 mt-10 border rounded-lg bg-gray-50">
                  <p className="pb-4 mb-6 text-sm font-medium text-gray-600 border-b">
                    Video montage behind youtube video button
                  </p>
                  <FormRow>
                    <InputImage
                      name="guide_montage_first_frame"
                      label="Land first frame"
                      register={register}
                      image={adventure.guide_montage_first_frame}
                      previewAspectRatio="16/9"
                    />
                    <div className="flex flex-col gap-y-6">
                      <InputVideo
                        name="guide_montage_land_mp4"
                        label="Land mp4"
                        register={register}
                        video={adventure.guide_montage_land_mp4}
                      />
                      <InputVideo
                        name="guide_montage_land_webm"
                        label="Land webm"
                        register={register}
                        video={adventure.guide_montage_land_webm}
                      />
                    </div>
                  </FormRow>
                </div>
              </FormSection>
              <FormSection>
                <FormSectionHeader
                  title="Experiences"
                  description="Specific special things including surfing that travelers might do on this adventure."
                />
                <FormSectionBody className="grid gap-y-6">
                  <InputText
                    name="guide_picks_description"
                    type="text"
                    label="Subtitle"
                    errors={errors}
                    register={register}
                    className="flex-grow mt-4"
                  />
                  <HighlightsTable
                    adventureID={adventureID}
                    highlights={adventure.adventure_highlights}
                    handleError={handleError}
                    handleSuccess={handleSuccess}
                  />
                </FormSectionBody>
              </FormSection>
              <FormSection>
                <FormSectionHeader
                  title="Accommodations"
                  description="Details on the recommended accommodations with a focus on beautiful unique places to stay. "
                />
                <FormSectionBody className="grid gap-y-6">
                  <InputText
                    name="accommodations_subtitle"
                    label="Subtitle"
                    type="text"
                    errors={errors}
                    register={register}
                    className="flex-grow mt-4"
                  />
                  <InputImage
                    name="accommodation_intro"
                    label="Subtitle background image"
                    register={register}
                    image={adventure.accommodation_intro}
                    className="flex-grow mr-10"
                    previewAspectRatio="1512/569"
                  />
                  <AccommodationsTable
                    adventureID={adventureID}
                    accommodations={adventure.adventure_website_accommodations}
                    handleError={handleError}
                    handleSuccess={handleSuccess}
                  />
                </FormSectionBody>
              </FormSection>
              <FormSection>
                <FormSectionHeader
                  title="FAQs"
                  description="The most common questions we get about adventures and this one in particular."
                />
                <FormSectionBody className="grid gap-y-6">
                  <div className="flex flex-col gap-x-10 gap-y-6">
                    {Object.keys(faqs).map((key, index) => (
                      <div key={`faqs-${index}`}>
                        <InputText
                          name={`faqs[${index}][0]`}
                          label="Question"
                          type="text"
                          errors={errors}
                          register={register}
                          placeholder="How well do I need to surf?"
                        />
                        <InputTextArea
                          name={`faqs[${index}][1]`}
                          errors={errors}
                          register={register}
                          rows={6}
                          className="mt-4"
                          placeholder="Write the answer..."
                        />
                        <div className="mt-1 text-sm">
                          <ButtonText
                            onClick={() => removeFaq(index)}
                            className="text-red-600 hover:text-red-900"
                          >
                            Delete
                          </ButtonText>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="pt-10 mt-10 border-t">
                    <button
                      type="button"
                      onClick={addFaq}
                      className="px-3 py-2 text-sm font-medium leading-4 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Add an FAQ
                    </button>
                  </div>
                </FormSectionBody>
              </FormSection>
              <FormSection>
                <FormSectionHeader
                  title="Getting there"
                  description="How do travelers get there? Can include which airport to take, airport transfer info, drive time from airport to accommodations, etc."
                />
                <FormSectionBody className="grid gap-y-6">
                  <InputTextArea
                    name="getting_there"
                    type="text"
                    helper="Limit 500 characters."
                    errors={errors}
                    register={register}
                    maxRows={3}
                    maxLength={500}
                  />
                </FormSectionBody>
              </FormSection>
              <FormSection>
                <FormSectionHeader
                  title="Gallery"
                  description="Photos that help a traveler imagine themselves on this adventure."
                />
                <FormSectionBody>
                  <FormRow className="grid grid-flow-row">
                    <Gallery
                      parentID={adventure.id}
                      images={adventure.adventure_media_images}
                      handleError={handleError}
                      handleSuccess={handleSuccess}
                      updateFunc={updateAdventure}
                      deleteFunc={deleteAdventureMediaImages}
                      updatePositionsFunc={updateAdventureMediaImagePositions}
                      imagesAttributeName="adventure_media_images_attributes"
                      control={control}
                      setValue={setValue}
                    />
                  </FormRow>
                </FormSectionBody>
              </FormSection>
            </FormSections>
          </Form>
        </DndProvider>
      </FormProvider>
    </>
  );
}
