import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalBody from "lunar/ModalBody";
import ModalFooter from "lunar/ModalFooter";
import Button from "lunar/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import TextField from "components/common/TextField";
import HiddenField from "components/common/HiddenField";

CreateUpdateEmployeeModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  handleSaveEmployee: PropTypes.func.isRequired,
  employee: PropTypes.object,
};

export default function CreateUpdateEmployeeModal({
  showModal,
  setShowModal,
  handleSaveEmployee,
  employee = {},
}) {
  const schema = Yup.object().shape({
    first_name: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    title: Yup.string().required("Required"),
  });

  const {
    reset,
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (employee) {
      reset({
        id: employee.id,
        first_name: employee.first_name,
        name: employee.name,
        title: employee.title,
        description: employee.description,
      });
    } else {
      reset({
        id: "",
        first_name: "",
        name: "",
        title: "",
        description: "",
      });
    }
  }, [reset, employee]);

  return (
    <Modal open={showModal} setOpen={setShowModal}>
      <ModalTitle>Create teammate</ModalTitle>
      <form onSubmit={handleSubmit(handleSaveEmployee)} noValidate>
        <ModalBody>
          <HiddenField name="id" register={register} />
          <TextField
            name="first_name"
            type="text"
            label="First Name"
            errors={errors}
            register={register}
          />
          <TextField
            name="name"
            type="text"
            label="Full Name"
            errors={errors}
            register={register}
          />
          <TextField
            name="title"
            type="text"
            label="Title"
            errors={errors}
            register={register}
          />
          <TextField
            name="description"
            type="text"
            label="Description"
            errors={errors}
            register={register}
          />
          <TextField
            name="main_image"
            type="file"
            label="Main Image"
            errors={errors}
            register={register}
          />
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button type="submit" isSubmitting={isSubmitting}>
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
