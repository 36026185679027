/* eslint-disable react/display-name, react/prop-types */
import React, { Fragment, useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import {
  getAdventureBookings,
  getAdventureBooking,
  createAdventureBooking,
  getEventBookings,
} from "lib/api";
import { useLoadingContext } from "context/loading-context";
import { longDate2, money, getDifferenceInDays } from "helpers/formatting";
import { ErrorAlert } from "components/common/Alerts";
import BadgeAdventure from "lunar/BadgeAdventure";
import DataTable from "lunar/DataTable";
import DataTableSearch from "lunar/DataTableSearch";
import Meta from "lunar/Meta";
import PageListNav from "components/common/PageListNav";
import NewModal from "components/adventure_bookings/NewModal";
import ListFilter from "components/adventure_bookings/ListFilter";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import ButtonText from "lunar/ButtonText";
import { useNavigate } from "react-router-dom";

export default function AdventureBookings() {
  const filterList = [
    "All",
    "Samples",
    "Created",
    "Bookable",
    "Partially paid",
    "Fully paid",
    "Expired",
    "Cancelled",
  ];
  const [bookings, setBookings] = useState(null);
  const [filteredBookings, setFilteredBookings] = useState(null);
  const [filterValue, setFilterValue] = useState(filterList[0]);
  const [errorMessage, setErrorMessage] = useState("");
  const [globalFilter, setGlobalFilter] = useState("");
  const { setLoading } = useLoadingContext();
  const { addToast } = useToasts();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      populatePageBookings();
    })();
  }, []);

  useEffect(() => {
    if (bookings) populateFilteredBookings("All");
  }, [bookings]);

  async function populatePageBookings() {
    setLoading(true);
    const response = await getAdventureBookings();
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      const eventResponse = await getEventBookings();
      setBookings(response.concat(eventResponse));
    }
    setLoading(false);
  }

  async function handleDuplicate(id) {
    setLoading(true);
    const response = await getAdventureBooking(id);
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      let newTripData = null;
      let attrs = null;
      if (response.email === "help@thermal.travel") {
        attrs = [
          "adventure_id",
          "email",
          "end_date",
          "first_name",
          "last_name",
          "num_guests",
          "phone_number",
          "start_date",
          "accommodation_id",
          "cs_note_to_traveler",
          "num_guiding_days",
          "num_guiding_half_days",
          "num_guiding_quarter_days",
          "getting_there",
          "cancellation_policy",
          "adventure_line_items_attributes",
          "total",
        ];
        newTripData = attrs
          .filter((key) => key in response)
          .reduce((obj2, key) => ((obj2[key] = response[key]), obj2), {});
        newTripData.adventure_bookings_itinerary_attributes_attributes =
          response.adventure_bookings_itinerary_attributes.map(
            ({ id, adventure_booking_id, ...rest }) => rest // eslint-disable-line no-unused-vars
          );
        newTripData.adventure_line_items_attributes =
          response.adventure_line_items.map(({ id, ...rest }) => rest); // eslint-disable-line no-unused-vars
      } else {
        attrs = [
          "adventure_id",
          "email",
          "end_date",
          "first_name",
          "last_name",
          "num_guests",
          "phone_number",
          "start_date",
        ];
        newTripData = attrs
          .filter((key) => key in response)
          .reduce((obj2, key) => ((obj2[key] = response[key]), obj2), {});
      }
      const newResponse = await createAdventureBooking(newTripData);
      if (newResponse.errorMsg) {
        setErrorMessage(newResponse.errorMsg);
      } else {
        addToast("Booking duplicated", { appearance: "success" });
        populatePageBookings();
      }
    }
    setLoading(false);
  }

  const columns = React.useMemo(() => [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row: { original } }) => {
        return (
          <>
            <p
              className={`font-medium text-gray-${
                original.is_example ? 500 : 900
              }`}
            >
              {original.first_name} {original.last_name}
            </p>
            <p className="text-xs leading-5 text-gray-500">
              {original.adventure || original.event_name}
            </p>
          </>
        );
      },
    },
    {
      Header: "Accommodation",
      accessor: "accommodation_name",
      Cell: (tableProps) => {
        return <>{tableProps.value}</>;
      },
    },
    {
      Header: "Created",
      accessor: "created_at",
      Cell: (tableProps) => {
        return <>{longDate2(tableProps.value)}</>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (tableProps) => {
        return <BadgeAdventure status={tableProps.value} />;
      },
    },
    {
      Header: <>Start&nbsp;date</>,
      accessor: "start_date",
      Cell: (tableProps) => {
        return <>{tableProps.value}</>;
      },
    },
    {
      Header: <>Days</>,
      accessor: "end_date",
      Cell: ({ row: { original } }) => {
        return (
          <>
            {original.start_date &&
              original.end_date &&
              getDifferenceInDays(original.end_date, original.start_date)}
          </>
        );
      },
    },
    {
      Header: <>Guests</>,
      accessor: "num_guests",
      Cell: (tableProps) => {
        return <>{tableProps.value}</>;
      },
    },
    {
      Header: "Total",
      accessor: "total",
      Cell: (tableProps) => {
        return <>{money(tableProps.value)}</>;
      },
    },

    {
      Header: "",
      accessor: "actionsId",
      Cell: ({ row: { original } }) => {
        return (
          <div className="flex justify-end">
            <Menu as="div" className="relative inline-block text-gray-900">
              {({ open }) => (
                <>
                  <Menu.Button className="flex items-center rounded-full focus:outline-none">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon
                      className="w-5 h-5"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="absolute right-0 z-10 w-40 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      <Menu.Item>
                        <ButtonText
                          onClick={(e) => {
                            e.preventDefault(),
                              handleDuplicate(original.actionsId);
                          }}
                          className="block w-full px-4 py-2 text-sm text-left focus:ring-blue-500 hover:bg-gray-100"
                        >
                          Duplicate
                        </ButtonText>
                      </Menu.Item>
                      <Menu.Item>
                        <ButtonText
                          onClick={(e) => {
                            e.preventDefault(), navigate(original.rowLink);
                          }}
                          className="block w-full px-4 py-2 text-sm text-left focus:ring-blue-500 hover:bg-gray-100"
                        >
                          Edit
                        </ButtonText>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        );
      },
    },
  ]);

  function populateFilteredBookings(val) {
    if (val === "All") {
      setFilteredBookings(bookings);
    } else if (val === "Samples") {
      setFilteredBookings(
        bookings.filter((booking) => booking.email === "help@thermal.travel")
      );
    } else {
      const backEndStatus = (
        val.charAt(0).toLowerCase() + val.slice(1)
      ).replace(/ /g, "_");
      setFilteredBookings(
        bookings.filter((booking) => booking.status === backEndStatus)
      );
    }
  }

  function changeFilterValueAndFilteredBookings(val) {
    setFilterValue(val);
    populateFilteredBookings(val);
  }

  function handleFilterChange(val) {
    changeFilterValueAndFilteredBookings(val.name);
  }

  function handleSetGlobalFilter(val) {
    changeFilterValueAndFilteredBookings("All");
    setGlobalFilter(val);
  }

  return (
    <>
      <Meta title={bookings && `All ${bookings.length} reservations`} />
      <div className="min-h-screen overflow-y-scroll bg-white">
        <PageListNav
          filter={
            <ListFilter
              filterValue={filterValue}
              handleFilterChange={handleFilterChange}
              allFilterValues={filterList}
            />
          }
          dataTableSearch={
            <DataTableSearch
              globalFilter={globalFilter}
              setGlobalFilter={handleSetGlobalFilter}
              placeholder="Search for reservations"
            />
          }
          actions={
            <div className="grid items-center grid-flow-col gap-6">
              <div className="ml-4">
                <NewModal populatePageBookings={populatePageBookings} />
              </div>
            </div>
          }
        />
        <ErrorAlert message={errorMessage} />
        {filteredBookings && (
          <DataTable
            globalFilter={globalFilter}
            data={filteredBookings.map((booking) => ({
              status: booking.status,
              first_name: booking.first_name,
              last_name: booking.last_name,
              name: `${booking.first_name} ${booking.last_name} ${booking.adventure_name}`,
              adventure: booking.adventure_name,
              event_name: booking.event_name,
              start_date: booking.start_date,
              end_date: booking.end_date,
              num_guests: booking.num_guests,
              is_example: booking.email === "help@thermal.travel",
              accommodation_name: booking.accommodation_name,
              total: booking.total,
              created_at: booking.created_at,
              actionsId: booking.transaction_number,
              rowLink: `/${
                booking.event_name !== undefined
                  ? "event_bookings"
                  : "adventure_bookings"
              }/${booking.transaction_number}`,
            }))}
            rowLinkAttribute="rowLink"
            columns={columns}
            sortOptions={{ id: "created_at", desc: true }}
          />
        )}
      </div>
    </>
  );
}
