import React from "react";
import PropTypes from "prop-types";

InputVideo.propTypes = {
  video: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
};

export default function InputVideo({
  video = null,
  label = "video",
  name,
  register,
}) {
  return (
    <div>
      <div className="block text-sm font-medium text-gray-700">{label}</div>
      <input
        id={name}
        name={name}
        type="file"
        {...register(name)}
        className="block w-full mt-2 text-sm text-gray-900 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
      />
      <p className="mt-2 text-xs">
        {video ? (
          <p>
            <a
              href={video.url}
              className="font-medium text-blue-600 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Open video
            </a>
          </p>
        ) : (
          <span className="italic text-gray-600">No current video</span>
        )}
      </p>
    </div>
  );
}
