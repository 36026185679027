import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

InputText.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helper: PropTypes.node,
  type: PropTypes.string.isRequired,
  errors: PropTypes.object,
  register: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  step: PropTypes.number,
  maxLength: PropTypes.number,
  onBlur: PropTypes.func,
  className: PropTypes.string,
};

export default function InputText({
  name,
  label = "",
  helper = "",
  type,
  errors = {},
  register,
  disabled = false,
  placeholder = "",
  maxLength = undefined,
  step = undefined,
  onBlur = undefined,
  className = undefined,
}) {
  return (
    <div className={className}>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <input
        id={name}
        name={name}
        type={type}
        placeholder={placeholder}
        {...register(name)}
        readOnly={disabled}
        maxLength={maxLength}
        step={step}
        onBlur={onBlur}
        className={cn(
          "mt-1 shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-400",
          { "bg-gray-200 cursor-not-allowed": disabled }
        )}
      />
      {errors[name] && (
        <p className="mt-2 text-xs text-red-600">{errors[name].message}</p>
      )}
      {helper && <p className="mt-2 text-xs text-gray-500">{helper}</p>}
    </div>
  );
}
