/* eslint-disable react/prop-types */
import React from "react";
import routes from "routes";
import { Route, Navigate, Routes, useLocation } from "react-router-dom";
import AuthenticatedLayout from "components/layout/Authenticated";
import UnauthenticatedLayout from "components/layout/Unauthenticated";
import ChangePassword from "components/passwords/Change";
import { isLoggedIn } from "helpers/auth";
import { useAuthContext } from "context/auth-context";

export default function App() {
  const user = useAuthContext().getUserData();
  const location = useLocation();

  function RequireAuth({ children }) {
    if (userNeedsPasswordChange()) return <ChangePassword forceChange={true} />
    return isLoggedIn() ? children : <Navigate to="login" state={{ from: location }} />;
  }

  function RequireNoAuth({ children }) {
    if (userNeedsPasswordChange()) return <ChangePassword forceChange={true} />
    return !isLoggedIn() ? children : <Navigate to="adventure_bookings" state={{ from: location }} />;
  }

  function userNeedsPasswordChange() {
    return isLoggedIn() && user.hasChangedPassword !== "true"
  }

  return (
    <Routes>
      {routes.map((route, i) =>
        !route.authorized ? (
          <Route key={i} element={<UnauthenticatedLayout />}>
            <Route
              path={route.path}
              element={
                <RequireNoAuth>
                  <route.component/>
                </RequireNoAuth>
              }
            />
          </Route>
        ) : (
          <Route key={i} element={<AuthenticatedLayout />}>
            <Route
              path={route.path}
              element={
                <RequireAuth>
                  <route.component/>
                </RequireAuth>
              }
            />
          </Route>
        )
      )}
      <Route
        path="*"
        element={
          <Navigate
            replace
            to={{ pathname: isLoggedIn() ? "adventure_bookings" : "login" }}
          />
        }
      />
    </Routes>
  );
}
