import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";

AdventureSelect.propTypes = {
  adventures: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  watchAdd: PropTypes.number,
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AdventureSelect({
  adventures = [],
  onChange,
  watchAdd,
}) {
  const [query, setQuery] = useState("");
  const [selectedAdventure, setSelectedAdventure] = useState(null);

  // clear the select menu after "add" button is clicked
  useEffect(() => {
    setSelectedAdventure(null);
  }, [watchAdd]);

  const filteredAdventures =
    query === ""
      ? adventures
      : adventures.filter((adventure) => {
          return adventure.name.toLowerCase().includes(query.toLowerCase());
        });

  function handleSelectChange(selectedOption) {
    setSelectedAdventure(selectedOption);
    onChange(selectedOption);
  }

  return (
    <Combobox as="div" value={selectedAdventure} onChange={handleSelectChange}>
      <div className="relative mt-1">
        <Combobox.Input
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(adventure) => adventure?.name}
          placeholder="Select an adventure"
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredAdventures.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredAdventures.map((adventure) => (
              <Combobox.Option
                key={adventure.id}
                value={adventure}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-blue-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex items-center">
                      <span
                        className={classNames(
                          "ml-3 truncate",
                          selected && "font-semibold"
                        )}
                      >
                        {adventure.name}
                        <span className="sr-only">
                          {" "}
                          is {adventure.is_visible ? "active" : "inactive"}
                        </span>
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-blue-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
