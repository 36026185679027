import React, { useState, useEffect } from "react";
import {
  getCollection,
  updateCollection,
  deleteCollection,
  deleteCollectionImages,
  updateCollectionImagePositions,
  getCollectionTags,
} from "lib/api";
import { useLoadingContext } from "context/loading-context";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { FormProvider } from "context/form-context";
import { humanDate } from "helpers/formatting";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useNavigate, useParams } from "react-router-dom";
import FormTitle from "lunar/FormTitle";
import InputText from "lunar/InputText";
import SelectField from "lunar/SelectField";
import InputTextArea from "lunar/InputTextArea";
import FormSection from "lunar/FormSection";
import FormSectionHeader from "lunar/FormSectionHeader";
import FormSectionBody from "lunar/FormSectionBody";
import FormRow from "lunar/FormRow";
import Form from "lunar/Form";
import Gallery from "lunar/gallery/Gallery";
import FormSections from "lunar/FormSections";
import Meta from "lunar/Meta";
import ButtonText from "lunar/ButtonText";

export default function EditCollection() {
  const [collection, setCollection] = useState({});
  const [tags, setTags] = useState([]);
  const navigate = useNavigate();
  const collectionID = useParams().id;
  const { addToast } = useToasts();
  const { setLoading } = useLoadingContext();

  const schema = Yup.object().shape({
    name: Yup.string().required("Required"),
  });

  const {
    control,
    register,
    handleSubmit,
    errors,
    reset,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    (async () => {
      getCollectionTagsForOptions();
    })();
  }, []);

  async function getCollectionTagsForOptions() {
    const response = await getCollectionTags();
    response.errorMsg ? handleError(response.errorMsg) : setTags(response);
  }

  useEffect(() => {
    if (collection) {
      reset({
        name: collection.name,
        slug: collection.slug,
        description: collection.description,
        is_visible: collection.is_visible,
        tag_id: collection.tag_id,
      });
    }
  }, [reset, collection]);

  useEffect(() => {
    (async () => {
      populateCollectionState(collectionID);
    })();
  }, [collectionID]);

  async function populateCollectionState(collectionID) {
    setLoading(true);
    const response = await getCollection(collectionID);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      setCollection(response);
    }
    setLoading(false);
  }

  function handleError(msg) {
    addToast(msg, { appearance: "error" });
  }

  function handleSuccess(msg) {
    populateCollectionState(collectionID);
    addToast(msg, { appearance: "success" });
  }

  async function submitForm(data) {
    const response = await updateCollection(collectionID, data);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Collection successfully updated");
    }
  }

  async function submitFormAndClearImages(data) {
    await submitForm(data);
    setValue("collection_images_attributes", []);
  }

  async function handleDeleteCollection(id) {
    if (window.confirm("Are you sure you want to delete this collection?")) {
      const response = await deleteCollection(id);
      if (response.errorMsg) {
        handleError(response.errorMsg);
      } else {
        navigate(`/collections`);
      }
    }
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Meta title={collection.name} />
      <FormProvider
        lastUpdated={collection.updated_at && humanDate(collection.updated_at)}
      >
        <Form
          handleSubmit={handleSubmit}
          submitForm={submitFormAndClearImages}
          isSubmitting={isSubmitting}
        >
          <FormTitle title={collection.name || ""} />
          <FormSections>
            <FormSection>
              <FormSectionHeader
                title="Settings"
                description="Collection information"
              />
              <FormSectionBody>
                <FormRow className="grid grid-cols-3">
                  <InputText
                    name="name"
                    type="text"
                    label="Name"
                    errors={errors}
                    register={register}
                  />
                  <InputText
                    name="slug"
                    type="text"
                    label="Slug"
                    disabled={true}
                    errors={errors}
                    register={register}
                  />
                  <SelectField
                    name="tag_id"
                    label="Tag"
                    options={tags.sort((a, b) => (a.name > b.name ? 1 : -1))}
                    errors={errors}
                    register={register}
                  />
                </FormRow>
                <FormRow className="grid grid-cols-3 mt-2">
                  <SelectField
                    name="is_visible"
                    label="Visible?"
                    options={["true", "false"]}
                    errors={errors}
                    register={register}
                  />
                </FormRow>
              </FormSectionBody>
            </FormSection>
            <FormSection>
              <FormSectionHeader
                title="Description"
                description="All the relevant information for this collection."
              />
              <FormSectionBody>
                <FormRow>
                  <InputTextArea
                    name="description"
                    type="text"
                    errors={errors}
                    register={register}
                    rows={10}
                    className="w-full"
                    helper="Basic markdown formatting supported"
                  />
                </FormRow>
              </FormSectionBody>
            </FormSection>
            <FormSection>
              <FormSectionHeader
                title="Photos"
                description="Photos of the collection."
              />
              <FormSectionBody>
                <FormRow>
                  <Gallery
                    parentID={collectionID}
                    images={collection.collection_images}
                    handleError={handleError}
                    handleSuccess={handleSuccess}
                    updateFunc={updateCollection}
                    deleteFunc={deleteCollectionImages}
                    updatePositionsFunc={updateCollectionImagePositions}
                    imagesAttributeName="collection_images_attributes"
                    control={control}
                    setValue={setValue}
                  />
                </FormRow>
              </FormSectionBody>
            </FormSection>
            <FormSection>
              <ButtonText
                className="mt-2 text-sm text-red-600 hover:underline"
                onClick={() => handleDeleteCollection(collectionID)}
              >
                Delete this collection
              </ButtonText>
            </FormSection>
          </FormSections>
        </Form>
      </FormProvider>
    </DndProvider>
  );
}
