import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

Table.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.node,
  fixed: PropTypes.bool,
};

export default function Table({ children, className, fixed = false }) {
  return (
    <table
      className={cn("w-full", className, fixed ? "table-fixed" : "table-auto")}
    >
      {children}
    </table>
  );
}
