import React from "react";
import PropTypes from "prop-types";
import FormNav from "lunar/FormNav";

Form.propTypes = {
  as: PropTypes.string,
  handleSubmit: PropTypes.func,
  submitForm: PropTypes.func,
  children: PropTypes.node,
  isSubmitting: PropTypes.bool,
};

export default function Form({
  as = "form",
  isSubmitting = false,
  handleSubmit = null,
  submitForm = null,
  children,
}) {
  const ElementType = as;

  return (
    <ElementType
      onSubmit={handleSubmit && handleSubmit(submitForm)}
      className="flex flex-col max-h-screen overflow-hidden"
      noValidate
    >
      <FormNav
        isSubmitting={isSubmitting}
        showSave={handleSubmit ? true : false}
      />
      <div className="overflow-y-auto">
        <div className="max-w-4xl px-20 py-20 mx-auto">{children}</div>
      </div>
    </ElementType>
  );
}
