import React from "react";
import PropTypes from "prop-types";

InputCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
  helper: PropTypes.node,
};

export default function InputCheckbox({
  name,
  value,
  register,
  tooltip = "",
  helper = "",
}) {
  return (
    <div className="relative flex items-start" title={tooltip}>
      <div className="flex items-center h-5">
        <input
          id={value}
          {...register(name)}
          aria-describedby={name}
          name={name}
          value={value}
          type="checkbox"
          className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={value} className="font-medium text-gray-700">
          {value}
        </label>
        {helper && <p className="block text-gray-500">{helper}</p>}
      </div>
    </div>
  );
}
