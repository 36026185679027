import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

FormRow.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.node,
};

export default function FormRow({ children, className }) {
  return (
    <div className={cn("flex flex-row gap-y-4 gap-x-6", className)}>
      {children}
    </div>
  );
}
