import React from "react";
import PropTypes from "prop-types";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalBody from "lunar/ModalBody";
import ModalFooter from "lunar/ModalFooter";
import Button from "lunar/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import TextArea from "components/common/TextArea";
import HiddenField from "components/common/HiddenField";
import Alert from "lunar/Alert";

DeclineModal.propTypes = {
  showDeclineModal: PropTypes.bool.isRequired,
  setShowDeclineModal: PropTypes.func.isRequired,
  handleDecline: PropTypes.func.isRequired,
  senderType: PropTypes.string,
  receiverID: PropTypes.string,
  receiverType: PropTypes.string,
  senderID: PropTypes.number,
  bookingID: PropTypes.string,
};

export default function DeclineModal({
  showDeclineModal,
  setShowDeclineModal,
  handleDecline,
  senderID,
  senderType,
  receiverID,
  receiverType,
  bookingID,
}) {
  const schema = Yup.object().shape({});

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      receiver_id: receiverID,
      receiver_type: receiverType,
      sender_type: senderType,
      sender_id: senderID,
      booking_id: bookingID,
    },
  });

  return (
    <Modal open={showDeclineModal} setOpen={setShowDeclineModal}>
      <ModalTitle>Message to traveler</ModalTitle>

      <form onSubmit={handleSubmit(handleDecline)} noValidate>
        <ModalBody>
          <Alert
            type="warning"
            title="Always message when declining"
            body="Travelers do not
            receive a reservation email for declines, so a traveler message is critical to letting
            them know what happened and why."
          />
          <HiddenField name="receiver_id" register={register} />
          <HiddenField name="receiver_type" register={register} />
          <HiddenField name="sender_type" register={register} />
          <HiddenField name="sender_id" register={register} />
          <HiddenField name="booking_id" register={register} />
          <TextArea
            name="content"
            errors={errors}
            register={register}
            className="mt-6"
            helper="Supports basic markdown formatting"
          />
        </ModalBody>
        <ModalFooter>
          <Button
            variant="secondary"
            onClick={() => setShowDeclineModal(false)}
          >
            Cancel
          </Button>
          <Button type="submit" isSubmitting={isSubmitting}>
            Decline
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
