import React from "react";
import PropTypes from "prop-types";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalBody from "lunar/ModalBody";
import ModalFooter from "lunar/ModalFooter";
import Button from "lunar/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import InputTextAddon from "lunar/InputTextAddon";
import InputText from "lunar/InputText";

WirePaymentModal.propTypes = {
  setShowWirePaymentModal: PropTypes.func.isRequired,
  handleSaveWirePayment: PropTypes.func.isRequired,
  showWirePaymentModal: PropTypes.bool.isRequired,
};

export default function WirePaymentModal({
  showWirePaymentModal,
  setShowWirePaymentModal,
  handleSaveWirePayment,
}) {
  const schema = Yup.object().shape({
    amount: Yup.number().notOneOf([0], "Can't be zero").required("Required"),
    payment_reference_number: Yup.string().required("Required"),
  });

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <Modal open={showWirePaymentModal} setOpen={setShowWirePaymentModal}>
      <ModalTitle>Create a wire payment</ModalTitle>
      <form onSubmit={handleSubmit(handleSaveWirePayment)} noValidate>
        <ModalBody>
          <InputTextAddon
            name="amount"
            type="text"
            label="Amount"
            errors={errors}
            register={register}
            addonLeft="$"
          />
          <InputText
            name="payment_reference_number"
            type="text"
            label="Reference number"
            errors={errors}
            register={register}
            className="mt-6"
          />
        </ModalBody>
        <ModalFooter>
          <Button
            variant="secondary"
            onClick={() => setShowWirePaymentModal(false)}
          >
            Cancel
          </Button>
          <Button type="submit" isSubmitting={isSubmitting}>
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
