import React, { useState } from "react";
import PropTypes from "prop-types";
import { TrashIcon } from "@heroicons/react/24/solid";
import { money } from "helpers/formatting";
import { updateEventBooking } from "lib/api";
import Button from "lunar/Button";
import CreateLineItemModal from "./CreateLineItemModal";
import Table from "lunar/Table";
import TableHead from "lunar/TableHead";
import TableRow from "lunar/TableRow";
import TableBody from "lunar/TableBody";
import TableCell from "lunar/TableCell";
import TableFoot from "lunar/TableFoot";
import TableActions from "lunar/TableActions";

Items.propTypes = {
  handleItemsError: PropTypes.func.isRequired,
  handleItemsSuccess: PropTypes.func.isRequired,
  booking: PropTypes.object.isRequired,
};

export default function Items({
  booking,
  handleItemsError,
  handleItemsSuccess,
}) {
  const [editMode, setEditMode] = useState(false);
  const [showLineItemModal, setShowLineItemModal] = useState(false);

  const bookingID = booking.transaction_number;

  const handleEdit = () => setEditMode(true);
  const handleCancelEdit = () => setEditMode(false);
  const handleShowLineItem = () => setShowLineItemModal(true);

  async function handleSaveLineItem(data) {
    const response = await updateEventBooking(bookingID, {
      event_line_items_attributes: [data],
    });
    if (response.errorMsg) {
      handleItemsError(response.errorMsg);
    } else {
      handleItemsSuccess("Line Item created");
    }
    setShowLineItemModal(false);
  }

  async function deleteItem(id, attributesName, successMsg) {
    const response = await updateEventBooking(bookingID, {
      [attributesName]: [{ id, _destroy: id }],
    });
    if (response.errorMsg) {
      handleItemsError(response.errorMsg);
    } else {
      handleItemsSuccess(successMsg);
    }
    setEditMode(false);
  }

  async function deleteLineItem(id) {
    deleteItem(id, "event_line_items_attributes", "Line Item deleted");
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell head first>
              Description
            </TableCell>
            <TableCell head last>
              Price
            </TableCell>
          </TableRow>
        </TableHead>
        {booking.event_line_items && (
          <TableBody>
            {booking.event_line_items.map((li) => (
              <TableRow key={`lineitem-${li.id}`}>
                <TableCell first className="text-gray-900">
                  {li.description}
                </TableCell>
                <TableCell last>
                  {editMode ? (
                    <button onClick={() => deleteLineItem(li.id)}>
                      <TrashIcon className="w-4 h-4" />
                    </button>
                  ) : (
                    <>{money(li.price)}</>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
        <TableFoot>
          <TableRow>
            <TableCell first className="font-bold text-gray-900">
              Total
            </TableCell>
            <TableCell last className="font-bold text-gray-900">
              {money(booking.total)}
            </TableCell>
          </TableRow>
        </TableFoot>
      </Table>
      <TableActions>
        {editMode ? (
          <>
            <Button variant="secondary" onClick={handleCancelEdit}>
              Cancel
            </Button>
          </>
        ) : (
          <div className="inline-grid grid-flow-col gap-1">
            <Button variant="secondary" onClick={handleEdit}>
              Edit
            </Button>
            <Button onClick={handleShowLineItem}>Add Item</Button>
          </div>
        )}
      </TableActions>
      <CreateLineItemModal
        handleSaveLineItem={handleSaveLineItem}
        setShowLineItemModal={setShowLineItemModal}
        showLineItemModal={showLineItemModal}
      />
    </>
  );
}
