import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { useToasts } from "react-toast-notifications";
import { useNavigate } from "react-router-dom";
import Badge from "lunar/Badge";
import Button from "lunar/Button";
import DialogActionPanel from "lunar/DialogActionPanel";
import DialogActionPanelCenter from "lunar/DialogActionPanelCenter";
import ApproveModal from "components/bookings/sections/ApproveModal";
import DeclineModal from "components/bookings/sections/DeclineModal";
import PaymentModal from "components/bookings/sections/PaymentModal";
import ExpireModal from "components/bookings/sections/ExpireModal";
import ReminderModal from "components/common/ReminderModal";
import {
  approveBooking,
  declineBooking,
  deleteBooking,
  expireBooking,
  createMessage,
  sendPreviewApprovedEmail,
  sendPreviewRequestedEmail,
  sendPreviewConfirmedEmail,
  cancelBooking,
  guideRequest,
  guidePayment,
  sendReminderReservationToGuide,
} from "lib/api";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import ButtonText from "lunar/ButtonText";

Header.propTypes = {
  handleError: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  booking: PropTypes.object,
};

export default function Header({ booking = null, handleError, handleSuccess }) {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const [openDeleteReservation, setOpenDeleteReservation] = useState(false);
  const [openCancelReservation, setOpenCancelReservation] = useState(false);
  const [openSendToGuide, setOpenSendToGuide] = useState(false);

  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showExpireModal, setShowExpireModal] = useState(false);
  const [showReminderModal, setShowReminderModal] = useState(false);

  async function handleCancelBooking() {
    const response = await cancelBooking(booking.transaction_number);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Booking cancelled");
    }
  }

  async function handleDeleteBooking() {
    const response = await deleteBooking(booking.transaction_number);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      addToast("Booking deleted", { appearance: "success" });
      navigate("/bookings");
    }
  }

  async function handleSendToGuide() {
    const response = await guideRequest(booking.transaction_number);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Booking sent to guide");
    }
  }

  async function handleApprove(data) {
    if (data.content.length > 0) {
      const response = await createMessage(data);
      if (response.errorMsg) {
        handleError(response.errorMsg);
      }
    }

    const response = await approveBooking(booking.transaction_number);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      setShowApproveModal(false);
      handleSuccess("Booking approved");
    }
  }

  async function handleDecline(data) {
    if (data.content.length > 0) {
      const response = await createMessage(data);
      if (response.errorMsg) {
        handleError(response.errorMsg);
      }
    }

    const response = await declineBooking(booking.transaction_number);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      setShowDeclineModal(false);
      handleSuccess("Booking declined");
    }
  }

  async function handleSendPaymentToGuide(data) {
    const notes = data.payout_notes.length > 0 ? data.payout_notes : null;
    const response = await guidePayment(booking.transaction_number, notes);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      setShowPaymentModal(false);
      handleSuccess("Booking payment details sent");
    }
  }

  async function handlePreviewRequested() {
    const response = await sendPreviewRequestedEmail(
      booking.transaction_number
    );
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Preview email sent");
    }
  }

  async function handlePreviewApproved() {
    const response = await sendPreviewApprovedEmail(booking.transaction_number);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Preview email sent");
    }
  }

  async function handlePreviewConfirmed() {
    const response = await sendPreviewConfirmedEmail(
      booking.transaction_number
    );
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Preview email sent");
    }
  }

  async function handleExpire(data) {
    const response = await expireBooking(
      booking.transaction_number,
      data.send_to_guide
    );
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      setShowExpireModal(false);
      handleSuccess("Booking expired");
    }
  }

  async function handleSendReminder(data) {
    const response = await sendReminderReservationToGuide(
      booking.transaction_number,
      data
    );
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      setShowReminderModal(false);
      handleSuccess("Reminder sent to guide");
    }
  }

  return (
    <div className="flex flex-row items-center gap-10">
      <div className="inline-flex items-center flex-grow gap-4">
        <div className="w-auto">
          <div className="inline-grid items-center justify-center w-12 h-12 bg-gray-500 rounded-full">
            <span className="font-medium leading-none text-white">{`${booking.user.first_name[0]}${booking.user.last_name[0]}`}</span>
          </div>
        </div>
        <div>
          <div className="inline-grid items-center grid-cols-2 gap-3">
            <h4 className="text-2xl font-bold">
              {`${booking.user.first_name} ${booking.user.last_name}`}
            </h4>
            <div className="w-auto">
              <Badge status={booking.status} />
            </div>
          </div>
          <p className="mt-1 text-sm font-semibold text-gray-600">{`Reserving ${booking.trip.name}`}</p>
        </div>
      </div>
      <div className="text-right">
        <div className="inline-grid grid-flow-col gap-2">
          {(booking.status === "created" || booking.status === "modified") && (
            <Button
              variant="secondary"
              onClick={() => setShowDeclineModal(true)}
            >
              Decline
            </Button>
          )}
          {(booking.status === "created" ||
            booking.status === "admin_created" ||
            booking.status === "modified") &&
            (!booking.sent_to_guide_at ? (
              <Button
                onClick={
                  booking.operator.alert_header
                    ? () => setOpenSendToGuide(true)
                    : handleSendToGuide
                }
              >
                Forward
              </Button>
            ) : (
              <Button onClick={() => setShowApproveModal(true)}>Approve</Button>
            ))}
          {booking.status === "paid" &&
            booking.payment_receipt_sent_to_guide_at === null && (
              <Button onClick={() => setShowPaymentModal(true)}>Forward</Button>
            )}
          <Menu as="div" className="relative z-10 inline-block text-left">
            {({ open }) => (
              <>
                <div>
                  <Menu.Button className="flex items-center mt-2 text-gray-400 rounded-full hover:text-gray-600 focus:outline-none">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon
                      className="w-5 h-5"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>
                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    {(booking.status === "created" ||
                      booking.status === "admin_created") &&
                      !booking.sent_to_guide_at && (
                        <div className="py-1">
                          <Menu.Item
                            disabled={
                              ![
                                "created",
                                "admin_created",
                                "modified",
                              ].includes(booking.status)
                            }
                          >
                            <ButtonText
                              onClick={() => setShowApproveModal(true)}
                              className="block w-full px-4 py-2 text-sm text-left focus:ring-blue-500 hover:bg-blue-50"
                            >
                              Approve without forwarding
                            </ButtonText>
                          </Menu.Item>
                        </div>
                      )}
                    <div className="py-1">
                      <Menu.Item>
                        <ButtonText
                          onClick={
                            booking.status === "paid"
                              ? handlePreviewConfirmed
                              : handlePreviewRequested
                          }
                          className="block w-full px-4 py-2 text-sm text-left focus:ring-blue-500 hover:bg-blue-50"
                          disabled={
                            !(
                              booking.status === "created" ||
                              (booking.status === "admin_created" &&
                                !booking.sent_to_guide_at) ||
                              booking.status === "paid"
                            )
                          }
                        >
                          Preview guide email
                        </ButtonText>
                      </Menu.Item>
                      <Menu.Item>
                        <ButtonText
                          onClick={handlePreviewApproved}
                          className="block w-full px-4 py-2 text-sm text-left focus:ring-blue-500 hover:bg-blue-50"
                          disabled={
                            !(
                              booking.status === "admin_created" ||
                              booking.sent_to_guide_at
                            )
                          }
                        >
                          Preview traveler email
                        </ButtonText>
                      </Menu.Item>
                    </div>
                    <div className="py-1">
                      <Menu.Item>
                        <ButtonText
                          onClick={() => setShowReminderModal(true)}
                          className="block w-full px-4 py-2 text-sm text-left focus:ring-blue-500 hover:bg-blue-50"
                          disabled={
                            !["created", "admin_created"].includes(
                              booking.status
                            ) || !booking.sent_to_guide_at
                          }
                        >
                          Remind guide of request
                        </ButtonText>
                      </Menu.Item>
                      <Menu.Item>
                        <ButtonText
                          onClick={() => setShowExpireModal(true)}
                          className="block w-full px-4 py-2 text-sm text-left focus:ring-blue-500 hover:bg-blue-50"
                          disabled={booking.status !== "approved"}
                        >
                          Mark as expired
                        </ButtonText>
                      </Menu.Item>
                      <Menu.Item>
                        <ButtonText
                          onClick={() => setOpenCancelReservation(true)}
                          className="block w-full px-4 py-2 text-sm text-left focus:ring-blue-500 hover:bg-blue-50"
                          disabled={["cancelled", "admin_created"].includes(
                            booking.status
                          )}
                        >
                          Cancel
                        </ButtonText>
                      </Menu.Item>
                    </div>
                    <div className="py-1">
                      <Menu.Item>
                        <ButtonText
                          onClick={() => setOpenDeleteReservation(true)}
                          className="block w-full px-4 py-2 text-sm text-left text-red-500 focus:ring-blue-500 hover:bg-blue-50"
                          disabled={booking.status !== "admin_created"}
                        >
                          Delete
                        </ButtonText>
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      </div>
      <DeclineModal
        handleDecline={handleDecline}
        setShowDeclineModal={setShowDeclineModal}
        showDeclineModal={showDeclineModal}
        senderID={booking?.operator?.primary_employee_id}
        senderType={"OperatorEmployee"}
        receiverID={booking?.user?.id}
        receiverType={"User"}
        bookingID={booking?.id}
      />
      <ApproveModal
        handleApprove={handleApprove}
        setShowApproveModal={setShowApproveModal}
        showApproveModal={showApproveModal}
        senderID={booking?.operator?.primary_employee_id}
        senderType={"OperatorEmployee"}
        receiverID={booking?.user?.id}
        receiverType={"User"}
        bookingID={booking?.id}
      />
      <ExpireModal
        handleExpire={handleExpire}
        setShowExpireModal={setShowExpireModal}
        showExpireModal={showExpireModal}
      />
      <ReminderModal
        handleSendReminder={handleSendReminder}
        setShowReminderModal={setShowReminderModal}
        showReminderModal={showReminderModal}
      />
      <PaymentModal
        handleSendPaymentToGuide={handleSendPaymentToGuide}
        setShowPaymentModal={setShowPaymentModal}
        showPaymentModal={showPaymentModal}
      />
      <DialogActionPanel
        title="Delete reservation"
        description="This will delete the reservation from the system.  Are you sure you want to do this?"
        buttonText={"Delete reservation"}
        onClick={handleDeleteBooking}
        open={openDeleteReservation}
        setOpen={setOpenDeleteReservation}
      />
      <DialogActionPanel
        title="Cancel reservation"
        description="This will cancel the reservation.  Are you sure you want to do this?"
        buttonText={"Cancel reservation"}
        onClick={handleCancelBooking}
        open={openCancelReservation}
        setOpen={setOpenCancelReservation}
      />
      {booking.operator.alert_header && (
        <DialogActionPanelCenter
          title={booking.operator.alert_header}
          description={booking.operator.alert_subheader}
          buttonText="Done, send to guide"
          onClick={handleSendToGuide}
          open={openSendToGuide}
          setOpen={setOpenSendToGuide}
        />
      )}
    </div>
  );
}
