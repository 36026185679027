import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLoadingContext } from "context/loading-context";
import { ErrorAlert } from "components/common/Alerts";
import { getRegions } from "lib/api";
import Nav from "./Nav";
import FormRegions from "./FormRegions";
import Meta from "lunar/Meta";

export default function RegionsIndex() {
  const [regions, setRegions] = useState([]);
  const [activeRegion, setActiveRegion] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const regionID = useParams().id;
  const { setLoading } = useLoadingContext();

  function handleRegionClick(id) {
    setActiveRegion(regions.find((region) => region.id === id));
    navigate(`/regions/${id}`);
  }

  async function populateRegions() {
    const response = await getRegions();
    setRegions(response);
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getRegions();
      if (response.errorMsg) {
        setErrorMessage(response.errorMsg);
      } else {
        setRegions(response);
        if (response.length > 0) {
          if (regionID === undefined) {
            // rt: hacky way to load the top nav item
            handleRegionClick(160);
          } else {
            setActiveRegion(response.find((region) => region.id === regionID));
          }
        }
      }
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <Meta title={activeRegion && `${activeRegion.name} region`} />
      <ErrorAlert message={errorMessage} />
      <div className="flex flex-row h-screen overflow-hidden ">
        <div className="flex flex-col overflow-hidden w-80">
          <Nav
            regions={regions}
            handleClick={handleRegionClick}
            activeRegionID={activeRegion?.id}
          />
        </div>
        <div className="flex flex-col flex-1 overflow-y-auto border-l border-gray-200">
          {activeRegion && (
            <FormRegions
              region={activeRegion}
              populateRegions={populateRegions}
            />
          )}
        </div>
      </div>
    </>
  );
}
