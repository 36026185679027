import React from "react";
import PropTypes from "prop-types";

ModalBody.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function ModalBody({ children }) {
  return (
    <div
      className="px-4 pt-5 pb-4 overflow-y-auto sm:p-6 sm:pb-6"
      style={{ maxHeight: "50vh" }}
    >
      {children}
    </div>
  );
}
