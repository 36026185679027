import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { formatImageURL } from "helpers/formatting";

InputImage.propTypes = {
  image: PropTypes.object,
  label: PropTypes.string,
  className: PropTypes.node,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  previewAspectRatio: PropTypes.string,
};

export default function InputImage({
  image = null,
  label = "Image",
  name,
  register,
  className = null,
  previewAspectRatio = "auto",
}) {
  return (
    <div className={className}>
      <div className="block text-sm font-medium text-gray-700">{label}</div>
      <div
        className={cn(
          "mt-1 cursor-pointer",
          previewAspectRatio !== "auto" && "inline-block"
        )}
      >
        <div
          className="relative w-auto w-full overflow-hidden rounded-md h-60"
          style={{ aspectRatio: previewAspectRatio }}
        >
          {image && (
            <img
              src={formatImageURL(image?.url, { resize: { height: 400 } })}
              style={{ maxHeight: 400 }}
              className="absolute inset-0 object-cover w-full h-full bg-gray-200"
              alt="Photo"
            />
          )}
          <label
            className={cn(
              "absolute inset-0 flex items-center justify-center w-full h-full",
              image
                ? "text-sm font-medium text-white bg-black bg-opacity-75 opacity-0 hover:opacity-100 focus-within:opacity-100"
                : "border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 "
            )}
          >
            {image ? (
              <div>Change</div>
            ) : (
              <div className="text-gray-500 bg-transparent hover:text-blue-500">
                <svg
                  width="38"
                  height="38"
                  viewBox="0 0 38 38"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M33 0C33.5523 0 34 0.447715 34 1V4H37C37.5523 4 38 4.44772 38 5C38 5.55228 37.5523 6 37 6H34V9C34 9.55229 33.5523 10 33 10C32.4477 10 32 9.55229 32 9V6H29C28.4477 6 28 5.55228 28 5C28 4.44772 28.4477 4 29 4H32V1C32 0.447715 32.4477 0 33 0ZM1.46447 5.46447C2.40215 4.52678 3.67392 4 5 4H21C21.5523 4 22 4.44772 22 5C22 5.55228 21.5523 6 21 6H5C4.20435 6 3.44129 6.31607 2.87868 6.87868C2.31607 7.44129 2 8.20435 2 9V26.5858L9.46489 19.1209C10.4025 18.1835 11.6742 17.6569 13 17.6569C14.3258 17.6569 15.5974 18.1834 16.535 19.1208L21 23.5858L23.4649 21.1209C24.4025 20.1835 25.6742 19.6569 27 19.6569C28.3258 19.6569 29.5974 20.1834 30.535 21.1208L32 22.5858V17C32 16.4477 32.4477 16 33 16C33.5523 16 34 16.4477 34 17V33C34 34.3261 33.4732 35.5979 32.5355 36.5355C31.5979 37.4732 30.3261 38 29 38H5C3.67392 38 2.40215 37.4732 1.46447 36.5355C0.526784 35.5979 0 34.3261 0 33V9C0 7.67392 0.526784 6.40215 1.46447 5.46447ZM32 25.4142L29.121 22.5352C29.121 22.5352 29.121 22.5352 29.121 22.5352C28.5584 21.9729 27.7954 21.6569 27 21.6569C26.2046 21.6569 25.4417 21.9728 24.8791 22.5351C24.8791 22.5351 24.8791 22.5351 24.8791 22.5351L22.4142 25L25.7071 28.2929C26.0976 28.6834 26.0976 29.3166 25.7071 29.7071C25.3166 30.0976 24.6834 30.0976 24.2929 29.7071L15.121 20.5352C15.121 20.5352 15.121 20.5352 15.121 20.5352C14.5584 19.9729 13.7954 19.6569 13 19.6569C12.2046 19.6569 11.4417 19.9728 10.8791 20.5351C10.8791 20.5351 10.8791 20.5351 10.8791 20.5351L2 29.4142V33C2 33.7957 2.31607 34.5587 2.87868 35.1213C3.44129 35.6839 4.20435 36 5 36H29C29.7956 36 30.5587 35.6839 31.1213 35.1213C31.6839 34.5587 32 33.7957 32 33V25.4142ZM20 13C20 12.4477 20.4477 12 21 12H21.02C21.5723 12 22.02 12.4477 22.02 13C22.02 13.5523 21.5723 14 21.02 14H21C20.4477 14 20 13.5523 20 13Z"
                  />
                </svg>
              </div>
            )}
            <span className="sr-only">{label}</span>
            <input
              id={name}
              name={name}
              type="file"
              {...register(name)}
              className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
            />
          </label>
        </div>
        <div className="mt-2 text-xs font-medium text-gray-500">
          {image ? (
            <div className="grid grid-flow-col">
              <div>
                {`${image.width}x${image.height}`}
                {/* {`, ${image.content_type?.replace("image/", "")}`} */}
                {`, ${parseInt(image.byte_size / 1000)}kb`}
                {/* <button
                  type="button"
                  className="ml-4 font-medium text-gray-500 hover:underline "
                >
                  Delete
                </button> */}
              </div>
              <div className="text-right">
                <a
                  href={formatImageURL(image.url)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-medium text-blue-600 hover:underline"
                >
                  Open
                </a>
              </div>
            </div>
          ) : (
            <div>Upload or drag and drop</div>
          )}
        </div>
      </div>
    </div>
  );
}
