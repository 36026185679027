import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalBody from "lunar/ModalBody";
import ModalFooter from "lunar/ModalFooter";
import Button from "lunar/Button";
import InputText from "lunar/InputText";

LocationModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  lat: PropTypes.string,
  lng: PropTypes.string,
};

export default function LocationModal({
  showModal,
  setShowModal,
  submitForm,
  lat,
  lng,
}) {
  const { register, handleSubmit, errors, reset } = useForm({});

  function handleSubmitForm(data) {
    const arr = data.lat_and_lng.split(",").map((item) => item.trim());
    submitForm({ address_lat: arr[0], address_lng: arr[1] });
    setShowModal(false);
  }

  useEffect(() => {
    if (lat) {
      reset({
        lat_and_lng: `${lat}, ${lng}`,
      });
    }
  }, [reset, lat, lng]);

  return (
    <Modal open={showModal} setOpen={setShowModal}>
      <ModalTitle>Set the location</ModalTitle>
      <form onSubmit={handleSubmit(handleSubmitForm)} noValidate>
        <ModalBody>
          <InputText
            name="lat_and_lng"
            type="text"
            label="Latitude and longitude"
            placeholder="9.343493, -82.179123"
            errors={errors}
            register={register}
            helper={
              <>
                The best way to find latitude and longitude for a location is
                through{" "}
                <a
                  href="https://www.google.com/maps/@37.7576548,-122.4353989,16055907m/data=!3m1!1e3"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-medium text-blue-500 hover:underline"
                >
                  Google Maps
                </a>
                . To copy the latitude and longitude, right-click on the map
                pin.
              </>
            }
          />
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button type="submit" isSubmitting={false}>
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
