import React from "react";
import PropTypes from "prop-types";
import { formatImageURL } from "helpers/formatting";

const GalleryCardImage = ({ imageObj = {} }) => (
  <div className="relative" style={{ cursor: "move" }}>
    {imageObj && (
      <div className="relative grid w-full overflow-hidden bg-black border rounded-lg h-36 place-items-center">
        <img
          src={formatImageURL(imageObj.file.url, {
            resize: { height: 150 },
          })}
          style={{ maxHeight: 150 }}
          draggable="false"
        />
      </div>
    )}
  </div>
);

GalleryCardImage.propTypes = {
  imageObj: PropTypes.object,
};

export default GalleryCardImage;
