/* eslint-disable react/display-name, react/prop-types */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getBookings } from "lib/api";
import { useLoadingContext } from "context/loading-context";
import { longDateHoursMinutes, money, humanDate } from "helpers/formatting";
import { ErrorAlert } from "components/common/Alerts";
import Badge from "lunar/Badge";
import DataTable from "lunar/DataTable";
import DataTableSearch from "lunar/DataTableSearch";
import Meta from "lunar/Meta";
import PageListNav from "components/common/PageListNav";
import ListStat from "components/common/ListStat";
import ListFilter from "components/bookings/ListFilter";
import NewModal from "components/bookings/NewModal";

export default function Bookings() {
  const filterList = ["Open", "Paid", "All"];
  Object.freeze(filterList);
  const [bookings, setBookings] = useState(null);
  const [filteredBookings, setFilteredBookings] = useState(null);
  const [filterValue, setFilterValue] = useState(filterList[0]);
  const [errorMessage, setErrorMessage] = useState("");
  const [globalFilter, setGlobalFilter] = useState("");
  const { setLoading } = useLoadingContext();

  useEffect(() => {
    (async () => {
      populatePageBookings();
    })();
  }, []);

  useEffect(() => {
    if (bookings) populateFilteredBookings("Open");
  }, [bookings]);

  async function populatePageBookings() {
    setLoading(true);
    const response = await getBookings();
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      setBookings(response);
    }
    setLoading(false);
  }

  const columns = React.useMemo(() => [
    {
      Header: "Status",
      accessor: "status",
      Cell: (tableProps) => {
        return <Badge status={tableProps.value} />;
      },
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row: { original } }) => {
        return (
          <>
            <Link
              className="block underline truncate hover:no-underline"
              style={{ maxWidth: "10rem" }}
              to={`/users/${original.userID}`}
            >
              {original.name}
            </Link>
          </>
        );
      },
    },
    {
      Header: "Trip",
      accessor: "trip",
      Cell: ({ row: { original } }) => {
        return (
          <>
            <Link
              className="underline hover:no-underline"
              to={`/trips/edit/${original.tripID}`}
            >
              {original.trip}
            </Link>
          </>
        );
      },
    },
    {
      Header: "Start date",
      accessor: "start_date",
      Cell: (tableProps) => {
        return <>{humanDate(tableProps.value)}</>;
      },
    },
    {
      Header: "Total",
      accessor: "total",
      Cell: (tableProps) => {
        return <>{money(tableProps.value)}</>;
      },
    },
    {
      Header: "Created",
      accessor: "created_at",
      Cell: (tableProps) => {
        return <>{longDateHoursMinutes(tableProps.value)}</>;
      },
    },
    {
      Header: "Actions",
      accessor: "actionsId",
      Cell: (tableProps) => {
        return (
          <>
            <Link
              className="text-blue-600 hover:text-blue-900"
              to={`/bookings/${tableProps.value}`}
            >
              View
            </Link>
          </>
        );
      },
    },
  ]);

  function populateFilteredBookings(val) {
    if (val === "Open") {
      setFilteredBookings(
        bookings.filter((booking) =>
          ["admin_created", "created", "approved"].includes(booking.status)
        )
      );
    } else if (val === "Paid") {
      setFilteredBookings(
        bookings.filter((booking) => booking.status === "paid")
      );
    } else {
      setFilteredBookings(bookings);
    }
  }

  function changeFilterValueAndFilteredBookings(val) {
    setFilterValue(val);
    populateFilteredBookings(val);
  }

  function handleFilterChange(val) {
    changeFilterValueAndFilteredBookings(val.name);
  }

  function handleSetGlobalFilter(val) {
    changeFilterValueAndFilteredBookings("All");
    setGlobalFilter(val);
  }

  return (
    <>
      <Meta title={bookings && `All ${bookings.length} reservations`} />
      <div className="min-h-screen overflow-y-scroll">
        <PageListNav
          filter={
            <ListFilter
              filterValue={filterValue}
              handleFilterChange={handleFilterChange}
              allFilterValues={filterList}
            />
          }
          dataTableSearch={
            <DataTableSearch
              globalFilter={globalFilter}
              setGlobalFilter={handleSetGlobalFilter}
              placeholder="Search for reservations"
            />
          }
          actions={
            <div className="grid items-center grid-flow-col gap-6">
              {bookings && (
                <>
                  <ListStat
                    color="red"
                    count={
                      bookings.filter((trip) => trip.status === "created")
                        .length
                    }
                    text="Created"
                  />
                  <ListStat
                    color="yellow"
                    count={
                      bookings.filter((trip) => trip.status === "approved")
                        .length
                    }
                    text="Approved"
                  />
                  <ListStat
                    color="green"
                    count={
                      bookings.filter((trip) => trip.status === "paid").length
                    }
                    text="Paid"
                  />
                  <ListStat color="gray" count={bookings.length} text="Total" />
                </>
              )}
              <div className="ml-4">
                <NewModal populatePageBookings={populatePageBookings} />
              </div>
            </div>
          }
        />
        <ErrorAlert message={errorMessage} />
        {filteredBookings && (
          <DataTable
            globalFilter={globalFilter}
            data={filteredBookings.map((booking) => ({
              status: booking.status,
              name: `${booking.user_first_name} ${booking.user_last_name}`,
              userID: booking.user_id,
              trip: booking.trip_name,
              tripID: booking.trip_id,
              start_date: booking.start_date,
              total: booking.total,
              created_at: booking.created_at,
              actionsId: booking.transaction_number,
            }))}
            columns={columns}
            sortOptions={{ id: "created_at", desc: true }}
          />
        )}
      </div>
    </>
  );
}
