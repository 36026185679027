import React from "react";
import PropTypes from "prop-types";
import { useTable, useSortBy } from "react-table";

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  sortOptions: PropTypes.object,
};

const defaultSortOptions = {};

export default function Table({
  columns = [],
  data = [],
  sortOptions = defaultSortOptions,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [sortOptions],
      },
    },
    useSortBy
  );

  return (
    <table className="min-w-full divide-y divide-gray-200" {...getTableProps()}>
      <thead className="bg-gray-50">
        {headerGroups.map((headerGroup, idx) => (
          <tr key={`group-${idx}`} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, idy) => (
              // <th
              //   className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              //   {...column.getHeaderProps()}
              // >
              //   {column.render("Header")}
              // </th>
              <th
                key={`column-${idx}-${idy}`}
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render("Header")}
                <span>
                  {column.isSorted ? (column.isSortedDesc ? "" : "") : ""}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody
        className="bg-white divide-y divide-gray-200"
        {...getTableBodyProps()}
      >
        {rows.map((row, idx) => {
          prepareRow(row);
          return (
            <tr key={`row-${idx}`} {...row.getRowProps()}>
              {row.cells.map((cell, idy) => {
                return (
                  <td
                    key={`cell-${idx}-${idy}`}
                    className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500"
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
