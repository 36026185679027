import React from "react";
import PropTypes from "prop-types";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalBody from "lunar/ModalBody";
import ModalFooter from "lunar/ModalFooter";
import Button from "lunar/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import TextField from "components/common/TextField";
import TextArea from "components/common/TextArea";

CreateCreditModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func.isRequired,
  handleCreateCredit: PropTypes.func.isRequired,
};

export default function CreateCreditModal({
  showModal = false,
  setShowModal,
  handleCreateCredit,
}) {
  const schema = Yup.object().shape({
    amount: Yup.number().moreThan(0).required("Required"),
  });

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <Modal open={showModal} setOpen={setShowModal}>
      <ModalTitle>Create Credit</ModalTitle>
      <form onSubmit={handleSubmit(handleCreateCredit)} noValidate>
        <ModalBody>
          <TextField
            name="amount"
            type="text"
            label="Amount ($)"
            errors={errors}
            register={register}
          />
          <TextField
            name="expiration_date"
            type="date"
            label="Expiration Date"
            placeholder="yyyy-mm-dd"
            errors={errors}
            register={register}
          />
          <TextArea
            name="notes"
            type="text"
            label="Notes"
            errors={errors}
            register={register}
          />
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button type="submit" isSubmitting={isSubmitting}>
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
