import React, { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { useParams } from "react-router-dom";
import { getEventBooking } from "lib/api";
import { useLoadingContext } from "context/loading-context";
import Meta from "lunar/Meta";
import Header from "./sections/Header";
import Summary from "./sections/Summary";
import Items from "./sections/Items";
import NoteToTraveler from "./sections/NoteToTraveler";
import Payments from "./sections/Payments";
import Refunds from "./sections/Refunds";
import Activity from "./sections/Activity";

export default function EventBooking() {
  const [booking, setBooking] = useState({});
  const bookingID = useParams().id;
  const { addToast } = useToasts();
  const { setLoading } = useLoadingContext();

  useEffect(() => {
    (async () => {
      populateBookingState(bookingID);
    })();
  }, [bookingID]);

  async function populateBookingState(bookingID) {
    setLoading(true);
    const response = await getEventBooking(bookingID);
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      setBooking(response);
    }
    setLoading(false);
  }

  function setErrorMessage(msg) {
    addToast(msg, { appearance: "error" });
  }

  function handleSaveSuccess(msg) {
    populateBookingState(bookingID);
    addToast(msg, { appearance: "success" });
  }

  return (
    <div className="bg-white">
      <Meta
        title={
          booking &&
          `Reservation for ${booking.first_name} ${booking.last_name}`
        }
      />
      {booking?.transaction_number && (
        <div className="px-20 py-10 mx-auto max-w-screen-2xl">
          <Header
            booking={booking}
            handleError={setErrorMessage}
            handleSuccess={handleSaveSuccess}
          />
          <div className="flex pt-10 mt-10 border-t">
            <div className="flex-none w-48">
              <Summary
                booking={booking}
                handleError={setErrorMessage}
                handleSuccess={handleSaveSuccess}
              />
            </div>
            <div className="grid gap-10 pl-10 ml-10 border-l divide-y auto-flow-row w-full">
              <div className={booking.status === "fully_paid" ? "pt-10" : undefined}>
                <h2 className="mb-3 text-xl font-medium">Note to traveler</h2>
                <NoteToTraveler
                  note={booking.cs_note_to_traveler}
                  handleError={setErrorMessage}
                  handleSuccess={handleSaveSuccess}
                  bookingID={booking.transaction_number}
                />
              </div>
              <div className="pt-10">
                <h2 className="mb-3 text-xl font-medium">Pricing</h2>
                <Items
                  booking={booking}
                  handleItemsError={setErrorMessage}
                  handleItemsSuccess={handleSaveSuccess}
                />
              </div>
              <div className="pt-10">
                <h2 className="mb-4 text-xl font-medium">Payments</h2>
                <Payments
                  bookingID={booking.transaction_number}
                  payments={booking.event_payments}
                  handleError={setErrorMessage}
                  handleSuccess={handleSaveSuccess}
                />
              </div>
              {booking.event_payments.length > 0 && (
                <div className="pt-10">
                  <h2 className="mb-4 text-xl font-medium">Refunds</h2>
                  <Refunds
                    bookingID={booking.id}
                    refunds={booking.event_refunds}
                    handleError={setErrorMessage}
                    handleSuccess={handleSaveSuccess}
                  />
                </div>
              )}
              <div className="pt-10">
                <h2 className="mb-4 text-xl font-medium">Activity</h2>
                <Activity booking={booking} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
