import React from "react";
import PropTypes from "prop-types";

Card.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function Card({ children }) {
  return (
    <div className="bg-white shadow sm:rounded-lg mb-10 overflow-hidden">
      {children}
    </div>
  );
}
