import React, { useEffect, useState, useCallback } from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import Modal from "lunar/Modal";
import ModalTitle from "lunar/ModalTitle";
import ModalBody from "lunar/ModalBody";
import ModalFooter from "lunar/ModalFooter";
import Button from "lunar/Button";
import { useForm } from "react-hook-form";
import HiddenField from "components/common/HiddenField";
import InputText from "lunar/InputText";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { isPast, differenceInCalendarDays, parseISO, format } from "date-fns";
import { Tab } from "@headlessui/react";
import styles from "components/products/FlexibleAvailabilityModal.module.scss";

FlexibleAvailabilityModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  productID: PropTypes.string,
  variants: PropTypes.array,
};

export default function FlexibleAvailabilityModal({
  showModal,
  setShowModal,
  handleSave,
  productID = "",
  variants = [],
}) {
  const [availabilityCalendarValue, setAvailabilityCalendarValue] =
    useState(null);
  const [unavailableDates, setUnavailableDates] = useState([]);

  const {
    reset,
    register,
    errors,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm({});

  useEffect(() => {
    setUnavailableDates(
      variants
        ?.map((variant) =>
          variant.quantity_available === 0 ? variant.usage_date : null
        )
        .filter((n) => n)
    );
  }, [variants]);

  useEffect(() => {
    reset({
      start_date: "",
      end_date: "",
      num_available: 0,
      product_id: productID,
    });
  }, [reset]);

  function tileDisabled({ date }) {
    return isPast(date);
  }

  function handleAvailabilityCalendarChange(value) {
    setValue("start_date", format(value[0], "yyyy-MM-dd"));
    setValue("end_date", format(value[1], "yyyy-MM-dd"));
    setAvailabilityCalendarValue(value);
  }

  const tileClassName = useCallback(
    ({ date }) => {
      if (
        unavailableDates.find(
          (dDate) => differenceInCalendarDays(parseISO(dDate), date) === 0
        )
      )
        return styles.blocked;
    },
    [unavailableDates]
  );

  const categories = ["Available", "Blocked"];

  return (
    <Modal open={showModal} setOpen={setShowModal}>
      <ModalTitle>Edit availability</ModalTitle>
      <form onSubmit={handleSubmit(handleSave)} noValidate>
        <ModalBody>
          <HiddenField name="product_id" register={register} />
          <HiddenField name="num_available" register={register} />
          <div className="grid gap-6">
            <Tab.Group
              defaultIndex={1}
              onChange={(index) => {
                setValue("num_available", index === 0 ? 10 : 0);
              }}
              className="border-b border-gray-200"
            >
              <Tab.List className="flex -mb-px">
                {categories.map((category) => (
                  <Tab
                    key={category}
                    className={({ selected }) =>
                      cn(
                        "border-transparent w-1/2 pb-4 px-1 text-center border-b-2 font-medium text-sm",
                        selected
                          ? "border-blue-500 text-blue-600"
                          : "text-gray-500 hover:text-gray-700 hover:border-gray-300"
                      )
                    }
                  >
                    {category === "Available"
                      ? "Make available"
                      : "Block dates"}
                  </Tab>
                ))}
              </Tab.List>
            </Tab.Group>
            <div className={styles.calendar}>
              <Calendar
                onChange={handleAvailabilityCalendarChange}
                value={availabilityCalendarValue}
                selectRange={true}
                calendarType="US"
                defaultView="month"
                minDetail="month"
                tileDisabled={tileDisabled}
                tileClassName={tileClassName}
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <InputText
                name="start_date"
                type="date"
                errors={errors}
                register={register}
              />
              <InputText
                name="end_date"
                type="date"
                errors={errors}
                register={register}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button type="submit" isSubmitting={isSubmitting}>
            Save changes
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
